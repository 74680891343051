<template>
  <div
    class="wrap__content"
    v-bind:class="{ 'wrap__content--placeholder': !user || !applications }"
  >
    <div class="page__placeholder" v-if="!user || !applications">
      <div class="form__block--placeholder">
        <div class="form__placeholder--circle">
          <div class="placeholder__circle">
            <svg width="73" height="73" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><circle cx="36.5" cy="36" r="24" stroke="#FFF7E1" stroke-width="2"/><g filter="url(#filter0_d_22_5658)"><path d="M16.16 23A24.208 24.208 0 0012 36.605C12 50.078 22.969 61 36.5 61S61 50.078 61 36.605c0-1.736-.182-3.43-.528-5.064" stroke="#FFCC47" stroke-width="4" stroke-linecap="round"/></g><defs><filter id="filter0_d_22_5658" x="5" y="16" width="63" height="52" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset/><feGaussianBlur stdDeviation="2.5"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix values="0 0 0 0 1 0 0 0 0 0.722826 0 0 0 0 0 0 0 0 0.5 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_22_5658"/><feBlend in="SourceGraphic" in2="effect1_dropShadow_22_5658" result="shape"/></filter></defs></svg>
          </div>
        </div>
        <div class="form__placeholder--logo">
          <img src="../../assets/img/logo.svg" alt="" />
        </div>
      </div>
    </div>
    <div class="content__page--block" v-if="user && applications">
      <div class="content__page">
        <!-- content__page--margintop -->

        <div class="container">
          <div class="section__welcome--block">
            <div class="row">
              <div class="col-xl-12">
                <div class="section__welcome section__welcome--dashboard">
                  <div class="section__welcome--row">
                    <div class="section__welcome--left">
                      <div class="section__welcome--title">
                        {{ $t('well_come') }}
                      </div>
                      <div class="section__welcome--info">
                        <div class="welcome__info--left">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2M12 11a4 4 0 100-8 4 4 0 000 8z" stroke="#0E1839" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="welcome__info--right">
                          <div class="welcome__info--name">
                            <span
                              >{{ user.data.surname }}
                              {{ user.data.name }}</span
                            >
                            <a
                              :href="'/' + $i18n.locale + '/account/edit'"
                              class="account--link"
                            >
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11 4H4a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2v-7" stroke="#FFCC47" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M18.5 2.5a2.121 2.121 0 113 3L12 15l-4 1 1-4 9.5-9.5z" stroke="#FFCC47" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                            </a>
                          </div>
                          <div class="welcome__info--position">
                            {{ user.data.roles.name }}
                          </div>
                        </div>
                      </div>

                      <div class="administrator__create_account">
                        <a href="#" class="administrator__create_account--link">
                          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M13.5 1.5l3 3M5.625 15.375L14.25 6.75l-3-3-8.625 8.625L1.5 16.5l4.125-1.125z" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                          Создать учетную запись
                        </a>
                      </div>
                    </div>
                    <div class="section__welcome--right">
                      <div class="section__welcome--info dashboard--welcome">
                        <div class="row">
                          <div class="col-lg-4">
                            <div class="administrator__dashboard--item">
                              <div class="administrator__dashboard--block">
                                <div class="administrator__dashboard--circle">
                                  <div
                                    class="administrator__dashboard--border"
                                  ></div>
                                  <div class="administrator__dashboard--number">
                                    157
                                  </div>
                                  <div class="administrator__dashboard--icon">
                                    <img
                                      src="../../assets/img/dashboard-received.svg"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="administrator__dashboard--text">
                                {{ $t('labels.applications_received') }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="administrator__dashboard--item">
                              <div class="administrator__dashboard--block">
                                <div class="administrator__dashboard--circle">
                                  <div
                                    class="administrator__dashboard--border"
                                  ></div>
                                  <div class="administrator__dashboard--number">
                                    91
                                  </div>
                                  <div class="administrator__dashboard--icon">
                                    <img
                                      src="../../assets/img/dashboard-approved.svg"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="administrator__dashboard--text">
                                {{ $t('labels.approved') }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="administrator__dashboard--item">
                              <div class="administrator__dashboard--block">
                                <div class="administrator__dashboard--circle">
                                  <div
                                    class="administrator__dashboard--border"
                                  ></div>
                                  <div class="administrator__dashboard--number">
                                    66
                                  </div>
                                  <div class="administrator__dashboard--icon">
                                    <img
                                      src="../../assets/img/dashboard-rejected.svg"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="administrator__dashboard--text">
                                {{ $t('labels.applications_rejected') }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="registry__table">
            <div class="registry__tabs">
              <div
                class="registry__tabs--item"
                v-bind:class="{ 'registry__tabs--active': Categories == 'all' }"
                @click="categoriesTab('all')"
              >
                <a href="#">{{ $t('labels.all_apps') }}</a>
              </div>
              <div
                class="registry__tabs--item"
                v-bind:class="{
                  'registry__tabs--active': Categories == 'foreign',
                }"
                @click="categoriesTab('foreign')"
              >
                <a href="#">{{ $t('menu.foreign') }}</a>
              </div>
              <div
                class="registry__tabs--item"
                v-bind:class="{
                  'registry__tabs--active': Categories == 'kids',
                }"
                @click="categoriesTab('kids')"
              >
                <a href="#">{{ $t('labels.minor_passengers') }}</a>
              </div>
            </div>

            <div class="table__block--block table__block--shadow">
              <div class="table__block--top">
                <div class="table__block--title">
                  {{ $t('labels.register_received_applications') }}
                </div>
              </div>

              <div class="table__block--content">
                <div class="table__block--filter">
                  <div class="table__filter--search">
                    <label class="search__label">
                      <input
                        type="text"
                        value=""
                        name="filter_search"
                        v-model="filters.search"
                        :placeholder="$t('labels.search_by_text')"
                      />
                      <button>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11 19a8 8 0 100-16 8 8 0 000 16zM21 21l-4.35-4.35" stroke="#0E1839" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </button>
                    </label>
                  </div>
                  <div class="table__filter--order">
                    <multiselect
                      v-model="filterCategories"
                      :options="orderList"
                      label="name"
                      :clearOnSelect="false"
                      :clear-on-select="false"
                      :placeholder="$t('labels.status')"
                      :hideSelected="true"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">{{
                        option.name
                      }}</template>
                    </multiselect>
                  </div>
                </div>
                <!-- :items="applications.data" -->
                <v-data-table
                  :headers="headers"
                  :items="filteredlist"
                  :loading="loading"
                  :options.sync="options"
                  :footer-props="{
                    'items-per-page-options': [5, 10, 20, 30, 40, 50],
                  }"
                >
                  <template slot="applications.data" slot-scope="props">
                    <td>{{ props.id }}</td>
                    <td>{{ props.name }}</td>
                    <td>{{ props.item.date }}</td>
                    <td>{{ props.item.time }}</td>
                    <td>{{ props.item.from }}</td>
                  </template>
                  <template v-slot:item.action="{ item }">
                    <a
                      class="tb__table--btn"
                      v-if="item.category_id == 'foreign'"
                      :href="
                        '/' +
                        $i18n.locale +
                        '/administrator/tourist-views/' +
                        item.id
                      "
                    >
                      <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M.875 10.5s3.5-7 9.625-7 9.625 7 9.625 7-3.5 7-9.625 7-9.625-7-9.625-7z" stroke="#FFCC47" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M10.5 13.125a2.625 2.625 0 100-5.25 2.625 2.625 0 000 5.25z" stroke="#FFCC47" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </a>
                    <a
                      class="tb__table--btn"
                      v-if="item.category_id == 'kids'"
                      :href="
                        '/' +
                        $i18n.locale +
                        '/administrator/kids-views/' +
                        item.id
                      "
                    >
                      <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M.875 10.5s3.5-7 9.625-7 9.625 7 9.625 7-3.5 7-9.625 7-9.625-7-9.625-7z" stroke="#FFCC47" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M10.5 13.125a2.625 2.625 0 100-5.25 2.625 2.625 0 000 5.25z" stroke="#FFCC47" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </a>
                  </template>
                  <v-alert slot="no-results" :value="true" color="error">
                    {{ $t('empty_search_results', [filters.search]) }}
                  </v-alert>
                </v-data-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from '../../boot/axios'
import Multiselect from 'vue-multiselect'

export default {
  name: 'Account',
  components: {
    Multiselect,
  },
  data() {
    return {
      applications: null,
      user: null,

      orderList: [
        {
          status: 'all',
          name: this.$t('labels.all'),
        },
        {
          status: 'sent-by',
          name: this.$t('labels.received'),
        },
        {
          status: 'registered',
          name: this.$t('labels.registered'),
        },
        {
          status: 'resend',
          name: this.$t('labels.resend'),
        },
        {
          status: 'denied',
          name: this.$t('labels.denied'),
        },
        {
          status: 'in-work',
          name: this.$t('labels.in-work'),
        },
        {
          status: 'approved',
          name: this.$t('labels.approved'),
        },
        {
          status: 'to-be-agreed',
          name: this.$t('labels.to-be-agreed'),
        },
        {
          status: 'paid',
          name: this.$t('labels.paid'),
        },
      ],
      Categories: 'all',
      filterCategories: {
        status: 'all',
        name: this.$t('labels.all'),
      },
      filters: {
        search: '',
      },
      page: 0,
      totalPassengers: 0,
      loading: false,
      options: {
        itemsPerPage: 5,
        page: 1,
      },
      headers: [
        { text: 'ID', value: 'id' },
        { text: this.$t('labels.full_tour'), value: 'name' },
        { text: this.$t('labels.app_view'), value: 'type', width: '200px' },
        { text: this.$t('labels.date'), value: 'date' },
        { text: this.$t('labels.time'), value: 'time' },
        { text: this.$t('labels.app_status'), value: 'status' },
        { text: this.$t('labels.action'), value: 'action', align: 'center', sortable: false },
      ],
    }
  },
  computed: {
    filteredlist() {
      // return this.itemsList.filter((d) => {
      return this.applications.data.filter((d) => {
        if (
          d.name.toLowerCase().includes(this.filters.search.toLowerCase()) &&
          (this.filterCategories.status == 'all' ||
            d.status == this.filterCategories.status) &&
          (this.Categories == 'all' || d.category_id == this.Categories)
        ) {
          return true
        }
      })
    },
  },
  methods: {
    categoriesTab(category) {
      this.Categories = category
    },
    filterTab(category) {
      this.filterCategories = category
    },
  },
  beforeCreate() {
    if (!localStorage.token) {
      this.$router.push('/ru/login')
    }

    api.get('user').then((response) => {
      if (response.data) {
        this.user = response.data
      }
    })

    api.get('applications').then((response) => {
      // console.log(response);
      if (response.data) {
        this.applications = response.data
      }
    })
  },
}
</script>

<style scoped></style>
