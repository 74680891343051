<template>
  <div
    class="wrap__content"
    v-bind:class="{ 'wrap__content--placeholder': !user || !applications }"
  >
    <div class="page__placeholder" v-if="!user || !applications">
      <div class="form__block--placeholder">
        <div class="form__placeholder--circle">
          <div class="placeholder__circle">
            <svg width="73" height="73" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><circle cx="36.5" cy="36" r="24" stroke="#FFF7E1" stroke-width="2"/><g filter="url(#filter0_d_22_5658)"><path d="M16.16 23A24.208 24.208 0 0012 36.605C12 50.078 22.969 61 36.5 61S61 50.078 61 36.605c0-1.736-.182-3.43-.528-5.064" stroke="#FFCC47" stroke-width="4" stroke-linecap="round"/></g><defs><filter id="filter0_d_22_5658" x="5" y="16" width="63" height="52" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset/><feGaussianBlur stdDeviation="2.5"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix values="0 0 0 0 1 0 0 0 0 0.722826 0 0 0 0 0 0 0 0 0.5 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_22_5658"/><feBlend in="SourceGraphic" in2="effect1_dropShadow_22_5658" result="shape"/></filter></defs></svg>
          </div>
        </div>
        <div class="form__placeholder--logo">
          <img src="../../assets/img/logo.svg" alt="" />
        </div>
      </div>
    </div>
    <div class="content__page--block" v-if="user && applications">
      <div class="content__page">
        <!-- content__page--margintop -->

        <div class="container">
          <div class="registry__table">
            <div class="table__block--block table__block--shadow">
              <div class="table__block--top">
                <div class="table__block--title">Подтверждение регистрации</div>
              </div>

              <div class="table__block--content">
                <div class="table__block--filter">
                  <div class="table__filter--search">
                    <label class="search__label">
                      <input
                        type="text"
                        value=""
                        name="filter_search"
                        v-model="filters.search"
                        :placeholder="$t('labels.search_by_text')"
                      />
                      <button>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11 19a8 8 0 100-16 8 8 0 000 16zM21 21l-4.35-4.35" stroke="#0E1839" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </button>
                    </label>
                  </div>
                  <div class="table__filter--order">
                    <multiselect
                      v-model="filterCategories"
                      :options="orderList"
                      label="name"
                      :clearOnSelect="false"
                      :clear-on-select="false"
                      :placeholder="$t('labels.status')"
                      :hideSelected="true"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">{{
                        option.name
                      }}</template>
                      <template slot="noOptions"> Пусто </template>
                      <template slot="noResult"> Результат не найден </template>
                    </multiselect>
                  </div>
                </div>
                <!-- :items="applications.data" -->
                <v-data-table
                  :headers="headers"
                  :items="filteredlist"
                  :loading="loading"
                  :options.sync="options"
                  :footer-props="{
                    'items-per-page-options': [5, 10, 20, 30, 40, 50],
                  }"
                >
                  <template slot="applications.data" slot-scope="props">
                    <td>{{ props.id }}</td>
                    <td>{{ props.name }}</td>
                    <td>{{ props.item.date }}</td>
                    <td>{{ props.item.time }}</td>
                    <td>{{ props.item.from }}</td>
                  </template>
                  <template v-slot:item.action="{ item }">
                    <div>
                      <a
                        class="tb__table--btn"
                        @click="$modal.show('modal__registration' + item.id)"
                      >
                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M.875 10.5s3.5-7 9.625-7 9.625 7 9.625 7-3.5 7-9.625 7-9.625-7-9.625-7z" stroke="#FFCC47" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M10.5 13.125a2.625 2.625 0 100-5.25 2.625 2.625 0 000 5.25z" stroke="#FFCC47" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </a>
                    </div>
                    <modal
                      class="modal__block modal__block--middle modal__block--registration"
                      :name="'modal__registration' + item.id"
                      :width="680"
                      :min-width="680"
                      height="auto"
                    >
                      <div class="modal__block--wrapper">
                        <div
                          class="modal__block--close"
                          @click="$modal.hide('modal__registration' + item.id)"
                        >
                          <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M31.5 10.5l-21 21M10.5 10.5l21 21" stroke="#0E1839" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="modal__wrapper">
                          <div class="modal__title">Данные пользователя</div>
                          <div class="modal__content">
                            <div class="modal__form">
                              <div class="form__block--line">
                                <div class="form__block--input">
                                  <div class="form__block--title">
                                    {{ $t('registration.input.name') }}
                                  </div>
                                  <input
                                    type="text"
                                    class="input-linck"
                                    disabled
                                  />
                                  <!-- input--error -->
                                  <span class="input-required">{{
                                    $t('registration.required.error')
                                  }}</span>
                                </div>
                              </div>
                              <div class="form__block--line">
                                <div class="form__block--input">
                                  <div class="form__block--title">
                                    {{ $t('registration.input.surname') }}
                                  </div>
                                  <input
                                    type="text"
                                    class="input-linck"
                                    disabled
                                  />
                                  <!-- input--error -->
                                  <span class="input-required">{{
                                    $t('registration.required.error')
                                  }}</span>
                                </div>
                              </div>
                              <div class="form__block--line">
                                <div class="form__block--input">
                                  <div class="form__block--title">
                                    {{ $t('registration.input.organization') }}
                                  </div>
                                  <input
                                    type="text"
                                    class="input-linck"
                                    disabled
                                  />
                                  <!-- input--error -->
                                  <span class="input-required">{{
                                    $t('registration.required.error')
                                  }}</span>
                                </div>
                              </div>
                              <div class="form__block--line">
                                <div class="form__block--input">
                                  <div class="form__block--title">
                                    {{ $t('registration.input.bin') }}
                                  </div>
                                  <input
                                    type="text"
                                    class="input-linck"
                                    disabled=""
                                  />
                                  <!-- input--error -->
                                  <span class="input-required">{{
                                    $t('registration.required.error')
                                  }}</span>
                                </div>
                              </div>
                              <div class="form__block--line">
                                <div class="form__block--input">
                                  <div class="form__block--title">
                                    {{ $t('registration.input.adres') }}
                                  </div>
                                  <input
                                    type="text"
                                    class="input-linck"
                                    disabled=""
                                  />
                                  <!-- input--error -->
                                  <span class="input-required">{{
                                    $t('registration.required.error')
                                  }}</span>
                                </div>
                              </div>
                              <div class="form__block--line">
                                <div class="form__block--input">
                                  <div class="form__block--title">
                                    {{ $t('registration.input.phone') }}
                                  </div>
                                  <TheMask
                                    class="input-linck"
                                    :mask="['+7 (###) #### ## ##']"
                                    disabled=""
                                  ></TheMask>
                                  <!-- input--error -->
                                  <span class="input-required">{{
                                    $t('registration.required.error')
                                  }}</span>
                                </div>
                              </div>
                              <div class="form__block--line">
                                <div class="form__block--input">
                                  <div class="form__block--title">
                                    {{ $t('registration.input.email') }}
                                  </div>
                                  <input
                                    type="text"
                                    class="input-linck"
                                    disabled=""
                                  />
                                  <!-- input--error -->
                                  <span class="input-required">{{
                                    $t('registration.required.error')
                                  }}</span>
                                </div>
                              </div>

                              <div
                                class="section--link modal__wrapper--footer modal__wrapper--center"
                              >
                                <button class="next__stage input__button">
                                  Подтвердить
                                </button>
                                <button
                                  class="next__stage input__button btn--error"
                                >
                                  Отклонить
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </modal>
                  </template>
                  <v-alert slot="no-results" :value="true" color="error">
                    {{ $t('empty_search_results', [filters.search]) }}
                  </v-alert>
                </v-data-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from '../../boot/axios'
import Multiselect from 'vue-multiselect'
import { TheMask } from 'vue-the-mask'

export default {
  components: {
    Multiselect,
    TheMask,
  },
  data() {
    return {
      applications: null,
      user: null,

      orderList: [
        {
          status: 'all',
          name: this.$t('labels.all'),
        },
        {
          status: 'Отправлено',
          name: this.$t('labels.received'),
        },
        {
          status: 'rejected',
          name: this.$t('labels.denied'),
        },
        {
          status: 'approved ',
          name: this.$t('labels.approved'),
        },
      ],
      Categories: 'all',
      filterCategories: {
        status: 'all',
        name: this.$t('labels.all'),
      },
      filters: {
        search: '',
      },
      page: 0,
      totalPassengers: 0,
      loading: false,
      options: {
        itemsPerPage: 5,
        page: 1,
      },
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'ФИО', value: 'name' },
        { text: this.$t('registration.input.phone'), value: 'phone', width: '200px' },
        { text: this.$t('registration.input.email'), value: 'email' },
        { text: this.$t('registration.input.organization'), value: 'organization' },
        { text: this.$t('labels.action'), value: 'action', align: 'center', sortable: false },
      ],
    }
  },
  computed: {
    filteredlist() {
      // return this.itemsList.filter((d) => {

      return this.applications.data.filter((d) => {
        if (
          d.view.name
            .toLowerCase()
            .includes(this.filters.search.toLowerCase()) &&
          (this.filterCategories.status == 'all' ||
            d.status == this.filterCategories.status) &&
          (this.Categories == 'all' || d.category_id == this.Categories)
        ) {
          return true
        }
      })
    },
  },
  methods: {
    categoriesTab(category) {
      this.Categories = category
    },
    filterTab(category) {
      this.filterCategories = category
    },
  },
  beforeCreate() {
    if (!localStorage.token) {
      this.$router.push('/ru/login')
    }

    api.get('user').then((response) => {
      if (response.data) {
        this.user = response.data
      }
    })

    api.get('applications').then((response) => {
      // console.log(response);
      if (response.data) {
        this.applications = response.data
      }
    })
  },
}
</script>

<style scoped></style>
