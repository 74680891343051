<template>
  <div
    class="wrap__content"
    v-bind:class="{ 'wrap__content--placeholder': !user || !application }"
  >
    <div class="page__placeholder" v-if="!user || !application">
      <div class="form__block--placeholder">
        <div class="form__placeholder--circle">
          <div class="placeholder__circle">
            <svg width="73" height="73" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><circle cx="36.5" cy="36" r="24" stroke="#FFF7E1" stroke-width="2"/><g filter="url(#filter0_d_22_5658)"><path d="M16.16 23A24.208 24.208 0 0012 36.605C12 50.078 22.969 61 36.5 61S61 50.078 61 36.605c0-1.736-.182-3.43-.528-5.064" stroke="#FFCC47" stroke-width="4" stroke-linecap="round"/></g><defs><filter id="filter0_d_22_5658" x="5" y="16" width="63" height="52" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset/><feGaussianBlur stdDeviation="2.5"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix values="0 0 0 0 1 0 0 0 0 0.722826 0 0 0 0 0 0 0 0 0.5 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_22_5658"/><feBlend in="SourceGraphic" in2="effect1_dropShadow_22_5658" result="shape"/></filter></defs></svg>
          </div>
        </div>
        <div class="form__placeholder--logo">
          <img src="../../../../assets/img/logo.svg" alt="" />
        </div>
      </div>
    </div>
    <div class="content__page--block" v-if="user && application">
      <div class="content__page content__page--margintop">
        <div class="container">
          <div class="content__page--back">
            <a :href="'/' + $i18n.locale + '/account/'">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19 12H5M12 19l-7-7 7-7" stroke="#0E1839" stroke-linecap="round" stroke-linejoin="round"/></svg>
              {{ $t('system.back') }}
            </a>
          </div>

          <div class="content__title--block">
            <div class="section__title--mini">
              Заявление на субсидирование затрат туроператоров в сфере въездного
              туризма
            </div>
            <div class="content__title section__title">
              <strong>за каждого иностранного туриста</strong>
            </div>
          </div>

          <div class="add_tourist--section add_tourist--section-transition">
            <div class="add add_tourist--block">
              <div class="add_tourist__section--title">
                Данные иностранного туриста
              </div>
              <div class="table__block--style">
                <v-data-table
                  :headers="headers"
                  :items="userDataSave"
                  :loading="loading"
                  :options.sync="options"
                  hide-default-footer
                >
                </v-data-table>
              </div>
            </div>
          </div>

          <div class="add_tourist--section">
            <div class="add_tourist__section--title">
              Подтверждающие документы
            </div>

            <div class="add_tourist--filelist">
              <div class="fileitem--col">
                <div
                  class="add_tourist--fileitem"
                  :class="file_document_1.class"
                >
                  <div class="fileitem__block">
                    <div class="fileitem__block--title">
                      Копия документа, удостоверяющего личность иностранного
                      туриста
                    </div>

                    <div
                      class="fileitem__block--file"
                      v-if="file_document_1.name"
                    >
                      <div class="fileitem__block--icon">
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </div>
                      <div class="fileitem__block--text">
                        {{ file_document_1.name }}
                      </div>
                    </div>
                    <div class="fileitem__block--file" v-else>
                      <div class="fileitem__block--icon">
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19 14.563l-6.146 6.243A3.984 3.984 0 0110.014 22a3.984 3.984 0 01-2.838-1.194A4.11 4.11 0 016 17.922a4.11 4.11 0 011.176-2.883l6.146-6.243A2.656 2.656 0 0115.215 8c.71 0 1.39.286 1.892.796a2.74 2.74 0 01.784 1.923 2.74 2.74 0 01-.784 1.922l-6.152 6.242a1.328 1.328 0 01-1.893 0 1.37 1.37 0 010-1.922l5.678-5.76" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </div>
                      <div class="fileitem__block--text">
                        Загрузить (doc, pdf, excel)
                      </div>
                    </div>
                  </div>
                  <span
                    class="fileitem__block--help"
                    v-tooltip.top-center="
                      'Договор на туристское обслуживание, заключенного между туроператором и иностранным туристом, составленного  в соответствии с приказом Министра по инвестициям  и развитию Республики Казахстан от 30 января 2015 года  № 81 «Об утверждении типового договора на туристское  обслуживание»'
                    "
                    >?</span
                  >
                </div>
              </div>
              <div class="fileitem--col">
                <div
                  class="add_tourist--fileitem"
                  :class="file_document_2.class"
                >
                  <div class="fileitem__block">
                    <div class="fileitem__block--title">
                      Договор на туристское обслуживание, с иностранным туристом
                    </div>

                    <div
                      class="fileitem__block--file"
                      v-if="file_document_2.name"
                    >
                      <div class="fileitem__block--icon">
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </div>
                      <div class="fileitem__block--text">
                        {{ file_document_2.name }}
                      </div>
                    </div>
                    <div class="fileitem__block--file" v-else>
                      <div class="fileitem__block--icon">
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19 14.563l-6.146 6.243A3.984 3.984 0 0110.014 22a3.984 3.984 0 01-2.838-1.194A4.11 4.11 0 016 17.922a4.11 4.11 0 011.176-2.883l6.146-6.243A2.656 2.656 0 0115.215 8c.71 0 1.39.286 1.892.796a2.74 2.74 0 01.784 1.923 2.74 2.74 0 01-.784 1.922l-6.152 6.242a1.328 1.328 0 01-1.893 0 1.37 1.37 0 010-1.922l5.678-5.76" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </div>
                      <div class="fileitem__block--text">
                        Загрузить (doc, pdf, excel)
                      </div>
                    </div>
                  </div>
                  <span
                    class="fileitem__block--help"
                    v-tooltip.top-center="
                      'Договор на туристское обслуживание, заключенного между туроператором и иностранным туристом, составленного  в соответствии с приказом Министра по инвестициям  и развитию Республики Казахстан от 30 января 2015 года  № 81 «Об утверждении типового договора на туристское  обслуживание»'
                    "
                    >?</span
                  >
                </div>
              </div>
              <div class="fileitem--col">
                <div
                  class="add_tourist--fileitem"
                  :class="file_document_3.class"
                >
                  <div class="fileitem__block">
                    <div class="fileitem__block--title">
                      Документ, подтверждающий оплату туристского продукта по
                      Договору
                    </div>

                    <div
                      class="fileitem__block--file"
                      v-if="file_document_3.name"
                    >
                      <div class="fileitem__block--icon">
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </div>
                      <div class="fileitem__block--text">
                        {{ file_document_3.name }}
                      </div>
                    </div>
                    <div class="fileitem__block--file" v-else>
                      <div class="fileitem__block--icon">
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19 14.563l-6.146 6.243A3.984 3.984 0 0110.014 22a3.984 3.984 0 01-2.838-1.194A4.11 4.11 0 016 17.922a4.11 4.11 0 011.176-2.883l6.146-6.243A2.656 2.656 0 0115.215 8c.71 0 1.39.286 1.892.796a2.74 2.74 0 01.784 1.923 2.74 2.74 0 01-.784 1.922l-6.152 6.242a1.328 1.328 0 01-1.893 0 1.37 1.37 0 010-1.922l5.678-5.76" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </div>
                      <div class="fileitem__block--text">
                        Загрузить (doc, pdf, excel)
                      </div>
                    </div>
                  </div>
                  <span
                    class="fileitem__block--help"
                    v-tooltip.top-center="
                      'Договор на туристское обслуживание, заключенного между туроператором и иностранным туристом, составленного  в соответствии с приказом Министра по инвестициям  и развитию Республики Казахстан от 30 января 2015 года  № 81 «Об утверждении типового договора на туристское  обслуживание»'
                    "
                    >?</span
                  >
                </div>
              </div>

              <div class="fileitem--col">
                <div
                  class="add_tourist--fileitem"
                  :class="file_document_4.class"
                >
                  <div class="fileitem__block">
                    <div class="fileitem__block--title">
                      Факт пересечения государственной границы РК туриста
                    </div>

                    <div
                      class="fileitem__block--file"
                      v-if="file_document_4.name"
                    >
                      <div class="fileitem__block--icon">
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </div>
                      <div class="fileitem__block--text">
                        {{ file_document_4.name }}
                      </div>
                    </div>
                    <div class="fileitem__block--file" v-else>
                      <div class="fileitem__block--icon">
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19 14.563l-6.146 6.243A3.984 3.984 0 0110.014 22a3.984 3.984 0 01-2.838-1.194A4.11 4.11 0 016 17.922a4.11 4.11 0 011.176-2.883l6.146-6.243A2.656 2.656 0 0115.215 8c.71 0 1.39.286 1.892.796a2.74 2.74 0 01.784 1.923 2.74 2.74 0 01-.784 1.922l-6.152 6.242a1.328 1.328 0 01-1.893 0 1.37 1.37 0 010-1.922l5.678-5.76" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </div>
                      <div class="fileitem__block--text">
                        Загрузить (doc, pdf, excel)
                      </div>
                    </div>
                  </div>
                  <span
                    class="fileitem__block--help"
                    v-tooltip.top-center="
                      'Договор на туристское обслуживание, заключенного между туроператором и иностранным туристом, составленного  в соответствии с приказом Министра по инвестициям  и развитию Республики Казахстан от 30 января 2015 года  № 81 «Об утверждении типового договора на туристское  обслуживание»'
                    "
                    >?</span
                  >
                </div>
              </div>
              <div class="fileitem--col">
                <div
                  class="add_tourist--fileitem"
                  :class="file_document_5.class"
                >
                  <div class="fileitem__block">
                    <div class="fileitem__block--title">
                      Копии билета или электронного билета, маршрутной квитанции и пассажирского купона или справки-подтверждения об использовании билета авиакомпании на несовершеннолетнего пассажира
                    </div>

                    <div
                      class="fileitem__block--file"
                      v-if="file_document_5.name"
                    >
                      <div class="fileitem__block--icon">
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </div>
                      <div class="fileitem__block--text">
                        {{ file_document_5.name }}
                      </div>
                    </div>
                    <div class="fileitem__block--file" v-else>
                      <div class="fileitem__block--icon">
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19 14.563l-6.146 6.243A3.984 3.984 0 0110.014 22a3.984 3.984 0 01-2.838-1.194A4.11 4.11 0 016 17.922a4.11 4.11 0 011.176-2.883l6.146-6.243A2.656 2.656 0 0115.215 8c.71 0 1.39.286 1.892.796a2.74 2.74 0 01.784 1.923 2.74 2.74 0 01-.784 1.922l-6.152 6.242a1.328 1.328 0 01-1.893 0 1.37 1.37 0 010-1.922l5.678-5.76" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </div>
                      <div class="fileitem__block--text">
                        Загрузить (doc, pdf, excel)
                      </div>
                    </div>
                  </div>
                  <span
                    class="fileitem__block--help"
                    v-tooltip.top-center="
                      'Договор на туристское обслуживание, заключенного между туроператором и иностранным туристом, составленного  в соответствии с приказом Министра по инвестициям  и развитию Республики Казахстан от 30 января 2015 года  № 81 «Об утверждении типового договора на туристское  обслуживание»'
                    "
                    >?</span
                  >
                </div>
              </div>
            </div>

            <div class="form__block--selectline">
              <div class="row">
                <div class="col-xl-5">
                  <div class="form__block--line">
                    <div class="form__block--input">
                      <div class="form__block--title">
                        Сведения об отсутствии/наличии задолженностей
                      </div>
                      <input
                        type="text"
                        class="input-linck input-linck--white"
                        placeholder="Сведения о задолженностей"
                        value="Документ 21.05.2021.word"
                        disabled=""
                      />
                      <!-- input--error -->
                      <span class="input-required">{{
                        $t('registration.required.error')
                      }}</span>
                      <!--
												<div class="subsidizing__banner--button section--link">
													<a class="link--active">{{ $t('commands.add') }} +</a>
												</div>
												-->
                    </div>
                  </div>
                </div>
                <div class="col-xl-5">
                  <div class="form__block--line">
                    <div class="form__block--input">
                      <div class="form__block--title">{{ $t('labels.checkingAccount') }}</div>
                      <input
                        type="text"
                        class="input-linck input-linck--white"
                        :placeholder="$t('labels.checkingAccount')"
                        value="*2131 *3123 *4345"
                        disabled=""
                      />
                      <!-- input--error -->
                      <span class="input-required">{{
                        $t('registration.required.error')
                      }}</span>
                      <!--
												<div class="subsidizing__banner--button section--link">
													<a class="link--active">{{ $t('commands.add') }} +</a>
												</div>
												-->
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form__block--history">
              <div class="form__block--title">История всех действий</div>
              <div class="row">
                <!--

									<div class="col-lg-12">
										<div class="history__item history__item--new">
											<div class="history__item--date">
												<span>27.01.2021</span> <span class="history__item--title">Возвращено с комментариями</span>
											</div>
											<div class="history__item--text">
												Документ не правильно оформлен, факт пересечения государственной границы РК туриста
											</div>
										</div>
									</div>

									-->

                <div
                  class="col-lg-12"
                  v-for="route in application.data.routes"
                  v-if="route.message"
                >
                  <div class="history__item">
                    <div class="history__item--date">
                      <span
                        >{{ route.created_at.substr(8, 2) }}.{{
                          route.created_at.substr(5, 2)
                        }}.{{ route.created_at.substr(0, 4) }}</span
                      >
                      <span class="history__item--title">Комментарий</span>
                    </div>
                    <div class="history__item--text">
                      {{ route.message }}
                    </div>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="history__item">
                    <div class="history__item--date">
                      <span
                        >{{ application.data.created_at.substr(8, 2) }}.{{
                          application.data.created_at.substr(5, 2)
                        }}.{{ application.data.created_at.substr(0, 4) }}</span
                      >
                    </div>
                    <div class="history__item--text">
                      Отправка заявки №{{ application.data.id }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal__wrapper--footer">
              <div class="section--link">
                <button
                  class="next__stage input__button"
                  @click="resend"
                  v-if="application.data.status.slug == 'resend'"
                >
                  Изменить согласно комментариям
                </button>
                <button class="next__stage input__button" disabled="" v-else>
                  Изменить согласно комментариям
                </button>
                <button class="next__stage input__button" disabled="">
                  Отозвать заявление
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from '../../../../boot/axios'

export default {
  name: 'ViewsTourist',
  data() {
    return {
      user: null,
      application: null,
      fileDocuments: {
        name: null, //	'Название документа.doc',
        class: null, //	'add_tourist--success'
      },
      file_document_1: {
        name: null,
        class: null,
      },
      file_document_2: {
        name: null,
        class: null,
      },
      file_document_3: {
        name: null,
        class: null,
      },
      file_document_4: {
        name: null,
        class: null,
      },
      file_document_5: {
        name: null,
        class: null,
      },
      userDataSave: [
        {
          name: 'Рамазан',
          surname: 'Есенов',
          patronymic: '',
          region: 'Казахстан',
          passport: '6345453223',
          period: '18 ноября 2021',
        },
      ],

      loading: false,
      options: {
        itemsPerPage: 1,
      },
      headers: [
        { text: 'ФИО туриста', value: 'name' },
        { text: 'Номер паспорта', value: 'passport' },
        { text: 'Гражданство', value: 'region' },
        { text: 'Период пребывания ', value: 'period' },
      ],

      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      imgPhoto: null,
      loaderCapture: false,
      index_for_web: 0,
    }
  },
  methods: {
    resend() {
      const formData = new FormData()

      formData.append('_method', 'put')

      api
        .post(
          'applications/' + this.$route.params.application_id + '/resend',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )
        .then((response) => {
          if (response.data.code == 200) {
            alert('Все ok ғой')
          }
        })
    },
  },
  beforeCreate() {
    if (!localStorage.token) {
      this.$router.push('/ru/login')
    }

    api.get('user').then((response) => {
      if (response.data) {
        this.user = response.data
      }
    })

    api
      .get('applications/' + this.$route.params.application_id)
      .then((response) => {
        if (response.data) {
          this.application = response.data

          if (response.data.data.fields.file_document_1.status) {
            this.file_document_1.status =
              response.data.data.fields.file_document_1.status
            this.file_document_1.name =
              response.data.data.fields.file_document_1.name

            if (response.data.data.fields.file_document_1.status == 'success') {
              this.file_document_1.class = 'add_tourist--success'
            }

            if (response.data.data.fields.file_document_1.status == 'fail') {
              this.file_document_1.class = 'add_tourist--error'
            }
          }

          if (response.data.data.fields.file_document_2.status) {
            this.file_document_2.status =
              response.data.data.fields.file_document_2.status
            this.file_document_2.name =
              response.data.data.fields.file_document_2.name

            if (response.data.data.fields.file_document_2.status == 'success') {
              this.file_document_2.class = 'add_tourist--success'
            }

            if (response.data.data.fields.file_document_2.status == 'fail') {
              this.file_document_2.class = 'add_tourist--error'
            }
          }

          if (response.data.data.fields.file_document_3.status) {
            this.file_document_3.status =
              response.data.data.fields.file_document_3.status
            this.file_document_3.name =
              response.data.data.fields.file_document_3.name

            if (response.data.data.fields.file_document_3.status == 'success') {
              this.file_document_3.class = 'add_tourist--success'
            }

            if (response.data.data.fields.file_document_3.status == 'fail') {
              this.file_document_3.class = 'add_tourist--error'
            }
          }

          if (response.data.data.fields.file_document_4.status) {
            this.file_document_4.status =
              response.data.data.fields.file_document_4.status
            this.file_document_4.name =
              response.data.data.fields.file_document_4.name

            if (response.data.data.fields.file_document_4.status == 'success') {
              this.file_document_4.class = 'add_tourist--success'
            }

            if (response.data.data.fields.file_document_4.status == 'fail') {
              this.file_document_4.class = 'add_tourist--error'
            }
          }

          if (response.data.data.fields.file_document_5.status) {
            this.file_document_5.status =
              response.data.data.fields.file_document_5.status
            this.file_document_5.name =
              response.data.data.fields.file_document_5.name

            if (response.data.data.fields.file_document_5.status == 'success') {
              this.file_document_5.class = 'add_tourist--success'
            }

            if (response.data.data.fields.file_document_5.status == 'fail') {
              this.file_document_5.class = 'add_tourist--error'
            }
          }
        }
      })
  },
}
</script>

<style scoped></style>
