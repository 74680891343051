<template>
  <header>
    <div class="container">
      <div class="header__row">
        <div class="header__left">
          <router-link :to="'/' + $i18n.locale + '/'">
            <img
              src="../assets/img/logo.svg"
              alt=""
              style="width: 85px; height: auto"
            />
          </router-link>
        </div>
        <div class="header__right">
          <div class="header__menu">
            <ul class="menu">
              <li>
                <a :href="'/' + $i18n.locale + '/'">{{ $t('menu.home') }}</a>
              </li>
              <!--              <li><a :href="'/'+$i18n.locale+'/about'">{{ $t('menu.about') }}</a></li>-->
              <li class="menu__item--children">
                <a
                  ><!--  :href="'/'+$i18n.locale +'/'" -->
                  {{ $t('menu.subsiding') }}
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M5 7.5l5 5 5-5" stroke="#0E1839" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                </a>
                <ul class="menu--children">
                  <li>
                    <a
                      :href="
                        '/' +
                        $i18n.locale +
                        '/account/applications/kids-go-free/create'
                      "
                      >{{ $t('menu.kids') }}</a
                    >
                  </li>
                  <li>
                    <a
                      :href="'/' + $i18n.locale + '/subsiding/foreign-tourist'"
                      >{{ $t('menu.foreign') }}</a
                    >
                  </li>
                </ul>
              </li>
              <!--<li><a :href="'/'+$i18n.locale +'/'">{{ $t('menu.consultation') }}</a></li>-->
            </ul>
          </div>
          <div class="header__lang">
            <div class="header__lang--top">
              <div class="header__lang--text">
                {{ langOn }}
              </div>
              <div class="header__lang--arrow">
                <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M5 7.978L.67.485h8.66L5 7.978z" fill="#FFCE03"/></svg>
              </div>
            </div>
            <div class="header__lang--list">
              <ul>
                <li>
                  <a href="#" @click.prevent="setLocale('kz')">Қазақ</a>
                </li>
                <li>
                  <a href="#" @click.prevent="setLocale('ru')">Русский</a>
                </li>
                <li>
                  <a href="#" @click.prevent="setLocale('en')">English</a>
                </li>
              </ul>
            </div>
          </div>
          <div
            class="header__login"
            v-if="
              user &&
              ['tour-operator-entity', 'tour-operator-individual'].includes(
                user.role.slug,
              )
            "
          >
            <a
              :href="'/' + $i18n.locale + '/account'"
              class="header__login--top"
            >
              <div class="header__login--name">{{ $t('menu.sign') }}</div>
              <div
                class="header__login--icon"
                v-tooltip.bottom="{
                  content: $t('menu.sign'),
                  class: ['table__tooltip'],
                }"
              >
                <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg" style="float:left" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M35.133 6.85A19.986 19.986 0 0020.99.998 19.878 19.878 0 006.85 6.851 19.842 19.842 0 00.991 20.98a19.987 19.987 0 005.82 14.091c.271.272.728.027.659-.35a13.75 13.75 0 01-.229-2.502c0-5.7 3.493-10.6 8.453-12.677a.389.389 0 00.122-.638 7.468 7.468 0 01-2.32-5.682C13.63 9.32 16.79 6.15 20.695 6c4.27-.166 7.796 3.257 7.796 7.487a7.469 7.469 0 01-2.325 5.418.389.389 0 00.122.638c4.96 2.077 8.453 6.977 8.453 12.678 0 .854-.078 1.69-.228 2.5-.07.378.387.623.658.351a19.985 19.985 0 005.82-14.092c0-5.337-2.08-10.356-5.858-14.13z" fill="#0E1839"/></svg>
              </div>
            </a>
          </div>
          <div
            class="header__login"
            v-else-if="
              user &&
              [
                'administrator',
                'executor',
                'stationery',
                'accountant',
              ].includes(user.role.slug)
            "
          >
            <a
              :href="'/' + $i18n.locale + '/administration'"
              class="header__login--top"
            >
              <div class="header__login--name">{{ $t('menu.sign') }}</div>
              <div
                class="header__login--icon"
                v-tooltip.bottom="{
                  content: $t('menu.sign'),
                  class: ['table__tooltip'],
                }"
              >
                <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg" style="float:left" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M35.133 6.85A19.986 19.986 0 0020.99.998 19.878 19.878 0 006.85 6.851 19.842 19.842 0 00.991 20.98a19.987 19.987 0 005.82 14.091c.271.272.728.027.659-.35a13.75 13.75 0 01-.229-2.502c0-5.7 3.493-10.6 8.453-12.677a.389.389 0 00.122-.638 7.468 7.468 0 01-2.32-5.682C13.63 9.32 16.79 6.15 20.695 6c4.27-.166 7.796 3.257 7.796 7.487a7.469 7.469 0 01-2.325 5.418.389.389 0 00.122.638c4.96 2.077 8.453 6.977 8.453 12.678 0 .854-.078 1.69-.228 2.5-.07.378.387.623.658.351a19.985 19.985 0 005.82-14.092c0-5.337-2.08-10.356-5.858-14.13z" fill="#0E1839"/></svg>
              </div>
            </a>
          </div>
          <div class="header__login" v-else>
            <a :href="'/' + $i18n.locale + '/login'" class="header__login--top">
              <div class="header__login--name">{{ $t('menu.sign') }}</div>
              <div
                class="header__login--icon"
                v-tooltip.bottom="{
                  content: $t('menu.sign'),
                  class: ['table__tooltip'],
                }"
              >
                <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg" style="float:left" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M35.133 6.85A19.986 19.986 0 0020.99.998 19.878 19.878 0 006.85 6.851 19.842 19.842 0 00.991 20.98a19.987 19.987 0 005.82 14.091c.271.272.728.027.659-.35a13.75 13.75 0 01-.229-2.502c0-5.7 3.493-10.6 8.453-12.677a.389.389 0 00.122-.638 7.468 7.468 0 01-2.32-5.682C13.63 9.32 16.79 6.15 20.695 6c4.27-.166 7.796 3.257 7.796 7.487a7.469 7.469 0 01-2.325 5.418.389.389 0 00.122.638c4.96 2.077 8.453 6.977 8.453 12.678 0 .854-.078 1.69-.228 2.5-.07.378.387.623.658.351a19.985 19.985 0 005.82-14.092c0-5.337-2.08-10.356-5.858-14.13z" fill="#0E1839"/></svg>
              </div>
            </a>
          </div>
          <div class="header__burger">
            <div class="header__burger--click" @click="mobileActive">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3 6h18M3 12h18M3 18h18" stroke="#0E1839" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
  data: function () {
    return {
      mobileClick: false,
      user: localStorage.user ? JSON.parse(localStorage.user) : null,
    }
  },
  computed: {
    langOn: function () {
      var lang = this.$i18n.locale
      if (lang == 'ru') {
        lang = 'Русский'
      } else if (lang == 'kz') {
        lang = 'Қазақ'
      } else if (lang == 'en') {
        lang = 'English'
      }
      return lang
    },
  },
  methods: {
    mobileActive() {
      this.mobileClick = true
      // this.$emit("mobileActive",this.mobileClick);
      document.body.classList.add('mobile__active')
    },
    setLocale(locale) {
      if (this.$i18n.locale != locale) {
        this.$i18n.locale = locale
        localStorage.setItem('lang_locale', locale)
        this.$router.push({
          params: { lang: locale },
        })
      }
    },
  },
}
</script>

<style></style>
