<template>
  <div
    class="wrap__content"
    v-bind:class="{ 'wrap__content--placeholder': !user || !applications }"
  >
    <div class="page__placeholder" v-if="!user || !applications">
      <div class="form__block--placeholder">
        <div class="form__placeholder--circle">
          <div class="placeholder__circle">
            <svg width="73" height="73" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><circle cx="36.5" cy="36" r="24" stroke="#FFF7E1" stroke-width="2"/><g filter="url(#filter0_d_22_5658)"><path d="M16.16 23A24.208 24.208 0 0012 36.605C12 50.078 22.969 61 36.5 61S61 50.078 61 36.605c0-1.736-.182-3.43-.528-5.064" stroke="#FFCC47" stroke-width="4" stroke-linecap="round"/></g><defs><filter id="filter0_d_22_5658" x="5" y="16" width="63" height="52" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset/><feGaussianBlur stdDeviation="2.5"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix values="0 0 0 0 1 0 0 0 0 0.722826 0 0 0 0 0 0 0 0 0.5 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_22_5658"/><feBlend in="SourceGraphic" in2="effect1_dropShadow_22_5658" result="shape"/></filter></defs></svg>
          </div>
        </div>
        <div class="form__placeholder--logo">
          <img src="../../assets/img/logo.svg" alt="" />
        </div>
      </div>
    </div>
    <div class="content__page--block" v-if="user && applications">
      <notifications group="foo" position="bottom center" />

      <div class="content__page">
        <!-- content__page--margintop -->

        <div class="container">
          <div class="section__welcome--block">
            <div class="row">
              <div class="col-xl-12">
                <div class="section__welcome">
                  <div class="section__welcome--row">
                    <div class="section__welcome--left">
                      <div class="section__welcome--title">
                        {{ $t('well_come') }}
                      </div>
                      <div class="section__welcome--info">
                        <div class="welcome__info--left">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2M12 11a4 4 0 100-8 4 4 0 000 8z" stroke="#0E1839" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="welcome__info--right">
                          <div class="welcome__info--name">
                            <span
                              >{{ user.data.surname }}
                              {{ user.data.name }}</span
                            >
                            <a
                              :href="'/' + $i18n.locale + '/account/edit'"
                              class="account--link"
                            >
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11 4H4a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2v-7" stroke="#FFCC47" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M18.5 2.5a2.121 2.121 0 113 3L12 15l-4 1 1-4 9.5-9.5z" stroke="#FFCC47" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                            </a>
                          </div>
                          <div class="welcome__info--position">
                            {{ user.data.roles.name }}
                          </div>
                          <div class="welcome__info--position">
                            <a
                              href="/download/ИНСТРУКЦИЯ  ТУРОПЕРАТОРА .docx.pdf"
                              target="blank"
                              >{{ $t('load_instruction') }}</a
                            >
                          </div>
                        </div>
                      </div>
                      <div class="section__welcome--elicense">
                        <div class="welcome__elicense--block">
                          <div class="welcome__elicense--img">
                            <img src="../../assets/img/elicense.png" />
                          </div>
                          <div
                            class="welcome__elicense--stasus welcome__stasus--success"
                          >
                            <!-- welcome__stasus--error -->
                            <div class="welcome__stasus--icon">
                            
                            </div>
                            <!--
														<div class="welcome__stasus--text">
															Лицензия не подтверждена
														</div>
														-->
                          </div>
                        </div>
                        <div class="welcome__elicense--desc">
                          <!-- №45Т412543235 Срок действия до 01.12.2022 г. -->
                          <!-- К сожалению, мы не смогли найти вашу лицензию в государственном центром лицензирования. Проверьте статус вашей лицензии в личном кабинете <a>центра лицензирования</a>.-->
                        </div>
                      </div>
                    </div>
                    <div class="section__welcome--right">
                      <div class="section__welcome--info">
                        <div class="row">
                          <div class="col-lg-4 col-md-4">
                            <div
                              class="welcome__check--item welcome__check--success"
                            >
                              <!-- .welcome__check--error -->

                              <div class="welcome__check--top">
                                <div class="welcome__check--icon">
                                  <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                                </div>
                                <div class="welcome__check--link">
                                  <a @click="$modal.show('add__deft')"
                                    >{{ $t('commands.add') }}</a
                                  >
                                </div>
                              </div>
                              <div class="welcome__check--body">
                                <div class="welcome__check--title">
                                  {{ $t('labels.license_for_tour') }}
                                </div>
                                <div
                                  class="welcome__check--desc"
                                  v-if="
                                    user.data.certificate_of_debts &&
                                    user.data.certificate_of_debts.length > 0
                                  "
                                >
                                  <a
                                    target="_blank"
                                    :href="
                                      'https://backend.subsidizing.crocos.kz/' +
                                      user.data.certificate_of_debts[
                                        user.data.certificate_of_debts.length -
                                          1
                                      ].src.path
                                    "
                                    style="color: #fff"
                                    >{{
                                      user.data.certificate_of_debts[
                                        user.data.certificate_of_debts.length -
                                          1
                                      ].src.name
                                    }}</a
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-4">
                            <div
                              class="welcome__check--item welcome__check--success"
                            >
                              <!-- .welcome__check--error -->

                              <div class="welcome__check--top">
                                <div class="welcome__check--icon">
                                  <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                                </div>
                                <div class="welcome__check--link">
                                  <a @click="$modal.show('add__check')"
                                    >{{ $t('labels.add_new_account') }}</a
                                  >
                                </div>
                              </div>
                              <div class="welcome__check--body">
                                <div class="welcome__check--title">
                                  {{ $t('labels.checkingAccount') }}
                                </div>
                                <div class="welcome__check--desc">
                                  <ul>
                                    <li
                                      v-for="payment_account in user.data
                                        .payment_accounts"
                                    >
                                      <a
                                        @click="
                                          $modal.show('edit__check'),
                                            updatePaymentAccount(
                                              payment_account,
                                            )
                                        "
                                        >*{{
                                          payment_account.number.substr(0, 4)
                                        }}</a
                                      >
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-4">
                            <div
                              class="welcome__check--item welcome__check--success"
                            >
                              <!-- .welcome__check--error -->

                              <div class="welcome__check--top">
                                <div class="welcome__check--icon">
                                  <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                                </div>
                                <div class="welcome__check--link">
                                  <a
                                    @click="$modal.show('add__title_documents')"
                                    >{{
                                      user.data.title_documents.length
                                        ? $t('commands.add_yet')
                                        : $t('commands.add')
                                    }}</a
                                  >
                                </div>
                              </div>
                              <div class="welcome__check--body">
                                <div class="welcome__check--title">
                                  {{ $t('labels.title_documents') }}
                                </div>
                                <div
                                  class="welcome__check--desc"
                                  v-if="
                                    user.data.title_documents &&
                                    user.data.title_documents.length > 0
                                  "
                                >
                                  <a
                                    target="_blank"
                                    :href="
                                      user.data.title_documents[
                                        user.data.title_documents.length - 1
                                      ].src
                                    "
                                    style="color: #fff"
                                    >{{
                                      user.data.title_documents[
                                        user.data.title_documents.length - 1
                                      ].src
                                        .split('/')
                                        .pop()
                                    }}</a
                                  >
                                </div>
                              </div>
                            </div>
                          </div>

                          <modal
                            class="modal__block modal__block--middle modal__block--check"
                            name="add__check"
                            :width="680"
                            :min-width="680"
                            height="auto"
                          >
                            <div class="modal__block--wrapper">
                              <div
                                class="modal__block--close"
                                @click="$modal.hide('add__check')"
                              >
                                <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M31.5 10.5l-21 21M10.5 10.5l21 21" stroke="#0E1839" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                              </div>
                              <div class="modal__wrapper">
                                <div class="modal__title">{{ $t('labels.account_adding') }}</div>
                                <div class="modal__content">
                                  <div class="modal__form">
                                    <form
                                      action="#"
                                      @submit.prevent="onSubmitPaymentAccount"
                                    >
                                      <div class="form__block--line">
                                        <div
                                          class="form__block--input input--error"
                                        >
                                          <div class="form__block--title">
                                            {{ $t('labels.full') }}
                                          </div>
                                          <input
                                            type="text"
                                            class="input-linck"
                                            :class="[
                                              payment_account.full_name.error
                                                ? 'input--error'
                                                : '',
                                            ]"
                                            name="name"
                                            value=""
                                            v-model="
                                              payment_account.full_name.value
                                            "
                                          />
                                          <!-- input--error -->
                                          <span
                                            class="input-required input--error"
                                            v-if="
                                              payment_account.full_name.error
                                            "
                                            >{{
                                              payment_account.full_name.error
                                            }}</span
                                          >
                                        </div>
                                      </div>
                                      <div class="form__block--line">
                                        <div class="form__block--input">
                                          <div class="form__block--title">
                                            {{ $t('labels.iin') }}
                                          </div>
                                          <input
                                            type="text"
                                            class="input-linck"
                                            :class="[
                                              payment_account.iin.error
                                                ? 'input--error'
                                                : '',
                                            ]"
                                            name="iin"
                                            v-mask="'### ### ### ###'"
                                            v-model="payment_account.iin.value"
                                          />
                                          <!-- input--error -->
                                          <span
                                            class="input-required"
                                            v-if="payment_account.iin.error"
                                            >{{
                                              payment_account.iin.error
                                            }}</span
                                          >
                                        </div>
                                      </div>
                                      <div class="form__block--line">
                                        <div class="form__block--input">
                                          <div class="form__block--title">
                                            {{ $t('labels.bik') }}
                                          </div>
                                          <input
                                            type="text"
                                            class="input-linck"
                                            :class="[
                                              payment_account.bik.error
                                                ? 'input--error'
                                                : '',
                                            ]"
                                            name="bik"
                                            v-mask="'NNNNNNNN'"
                                            :value="payment_account.bik.value"
                                            @input="
                                              ($event) => {
                                                if ($event.target.value > 8) {
                                                  $event.target.value =
                                                    $event.target.value.slice(
                                                      0,
                                                      8,
                                                    )
                                                }
                                                payment_account.bik.value =
                                                  $event.target.value.toUpperCase()
                                              }
                                            "
                                          />
                                          <!-- input--error -->
                                          <span
                                            class="input-required"
                                            v-if="payment_account.bik.error"
                                            >{{
                                              payment_account.bik.error
                                            }}</span
                                          >
                                        </div>
                                      </div>
                                      <div class="form__block--line">
                                        <div class="form__block--input">
                                          <div class="form__block--title">
                                            {{ $t('labels.account_number') }}
                                          </div>
                                          <input
                                            :value="
                                              payment_account.number.value
                                            "
                                            type="text"
                                            class="input-linck"
                                            :class="[
                                              payment_account.number.error
                                                ? 'input--error'
                                                : '',
                                            ]"
                                            name="number"
                                            v-mask="
                                              'NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN'
                                            "
                                            @input="
                                              ($event) => {
                                                if ($event.target.value > 34) {
                                                  $event.target.value =
                                                    $event.target.value.slice(
                                                      0,
                                                      34,
                                                    )
                                                }
                                                payment_account.number.value =
                                                  $event.target.value.toUpperCase()
                                              }
                                            "
                                          />
                                          <!-- input--error -->
                                          <span
                                            class="input-required"
                                            v-if="payment_account.number.error"
                                            >{{
                                              payment_account.number.error
                                            }}</span
                                          >
                                        </div>
                                      </div>
                                      <div
                                        class="modal__wrapper--footer modal__wrapper--center"
                                      >
                                        <button
                                          type="submit"
                                          class="next__stage input__button"
                                        >
                                        {{ $t('commands.add') }}
                                        </button>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </modal>

                          <modal
                            class="modal__block modal__block--middle modal__block--check"
                            name="add__deft"
                            :width="680"
                            :min-width="680"
                            height="auto"
                          >
                            <div class="modal__block--wrapper">
                              <div
                                class="modal__block--close"
                                @click="$modal.hide('add__deft')"
                              >
                                <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M31.5 10.5l-21 21M10.5 10.5l21 21" stroke="#0E1839" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                              </div>
                              <div class="modal__wrapper">
                                <div class="modal__title">
                                  {{ $t('labels.license_for_tour') }}
                                </div>
                                <div class="modal__content">
                                  <div class="modal__form">
                                    <form
                                      action="#"
                                      @submit.prevent="
                                        onSubmitCertificateOfDebt
                                      "
                                    >
                                      <div class="form__block--line">
                                        <label
                                          class="section--link"
                                          style="width: 100%"
                                        >
                                          <input
                                            @change="
                                              uploadFile(
                                                certificate_of_debt.document,
                                              )
                                            "
                                            type="file"
                                            accept=".pdf,.jpg,.jpeg,.png"
                                            class="input-linck"
                                            :class="[
                                              certificate_of_debt.document.error
                                                ? 'input--error'
                                                : '',
                                            ]"
                                            name="document"
                                            style="display: none"
                                          />
                                          <p
                                            style="
                                              background-color: #6b69df;
                                              box-shadow: 1px 10px 22px
                                                rgb(107, 105, 223 / 80%);
                                            "
                                          >
                                            {{ $t('labels.select_file') }}
                                          </p>
                                        </label>
                                      </div>
                                      <p style="text-align: center">
                                        {{
                                          certificate_of_debt.document.value ||
                                          `(pdf, jpg, jpeg, png)`
                                        }}
                                      </p>
                                      <div
                                        class="modal__wrapper--footer modal__wrapper--center"
                                      >
                                        <button
                                          type="submit"
                                          class="next__stage input__button"
                                        >
                                        {{ $t('commands.add') }}
                                        </button>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </modal>

                          <modal
                            class="modal__block modal__block--middle modal__block--check"
                            name="edit__check"
                            :width="680"
                            :min-width="680"
                            height="auto"
                          >
                            <div class="modal__block--wrapper">
                              <div
                                class="modal__block--close"
                                @click="$modal.hide('edit__check')"
                              >
                                <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M31.5 10.5l-21 21M10.5 10.5l21 21" stroke="#0E1839" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                              </div>
                              <div class="modal__wrapper">
                                <div class="modal__title">
                                  {{ $t('labels.account_editing') }}
                                </div>
                                <div class="modal__content">
                                  <div class="modal__form">
                                    <form
                                      action="#"
                                      @submit.prevent="
                                        onSubmitPaymentAccountUpdate
                                      "
                                    >
                                      <div class="form__block--line">
                                        <div class="form__block--input">
                                          <div class="form__block--title">
                                            {{ $t('labels.full') }}
                                          </div>
                                          <input
                                            type="text"
                                            class="input-linck"
                                            :class="[
                                              payment_account.full_name.error
                                                ? 'input--error'
                                                : '',
                                            ]"
                                            name="name"
                                            v-model="
                                              payment_account.full_name.value
                                            "
                                          />
                                          <!-- input--error -->
                                          <span
                                            class="input-required"
                                            v-if="
                                              payment_account.full_name.error
                                            "
                                            >{{
                                              payment_account.full_name.error
                                            }}</span
                                          >
                                        </div>
                                      </div>
                                      <div class="form__block--line">
                                        <div class="form__block--input">
                                          <div class="form__block--title">
                                            {{ $t('labels.iin') }}
                                          </div>
                                          <input
                                            type="text"
                                            class="input-linck"
                                            :class="[
                                              payment_account.iin.error
                                                ? 'input--error'
                                                : '',
                                            ]"
                                            name="iin"
                                            v-mask="'### ### ### ###'"
                                            v-model="payment_account.iin.value"
                                          />
                                          <!-- input--error -->
                                          <span
                                            class="input-required"
                                            v-if="payment_account.iin.error"
                                            >{{
                                              payment_account.iin.error
                                            }}</span
                                          >
                                        </div>
                                      </div>
                                      <div class="form__block--line">
                                        <div class="form__block--input">
                                          <div class="form__block--title">
                                            {{ $t('labels.bik') }}
                                          </div>
                                          <input
                                            type="text"
                                            class="input-linck"
                                            :class="[
                                              payment_account.bik.error
                                                ? 'input--error'
                                                : '',
                                            ]"
                                            name="bik"
                                            v-mask="'NNNNNNNN'"
                                            :value="payment_account.bik.value"
                                            @input="
                                              ($event) => {
                                                if ($event.target.value > 8) {
                                                  $event.target.value =
                                                    $event.target.value.slice(
                                                      0,
                                                      8,
                                                    )
                                                }
                                                payment_account.bik.value =
                                                  $event.target.value.toUpperCase()
                                              }
                                            "
                                          />
                                          <!-- input--error -->
                                          <span
                                            class="input-required"
                                            v-if="payment_account.bik.error"
                                            >{{
                                              payment_account.bik.error
                                            }}</span
                                          >
                                        </div>
                                      </div>
                                      <div class="form__block--line">
                                        <div class="form__block--input">
                                          <div class="form__block--title">
                                            {{ $t('labels.account_number') }}
                                          </div>
                                          <input
                                            :value="
                                              payment_account.number.value
                                            "
                                            type="text"
                                            class="input-linck"
                                            :class="[
                                              payment_account.number.error
                                                ? 'input--error'
                                                : '',
                                            ]"
                                            name="number"
                                            v-mask="
                                              'NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN'
                                            "
                                            @input="
                                              ($event) => {
                                                if ($event.target.value > 34) {
                                                  $event.target.value =
                                                    $event.target.value.slice(
                                                      0,
                                                      34,
                                                    )
                                                }
                                                payment_account.number.value =
                                                  $event.target.value.toUpperCase()
                                              }
                                            "
                                          />
                                          <!-- input--error -->
                                          <span
                                            class="input-required"
                                            v-if="payment_account.number.error"
                                            >{{
                                              payment_account.number.error
                                            }}</span
                                          >
                                        </div>
                                      </div>

                                      <div
                                        class="modal__wrapper--footer modal__wrapper--center"
                                      >
                                        <button
                                          type="submit"
                                          class="next__stage input__button"
                                        >
                                          {{ $t('commands.save') }}
                                        </button>
                                        <div
                                          class="next__stage input__button input__button--delete"
                                          @click="
                                            onDeletePaymentAccount(
                                              payment_account.id.value,
                                              payment_account.index.value,
                                            )
                                          "
                                        >
                                        {{ $t('commands.remove') }}
                                        </div>
                                      </div>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </modal>

                          <modal
                            class="modal__block modal__block--middle modal__block--check"
                            name="add__title_documents"
                            :width="680"
                            :min-width="680"
                            height="auto"
                          >
                            <div class="modal__block--wrapper">
                              <div
                                class="modal__block--close"
                                @click="$modal.hide('add__title_documents')"
                              >
                                <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M31.5 10.5l-21 21M10.5 10.5l21 21" stroke="#0E1839" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                              </div>
                              <div class="modal__wrapper">
                                <div class="modal__title">
                                  {{ $t('labels.title_documents') }}
                                </div>
                                <div class="modal__content">
                                  <div class="modal__form">
                                    <form
                                      v-if="
                                        user.data.title_documents.length < 5
                                      "
                                      action="#"
                                      @submit.prevent="onSubmitTitleDocuments"
                                    >
                                      <div class="form__block--line">
                                        <label
                                          class="section--link"
                                          style="width: 100%"
                                        >
                                          <input
                                            @change="
                                              uploadFile(
                                                title_documents.document,
                                              )
                                            "
                                            type="file"
                                            accept=".pdf,.jpg,.jpeg,.png"
                                            class="input-linck"
                                            :class="[
                                              title_documents.document.error
                                                ? 'input--error'
                                                : '',
                                            ]"
                                            name="documents[0]"
                                            style="display: none"
                                          />
                                          <p
                                            style="
                                              background-color: #6b69df;
                                              box-shadow: 1px 10px 22px
                                                rgb(107, 105, 223 / 80%);
                                            "
                                          >
                                            {{ $t('labels.select_file') }}
                                          </p>
                                        </label>
                                      </div>
                                      <p style="text-align: center">
                                        {{
                                          title_documents.document.value ||
                                          `(pdf, jpg, jpeg, png)`
                                        }}
                                      </p>
                                      <div
                                        class="modal__wrapper--footer modal__wrapper--center"
                                      >
                                        <button
                                          type="submit"
                                          class="next__stage input__button"
                                        >
                                        {{ $t('commands.add') }}
                                        </button>
                                      </div>
                                    </form>
                                    <div
                                      v-if="
                                        user.data.title_documents &&
                                        user.data.title_documents.length > 0
                                      "
                                      class="welcome__file-list"
                                    >
                                      <div
                                        v-for="tDoc in user.data
                                          .title_documents"
                                        :key="tDoc.id"
                                      >
                                        <a target="_blank" :href="tDoc.src">{{
                                          tDoc.src.split('/').pop()
                                        }}</a>
                                        <button @click="removeTitleDocument(tDoc.id)">
                                          <svg width="15" height="15" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M31.5 10.5l-21 21M10.5 10.5l21 21" stroke="#0E1839" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </modal>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="account__infoblock--block">
            <div class="row">
              <div class="col-xl-6 col-lg-6">
                <div class="account__infoblock">
                  <div class="account__infoblock--left">
                    <div class="account__infoblock--img">
                      <img src="../../assets/img/infoblock-2.png" alt="" />
                    </div>
                  </div>
                  <div class="account__infoblock--right">
                    <div class="account__infoblock--top">
                      <div class="account__infoblock--subtitle">
                        {{ $t('applic_for_subs') }}
                      </div>
                      <div class="account__infoblock--title">
                        {{ $t('minor_passengers') }}
                      </div>
                    </div>
                    <div class="account__infoblock--body">
                      <div class="section--link">
                        <a
                          :href="
                            '/' +
                            $i18n.locale +
                            '/account/applications/kids-go-free/create'
                          "
                          >{{ $t('system.apply') }}</a
                        >
                      </div>
                      <div class="account__infoblock--download">
                        <a
                          :href="
                            '/' +
                            $i18n.locale +
                            '/account/subsiding/kids-go-free'
                          "
                          target="_blank"
                          >{{ $t('system.look_rules') }}</a
                        >
                        <a href="/download/кидсгоуфри.pdf" download=""
                          >{{ $t('registration.downloadPdf') }}</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xl-6 col-lg-6">
                <div class="account__infoblock account__infoblock--yellow">
                  <div class="account__infoblock--left">
                    <div class="account__infoblock--img">
                      <img src="../../assets/img/infoblock-1.png" alt="" />
                    </div>
                  </div>
                  <div class="account__infoblock--right">
                    <div class="account__infoblock--top">
                      <div class="account__infoblock--subtitle">
                        {{ $t('applic_for_subs_costs_tour') }}
                      </div>
                      <div class="account__infoblock--title">
                        {{ $t('for_ever_foreign_tour') }}
                      </div>
                    </div>
                    <div class="account__infoblock--body">
                      <div class="section--link">
                        <!--<a :href="'/'+ $i18n.locale +'/account/applications/foreign-tourist/create'">{{ $t('system.apply') }}</a>-->
                        <a class="input__button--disabled">{{ $t('system.apply') }}</a>
                      </div>
                      <div class="account__infoblock--download">
                        <a
                          :href="
                            '/' +
                            $i18n.locale +
                            '/account/subsiding/foreign-tourist'
                          "
                          target="_blank"
                          >{{ $t('system.look_rules') }}</a
                        >
                        <a href="/download/за каждого интуриста.pdf" download=""
                          >{{ $t('registration.downloadPdf') }}</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="registry__table">
            <div class="registry__tabs">
              <div
                class="registry__tabs--item"
                v-bind:class="{ 'registry__tabs--active': Categories == 'all' }"
                @click="categoriesTab('all')"
              >
                <a href="#">{{ $t('labels.all_apps') }}</a>
              </div>
              <div
                class="registry__tabs--item"
                v-bind:class="{
                  'registry__tabs--active': Categories == 'foreign',
                }"
                @click="categoriesTab('foreign')"
              >
                <a href="#">{{ $t('menu.foreign') }}</a>
              </div>
              <div
                class="registry__tabs--item"
                v-bind:class="{
                  'registry__tabs--active': Categories == 'kids',
                }"
                @click="categoriesTab('kids')"
              >
                <a href="#">{{ $t('labels.minor_passengers') }}</a>
              </div>
            </div>

            <div class="table__block--block table__block--shadow">
              <div class="table__block--top">
                <div class="table__block--title">{{ $t('system.my_statements') }}</div>
              </div>

              <div class="table__block--content">
                <div class="table__block--filter">
                  <div class="table__filter--search">
                    <label class="search__label">
                      <input
                        type="text"
                        value=""
                        name="filter_search"
                        v-model="filters.search"
                        :placeholder="$t('labels.search_by_text')"
                      />
                      <button>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11 19a8 8 0 100-16 8 8 0 000 16zM21 21l-4.35-4.35" stroke="#0E1839" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </button>
                    </label>
                  </div>
                  <div class="table__filter--order">
                    <a
                      href="#"
                      class="filter__order--item"
                      v-bind:class="{
                        'filter__order--active': filterCategories == 'all',
                      }"
                      @click="filterTab('all')"
                      >{{ $t('labels.all') }}</a
                    >
                    <a
                      href="#"
                      class="filter__order--item"
                      v-bind:class="{
                        'filter__order--active': filterCategories == 'sent-by',
                      }"
                      @click="filterTab('sent-by')"
                      >{{ $t('labels.sended') }}</a
                    >
                    <a
                      href="#"
                      class="filter__order--item"
                      v-bind:class="{
                        'filter__order--active':
                          filterCategories == 'under-consideration',
                      }"
                      @click="filterTab('under-consideration')"
                      >{{ $t('labels.pending') }}</a
                    >
                    <a
                      href="#"
                      class="filter__order--item"
                      v-bind:class="{
                        'filter__order--active': filterCategories == 'approved',
                      }"
                      @click="filterTab('approved')"
                      >{{ $t('labels.approved') }}</a
                    >
                  </div>
                </div>
                <!-- :items="applications.data"-->
                <v-data-table
                  :headers="headers"
                  :items="filteredlist"
                  :loading="loading"
                  :options.sync="options"
                  :footer-props="{
                    'items-per-page-options': [5, 10, 20, 30, 40, 50],
                  }"
                >
                  <template v-slot:item.fields="{ item }">
                    <td style="white-space: pre-line">
                      {{ getNames(item.fields.tourists) }}
                    </td>
                  </template>
                  <template slot="applications.data" slot-scope="props">
                    <td>{{ props.id }}</td>
                    <td>{{ props.name }}</td>
                    <td>{{ props.item.date }}</td>
                    <td>{{ props.item.time }}</td>
                    <td>{{ props.item.from }}</td>
                  </template>
                  <template v-slot:item.action="{ item }">
                    <a
                      class="tb__table--btn"
                      v-if="item.category_id == 'foreign'"
                      :href="
                        '/' +
                        $i18n.locale +
                        '/account/applications/foreign-tourist/' +
                        item.id
                      "
                    >
                      <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M.875 10.5s3.5-7 9.625-7 9.625 7 9.625 7-3.5 7-9.625 7-9.625-7-9.625-7z" stroke="#FFCC47" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M10.5 13.125a2.625 2.625 0 100-5.25 2.625 2.625 0 000 5.25z" stroke="#FFCC47" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </a>
                    <a
                      class="tb__table--btn"
                      v-if="item.category_id == 'kids'"
                      :href="
                        '/' +
                        $i18n.locale +
                        '/account/applications/kids-go-free/' +
                        item.id
                      "
                    >
                      <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M.875 10.5s3.5-7 9.625-7 9.625 7 9.625 7-3.5 7-9.625 7-9.625-7-9.625-7z" stroke="#FFCC47" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M10.5 13.125a2.625 2.625 0 100-5.25 2.625 2.625 0 000 5.25z" stroke="#FFCC47" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </a>
                    <a
                      v-if="
                        item.category_id == 'kids' &&
                        (item.status.slug === 'draft' ||
                          item.status.slug === 'resend')
                      "
                      class="tb__table--btn"
                      :href="
                        '/' +
                        $i18n.locale +
                        '/account/applications/kids-go-free/' +
                        item.id +
                        '/edit'
                      "
                    >
                      <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M9.625 3.5H3.5a1.75 1.75 0 00-1.75 1.75V17.5a1.75 1.75 0 001.75 1.75h12.25a1.75 1.75 0 001.75-1.75v-6.125" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M16.188 2.187a1.856 1.856 0 012.625 2.625L10.5 13.125 7 14l.875-3.5 8.313-8.313z" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </a>
                  </template>
                  <v-alert slot="no-results" :value="true" color="error"
                    >{{ $t('empty_search_results', [filters.search]) }}</v-alert
                  >
                </v-data-table>
              </div>

              <modal
                class="modal__block modal__block--middle modal__block--check"
                name="registry__edit"
                :width="680"
                :min-width="680"
                height="auto"
              >
                <div class="modal__block--wrapper">
                  <div
                    class="modal__block--close"
                    @click="$modal.hide('registry__edit')"
                  >
                    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M31.5 10.5l-21 21M10.5 10.5l21 21" stroke="#0E1839" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                  </div>
                  <div class="modal__wrapper">
                    <div class="modal__title">{{ $t('changing_tour_data') }}</div>
                    <div class="modal__content">
                      <div class="modal__form">
                        <form action="#">
                          <div class="form__block--line">
                            <div class="form__block--input">
                              <div class="form__block--title">
                                {{ $t('registration.input.name') }}
                                <span>*</span>
                              </div>
                              <input
                                type="text"
                                class="input-linck"
                                required=""
                                name="name"
                                value="Иван"
                              />
                              <!-- input--error -->
                              <span class="input-required">{{
                                $t('registration.required.error')
                              }}</span>
                            </div>
                          </div>
                          <div class="form__block--line">
                            <div class="form__block--input">
                              <div class="form__block--title">
                                {{ $t('registration.input.surname') }}
                                <span>*</span>
                              </div>
                              <input
                                type="text"
                                class="input-linck"
                                required=""
                                name="surname"
                                value="Виванов"
                              />
                              <!-- input--error -->
                              <span class="input-required">{{
                                $t('registration.required.error')
                              }}</span>
                            </div>
                          </div>
                          <div class="form__block--line">
                            <div class="form__block--input">
                              <div class="form__block--title">{{ $t('labels.patronymic') }}</div>
                              <input
                                type="text"
                                class="input-linck"
                                name="surname"
                                value="Иванович"
                              />
                              <!-- input--error -->
                              <span class="input-required">{{
                                $t('registration.required.error')
                              }}</span>
                            </div>
                          </div>
                          <div class="form__block--line">
                            <div class="form__block--input">
                              <div class="form__block--title">
                                {{ $t('labels.password_number') }}
                              </div>
                              <input
                                type="text"
                                class="input-linck"
                                name="surname"
                                value="4353643574567458567"
                              />
                              <!-- input--error -->
                              <span class="input-required">{{
                                $t('registration.required.error')
                              }}</span>
                            </div>
                          </div>
                          <div class="form__block--line">
                            <div class="form__block--input">
                              <div class="form__block--title">
                                {{ $t('labels.citizenshipe') }} <span>*</span>
                              </div>
                              <multiselect
                                v-model="valueRegion"
                                :options="optionsRegion"
                                :clearOnSelect="false"
                                :clear-on-select="false"
                                :placeholder="$t('labels.citizenshipe')"
                                :hideSelected="true"
                                required=""
                              ></multiselect>

                              <!-- input--error -->
                              <span class="input-required">{{
                                $t('registration.required.error')
                              }}</span>
                            </div>
                          </div>
                          <div class="form__block--line">
                            <div class="form__block--input">
                              <div class="form__block--title">
                                {{ $t('period_stay_tour') }} <span>*</span>
                              </div>
                              <input
                                type="date"
                                class="input-linck"
                                name="surname"
                                value="2018-07-22"
                              /><!-- 18.11.2021 - 20.11.2021 -->
                              <!-- input--error -->
                              <span class="input-required">{{
                                $t('registration.required.error')
                              }}</span>
                            </div>
                          </div>

                          <div
                            class="modal__wrapper--footer modal__wrapper--center"
                          >
                            <div class="next__stage--block">
                              <div class="next__stage--text">
                                {{ $t('registration.input.required') }} *
                              </div>
                              <div class="section--link">
                                <button class="next__stage input__button">
                                  {{ $t('labels.change') }}
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </modal>

              <modal
                class="modal__block modal__block--middle modal__block--check"
                name="registry__views"
                :width="680"
                :min-width="680"
                height="auto"
              >
                <div class="modal__block--wrapper">
                  <div
                    class="modal__block--close"
                    @click="$modal.hide('registry__views')"
                  >
                    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M31.5 10.5l-21 21M10.5 10.5l21 21" stroke="#0E1839" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                  </div>
                  <div class="modal__wrapper">
                    <div class="modal__title">{{ $t('labels.adding_tour') }}</div>
                    <div class="modal__content">
                      <div class="modal__form">
                        <form action="#">
                          <div class="form__block--line">
                            <div class="form__block--input">
                              <div class="form__block--title">
                                {{ $t('registration.input.name') }}
                                <span>*</span>
                              </div>
                              <input
                                type="text"
                                class="input-linck"
                                required=""
                                name="name"
                                value="Иван"
                                disabled=""
                              />
                              <!-- input--error -->
                              <span class="input-required">{{
                                $t('registration.required.error')
                              }}</span>
                            </div>
                          </div>
                          <div class="form__block--line">
                            <div class="form__block--input">
                              <div class="form__block--title">
                                {{ $t('registration.input.surname') }}
                                <span>*</span>
                              </div>
                              <input
                                type="text"
                                class="input-linck"
                                required=""
                                name="surname"
                                value="Виванов"
                                disabled=""
                              />
                              <!-- input--error -->
                              <span class="input-required">{{
                                $t('registration.required.error')
                              }}</span>
                            </div>
                          </div>
                          <div class="form__block--line">
                            <div class="form__block--input">
                              <div class="form__block--title">{{ $t('labels.patronymic') }}</div>
                              <input
                                type="text"
                                class="input-linck"
                                name="surname"
                                value="Иванович"
                                disabled=""
                              />
                              <!-- input--error -->
                              <span class="input-required">{{
                                $t('registration.required.error')
                              }}</span>
                            </div>
                          </div>
                          <div class="form__block--line">
                            <div class="form__block--input">
                              <div class="form__block--title">
                                {{ $t('labels.password_number') }}
                              </div>
                              <input
                                type="text"
                                class="input-linck"
                                name="surname"
                                value="4353643574567458567"
                                disabled=""
                              />
                              <!-- input--error -->
                              <span class="input-required">{{
                                $t('registration.required.error')
                              }}</span>
                            </div>
                          </div>
                          <div class="form__block--line">
                            <div class="form__block--input">
                              <div class="form__block--title">
                                {{ $t('labels.citizenshipe') }} <span>*</span>
                              </div>
                              <input
                                type="text"
                                class="input-linck"
                                name="surname"
                                value="Казахстан"
                                disabled=""
                              />

                              <!-- input--error -->
                              <span class="input-required">{{
                                $t('registration.required.error')
                              }}</span>
                            </div>
                          </div>
                          <div class="form__block--line">
                            <div class="form__block--input">
                              <div class="form__block--title">
                                {{ $t('period_stay_tour') }} <span>*</span>
                              </div>
                              <input
                                type="date"
                                class="input-linck"
                                name="surname"
                                value="2018-07-22"
                                disabled=""
                              /><!-- 18.11.2021 - 20.11.2021 -->
                              <!-- input--error -->
                              <span class="input-required">{{
                                $t('registration.required.error')
                              }}</span>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from '../../boot/axios'
import Multiselect from 'vue-multiselect'
import * as Validator from 'validatorjs'

export default {
  name: 'Account',
  components: { Multiselect },
  data() {
    return {
      applications: null,
      valueRegion: null,
      optionsRegion: ['Казахстан', 'Россия'],
      Categories: 'all',
      filterCategories: 'all',
      filters: {
        search: '',
      },
      user: null,
      page: 0,
      totalPassengers: 0,
      loading: false,
      options: {
        itemsPerPage: 5,
        page: 1,
      },
      title_documents: {
        document: {
          value: null,
          error: null,
        },
      },
      certificate_of_debt: {
        document: {
          value: null,
          error: null,
        },
      },
      payment_account: {
        id: {
          value: null,
        },
        index: {
          value: null,
        },
        full_name: {
          value: null,
          error: null,
        },
        iin: {
          value: null,
          error: null,
        },
        bik: {
          value: null,
          error: null,
        },
        number: {
          value: null,
          error: null,
        },
      },
      payment_account_messages: {
        'required.full_name.value': 'Поле ФИО обязательно для заполнения.',
        'required.iin.value': 'Поле ИИН обязательно для заполнения.',
        'required.bik.value': 'Поле БИК обязательно для заполнения.',
        'required.number.value':
          'Поле Номер расчетного счета обязательно для заполнения.',
      },
      headers: [
        {
          text: 'ID',
          value: 'id',
        },
        {
          text: this.$t('labels.full_tour'),
          value: 'fields',
        },
        {
          text: this.$t('labels.app_view'),
          value: 'type',
          width: '200px',
        },
        {
          text: this.$t('labels.date'),
          value: 'date',
        },
        {
          text: this.$t('labels.time'),
          value: 'time',
        },
        {
          text: this.$t('labels.app_status'),
          value: 'status.name',
        },
        {
          text: this.$t('labels.action'),
          value: 'action',
          align: 'center',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    filteredlist() {
      //	console.log(this.filters.search.toLowerCase());
      // return this.itemsList.filter((d) => {
      return this.applications.data
        .filter((d) => {
          if (
            d.view.name
              .toLowerCase()
              .includes(this.filters.search.toLowerCase()) &&
            (this.filterCategories == 'all' ||
              d.status?.slug == this.filterCategories) &&
            (this.Categories == 'all' || d.category_id == this.Categories)
          ) {
            return true
          }
        })
        .map((d) => {
          return {
            ...d,
            date: d.datetime?.split(' ')[0] || '',
            time: d.datetime?.split(' ')[1] || '',
          }
        })
    },
  },
  methods: {
    resetPaymentAccount() {
      this.payment_account = {
        id: {
          value: null,
        },
        index: {
          value: null,
        },
        full_name: {
          value: null,
          error: null,
        },
        iin: {
          value: null,
          error: null,
        },
        bik: {
          value: null,
          error: null,
        },
        number: {
          value: null,
          error: null,
        },
      }
    },
    getNames(tourists) {
      return tourists.reduce((s, i) => {
        if (s.length) s += ', \n'
        s += i.surname + ' ' + i.name + ' ' + i.patronymic
        return s
      }, '')
    },
    modalShowCheck() {
      this.$modal.show('add__check')
    },
    modalSHideCheck() {
      this.$modal.hide('add__check')
    },
    categoriesTab(category) {
      this.Categories = category

      if (category == 'all') {
        this.headers[1]['text'] = this.$t('labels.full_tour')
      }

      if (category == 'kids') {
        this.headers[1]['text'] = 'ФИО пассажира'
      }

      if (category == 'kids') {
        this.headers[1]['text'] = 'ФИО туриста'
      }
    },
    filterTab(category) {
      this.filterCategories = category
    },
    parseToCorrectData() {
      return {
        iin: this.payment_account.iin.value.replace(/[^0-9]/g, ''),
        bik: this.payment_account.bik.value.replace(/[^0-9]/g, ''),
        full_name: this.payment_account.full_name.value,
        number: this.payment_account.number.value,
      }
    },
    onSubmitPaymentAccount() {
      this.payment_account.full_name.error = null
      this.payment_account.iin.error = null
      this.payment_account.bik.error = null
      this.payment_account.number.error = null

      let rules = {
        'full_name.value': 'required|string|max:255',
        'iin.value': 'required|string|max:255|min:14',
        'bik.value': 'required|string|max:255|min:8',
        'number.value': 'required|string|max:255|min:15',
      }

      let messages = {
        'max.full_name.value': this.$t(`messages.max`, [
          this.$t('labels.full'),
          255,
        ]),
        'required.full_name.value': this.$t(`messages.required`, [
          this.$t('labels.full'),
        ]),
        'required.iin.value': this.$t(`messages.required`, [
          this.$t('labels.iin'),
        ]),
        'min.iin.value': this.$t(`messages.min`, [this.$t('labels.iin'), 12]),
        'required.bik.value': this.$t(`messages.required`, [
          this.$t('labels.bik'),
        ]),
        'min.bik.value': this.$t(`messages.min`, [this.$t('labels.bik'), 8]),
        'required.number.value': this.$t(`messages.required`, [
          this.$t('labels.account_number'),
        ]),
        'min.number.value': this.$t(`messages.min`, [
          this.$t('labels.account_number'),
          15,
        ]),
      }

      let validation = new Validator(this.payment_account, rules, messages)

      if (validation.fails()) {
        for (let key in validation.errors.errors) {
          if (key == 'full_name.value')
            this.payment_account.full_name.error = validation.errors.first(key)
          if (key == 'iin.value')
            this.payment_account.iin.error = validation.errors.first(key)
          if (key == 'bik.value')
            this.payment_account.bik.error = validation.errors.first(key)
          if (key == 'number.value')
            this.payment_account.number.error = validation.errors.first(key)
        }

        return
      }

      const correctData = this.parseToCorrectData()

      const formData = new FormData()

      formData.append('full_name', correctData.full_name)
      formData.append('iin', correctData.iin)
      formData.append('bik', correctData.bik)
      formData.append('number', correctData.number)

      api
        .post('user/payment-accounts', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          if (response.data.code == 201) {
            this.$notify({
              group: 'foo',
              title: 'Расчетный счет успешно добавлен',
              position: 'bottom center',
            })

            this.updateUser()

            this.$modal.hide('add__check')
            this.resetPaymentAccount()
          } else if (response.data.code == 422 && response.data.messages) {
            Object.keys(this.payment_account).forEach((key) => {
              if (response.data.messages[key]) {
                this.payment_account[key].error = response.data.messages[
                  key
                ].reduce((summ, errorText) => {
                  if (summ.length) summ += ' '
                  summ += errorText
                  return summ
                }, '')
              }
            })
          }
        })
    },
    updatePaymentAccount(payment_account) {
      this.payment_account.id.value = payment_account.id
      this.payment_account.id.error = ''
      this.payment_account.full_name.value = payment_account.full_name
      this.payment_account.full_name.error = ''
      this.payment_account.iin.value = payment_account.iin
      this.payment_account.iin.error = ''
      this.payment_account.bik.value = payment_account.bik
      this.payment_account.bik.error = ''
      this.payment_account.number.value = payment_account.number
      this.payment_account.number.error = ''
    },
    onSubmitPaymentAccountUpdate() {
      this.payment_account.full_name.error = null
      this.payment_account.iin.error = null
      this.payment_account.bik.error = null
      this.payment_account.number.error = null

      let rules = {
        'full_name.value': 'required|string|max:255',
        'iin.value': 'required|string|max:255|min:14',
        'bik.value': 'required|string|max:255|min:8',
        'number.value': 'required|string|max:255|min:15',
      }

      let messages = {
        'max.full_name.value': this.$t(`messages.max`, [
          this.$t('labels.full'),
          255,
        ]),
        'required.full_name.value': this.$t(`messages.required`, [
          this.$t('labels.full'),
        ]),
        'required.iin.value': this.$t(`messages.required`, [
          this.$t('labels.iin'),
        ]),
        'min.iin.value': this.$t(`messages.min`, [this.$t('labels.iin'), 12]),
        'required.bik.value': this.$t(`messages.required`, [
          this.$t('labels.bik'),
        ]),
        'min.bik.value': this.$t(`messages.min`, [this.$t('labels.bik'), 8]),
        'required.number.value': this.$t(`messages.required`, [
          this.$t('labels.account_number'),
        ]),
        'min.number.value': this.$t(`messages.min`, [
          this.$t('labels.account_number'),
          15,
        ]),
      }

      let validation = new Validator(this.payment_account, rules, messages)

      if (validation.fails()) {
        for (let key in validation.errors.errors) {
          if (key == 'full_name.value')
            this.payment_account.full_name.error = validation.errors.first(key)
          if (key == 'iin.value')
            this.payment_account.iin.error = validation.errors.first(key)
          if (key == 'bik.value')
            this.payment_account.bik.error = validation.errors.first(key)
          if (key == 'number.value')
            this.payment_account.number.error = validation.errors.first(key)
        }

        return
      }

      const correctData = this.parseToCorrectData()

      const formData = new FormData()

      formData.append('_method', 'PUT')
      formData.append('full_name', correctData.full_name)
      formData.append('iin', correctData.iin)
      formData.append('bik', correctData.bik)
      formData.append('number', correctData.number)

      api
        .post(
          'user/payment-accounts/' + this.payment_account.id.value,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )
        .then((response) => {
          if (response.data.code == 200) {
            this.$notify({
              group: 'foo',
              title: 'Расчетный счет успешно изменен',
              position: 'bottom center',
            })

            this.updateUser()

            this.$modal.hide('edit__check')

            this.resetPaymentAccount()
          } else if (response.data.code == 422 && response.data.messages) {
            Object.keys(this.payment_account).forEach((key) => {
              if (response.data.messages[key]) {
                this.payment_account[key].error = response.data.messages[
                  key
                ].reduce((summ, errorText) => {
                  if (summ.length) summ += ' '
                  summ += errorText
                  return summ
                }, '')
              }
            })
          }
        })
    },
    onDeletePaymentAccount(id) {
      const formData = new FormData()

      formData.append('_method', 'DELETE')

      api
        .post('user/payment-accounts/' + id, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          if (response.data.code == 204) {
            this.$notify({
              group: 'foo',
              title: 'Расчетный счет успешно удален',
              position: 'bottom center',
            })

            for (let i = 0; i < this.user.data.payment_accounts.length; i++) {
              if (this.user.data.payment_accounts[i].id == id) {
                delete this.user.data.payment_accounts[i]
              }
            }

            this.updateUser()

            this.$modal.hide('edit__check')
          }
        })
    },
    updateUser() {
      api.get('user').then((response) => {
        if (response.data) {
          this.user = response.data
        }
      })
    },
    onSubmitTitleDocuments(evt) {
      const formData = new FormData(evt.target)

      api
        .post('/user/title-documents', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          if (response.status == 201) {
            this.$notify({
              group: 'foo',
              title: this.$t('labels.doc_added_succesfull'),
              position: 'bottom center',
            })

            this.updateUser()
          }
        })
    },
    removeTitleDocument(id) {
      const formData = new FormData()
      formData.append('_method', 'DELETE')
      api
        .post('user/title-documents/' + id, formData, {})
        .then((response) => {
          if (response.status == 204) {
            this.$notify({
              group: 'foo',
              title: this.$t('labels.doc_deleted_succesfull'),
              position: 'bottom center',
            })

            this.updateUser()
          }
        })
    },
    onSubmitCertificateOfDebt(evt) {
      const formData = new FormData(evt.target)

      api
        .post('user/certificate-of-debts', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          if (response.data.code == 201) {
            this.$notify({
              group: 'foo',
              title: this.$t('labels.doc_added_succesfull'),
              position: 'bottom center',
            })

            this.updateUser()

            this.$modal.hide('add__deft')
          }
        })
    },
    uploadFile(vari) {
      vari.value = Array.from(event.target.files)[0]['name']
    },
  },
  beforeCreate() {
    if (!localStorage.token) {
      this.$router.push('/ru/login')
    }

    api.get('user').then((response) => {
      if (response.data) {
        this.user = response.data
        // console.log(this.user.data.roles[0]);
      }
    })

    api.get('applications').then((response) => {
      // console.log(response.data);
      if (response.data) {
        this.applications = response.data
      }
    })
  },
}
</script>

<style scoped></style>
