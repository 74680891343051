<template>
  <div
    class="wrap__content"
    v-bind:class="{ 'wrap__content--placeholder': !user || !applications }"
  >
    <div
      class="page__placeholder"
      v-if="
        !user ||
        !applications ||
        !approved_applications ||
        !rejected_applications
      "
    >
      <div class="form__block--placeholder">
        <div class="form__placeholder--circle">
          <div class="placeholder__circle">
            <svg width="73" height="73" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><circle cx="36.5" cy="36" r="24" stroke="#FFF7E1" stroke-width="2"/><g filter="url(#filter0_d_22_5658)"><path d="M16.16 23A24.208 24.208 0 0012 36.605C12 50.078 22.969 61 36.5 61S61 50.078 61 36.605c0-1.736-.182-3.43-.528-5.064" stroke="#FFCC47" stroke-width="4" stroke-linecap="round"/></g><defs><filter id="filter0_d_22_5658" x="5" y="16" width="63" height="52" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset/><feGaussianBlur stdDeviation="2.5"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix values="0 0 0 0 1 0 0 0 0 0.722826 0 0 0 0 0 0 0 0 0.5 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_22_5658"/><feBlend in="SourceGraphic" in2="effect1_dropShadow_22_5658" result="shape"/></filter></defs></svg>
          </div>
        </div>
        <div class="form__placeholder--logo">
          <img src="../../assets/img/logo.svg" alt="" />
        </div>
      </div>
    </div>

    <div
      class="content__page--block"
      v-if="
        user && applications && approved_applications && rejected_applications
      "
    >
      <div class="content__page">
        <!-- content__page--margintop -->

        <div class="container">
          <div class="section__welcome--block">
            <div class="row">
              <div class="col-xl-12">
                <div class="section__welcome section__welcome--dashboard">
                  <div class="section__welcome--row">
                    <div class="section__welcome--left">
                      <div class="section__welcome--title">
                        {{ $t('well_come') }}
                      </div>
                      <div class="section__welcome--info">
                        <div class="welcome__info--left">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2M12 11a4 4 0 100-8 4 4 0 000 8z" stroke="#0E1839" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="welcome__info--right">
                          <div class="welcome__info--name">
                            <span
                              >{{ user.data.surname }}
                              {{ user.data.name }}</span
                            >
                          </div>
                          <div class="welcome__info--position">
                            {{ user.data.roles.name }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="section__welcome--right">
                      <div class="section__welcome--info dashboard--welcome">
                        <div class="row">
                          <div class="col-lg-4">
                            <div class="administrator__dashboard--item">
                              <div class="administrator__dashboard--block">
                                <div class="administrator__dashboard--circle">
                                  <div
                                    class="administrator__dashboard--border"
                                  ></div>
                                  <div class="administrator__dashboard--number">
                                    {{ applications.meta.total }}
                                  </div>
                                  <div class="administrator__dashboard--icon">
                                    <img
                                      src="../../assets/img/dashboard-received.svg"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="administrator__dashboard--text">
                                {{ $t('labels.applications_received') }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="administrator__dashboard--item">
                              <div class="administrator__dashboard--block">
                                <div class="administrator__dashboard--circle">
                                  <div
                                    class="administrator__dashboard--border"
                                  ></div>
                                  <div class="administrator__dashboard--number">
                                    {{ approved_applications.meta.total }}
                                  </div>
                                  <div class="administrator__dashboard--icon">
                                    <img
                                      src="../../assets/img/dashboard-approved.svg"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="administrator__dashboard--text">
                                {{ $t('labels.approved') }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="administrator__dashboard--item">
                              <div class="administrator__dashboard--block">
                                <div class="administrator__dashboard--circle">
                                  <div
                                    class="administrator__dashboard--border"
                                  ></div>
                                  <div class="administrator__dashboard--number">
                                    {{ rejected_applications.meta.total }}
                                  </div>
                                  <div class="administrator__dashboard--icon">
                                    <img
                                      src="../../assets/img/dashboard-rejected.svg"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="administrator__dashboard--text">
                                {{ $t('labels.applications_rejected') }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div
            class="account__infoblock--block"
            v-if="['stationery', 'accountant'].includes(user.data.role.slug)"
          >
            <div class="row">
              <div class="col-xl-6 col-lg-6">
                <div class="account__infoblock">
                  <div class="account__infoblock--left">
                    <div class="account__infoblock--img">
                      <img src="../../assets/img/infoblock-2.png" alt="" />
                    </div>
                  </div>
                  <div class="account__infoblock--right">
                    <div class="account__infoblock--top">
                      <div class="account__infoblock--subtitle">
                        Заявление на субсидирование<br />
                        стоимости билета, включенного в туристский продукт, при
                        воздушной перевозке
                      </div>
                      <div class="account__infoblock--title">
                        {{ $t('minor_passengers') }}
                      </div>
                    </div>
                    <div class="account__infoblock--body">
                      <div class="section--link">
                        <a
                          :href="
                            '/' +
                            $i18n.locale +
                            '/administration/applications/kids-go-free/create'
                          "
                          >{{ $t('system.apply') }}</a
                        >
                      </div>
                      <div class="account__infoblock--download">
                        <a
                          :href="
                            '/' +
                            $i18n.locale +
                            '/account/subsiding/kids-go-free'
                          "
                          >{{ $t('system.look_rules') }}</a
                        >
                        <a href="/download/кидсгоуфри.pdf" download=""
                          >{{ $t('registration.downloadPdf') }}</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              
							<div class="col-xl-6 col-lg-6">
								<div class="account__infoblock account__infoblock--yellow">
									<div class="account__infoblock--left">
										<div class="account__infoblock--img">
											<img src="../../assets/img/infoblock-1.png" alt="">
										</div>
									</div>
									<div class="account__infoblock--right">
										<div class="account__infoblock--top">
											<div class="account__infoblock--subtitle">
												Заявление на субсидирование<br>
												затрат туроператоров в сфере въездного туризма
											</div>
											<div class="account__infoblock--title">
												За каждого иностранного туриста
											</div>
										</div>
										<div class="account__infoblock--body">

											<div class="section--link">
												<a :href="'/'+ $i18n.locale +'/account/tourist-add'">{{ $t('system.apply') }}</a>
											</div>
											<div class="account__infoblock--download">
												<a :href="'/'+$i18n.locale +'/account/subsiding/foreign-tourist'">{{ $t('system.look_rules') }}</a>
												<a href="/download/за каждого интуриста.pdf" download="">{{ $t('registration.downloadPdf') }}</a>
											</div>

										</div>
									</div>
								</div>
							</div>
            </div>
          </div> -->

          <div class="registry__table">
            <div class="registry__tabs">
              <div
                class="registry__tabs--item"
                v-bind:class="{ 'registry__tabs--active': Categories == 'all' }"
                @click="categoriesTab('all')"
              >
                <a href="#">{{ $t('labels.all_apps') }}</a>
              </div>
              <div
                class="registry__tabs--item"
                v-bind:class="{
                  'registry__tabs--active': Categories == 'foreign',
                }"
                @click="categoriesTab('foreign')"
              >
                <a href="#">{{ $t('menu.foreign') }}</a>
              </div>
              <div
                class="registry__tabs--item"
                v-bind:class="{
                  'registry__tabs--active': Categories == 'kids',
                }"
                @click="categoriesTab('kids')"
              >
                <a href="#">{{ $t('labels.minor_passengers') }}</a>
              </div>
            </div>

            <div class="table__block--block table__block--shadow">
              <div class="table__block--top">
                <div class="table__block--title">
                  {{ $t('labels.register_received_applications') }}
                </div>
              </div>

              <div class="table__block--content">
                <div class="table__block--filter">
                  <div class="table__filter--search">
                    <label class="search__label">
                      <input
                        type="text"
                        value=""
                        name="filter_search"
                        v-model="filters.search"
                        :placeholder="$t('labels.search_by_text')"
                      />
                      <button>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11 19a8 8 0 100-16 8 8 0 000 16zM21 21l-4.35-4.35" stroke="#0E1839" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </button>
                    </label>
                  </div>
                  <div class="table__filter--order">
                    <multiselect
                      v-model="filterCategories"
                      :options="orderList"
                      label="name"
                      :clearOnSelect="false"
                      :clear-on-select="false"
                      :placeholder="$t('labels.status')"
                      :hideSelected="true"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">{{
                        option.name
                      }}</template>
                    </multiselect>
                  </div>
                  <div style="width: 20px; height: auto; float: left"></div>
                  <div class="table__filter--order">
                    <multiselect
                      v-model="period"
                      :options="periods"
                      label="title"
                      :clearOnSelect="false"
                      :clear-on-select="false"
                      :placeholder="$t('labels.period')"
                      :hideSelected="true"
                    >
                      <template
                        slot="singleLabel"
                        slot-scope="{ option }"
                        @click="onClickPeriod(option)"
                        >{{ option.title }}</template
                      >
                    </multiselect>
                  </div>

                  <div style="width: 20px; height: auto; float: left"></div>

                  <div>
                    <!--<div v-for="item in periods" style="padding: 5px 15px; float: left;" @click="onClickPeriod(option)">{{ item.title }}</div>-->
                    <input
                      type="date"
                      v-model="another_period_start"
                      v-if="period && period.value == 'another'"
                      style="
                        border: 1px solid #000;
                        height: 40px;
                        margin-left: 0px;
                        margin-right: 5px;
                        border-radius: 5px;
                        padding: 0 10px;
                      "
                    />
                    <input
                      type="date"
                      v-model="another_period_end"
                      v-if="period && period.value == 'another'"
                      style="
                        border: 1px solid #000;
                        height: 40px;
                        margin-left: 5px;
                        margin-right: 0px;
                        border-radius: 5px;
                        padding: 0 10px;
                      "
                    />
                    <!--<div @click="onClickDownloadReport">Скачать отчет</div>-->
                  </div>

                  <div>
                    <div style="width: 250px; height: 40px; float: left">
                      <div class="section--link" style="margin: 10px 0">
                        <a
                          style="padding-top: 12px; padding-bottom: 12px"
                          v-if="generate_report_url"
                          target="_blank"
                          :href="
                            'https://backend.subsidizing.crocos.kz/api/v1/applications/generate-report?filter[export_format]=pdf&' +
                            generate_report_url.join('&')
                          "
                          >{{ $t('labels.download_pdf') }}</a
                        >
                        <a
                          style="padding-top: 12px; padding-bottom: 12px"
                          v-else
                          target="_blank"
                          :href="'https://backend.subsidizing.crocos.kz/api/v1/applications/generate-report?filter[export_format]=pdf'"
                          >{{ $t('labels.download_pdf') }}</a
                        >
                      </div>
                    </div>
                    <div style="width: 250px; height: 40px; float: left">
                      <div class="section--link" style="margin: 10px 0">
                        <a
                          style="padding-top: 12px; padding-bottom: 12px"
                          v-if="generate_report_url"
                          target="_blank"
                          :href="
                            'https://backend.subsidizing.crocos.kz/api/v1/applications/generate-report?filter[export_format]=excel&' +
                            generate_report_url.join('&')
                          "
                          >{{ $t('labels.download_excel') }}</a
                        >
                        <a
                          style="padding-top: 12px; padding-bottom: 12px"
                          v-else
                          target="_blank"
                          :href="'https://backend.subsidizing.crocos.kz/api/v1/applications/generate-report?filter[export_format]=excel'"
                          >{{ $t('labels.download_excel') }}</a
                        >
                      </div>
                    </div>
                  </div>
                  <!--
									<div>
										<div style="width: 250px; height: 40px; float: left;">
											<div class="section--link">
												<a style="padding-top: 12px; padding-bottom: 12px;" v-if="generate_report_url" target="_blank" :href="'https://backend.subsidizing.crocos.kz/api/v1/applications/generate-report?' + generate_report_url.join('&')">Скачать отчет</a>
												<a style="padding-top: 12px; padding-bottom: 12px;" v-else target="_blank" :href="'https://backend.subsidizing.crocos.kz/api/v1/applications/generate-report'">Скачать отчет excel</a>
											</div>
										</div>
									</div>
									-->
                </div>
                <!-- :items="applications.data" -->
                <v-data-table
                  :headers="headers"
                  :items="filteredlist"
                  :loading="loading"
                  :options.sync="options"
                  :footer-props="{
                    'items-per-page-options': [5, 10, 20, 30, 40, 50],
                  }"
                >
                  <template slot="applications.data" slot-scope="props">
                    <td>{{ props.id }}</td>
                    <td>{{ props.name }}</td>
                    <td>{{ props.item.date }}</td>
                    <td>{{ props.item.time }}</td>
                  </template>
                  <template v-slot:item.status="{ item }">
                    {{ getStatusText(item.status) }}
                  </template>
                  <template v-slot:item.action="{ item }">
                    <td>
                      <a
                        class="tb__table--btn"
                        v-if="item.category_id == 'foreign'"
                        :href="
                          '/' +
                          $i18n.locale +
                          '/administration/applications/tourist-views/' +
                          item.id +
                          '/edit'
                        "
                        style="
                          margin: 0 auto;
                          display: block;
                          width: 21px;
                          height: 21px;
                        "
                      >
                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M.875 10.5s3.5-7 9.625-7 9.625 7 9.625 7-3.5 7-9.625 7-9.625-7-9.625-7z" stroke="#FFCC47" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M10.5 13.125a2.625 2.625 0 100-5.25 2.625 2.625 0 000 5.25z" stroke="#FFCC47" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </a>
                      <a
                        class="tb__table--btn"
                        v-if="item.category_id == 'kids'"
                        :href="
                          '/' +
                          $i18n.locale +
                          '/administration/applications/kids-go-free/' +
                          item.id +
                          '/edit'
                        "
                        style="
                          margin: 0 auto;
                          display: block;
                          width: 21px;
                          height: 21px;
                        "
                      >
                        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M.875 10.5s3.5-7 9.625-7 9.625 7 9.625 7-3.5 7-9.625 7-9.625-7-9.625-7z" stroke="#FFCC47" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M10.5 13.125a2.625 2.625 0 100-5.25 2.625 2.625 0 000 5.25z" stroke="#FFCC47" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </a>
                    </td>
                  </template>
                  <v-alert slot="no-results" :value="true" color="error"
                    >{{ $t('empty_search_results', [filters.search]) }}</v-alert
                  >
                </v-data-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from '../../boot/axios'
import Multiselect from 'vue-multiselect'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  name: 'Account',
  components: {
    Multiselect,
    DatePicker,
  },
  data() {
    return {
      applications: null,
      approved_applications: null,
      rejected_applications: null,
      user: null,
      generate_report_url: null,
      periods: [
        {
          title: this.$t('labels.all'),
          value: 'all',
        },
        {
          title: this.$t('labels.month'),
          value: 'last month',
        },
        {
          title: this.$t('labels.quater'),
          value: 'last 3 months',
        },
        {
          title: this.$t('labels.half_year'),
          value: 'last 6 months',
        },
        {
          title: this.$t('labels.year'),
          value: 'last year',
        },
        {
          title: this.$t('labels.other_period'),
          value: 'another',
        },
      ],
      period: null,
      another_period_start: null,
      another_period_end: null,
      orderList: [
        {
          status: 'all',
          name: this.$t('labels.all'),
        },
        {
          status: 'sent-by',
          name: this.$t('labels.received'),
        },
        {
          status: 'registered',
          name: this.$t('labels.registered'),
        },
        {
          status: 'resend',
          name: this.$t('labels.resend'),
        },
        {
          status: 'denied',
          name: this.$t('labels.denied'),
        },
        {
          status: 'in-work',
          name: this.$t('labels.in-work'),
        },
        {
          status: 'approved',
          name: this.$t('labels.approved'),
        },
        {
          status: 'paid',
          name: this.$t('labels.paid'),
        },
      ],
      Categories: 'all',
      filterCategories: {
        status: 'all',
        name: this.$t('labels.all'),
      },
      filters: {
        search: '',
      },
      page: 0,
      totalPassengers: 0,
      loading: false,
      options: {
        itemsPerPage: 5,
        page: 1,
      },
      headers: [
        {
          text: 'ID',
          value: 'id',
        },
        {
          text: this.$t('labels.tour_name'),
          value: 'applicant_name',
        },
        {
          text: this.$t('labels.full_tour'),
          value: 'tourist',
        },
        {
          text: this.$t('labels.app_view'),
          value: 'type',
          width: '200px',
        },
        {
          text: this.$t('labels.date_and_time'),
          value: 'datetime',
        },
        {
          text: this.$t('labels.status'),
          value: 'status',
        },
        {
          text: this.$t('labels.action'),
          value: 'action',
          align: 'center',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    getStatusText() {
      return (item) => {
        return (
          this.orderList.find((e) => e.status === item.slug)?.name || item.name
        )
      }
    },
    filteredlist() {
      //	console.log(this.filterCategories);

      return this.applications.data.filter((d) => {
        if (
          d.view.name
            .toLowerCase()
            .includes(this.filters.search.toLowerCase()) &&
          (this.filterCategories.status == 'all' ||
            d.status.slug == this.filterCategories.status) &&
          (this.Categories == 'all' || d.category_id == this.Categories)
        ) {
          return true
        }

        /*
					if ((d.view.name.toLowerCase().includes(this.filters.search.toLowerCase()) || d.tourist.toLowerCase().includes(this.filters.search.toLowerCase()) || d.date.toLowerCase().includes(this.filters.search.toLowerCase())) && (d.status.slug.toLowerCase().includes())) {
						return true;
					}
					*/
      })
    },

    listenChange() {
      const { period, filterCategories } = this
      return { period, filterCategories }
    },
  },
  methods: {
    getApplications() {
      this.generate_report_url = []

      if (this.period && this.period.value != 'all')
        this.generate_report_url.push('filter[period]=' + this.period.value)
      if (this.period && this.period.value == 'another')
        this.generate_report_url.push(
          'filter[another_date_start]=' + this.another_period_start,
        )
      if (this.period && this.period.value == 'another')
        this.generate_report_url.push(
          'filter[another_date_end]=' + this.another_period_end,
        )
      if (this.filterCategories && this.filterCategories.status != 'all')
        this.generate_report_url.push(
          'filter[status]=' + this.filterCategories.status,
        )

      api
        .get('applications', {
          params: {
            'filter[period]': this.period ? this.period.value : null,
            'filter[another_period_start]':
              this.period && this.period.value == 'another'
                ? this.another_period_start
                : null,
            'filter[another_period_end]':
              this.period && this.period.value == 'another'
                ? this.another_period_end
                : null,
            'filter[status]':
              this.filterCategories && this.filterCategories.status != 'all'
                ? this.filterCategories.status
                : null,
          },
        })
        .then((response) => {
          if (response.data) {
            this.applications = response.data
          }
        })
    },
    categoriesTab(category) {
      this.Categories = category

      if (category == 'foreign') this.headers[1].text = 'ФИО туриста'
      if (category == 'kids') this.headers[1].text = 'ФИО пассажира'
      if (category == 'all') this.headers[1].text = this.$t('labels.full_tour')
    },
    filterTab(category) {
      this.filterCategories = category

      //	console.log(category);
    },
    onClickPeriod(item) {
      this.period = item

      if (
        ['last month', 'last 3 months', 'last 6 months', 'last year'].includes(
          item.value,
        )
      ) {
        this.getApplications()
      }
    },
    onClickDownloadReport() {
      api
        .get('applications/generate-report', {
          params: {
            'filter[period]': this.period ? this.period.value : null,
            'filter[another_period_start]':
              this.period && this.period.value == 'another'
                ? this.another_period_start
                : null,
            'filter[another_period_end]':
              this.period && this.period.value == 'another'
                ? this.another_period_end
                : null,
          },
        })
        .then((response) => {
          if (response.data) {
            this.applications = response.data
          }
        })
    },
  },
  watch: {
    listenChange() {
      this.getApplications()
    },
    another_period_start() {
      if (this.another_period_start && this.another_period_end) {
        this.getApplications()
      }
    },
    another_period_end() {
      if (this.another_period_start && this.another_period_end) {
        this.getApplications()
      }
    },
  },
  beforeCreate() {
    if (!localStorage.token) {
      this.$router.push('/' + this.$i18n.locale + '/login')
    }

    api.get('user').then((response) => {
      if (response.data) {
        this.user = response.data
      }
    })

    api
      .get('applications', {
        params: {},
      })
      .then((response) => {
        if (response.data) {
          this.applications = response.data
        }
      })

    api
      .get('applications', {
        params: {
          'filter[status]': 'approved',
        },
      })
      .then((response) => {
        if (response.data) {
          this.approved_applications = response.data
        }
      })

    api
      .get('applications', {
        params: {
          'filter[status]': 'denied',
        },
      })
      .then((response) => {
        if (response.data) {
          this.rejected_applications = response.data
        }
      })
  },
}
</script>

<style scoped></style>
