import { render, staticRenderFns } from "./MainSectionTitle.vue?vue&type=template&id=051ae09a&scoped=true&"
import script from "./MainSectionTitle.vue?vue&type=script&lang=js&"
export * from "./MainSectionTitle.vue?vue&type=script&lang=js&"
import style0 from "./MainSectionTitle.vue?vue&type=style&index=0&id=051ae09a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "051ae09a",
  null
  
)

export default component.exports