<template>
  <div
    class="wrap__content"
    v-bind:class="{ 'wrap__content--placeholder': !citizenships }"
  >
    <div class="page__placeholder" v-if="!citizenships">
      <div class="form__block--placeholder">
        <div class="form__placeholder--circle">
          <div class="placeholder__circle">
            <svg width="73" height="73" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><circle cx="36.5" cy="36" r="24" stroke="#FFF7E1" stroke-width="2"/><g filter="url(#filter0_d_22_5658)"><path d="M16.16 23A24.208 24.208 0 0012 36.605C12 50.078 22.969 61 36.5 61S61 50.078 61 36.605c0-1.736-.182-3.43-.528-5.064" stroke="#FFCC47" stroke-width="4" stroke-linecap="round"/></g><defs><filter id="filter0_d_22_5658" x="5" y="16" width="63" height="52" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset/><feGaussianBlur stdDeviation="2.5"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix values="0 0 0 0 1 0 0 0 0 0.722826 0 0 0 0 0 0 0 0 0.5 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_22_5658"/><feBlend in="SourceGraphic" in2="effect1_dropShadow_22_5658" result="shape"/></filter></defs></svg>
          </div>
        </div>
        <div class="form__placeholder--logo">
          <img src="../../../../assets/img/logo.svg" alt="" />
        </div>
      </div>
    </div>
    <div class="content__page--block" v-if="citizenships">
      <notifications position="center bottom" />

      <div class="content__page content__page--margintop">
        <div class="container">
          <div class="content__page--back">
            <a @click="$router.go(-1)">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19 12H5M12 19l-7-7 7-7" stroke="#0E1839" stroke-linecap="round" stroke-linejoin="round"/></svg>
              {{ $t('system.back') }}
            </a>
          </div>

          <form method="POST" @submit.prevent="onSubmit">
            <div class="content__title--block">
              <div class="section__title--mini">
                {{ $t('applic_for_subs_costs_tour') }}
              </div>
              <div class="content__title section__title">
                <strong>за каждого иностранного туриста</strong>
              </div>
            </div>

            <div class="add_tourist--section" v-if="!application.clients.value">
              <div class="add_tourist__section--title">
                Данные иностранного туриста
              </div>
              <div class="add_tourist__section--btn">
                <div class="form__block--line">
                  <div class="form__block--input">
                    <div class="section--link form__file--blue">
                      <!--<a @click="$modal.show('registry__down'); toggleCamera();">Загрузить / фотографировать</a>-->
                      <a @click="$modal.show('registry__down')"
                        >Загрузить / фотографировать</a
                      >
                    </div>
                  </div>
                </div>
                <div class="form__block--line">
                  <div class="form__block--input">
                    <div class="section--link form__file--yellow">
                      <a @click="$modal.show('registry__add')"
                        >Ввести вручную</a
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div
                style="
                  width: 100%;
                  text-align: center;
                  display: block;
                  padding: 10px;
                  color: red;
                "
                v-if="!application.clients.value && application.clients.error"
              >
                {{ application.clients.error }}
              </div>
            </div>
            <div
              class="add_tourist--section add_tourist--section-transition"
              v-if="application.clients.value"
            >
              <div class="add add_tourist--block">
                <div class="add_tourist__section--title">
                  Данные иностранного туриста
                </div>
                <v-data-table
                  :headers="headers"
                  :items="application.clients.value"
                  :loading="loading"
                  :options.sync="options"
                  hide-default-footer
                >
                  <template v-slot:item.action="{ item }">
                    <a
                      class="tb__table--btn tb__table--edit"
                      @click="$modal.show('registry__add'), client_reset()"
                      :data-id="item.id"
                    >
                      <!-- :href="'/'+$i18n.locale + '/registry/view-'+ item.id" -->
                      <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M9.625 3.5H3.5a1.75 1.75 0 00-1.75 1.75V17.5a1.75 1.75 0 001.75 1.75h12.25a1.75 1.75 0 001.75-1.75v-6.125" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M16.188 2.187a1.856 1.856 0 012.625 2.625L10.5 13.125 7 14l.875-3.5 8.313-8.313z" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </a>
                  </template>
                </v-data-table>
              </div>
            </div>

            <div class="add_tourist--section">
              <div class="add_tourist__section--title">
                Подтверждающие документы
              </div>

              <div class="add_tourist--filelist">
                <div class="fileitem--col">
                  <label
                    class="add_tourist--fileitem"
                    :class="fileDocuments1.fileClass"
                    v-if="
                      !application
                        .a_copy_of_the_identity_document_of_a_foreign_tourist
                        .error
                    "
                  >
                    <input
                      type="file"
                      class="add_tourist--input"
                      ref="fileDocuments1"
                      name="file_document_1"
                      accept=".pdf,.png,.jpg,.doc,.docx,.exel"
                      v-on:change="handleFileUpload1()"
                    />
                    <div class="fileitem__block">
                      <div class="fileitem__block--title">
                        Копия документа, удостоверяющего личность иностранного
                        туриста <span style="color: red">*</span>
                      </div>
                      <div
                        class="fileitem__block--file"
                        v-if="fileDocuments1.fileName"
                      >
                        <div class="fileitem__block--icon">
                          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="fileitem__block--text">
                          {{ fileDocuments1.fileName }}
                        </div>
                      </div>
                      <div class="fileitem__block--file" v-else>
                        <div class="fileitem__block--icon">
                          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19 14.563l-6.146 6.243A3.984 3.984 0 0110.014 22a3.984 3.984 0 01-2.838-1.194A4.11 4.11 0 016 17.922a4.11 4.11 0 011.176-2.883l6.146-6.243A2.656 2.656 0 0115.215 8c.71 0 1.39.286 1.892.796a2.74 2.74 0 01.784 1.923 2.74 2.74 0 01-.784 1.922l-6.152 6.242a1.328 1.328 0 01-1.893 0 1.37 1.37 0 010-1.922l5.678-5.76" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="fileitem__block--text">
                          Загрузить (doc, pdf, excel)
                        </div>
                      </div>
                    </div>
                    <span
                      class="fileitem__block--help"
                      v-tooltip.top-center="
                        'Договор на туристское обслуживание, заключенного между туроператором и иностранным туристом, составленного  в соответствии с приказом Министра по инвестициям  и развитию Республики Казахстан от 30 января 2015 года  № 81 «Об утверждении типового договора на туристское  обслуживание»'
                      "
                      >?</span
                    >
                  </label>
                  <label
                    class="add_tourist--fileitem"
                    :class="fileDocuments1.fileClass"
                    v-if="
                      application
                        .a_copy_of_the_identity_document_of_a_foreign_tourist
                        .error
                    "
                    style="background-color: red"
                  >
                    <input
                      type="file"
                      class="add_tourist--input"
                      ref="fileDocuments1"
                      name="file_document_1"
                      accept=".pdf,.png,.jpg,.doc,.docx,.exel"
                      v-on:change="handleFileUpload1()"
                    />
                    <div class="fileitem__block">
                      <div class="fileitem__block--title" style="color: #fff">
                        Копия документа, удостоверяющего личность иностранного
                        туриста <span style="color: red">*</span>
                      </div>
                      <div
                        class="fileitem__block--file"
                        v-if="fileDocuments1.fileName"
                      >
                        <div class="fileitem__block--icon">
                          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="fileitem__block--text">
                          {{ fileDocuments1.fileName }}
                        </div>
                      </div>
                      <div class="fileitem__block--file" v-else>
                        <div class="fileitem__block--icon">
                          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19 14.563l-6.146 6.243A3.984 3.984 0 0110.014 22a3.984 3.984 0 01-2.838-1.194A4.11 4.11 0 016 17.922a4.11 4.11 0 011.176-2.883l6.146-6.243A2.656 2.656 0 0115.215 8c.71 0 1.39.286 1.892.796a2.74 2.74 0 01.784 1.923 2.74 2.74 0 01-.784 1.922l-6.152 6.242a1.328 1.328 0 01-1.893 0 1.37 1.37 0 010-1.922l5.678-5.76" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="fileitem__block--text" style="color: #fff">
                          Загрузить (doc, pdf, excel)
                        </div>
                      </div>
                    </div>
                    <span
                      class="fileitem__block--help"
                      v-tooltip.top-center="
                        'Договор на туристское обслуживание, заключенного между туроператором и иностранным туристом, составленного  в соответствии с приказом Министра по инвестициям  и развитию Республики Казахстан от 30 января 2015 года  № 81 «Об утверждении типового договора на туристское  обслуживание»'
                      "
                      >?</span
                    >
                  </label>
                </div>

                <!-- block 2 -->
                <div class="fileitem--col">
                  <label
                    class="add_tourist--fileitem"
                    :class="fileDocuments2.fileClass"
                    v-if="
                      !application
                        .tourist_service_agreement_with_a_foreign_tourist.error
                    "
                  >
                    <input
                      type="file"
                      class="add_tourist--input"
                      ref="fileDocuments2"
                      name="file_document_2"
                      accept=".pdf,.png,.jpg,.doc,.docx,.exel"
                      v-on:change="handleFileUpload2()"
                    />
                    <div class="fileitem__block">
                      <div class="fileitem__block--title">
                        Договор на туристское обслуживание, с иностранным
                        туристом <span style="color: red">*</span>
                      </div>

                      <div
                        class="fileitem__block--file"
                        v-if="fileDocuments2.fileName"
                      >
                        <div class="fileitem__block--icon">
                          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="fileitem__block--text">
                          {{ fileDocuments2.fileName }}
                        </div>
                      </div>
                      <div class="fileitem__block--file" v-else>
                        <div class="fileitem__block--icon">
                          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19 14.563l-6.146 6.243A3.984 3.984 0 0110.014 22a3.984 3.984 0 01-2.838-1.194A4.11 4.11 0 016 17.922a4.11 4.11 0 011.176-2.883l6.146-6.243A2.656 2.656 0 0115.215 8c.71 0 1.39.286 1.892.796a2.74 2.74 0 01.784 1.923 2.74 2.74 0 01-.784 1.922l-6.152 6.242a1.328 1.328 0 01-1.893 0 1.37 1.37 0 010-1.922l5.678-5.76" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="fileitem__block--text">
                          Загрузить (doc, pdf, excel)
                        </div>
                      </div>
                    </div>
                    <span
                      class="fileitem__block--help"
                      v-tooltip.top-center="
                        'Договор на туристское обслуживание, заключенного между туроператором и иностранным туристом, составленного  в соответствии с приказом Министра по инвестициям  и развитию Республики Казахстан от 30 января 2015 года  № 81 «Об утверждении типового договора на туристское  обслуживание»'
                      "
                      >?</span
                    >
                  </label>
                  <label
                    class="add_tourist--fileitem"
                    :class="fileDocuments2.fileClass"
                    v-if="
                      application
                        .tourist_service_agreement_with_a_foreign_tourist.error
                    "
                    style="background-color: red"
                  >
                    <input
                      type="file"
                      class="add_tourist--input"
                      ref="fileDocuments2"
                      name="file_document_2"
                      accept=".pdf,.png,.jpg,.doc,.docx,.exel"
                      v-on:change="handleFileUpload2()"
                    />
                    <div class="fileitem__block">
                      <div class="fileitem__block--title" style="color: #fff">
                        Договор на туристское обслуживание, с иностранным
                        туристом <span style="color: red">*</span>
                      </div>

                      <div
                        class="fileitem__block--file"
                        v-if="fileDocuments2.fileName"
                      >
                        <div class="fileitem__block--icon">
                          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="fileitem__block--text" style="color: #fff">
                          {{ fileDocuments2.fileName }}
                        </div>
                      </div>
                      <div class="fileitem__block--file" v-else>
                        <div class="fileitem__block--icon">
                          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19 14.563l-6.146 6.243A3.984 3.984 0 0110.014 22a3.984 3.984 0 01-2.838-1.194A4.11 4.11 0 016 17.922a4.11 4.11 0 011.176-2.883l6.146-6.243A2.656 2.656 0 0115.215 8c.71 0 1.39.286 1.892.796a2.74 2.74 0 01.784 1.923 2.74 2.74 0 01-.784 1.922l-6.152 6.242a1.328 1.328 0 01-1.893 0 1.37 1.37 0 010-1.922l5.678-5.76" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="fileitem__block--text" style="color: #fff">
                          Загрузить (doc, pdf, excel)
                        </div>
                      </div>
                    </div>
                    <span
                      class="fileitem__block--help"
                      v-tooltip.top-center="
                        'Договор на туристское обслуживание, заключенного между туроператором и иностранным туристом, составленного  в соответствии с приказом Министра по инвестициям  и развитию Республики Казахстан от 30 января 2015 года  № 81 «Об утверждении типового договора на туристское  обслуживание»'
                      "
                      >?</span
                    >
                  </label>
                </div>

                <!-- block 3 -->
                <div class="fileitem--col">
                  <label
                    class="add_tourist--fileitem"
                    :class="fileDocuments3.fileClass"
                    v-if="
                      !application
                        .document_confirming_payment_for_the_tourist_product_under_the_agreement
                        .error
                    "
                  >
                    <input
                      type="file"
                      class="add_tourist--input"
                      ref="fileDocuments3"
                      name="file_document_3"
                      accept=".pdf,.png,.jpg,.doc,.docx,.exel"
                      v-on:change="handleFileUpload3()"
                    />
                    <div class="fileitem__block">
                      <div class="fileitem__block--title">
                        Документ, подтверждающий оплату туристского продукта по
                        Договору <span style="color: red">*</span>
                      </div>

                      <div
                        class="fileitem__block--file"
                        v-if="fileDocuments3.fileName"
                      >
                        <div class="fileitem__block--icon">
                          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="fileitem__block--text">
                          {{ fileDocuments3.fileName }}
                        </div>
                      </div>
                      <div class="fileitem__block--file" v-else>
                        <div class="fileitem__block--icon">
                          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19 14.563l-6.146 6.243A3.984 3.984 0 0110.014 22a3.984 3.984 0 01-2.838-1.194A4.11 4.11 0 016 17.922a4.11 4.11 0 011.176-2.883l6.146-6.243A2.656 2.656 0 0115.215 8c.71 0 1.39.286 1.892.796a2.74 2.74 0 01.784 1.923 2.74 2.74 0 01-.784 1.922l-6.152 6.242a1.328 1.328 0 01-1.893 0 1.37 1.37 0 010-1.922l5.678-5.76" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="fileitem__block--text">
                          Загрузить (doc, pdf, excel)
                        </div>
                      </div>
                    </div>
                    <span
                      class="fileitem__block--help"
                      v-tooltip.top-center="
                        'Договор на туристское обслуживание, заключенного между туроператором и иностранным туристом, составленного  в соответствии с приказом Министра по инвестициям  и развитию Республики Казахстан от 30 января 2015 года  № 81 «Об утверждении типового договора на туристское  обслуживание»'
                      "
                      >?</span
                    >
                  </label>
                  <label
                    class="add_tourist--fileitem"
                    :class="fileDocuments3.fileClass"
                    v-if="
                      application
                        .document_confirming_payment_for_the_tourist_product_under_the_agreement
                        .error
                    "
                    style="background-color: red"
                  >
                    <input
                      type="file"
                      class="add_tourist--input"
                      ref="fileDocuments3"
                      name="file_document_3"
                      accept=".pdf,.png,.jpg,.doc,.docx,.exel"
                      v-on:change="handleFileUpload3()"
                    />
                    <div class="fileitem__block">
                      <div class="fileitem__block--title" style="color: #fff">
                        Документ, подтверждающий оплату туристского продукта по
                        Договору <span style="color: red">*</span>
                      </div>

                      <div
                        class="fileitem__block--file"
                        v-if="fileDocuments3.fileName"
                      >
                        <div class="fileitem__block--icon">
                          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="fileitem__block--text" style="color: #fff">
                          {{ fileDocuments3.fileName }}
                        </div>
                      </div>
                      <div class="fileitem__block--file" v-else>
                        <div class="fileitem__block--icon">
                          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19 14.563l-6.146 6.243A3.984 3.984 0 0110.014 22a3.984 3.984 0 01-2.838-1.194A4.11 4.11 0 016 17.922a4.11 4.11 0 011.176-2.883l6.146-6.243A2.656 2.656 0 0115.215 8c.71 0 1.39.286 1.892.796a2.74 2.74 0 01.784 1.923 2.74 2.74 0 01-.784 1.922l-6.152 6.242a1.328 1.328 0 01-1.893 0 1.37 1.37 0 010-1.922l5.678-5.76" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="fileitem__block--text" style="color: #fff">
                          Загрузить (doc, pdf, excel)
                        </div>
                      </div>
                    </div>
                    <span
                      class="fileitem__block--help"
                      v-tooltip.top-center="
                        'Договор на туристское обслуживание, заключенного между туроператором и иностранным туристом, составленного  в соответствии с приказом Министра по инвестициям  и развитию Республики Казахстан от 30 января 2015 года  № 81 «Об утверждении типового договора на туристское  обслуживание»'
                      "
                      >?</span
                    >
                  </label>
                </div>

                <div class="fileitem--col">
                  <label
                    class="add_tourist--fileitem"
                    :class="fileDocuments4.fileClass"
                    v-if="
                      !application
                        .the_fact_of_a_tourist_crossing_the_state_border_of_the_republic_of_kazakhstan
                        .error
                    "
                  >
                    <input
                      type="file"
                      class="add_tourist--input"
                      ref="fileDocuments4"
                      name="file_document_4"
                      accept=".pdf,.png,.jpg,.doc,.docx,.exel"
                      v-on:change="handleFileUpload4()"
                    />
                    <div class="fileitem__block">
                      <div class="fileitem__block--title">
                        Факт пересечения государственной границы РК туриста
                        <span style="color: red">*</span>
                      </div>

                      <div
                        class="fileitem__block--file"
                        v-if="fileDocuments4.fileName"
                      >
                        <div class="fileitem__block--icon">
                          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="fileitem__block--text">
                          {{ fileDocuments4.fileName }}
                        </div>
                      </div>
                      <div class="fileitem__block--file" v-else>
                        <div class="fileitem__block--icon">
                          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19 14.563l-6.146 6.243A3.984 3.984 0 0110.014 22a3.984 3.984 0 01-2.838-1.194A4.11 4.11 0 016 17.922a4.11 4.11 0 011.176-2.883l6.146-6.243A2.656 2.656 0 0115.215 8c.71 0 1.39.286 1.892.796a2.74 2.74 0 01.784 1.923 2.74 2.74 0 01-.784 1.922l-6.152 6.242a1.328 1.328 0 01-1.893 0 1.37 1.37 0 010-1.922l5.678-5.76" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="fileitem__block--text">
                          Загрузить (doc, pdf, excel)
                        </div>
                      </div>
                    </div>
                    <span
                      class="fileitem__block--help"
                      v-tooltip.top-center="
                        'Договор на туристское обслуживание, заключенного между туроператором и иностранным туристом, составленного  в соответствии с приказом Министра по инвестициям  и развитию Республики Казахстан от 30 января 2015 года  № 81 «Об утверждении типового договора на туристское  обслуживание»'
                      "
                      >?</span
                    >
                  </label>
                  <label
                    class="add_tourist--fileitem"
                    :class="fileDocuments4.fileClass"
                    v-if="
                      application
                        .the_fact_of_a_tourist_crossing_the_state_border_of_the_republic_of_kazakhstan
                        .error
                    "
                    style="background-color: red"
                  >
                    <input
                      type="file"
                      class="add_tourist--input"
                      ref="fileDocuments4"
                      name="file_document_4"
                      accept=".pdf,.png,.jpg,.doc,.docx,.exel"
                      v-on:change="handleFileUpload4()"
                    />
                    <div class="fileitem__block">
                      <div class="fileitem__block--title" style="color: #fff">
                        Факт пересечения государственной границы РК туриста
                        <span style="color: red">*</span>
                      </div>

                      <div
                        class="fileitem__block--file"
                        v-if="fileDocuments4.fileName"
                      >
                        <div class="fileitem__block--icon">
                          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="fileitem__block--text" style="color: #fff">
                          {{ fileDocuments4.fileName }}
                        </div>
                      </div>
                      <div class="fileitem__block--file" v-else>
                        <div class="fileitem__block--icon">
                          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19 14.563l-6.146 6.243A3.984 3.984 0 0110.014 22a3.984 3.984 0 01-2.838-1.194A4.11 4.11 0 016 17.922a4.11 4.11 0 011.176-2.883l6.146-6.243A2.656 2.656 0 0115.215 8c.71 0 1.39.286 1.892.796a2.74 2.74 0 01.784 1.923 2.74 2.74 0 01-.784 1.922l-6.152 6.242a1.328 1.328 0 01-1.893 0 1.37 1.37 0 010-1.922l5.678-5.76" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="fileitem__block--text" style="color: #fff">
                          Загрузить (doc, pdf, excel)
                        </div>
                      </div>
                    </div>
                    <span
                      class="fileitem__block--help"
                      v-tooltip.top-center="
                        'Договор на туристское обслуживание, заключенного между туроператором и иностранным туристом, составленного  в соответствии с приказом Министра по инвестициям  и развитию Республики Казахстан от 30 января 2015 года  № 81 «Об утверждении типового договора на туристское  обслуживание»'
                      "
                      >?</span
                    >
                  </label>
                </div>

                <div class="fileitem--col">
                  <label
                    class="add_tourist--fileitem"
                    :class="fileDocuments5.fileClass"
                    v-if="
                      !application.attach_passenger_coupon_boarding_pass.error
                    "
                  >
                    <input
                      type="file"
                      class="add_tourist--input"
                      ref="fileDocuments5"
                      name="file_document_5"
                      accept=".pdf,.png,.jpg,.doc,.docx,.exel"
                      v-on:change="handleFileUpload5()"
                    />
                    <div class="fileitem__block">
                      <div class="fileitem__block--title">
                        Копии билета или электронного билета, маршрутной квитанции и пассажирского купона или справки-подтверждения об использовании билета авиакомпании на несовершеннолетнего пассажира
                      </div>

                      <div
                        class="fileitem__block--file"
                        v-if="fileDocuments5.fileName"
                      >
                        <div class="fileitem__block--icon">
                          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="fileitem__block--text">
                          {{ fileDocuments5.fileName }}
                        </div>
                      </div>
                      <div class="fileitem__block--file" v-else>
                        <div class="fileitem__block--icon">
                          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19 14.563l-6.146 6.243A3.984 3.984 0 0110.014 22a3.984 3.984 0 01-2.838-1.194A4.11 4.11 0 016 17.922a4.11 4.11 0 011.176-2.883l6.146-6.243A2.656 2.656 0 0115.215 8c.71 0 1.39.286 1.892.796a2.74 2.74 0 01.784 1.923 2.74 2.74 0 01-.784 1.922l-6.152 6.242a1.328 1.328 0 01-1.893 0 1.37 1.37 0 010-1.922l5.678-5.76" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="fileitem__block--text">
                          Загрузить (doc, pdf, excel)
                        </div>
                      </div>
                    </div>
                    <span
                      class="fileitem__block--help"
                      v-tooltip.top-center="
                        'Договор на туристское обслуживание, заключенного между туроператором и иностранным туристом, составленного  в соответствии с приказом Министра по инвестициям  и развитию Республики Казахстан от 30 января 2015 года  № 81 «Об утверждении типового договора на туристское  обслуживание»'
                      "
                      >?</span
                    >
                  </label>
                  <label
                    class="add_tourist--fileitem"
                    :class="fileDocuments5.fileClass"
                    v-if="
                      application.attach_passenger_coupon_boarding_pass.error
                    "
                    style="background-color: red"
                  >
                    <input
                      type="file"
                      class="add_tourist--input"
                      ref="fileDocuments5"
                      name="file_document_5"
                      accept=".pdf,.png,.jpg,.doc,.docx,.exel"
                      v-on:change="handleFileUpload5()"
                    />
                    <div class="fileitem__block">
                      <div class="fileitem__block--title" style="color: #fff">
                        Копии билета или электронного билета, маршрутной квитанции и пассажирского купона или справки-подтверждения об использовании билета авиакомпании на несовершеннолетнего пассажира
                      </div>

                      <div
                        class="fileitem__block--file"
                        v-if="fileDocuments5.fileName"
                      >
                        <div class="fileitem__block--icon">
                          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="fileitem__block--text" style="color: #fff">
                          {{ fileDocuments5.fileName }}
                        </div>
                      </div>
                      <div class="fileitem__block--file" v-else>
                        <div class="fileitem__block--icon">
                          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19 14.563l-6.146 6.243A3.984 3.984 0 0110.014 22a3.984 3.984 0 01-2.838-1.194A4.11 4.11 0 016 17.922a4.11 4.11 0 011.176-2.883l6.146-6.243A2.656 2.656 0 0115.215 8c.71 0 1.39.286 1.892.796a2.74 2.74 0 01.784 1.923 2.74 2.74 0 01-.784 1.922l-6.152 6.242a1.328 1.328 0 01-1.893 0 1.37 1.37 0 010-1.922l5.678-5.76" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="fileitem__block--text" style="color: #fff">
                          Загрузить (doc, pdf, excel)
                        </div>
                      </div>
                    </div>
                    <span
                      class="fileitem__block--help"
                      v-tooltip.top-center="
                        'Договор на туристское обслуживание, заключенного между туроператором и иностранным туристом, составленного  в соответствии с приказом Министра по инвестициям  и развитию Республики Казахстан от 30 января 2015 года  № 81 «Об утверждении типового договора на туристское  обслуживание»'
                      "
                      >?</span
                    >
                  </label>
                </div>
              </div>

              <div class="form__block--selectline">
                <div class="row">
                  <div class="col-xl-5">
                    <div class="form__block--line">
                      <div class="form__block--input">
                        <div class="form__block--title">
                          Сведения об отсутствии/наличии задолженностей
                          <span style="color: red">*</span>
                        </div>
                        <multiselect
                          v-model="
                            application
                              .information_about_the_absence_presence_of_debts
                              .value
                          "
                          :options="optionsIndebtedness"
                          label="label"
                          placeholder="Сведения о задолженностей"
                        >
                          <template
                            slot="singleLabel"
                            slot-scope="{ option }"
                            >{{ option.label }}</template
                          >
                          <template slot="noOptions">Пусто</template>
                          <template slot="noResult"
                            >Результат не найден</template
                          >
                        </multiselect>
                        <!-- input--error -->
                        <span
                          style="padding: 5px 10px; color: red; font-size: 13px"
                          v-if="
                            !application
                              .information_about_the_absence_presence_of_debts
                              .value &&
                            application
                              .information_about_the_absence_presence_of_debts
                              .error
                          "
                          >{{
                            application
                              .information_about_the_absence_presence_of_debts
                              .error
                          }}</span
                        >
                        <span class="input-required">{{
                          $t('registration.required.error')
                        }}</span>
                        <!--
					  <div class="subsidizing__banner--button section--link">
						<a class="link--active">{{ $t('commands.add') }} +</a>
					  </div>
					  -->
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-5">
                    <div class="form__block--line">
                      <div class="form__block--input">
                        <div class="form__block--title">
                          {{ $t('labels.checkingAccount') }} <span style="color: red">*</span>
                        </div>
                        <multiselect
                          v-model="application.payment_account.value"
                          :options="optionsCheck"
                          label="label"
                          :placeholder="$t('labels.checkingAccount')"
                        >
                          <template
                            slot="singleLabel"
                            slot-scope="{ option }"
                            >{{ option.label }}</template
                          >
                          <template slot="noOptions">Пусто</template>
                          <template slot="noResult"
                            >Результат не найден</template
                          >
                        </multiselect>
                        <!-- input--error -->
                        <span
                          style="padding: 5px 10px; color: red; font-size: 13px"
                          v-if="
                            !application.payment_account.value &&
                            application.payment_account.error
                          "
                          >{{ application.payment_account.error }}</span
                        >
                        <span class="input-required input--error">{{
                          $t('registration.required.error')
                        }}</span>
                        <!--
					  <div class="subsidizing__banner--button section--link">
						<a class="link--active">{{ $t('commands.add') }} +</a>
					  </div>
					  -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <label class="form__block--checkbox">
                <div class="form__checkbox--left">
                  <input
                    type="checkbox"
                    v-model="application.confirm.value"
                    @click="clickConfirm"
                  />
                  <span
                    class="form__checkbox--checkbox"
                    v-if="!application.confirm.error"
                  ></span>
                  <span
                    class="form__checkbox--checkbox"
                    style="border-color: red"
                    v-if="application.confirm.error"
                  ></span>
                </div>
                <div class="form__checkbox--left">
                  <div class="form__checkbox--text">
                    Подтверждаю достоверность представленной информации, в том
                    числе, что не являюсь банкротом, не подлежу процедуре
                    банкротства или ликвидации в соответствии с Законом
                    Республики Казахстан «О реабилитации и банкротстве» и даю
                    согласие на использование сведений, составляющих охраняемую
                    законом тайну, а также на сбор, обработку, хранение,
                    выгрузку и использование персональных данных.
                  </div>
                </div>
              </label>

              <div class="modal__wrapper--footer">
                <div class="section--link">
                  <button
                    type="submit"
                    class="next__stage input__button"
                    @click="application.status.value = 'sent-by'"
                  >
                    Подписать и отправить заявление (ЭЦП)
                  </button>
                  <button
                    class="next__stage input__button btn--blue"
                    @click="application.status.value = 'draft'"
                  >
                    Сохранить в черновик
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <modal
        class="modal__block modal__block--middle modal__block--check"
        name="registry__down"
        :width="680"
        :min-width="680"
        height="auto"
      >
        <div class="modal__block--wrapper registry__download--wrapper">
          <div
            class="modal__block--close"
            @click="$modal.hide('registry__down')"
          >
            <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M31.5 10.5l-21 21M10.5 10.5l21 21" stroke="#0E1839" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
          </div>
          <div class="modal__wrapper">
            <!--<div class="modal__title" @click="toggleCamera">Данные туриста</div>-->
            <div class="modal__title">Данные туриста</div>
            <div class="modal__content">
              <div class="modal__form">
                <div class="modal__scaner--block">
                  <div class="modal__scaner--video" v-show="!isPhotoTaken">
                    <video id="modal__video" ref="camera"></video>
                    <canvas ref="canvas" id="photoTaken"></canvas>
                    <img
                      src="../../../../assets/img/photo-pattern.png"
                      class="modal__scaner__range"
                    />
                  </div>
                  <div
                    class="modal__scaner--video modal__scaner--photo"
                    v-show="isPhotoTaken"
                  >
                    <img :src="imgPhoto" ref="cameraImg" />
                  </div>
                </div>
                <div class="modal__wrapper--footer modal__wrapper--center">
                  <div class="next__stage--block">
                    <div
                      class="section--link"
                      v-if="isCameraOpen && !isLoading"
                    >
                      <a @click="takePhoto" class="next__stage input__button"
                        >Сфотографировать</a
                      >
                    </div>

                    <div
                      class="section--link"
                      v-if="isPhotoTaken && !isCameraOpen"
                    >
                      <a
                        @click="readyImage()"
                        class="next__stage input__button input__button--blue"
                        >Отправить</a
                      >
                      <!--<a @click="toggleCamera" class="next__stage input__button">Переснять</a>-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </modal>
      <modal
        class="modal__block modal__block--middle modal__block--check"
        name="registry__add"
        :width="680"
        :min-width="680"
        height="auto"
      >
        <div class="modal__block--wrapper">
          <div
            class="modal__block--close"
            @click="$modal.hide('registry__add')"
          >
            <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M31.5 10.5l-21 21M10.5 10.5l21 21" stroke="#0E1839" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
          </div>
          <div class="modal__wrapper">
            <div class="modal__title">Данные туриста</div>
            <div class="modal__content">
              <div class="modal__form">
                <form @submit.prevent="userDataFunct()">
                  <!-- $modal.hide('registry__add') -->
                  <div class="form__block--line">
                    <div class="form__block--input">
                      <div class="form__block--title">
                        {{ $t('registration.input.name') }}
                        <span style="color: red">*</span>
                      </div>
                      <input
                        type="text"
                        class="input-linck"
                        :class="[client.name.error ? 'input--error' : '']"
                        v-model="client.name.value"
                      />
                      <!-- input--error -->
                      <!--<span class="input-required">{{ $t('registration.required.error') }}</span>-->
                      <span
                        class="input-required"
                        :class="[client.name.error ? 'input--error' : '']"
                        >{{ client.name.error }}</span
                      >
                    </div>
                  </div>
                  <div class="form__block--line">
                    <div class="form__block--input">
                      <div class="form__block--title">
                        {{ $t('registration.input.surname') }}
                        <span style="color: red">*</span>
                      </div>
                      <input
                        type="text"
                        class="input-linck"
                        :class="[client.surname.error ? 'input--error' : '']"
                        v-model="client.surname.value"
                      />
                      <!-- input--error -->
                      <!-- <span class="input-required">{{ $t('registration.required.error') }}</span>-->
                      <span
                        class="input-required"
                        :class="[client.surname.error ? 'input--error' : '']"
                        >{{ client.surname.error }}</span
                      >
                    </div>
                  </div>
                  <div class="form__block--line">
                    <div class="form__block--input">
                      <div class="form__block--title">{{ $t('labels.patronymic') }}</div>
                      <input
                        type="text"
                        class="input-linck"
                        :class="[client.patronymic.error ? 'input--error' : '']"
                        name="patronymic"
                        v-model="client.patronymic.value"
                      />
                      <!-- input--error -->
                      <!-- <span class="input-required">{{ $t('registration.required.error') }}</span>-->
                      <span
                        class="input-required"
                        :class="[client.patronymic.error ? 'input--error' : '']"
                        >{{ client.patronymic.error }}</span
                      >
                    </div>
                  </div>
                  <div class="form__block--line">
                    <div class="form__block--input">
                      <div class="form__block--title">
                        {{ $t('labels.password_number') }} <span style="color: red">*</span>
                      </div>
                      <input
                        type="text"
                        class="input-linck"
                        :class="[
                          client.document_number.error ? 'input--error' : '',
                        ]"
                        name="document_number"
                        v-model="client.document_number.value"
                      />
                      <!-- input--error -->
                      <!-- <span class="input-required">{{ $t('registration.required.error') }}</span>-->
                      <span
                        class="input-required"
                        :class="[
                          client.document_number.error ? 'input--error' : '',
                        ]"
                        >{{ client.document_number.error }}</span
                      >
                    </div>
                  </div>
                  <div class="form__block--line">
                    <div class="form__block--input">
                      <div class="form__block--title">
                        {{$t('labels.citizenshipe')}} <span style="color: red">*</span>
                      </div>
                      <multiselect
                        v-model="client.citizenship_id.value"
                        :options="citizenships"
                        :clearOnSelect="false"
                        :clear-on-select="false"
                        :placeholder="$t('labels.citizenshipe')"
                        :hideSelected="true"
                      ></multiselect>

                      <!-- input--error -->
                      <!-- <span class="input-required">{{ $t('registration.required.error') }}</span> -->
                      <span
                        class="input-required input--error"
                        :class="[
                          client.citizenship_id.error ? 'input--error' : '',
                        ]"
                        >{{ client.citizenship_id.error }}</span
                      >
                    </div>
                  </div>
                  <div class="form__block--line">
                    <div class="form__block--input">
                      <div class="form__block--title">
                        Период начала пребывания иностранного туриста в
                        Республике Казахстан <span style="color: red">*</span>
                      </div>
                      <input
                        type="date"
                        class="input-linck"
                        :class="[client.date_start.error ? 'input--error' : '']"
                        v-model="client.date_start.value"
                        pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                      /><!-- 18.11.2021 - 20.11.2021 -->
                      <!-- input--error -->
                      <span
                        class="input-required"
                        :class="[client.date_start.error ? 'input--error' : '']"
                        >{{ client.date_start.error }}</span
                      >
                    </div>
                  </div>

                  <div class="form__block--line">
                    <div class="form__block--input">
                      <div class="form__block--title">
                        Период окончания пребывания иностранного туриста в
                        Республике Казахстан <span style="color: red">*</span>
                      </div>
                      <input
                        type="date"
                        class="input-linck"
                        :class="[client.date_end.error ? 'input--error' : '']"
                        v-model="client.date_end.value"
                        pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                      /><!-- 18.11.2021 - 20.11.2021 -->
                      <!-- input--error -->
                      <span
                        class="input-required"
                        :class="[client.date_end.error ? 'input--error' : '']"
                        >{{ client.date_end.error }}</span
                      >
                    </div>
                  </div>

                  <div class="modal__wrapper--footer modal__wrapper--center">
                    <div class="next__stage--block">
                      <div class="next__stage--text">
                        {{ $t('registration.input.required') }} *
                      </div>
                      <div class="section--link">
                        <button
                          class="next__stage input__button"
                          v-if="!application.clients.value"
                        >
                        {{ $t('commands.add') }}
                        </button>
                        <button
                          class="next__stage input__button"
                          v-if="application.clients.value"
                        >
                          Редактировать
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </modal>
    </div>
  </div>
</template>

<script>
import { api } from '../../../../boot/axios'
import Multiselect from 'vue-multiselect'
import * as Validator from 'validatorjs'

export default {
  name: 'AddTourist',
  components: {
    Multiselect,
  },
  data() {
    return {
      application: {
        status: {
          value: null,
          error: null,
        },
        a_copy_of_the_identity_document_of_a_foreign_tourist: {
          value: null,
          error: null,
        },
        tourist_service_agreement_with_a_foreign_tourist: {
          value: null,
          error: null,
        },
        document_confirming_payment_for_the_tourist_product_under_the_agreement:
          {
            value: null,
            error: null,
          },
        the_fact_of_a_tourist_crossing_the_state_border_of_the_republic_of_kazakhstan:
          {
            value: null,
            error: null,
          },
        attach_passenger_coupon_boarding_pass: {
          value: null,
          error: null,
        },
        clients: {
          value: null,
          error: null,
        },
        confirm: {
          value: null,
          error: null,
        },
        information_about_the_absence_presence_of_debts: {
          value: null,
          error: null,
        },
        payment_account: {
          value: null,
          error: null,
        },
      },
      client: {
        surname: {
          value: null,
          error: null,
        },
        name: {
          value: null,
          error: null,
        },
        patronymic: {
          value: null,
          error: null,
        },
        document_number: {
          value: null,
          error: null,
        },
        citizenship_id: {
          value: null,
          error: null,
        },
        date_start: {
          value: null,
          error: null,
        },
        date_end: {
          value: null,
          error: null,
        },
      },
      citizenships: [],
      valueIndebtedness: null,
      optionsIndebtedness: [],
      valueCheck: null,
      optionsCheck: [],
      fileDocuments: {
        fileName: '',
        fileClass: '',
      },
      fileDocuments1: {
        fileName: '',
        fileClass: '',
      },
      fileDocuments2: {
        fileName: '',
        fileClass: '',
      },
      fileDocuments3: {
        fileName: '',
        fileClass: '',
      },
      fileDocuments4: {
        fileName: '',
        fileClass: '',
      },
      fileDocuments5: {
        fileName: '',
        fileClass: '',
      },
      userDataBull: true,
      userDataSave: [],
      userData: {
        name: null,
        surname: null,
        patronymic: null,
        passport: null,
        period: null,
      },
      loading: false,
      options: {
        itemsPerPage: 1,
      },
      headers: [
        {
          text: 'Фамилия туриста',
          value: 'surname',
        },
        {
          text: 'Имя туриста',
          value: 'name',
        },
        {
          text: 'Номер паспорта',
          value: 'document_number',
        },
        {
          text: 'Гражданство',
          value: 'citizenship_id',
        },
        {
          text: 'Период начала пребывания',
          value: 'date_start',
        },
        {
          text: 'Период окончания пребывания',
          value: 'date_end',
        },
        {
          text: this.$t('labels.action'),
          value: 'action',
          align: 'center',
          sortable: false,
        },
      ],
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      imgPhoto: null,
      loaderCapture: false,
      index_for_web: 0,
    }
  },
  beforeCreate() {
    api
      .get('/countries', {
        params: {
          'filterNot[code]': 'kz',
        },
      })
      .then((response) => {
        if (response.data) {
          this.citizenships = []

          for (let i = 0; i < response.data.data.length; i++) {
            this.citizenships[i] = response.data.data[i].name
          }
        }
      })

    api.get('/user').then((response) => {
      if (response.data) {
        if (
          response.data.data.payment_accounts &&
          response.data.data.payment_accounts.length > 0
        ) {
          this.optionsCheck = []
          this.optionsIndebtedness = []

          for (let i = 0; i < response.data.data.payment_accounts.length; i++) {
            this.optionsCheck.push({
              label: response.data.data.payment_accounts[i].number,
              value: response.data.data.payment_accounts[i].id,
            })

            /*
							this.optionsCheck[i] = response.data.data.payment_accounts[i].id
							*/
          }

          for (
            let i = 0;
            i < response.data.data.certificate_of_debts.length;
            i++
          ) {
            this.optionsIndebtedness.push({
              label: response.data.data.certificate_of_debts[i].src.name,
              value: response.data.data.certificate_of_debts[i].id,
            })

            /*
							this.optionsIndebtedness[i] = response.data.data.certificate_of_debts[i].id
							*/
          }
        }

        this.user = response.data
      }
    })
  },
  methods: {
    onSubmit(evt) {
      this.application.clients.error = null
      this.application.a_copy_of_the_identity_document_of_a_foreign_tourist.error =
        null
      this.application.tourist_service_agreement_with_a_foreign_tourist.error =
        null
      this.application.the_fact_of_a_tourist_crossing_the_state_border_of_the_republic_of_kazakhstan.error =
        null
      this.application.attach_passenger_coupon_boarding_pass.error = null
      this.application.confirm.error = null
      this.application.document_confirming_payment_for_the_tourist_product_under_the_agreement.error =
        null
      this.application.information_about_the_absence_presence_of_debts.error =
        null
      this.application.payment_account.error = null

      let rules = {
        'clients.value': 'required',
        'a_copy_of_the_identity_document_of_a_foreign_tourist.value':
          'required',
        'tourist_service_agreement_with_a_foreign_tourist.value': 'required',
        'document_confirming_payment_for_the_tourist_product_under_the_agreement.value':
          'required',
        'the_fact_of_a_tourist_crossing_the_state_border_of_the_republic_of_kazakhstan.value':
          'required',
        'confirm.value': 'required|accepted',
        'information_about_the_absence_presence_of_debts.value': 'required',
        'payment_account.value': 'required',
      }

      let messages = {
        ru: {
          'required.information_about_the_absence_presence_of_debts.value':
            'Поле Сведения об отсутствии/наличии задолженностей обязательно к заполнению.',
          'required.payment_account.value':
            'Поле Расчетный счет обязательно к заполнению.',
          'required.clients.value': 'Заполните данные туриста',
        },
        kz: {
          'required.information_about_the_absence_presence_of_debts.value':
            'Поле Сведения об отсутствии/наличии задолженностей обязательно к заполнению.',
          'required.payment_account.value':
            'Поле Расчетный счет обязательно к заполнению.',
          'required.clients.value': 'Заполните данные туриста',
        },
      }

      let validation = new Validator(
        this.application,
        rules,
        messages[this.$i18n.locale],
      )

      if (validation.fails()) {
        for (let key in validation.errors.errors) {
          if (key == 'clients.value') {
            this.application.clients.error = validation.errors.first(key)
          }

          if (
            key == 'a_copy_of_the_identity_document_of_a_foreign_tourist.value'
          )
            this.application.a_copy_of_the_identity_document_of_a_foreign_tourist.error =
              validation.errors.first(key)
          if (key == 'tourist_service_agreement_with_a_foreign_tourist.value')
            this.application.tourist_service_agreement_with_a_foreign_tourist.error =
              validation.errors.first(key)
          if (
            key ==
            'document_confirming_payment_for_the_tourist_product_under_the_agreement.value'
          )
            this.application.document_confirming_payment_for_the_tourist_product_under_the_agreement.error =
              validation.errors.first(key)
          if (
            key ==
            'the_fact_of_a_tourist_crossing_the_state_border_of_the_republic_of_kazakhstan.value'
          )
            this.application.the_fact_of_a_tourist_crossing_the_state_border_of_the_republic_of_kazakhstan.error =
              validation.errors.first(key)
          if (key == 'confirm.value')
            this.application.confirm.error = validation.errors.first(key)
          if (key == 'information_about_the_absence_presence_of_debts.value')
            this.application.information_about_the_absence_presence_of_debts.error =
              validation.errors.first(key)
          if (key == 'payment_account.value')
            this.application.payment_account.error =
              validation.errors.first(key)
        }

        return
      }

      const formData = new FormData(evt.target)

      formData.append('view_id', 1)
      formData.append('status', this.application.status.value)

      formData.append('tourist[type]', 'adult')
      formData.append(
        'tourist[surname]',
        this.application.clients.value[0].surname,
      )
      formData.append('tourist[name]', this.application.clients.value[0].name)

      if (this.application.clients.value[0].patronymic) {
        formData.append(
          'tourist[patronymic]',
          this.application.clients.value[0].patronymic,
        )
      }

      formData.append(
        'tourist[citizenship_name]',
        this.application.clients.value[0].citizenship_id,
      )

      if (this.application.clients.value[0].document_number) {
        formData.append(
          'tourist[document_number]',
          this.application.clients.value[0].document_number,
        )
      }

      formData.append(
        'tourist[date_start]',
        this.application.clients.value[0].date_start,
      )
      formData.append(
        'tourist[date_end]',
        this.application.clients.value[0].date_end,
      )

      formData.append(
        'certificate_of_debt_id',
        this.application.information_about_the_absence_presence_of_debts.value
          .value,
      )
      formData.append(
        'payment_account_id',
        this.application.payment_account.value.value,
      )

      if (this.application.status.value == 'draft') {
        api
          .post('/applications', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            if (response.data.code == 201) {
              this.$router.push('/' + this.$i18n.locale + '/account')
            }
          })

        return true
      }

      /*

				const websocket = new WebSocket("wss://127.0.0.1:13579/");

				var ready = null;

				let ecpData = {
					path: null,
					owner: null,
					data: null,
				};

				websocket.onopen = () => {
					ready = true;
					handle();
				};

				websocket.onclose = (e) => {
					if (e.wasClean) {
						console.log("connection closed");
					} else {
						if (this.$i18n.locale == 'kz') alert('NCYLayer бағдарламасы іске қосылғандығына көз жеткізіңіз');
						if (this.$i18n.locale == 'ru') alert('Убедитесь, что программа NCALayer запущена');
						if (this.$i18n.locale == 'en') alert('Make sure that the NCALayer program is running');
					
						console.log("error?");
						//setErrormodal(true);
						setTimeout(() => {
						  //setErrormodal(false);
						}, 5000);
					}
				};

				function handle(){
					if (!ready) {
						alert("Убедитесь, что программа NCALayer запущена");
					} else {
						const data = {
							module: "kz.gov.pki.knca.commonUtils",
							method: "signXml",
							args: [
								"PKCS12",
								"AUTHENTICATION",
								"<login><sessionid>caacda70-fd36-45ed-8d94-45a88890f83a</sessionid></login>",
								"",
								"",
							],
						};

						websocket.send(JSON.stringify(data));
					}
				}

				websocket.onmessage = (e) => {
					const data1 = JSON.parse(e.data);

					ecpData.data = data1.responseObject;

					if (typeof ecpData.data === 'string' || ecpData.data instanceof String) {
						const formDataPki = new FormData();

						formDataPki.append("ecp", ecpData.data);

						api.post('pki-check', formDataPki, {
							headers: {
								'Content-Type': 'multipart/form-data',
							}
						}).then(response => {
							if (response.data.code == 401) {
								this.$notify({
									type: 'error',
									title: 'Неверный ЭЦП',
								});
							}

							if (response.data.code == 200) {
								*/
      api
        .post('/applications', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          if (response.data.code == 201) {
            this.$router.push('/' + this.$i18n.locale + '/account')
          }
        })
      /*
							}
						});
					}
				};
				*/
    },
    clickConfirm() {
      if (this.application.confirm.value) {
        this.application.confirm.error = 'the confirm'
      } else {
        this.application.confirm.error = null
      }
    },
    onAddClient() {},
    userDataFunct() {
      this.client.surname.error = null
      this.client.name.error = null
      this.client.patronymic.error = null
      this.client.citizenship_id.error = null
      this.client.document_number.error = null
      this.client.date_start.error = null
      this.client.date_end.error = null

      let rules = {
        'surname.value': 'required|string|max:255',
        'name.value': 'required|string|max:255',
        'patronymic.value': 'string|max:255',
        'citizenship_id.value': 'required|string|max:255',
        'document_number.value': 'required|string|max:255',
        'date_start.value': 'required|string|max:10',
        'date_end.value': 'required|string|max:10',
      }

      let messages = {
        ru: {
          'required.surname.value': 'Поле Фамилия обязательно к заполнению.',
          'required.name.value': 'Поле Имя обязательно к заполнению.',
          'required.patronymic.value':
            'Поле Отчество обязательно к заполнению.',
          'required.document_number.value':
            'Поле Номер паспорта обязательно к заполнению.',
          'required.citizenship_id.value':
            'Поле Гражданство обязательно к заполнению.',
          'required.date_start.value':
            'Поле Период начала пребывания иностранного туриста в Республике Казахстан обязательно к заполнению.',
          'required.date_end.value':
            'Поле Период окончания пребывания иностранного туриста в Республике Казахстан обязательно к заполнению.',
          'max.date_start.value':
            'Поле Период начала пребывания иностранного туриста в Республике Казахстан не является действительной датой.',
          'max.date_end.value':
            'Поле Период окончания пребывания иностранного туриста в Республике Казахстан не является действительной датой.',
        },
        kz: {
          'required.surname.value': 'Поле Фамилия обязательно к заполнению.',
          'required.name.value': 'Поле Имя обязательно к заполнению.',
          'required.patronymic.value':
            'Поле Отчество обязательно к заполнению.',
          'required.document_number.value':
            'Поле Номер паспорта обязательно к заполнению.',
          'required.citizenship_id.value':
            'Поле Гражданство обязательно к заполнению.',
          'required.date_start.value':
            'Поле Период начала пребывания иностранного туриста в Республике Казахстан обязательно к заполнению.',
          'required.date_end.value':
            'Поле Период окончания пребывания иностранного туриста в Республике Казахстан обязательно к заполнению.',
        },
      }

      let validation = new Validator(
        this.client,
        rules,
        messages[this.$i18n.locale],
      )

      if (validation.fails()) {
        for (let key in validation.errors.errors) {
          if (key == 'surname.value')
            this.client.surname.error = validation.errors.first(key)
          if (key == 'name.value')
            this.client.name.error = validation.errors.first(key)
          if (key == 'patronymic.value')
            this.client.patronymic.error = validation.errors.first(key)
          if (key == 'citizenship_id.value')
            this.client.citizenship_id.error = validation.errors.first(key)
          if (key == 'document_number.value')
            this.client.document_number.error = validation.errors.first(key)
          if (key == 'date_start.value')
            this.client.date_start.error = validation.errors.first(key)
          if (key == 'date_end.value')
            this.client.date_end.error = validation.errors.first(key)
        }

        return
      }

      if (this.application.clients.value) {
        this.application.clients.value[0]['surname'] = this.client.surname.value
        this.application.clients.value[0]['name'] = this.client.name.value
        this.application.clients.value[0]['patronymic'] =
          this.client.patronymic.value
        this.application.clients.value[0]['document_number'] =
          this.client.document_number.value
        this.application.clients.value[0]['citizenship_id'] =
          this.client.citizenship_id.value
        this.application.clients.value[0]['date_start'] =
          this.client.date_start.value
        this.application.clients.value[0]['date_end'] =
          this.client.date_end.value
      } else {
        this.application.clients.value = []

        this.application.clients.value.push({
          surname: this.client.surname.value,
          name: this.client.name.value,
          patronymic: this.client.patronymic.value,
          document_number: this.client.document_number.value,
          citizenship_id: this.client.citizenship_id.value,
          date_start: this.client.date_start.value,
          date_end: this.client.date_end.value,
        })
      }

      this.$modal.hide('registry__add')

      this.client_reset()
    },
    client_reset() {
      this.client.surname.value = this.application.clients.value[0].surname
      this.client.name.value = this.application.clients.value[0].name
      this.client.patronymic.value =
        this.application.clients.value[0].patronymic
      this.client.document_number.value =
        this.application.clients.value[0].document_number
      this.client.citizenship_id.value =
        this.application.clients.value[0].citizenship_id
      this.client.date_start.value =
        this.application.clients.value[0].date_start
      this.client.date_end.value = this.application.clients.value[0].date_end
    },
    handleFileUpload1() {
      this.fileDocuments1.fileName = this.$refs.fileDocuments1.files[0].name
      this.application.a_copy_of_the_identity_document_of_a_foreign_tourist.value =
        '1'
      this.application.a_copy_of_the_identity_document_of_a_foreign_tourist.error =
        null
      this.fileDocuments1.fileClass = 'add_tourist--success'
    },
    handleFileUpload2() {
      this.fileDocuments2.fileName = this.$refs.fileDocuments2.files[0].name
      this.application.tourist_service_agreement_with_a_foreign_tourist.value =
        '1'
      this.application.tourist_service_agreement_with_a_foreign_tourist.error =
        null
      this.fileDocuments2.fileClass = 'add_tourist--success'
    },
    handleFileUpload3() {
      this.fileDocuments3.fileName = this.$refs.fileDocuments3.files[0].name
      this.application.document_confirming_payment_for_the_tourist_product_under_the_agreement.value =
        '1'
      this.application.document_confirming_payment_for_the_tourist_product_under_the_agreement.error =
        null
      this.fileDocuments3.fileClass = 'add_tourist--success'
    },
    handleFileUpload4() {
      this.fileDocuments4.fileName = this.$refs.fileDocuments4.files[0].name
      this.application.the_fact_of_a_tourist_crossing_the_state_border_of_the_republic_of_kazakhstan.value =
        '1'
      this.application.the_fact_of_a_tourist_crossing_the_state_border_of_the_republic_of_kazakhstan.error =
        null
      this.fileDocuments4.fileClass = 'add_tourist--success'
    },
    handleFileUpload5() {
      this.fileDocuments5.fileName = this.$refs.fileDocuments5.files[0].name
      this.application.attach_passenger_coupon_boarding_pass.value = '1'
      this.application.attach_passenger_coupon_boarding_pass.error = null
      this.fileDocuments5.fileClass = 'add_tourist--success'
    },
  },
}
</script>

<style scoped>
.br-cr-red {
  border-color: red;
}
</style>
