<template>
  <div
    class="wrap__content"
    v-bind:class="{ 'wrap__content--placeholder': !user }"
  >
    <div class="page__placeholder" v-if="!user">
      <div class="form__block--placeholder">
        <div class="form__placeholder--circle">
          <div class="placeholder__circle">
            <svg width="73" height="73" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><circle cx="36.5" cy="36" r="24" stroke="#FFF7E1" stroke-width="2"/><g filter="url(#filter0_d_22_5658)"><path d="M16.16 23A24.208 24.208 0 0012 36.605C12 50.078 22.969 61 36.5 61S61 50.078 61 36.605c0-1.736-.182-3.43-.528-5.064" stroke="#FFCC47" stroke-width="4" stroke-linecap="round"/></g><defs><filter id="filter0_d_22_5658" x="5" y="16" width="63" height="52" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset/><feGaussianBlur stdDeviation="2.5"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix values="0 0 0 0 1 0 0 0 0 0.722826 0 0 0 0 0 0 0 0 0.5 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_22_5658"/><feBlend in="SourceGraphic" in2="effect1_dropShadow_22_5658" result="shape"/></filter></defs></svg>
          </div>
        </div>
        <div class="form__placeholder--logo">
          <img src="../../assets/img/logo.svg" alt="" />
        </div>
      </div>
    </div>
    <div class="content__page--block" v-if="user">
      <div class="content__page">
        <!-- content__page--margintop -->

        <div class="container">
          <div class="content__page--back">
          
          </div>

          <h1 class="section__title section__title--account">
            <strong
              >Правила субсидирования стоимости билета, включенного в туристский продукт, при воздушной перевозке несовершеннолетних пассажиров на территории Республики Казахстан</strong
            >
          </h1>

          <div class="section--desc account__desc--subsiding">
            <h2>Глава 1. Общие положения</h2>
            <p>
              1. Настоящие Правила субсидирования стоимости билета, включенного в туристский продукт, при воздушной перевозке несовершеннолетних пассажиров на территории Республики Казахстан (далее – Правила) разработаны в соответствии с подпунктом 20-11) статьи 11 Закона Республики Казахстан "О туристской деятельности в Республике Казахстан" и определяют порядок субсидирования стоимости билета, включенного в туристский продукт, при воздушной перевозке несовершеннолетних пассажиров на территории Республики Казахстан.
            </p>
            <p>
              2. В настоящих Правилах используются следующие основные понятия:
              <br />1) близкие родственники – родители (родитель), дети, усыновители (удочерители), усыновленные (удочеренные), полнородные и неполнородные братья и сестры, дедушка, бабушка, внуки;
              <br />
2) пассажир – физическое лицо, не входящее в состав экипажа и перевозимое на воздушном судне в соответствии с договором воздушной перевозки или на иных законных основаниях;
<br />
3) пассажирский билет (далее – билет) – документ на перевозку пассажира, выдаваемый авиакомпанией либо его уполномоченными агентами, подтверждающий ознакомление пассажиром с условиями договора воздушной перевозки и заключение между пассажиром и авиакомпанией договора воздушной перевозки;
<br />
4) пассажирский купон – часть билета, представленная в виде электронно-цифровой записи или на бумажном носителе (бланк строгой отчетности), удостоверяющая заключение договора воздушной перевозки пассажира и его багажа;
<br />
4-1) маршрутная квитанция – документ (документы), который (которые) являются составляющей частью электронного билета и содержит необходимые сведения (например: фамилию пассажира, маршрут, тариф), уведомления и сообщения;
<br />
5) места размещения туристов – гостиницы, мотели, кемпинги, туристские базы, гостевые дома, дома отдыха, пансионаты и другие здания и сооружения, используемые для проживания туристов и их обслуживания;
<br />
6) туристские услуги – услуги, необходимые для удовлетворения потребностей туриста, предоставляемые в период его путешествия и в связи с этим путешествием (размещение, перевозка, питание, экскурсии, услуги инструкторов туризма, гидов, и другие услуги), предусмотренные договором на туристское обслуживание, оказываемые в зависимости от целей поездки;
<br />
7) туристский оператор (далее – туроператор) – физическое или юридическое лицо, осуществляющее туристскую операторскую деятельность, туристскую операторскую деятельность в сфере внутреннего, въездного или выездного туризма;
<br />
8) туристский продукт – комплекс всех необходимых туристских услуг, достаточных для удовлетворения потребностей туриста во время путешествия;
<br />
9) уполномоченный орган – центральный исполнительный орган, осуществляющий функции государственного управления в области туристской деятельности;
<br />
10) туроператорская деятельность в сфере внутреннего туризма – предпринимательская деятельность физических или юридических лиц, имеющих лицензию на данный вид деятельности, по формированию туристского продукта, его продвижению и реализации туристам, постоянно проживающим на территории Республики Казахстан, в том числе через турагентов;
<br />
11) электронный билет – электронный документ, подтверждающий ознакомление пассажиром с условиями договора воздушной перевозки и заключение между пассажиром и авиакомпанией договора воздушной перевозки.
            </p>

            <h2>
              Глава 2. Порядок субсидирования стоимости билета, включенного в туристский продукт, при воздушной перевозке несовершеннолетних пассажиров на территории Республики Казахстан
            </h2>
            <p>
              3. Для получения субсидирования стоимости билета, включенного в туристский продукт, при воздушной перевозке несовершеннолетних пассажиров на территории Республики Казахстан (далее – субсидия) туроператор в сфере внутреннего туризма направляет в организацию, созданную постановлением Правительства Республики Казахстан от 14 июля 2017 года № 428 "О создании акционерного общества "Национальная компания "Kazakh Tourism" (далее – Организация), заявку на получение субсидии (далее – заявка) в бумажной или электронной форме посредствам почты либо нарочно с первого рабочего дня и до 1 декабря соответствующего календарного года по форме согласно приложению 1 к настоящим Правилам, с приложением следующих документов и сведений:
            </p>
            <p>
              1) в случае подачи заявки юридическим лицом – копии учредительных документов юридического лица, в случае подачи заявления физическим лицом – копия документа, удостоверяющего личность, копия документа, подтверждающего начало деятельности в качестве индивидуального предпринимателя, а при подаче заявки представителем туроператора дополнительно представляется нотариально засвидетельствованная доверенность на представление интересов туроператора;
              <br />
      2) сведения об отсутствии (наличии) задолженностей, учет по которым ведется в органах государственных доходов, полученные на первое число месяца, предшествующего месяцу, в котором планируется подача заявки;
      <br />
      3) копии билета или электронного билета, маршрутной квитанции и пассажирского купона или справки-подтверждения об использовании билета авиакомпании на несовершеннолетнего пассажира, являющегося гражданином Республики Казахстан либо лицом без гражданства, имеющего разрешение на постоянное жительство в Республике Казахстан (далее – несовершеннолетний пассажир);
      <br />
      4) копия разрешения на постоянное жительство в Республике Казахстан (для несовершеннолетнего пассажира, являющегося лицом без гражданства);
      <br />
      5) копия нотариально засвидетельственной доверенности (в случае если несовершеннолетний пассажир перевезен с близким родственником);
      <br />
      6) копия договора на туристское обслуживание (далее – Договор), составленного в соответствии с приказом Министра по инвестициям и развитию Республики Казахстан от 30 января 2015 года № 81 "Об утверждении типового договора на туристское обслуживание" (зарегистрирован в Реестре государственной регистрации нормативных правовых актов за № 10604);
      <br />
      7) Сведения об отсутствии (наличии) задолженностей;
      <br />
      8) сведения о предоставлении туристских услуг для несовершеннолетних пассажиров по форме согласно приложению 2 к настоящим Правилам.
            </p>
            <p>
              4. Поступившие заявки и прилагаемые к ней документы и сведения (далее – пакет документов) регистрируются и рассматриваются Организацией на их полноту в течение двух рабочих дней после дня их поступления.
            </p>
            <p>
              В случае предоставления неполного пакета документов Организация возвращает их туроператору в сфере внутреннего туризма письменным уведомлением в сроки, указанные в части первой настоящего пункта, с указанием перечня недостающих документов. <br />При устранении причин, послуживших основанием для возврата, туроператор в сфере внутреннего туризма подает пакет документов повторно.
            </p>
            <p>
              5. В случае полноты пакета документов, установленных пунктом 3 настоящих Правил, Организация в течение семи рабочих дней со дня их поступления рассматривает пакет документов и принимает одно из следующих решений:
            </p>
            <p>
              1) о выплате субсидии туроператору в сфере внутреннего туризма при соответствии пункту 3 настоящих Правил;
              <br />
      2) об отказе в выплате субсидии туроператору в сфере внутреннего туризма, при несоответствии пункту 3 настоящих Правил.
      <br />
      5-1. При выявлении оснований для отказа в выплате субсидии туроператору в сфере внутреннего туризма Организация уведомляет туроператора в сфере внутреннего туризма о предварительном решении об отказе в выплате субсидии туроператору в сфере внутреннего туризма, а также времени и месте (способе) проведения заслушивания для возможности выразить позицию туроператором в сфере внутреннего туризма по предварительному решению.
      <br />
      Уведомление о заслушивании направляется не менее чем за 3 (три) рабочих дня до принятия решения согласно статьи 73 Административного процедурно-процессуального кодекса Республики Казахстан. Заслушивание проводится не позднее 2 (двух) рабочих дней со дня уведомления.
      <br />
      По результатам заслушивания Организация выдает результат о выплате субсидии туроператору в сфере внутреннего туризма либо мотивированный ответ об отказе в выплате субсидии туроператору в сфере внутреннего туризма.
            </p>
            <p>
              6. Информация о принятом решении размещается на интернет-ресурсе Организацией в течение двух рабочих дней со дня его принятия.
            </p>
            <p>
              7. На основании решения о выплате субсидии туроператору в сфере внутреннего туризма Организация в течение двадцати пяти рабочих дней осуществляет перечисление суммы субсидии, на расчетный счет туроператора в сфере внутреннего туризма, открытый в банке второго уровня, указанный в заявке.
            </p>
            <p>
              8. Субсидия выплачивается за стоимость билета на несовершеннолетнего пассажира, включенного в туристский продукт, приобретеннего у туроператора, осуществляющего деятельность в сфере внутреннего туризма.
            </p>
            <p>
              9. Организация ежегодно не позднее двадцатого декабря представляет в уполномоченный орган информацию об использовании выделенных средств субсидии с указанием сумм выплаченных, за соответствующий финансовый год.
            </p>
            <p>
              10. Контроль, мониторинг и реализация субсидии осуществляются в соответствии с бюджетным законодательством Республики Казахстан.
            </p>
          </div>

          <div class="section--link link--senter">
            <a href="/download/regulations-kids-go-free.pdf" download=""
              >Скачать заявку на субсидирование</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from '../../boot/axios'

export default {
  name: 'SubsidingKids',
  data() {
    return {
      user: null,
    }
  },
  beforeCreate() {
    api.get('user').then((response) => {
      if (response.data) {
        this.user = response.data
      }
    })
  },
}
</script>

<style scoped></style>
