<template>
  <div
    class="wrap__content"
    v-bind:class="{
      'wrap__content--placeholder': !user || !application,
      'wrap__content--administrator': true,
    }"
  >
    <div class="page__placeholder" v-if="!user || !application">
      <div class="form__block--placeholder">
        <div class="form__placeholder--circle">
          <div class="placeholder__circle">
            <svg width="73" height="73" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><circle cx="36.5" cy="36" r="24" stroke="#FFF7E1" stroke-width="2"/><g filter="url(#filter0_d_22_5658)"><path d="M16.16 23A24.208 24.208 0 0012 36.605C12 50.078 22.969 61 36.5 61S61 50.078 61 36.605c0-1.736-.182-3.43-.528-5.064" stroke="#FFCC47" stroke-width="4" stroke-linecap="round"/></g><defs><filter id="filter0_d_22_5658" x="5" y="16" width="63" height="52" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset/><feGaussianBlur stdDeviation="2.5"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix values="0 0 0 0 1 0 0 0 0 0.722826 0 0 0 0 0 0 0 0 0.5 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_22_5658"/><feBlend in="SourceGraphic" in2="effect1_dropShadow_22_5658" result="shape"/></filter></defs></svg>
          </div>
        </div>
        <div class="form__placeholder--logo">
          <img src="../../assets/img/logo.svg" alt="" />
        </div>
      </div>
    </div>
    <div class="content__page--block" v-if="user && application">
      <div class="content__page content__page--margintop">
        <div class="container">
          <div class="content__page--back">
            <a :href="'/' + $i18n.locale + '/account/'">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19 12H5M12 19l-7-7 7-7" stroke="#0E1839" stroke-linecap="round" stroke-linejoin="round"/></svg>
              {{ $t('system.back') }}
            </a>
          </div>

          <div class="content__title--block">
            <div class="section__title--mini">
              Заявление на субсидирование затрат туроператоров в сфере въездного
              туризма
            </div>
            <div class="content__title section__title">
              <strong>за каждого иностранного туриста №10001</strong>
            </div>
          </div>

          <div class="add_tourist--section add_tourist--section-transition">
            <div class="add_tourist--block">
              <div class="add_tourist__section--title">Данные Заявителя</div>
              <div class="table__block--style table__block--blue">
                <v-data-table
                  :headers="headersApplicant"
                  :items="DataApplicant"
                  :loading="loading"
                  :options.sync="options"
                  hide-default-footer
                >
                  <template v-slot:item.reference="{ item }">
                    <a
                      class="tb__table--views"
                      :href="item.reference_src"
                      data-fancybox="reference"
                    >
                      Посмотреть
                    </a>
                  </template>
                  <template v-slot:item.requisites="{ item }">
                    <a
                      class="tb__table--views"
                      :href="item.requisites_src"
                      data-fancybox="requisites"
                    >
                      Посмотреть
                    </a>
                  </template>
                </v-data-table>
              </div>
            </div>
            <div class="add_tourist--block">
              <div class="add_tourist__section--title">
                Данные иностранного туриста
              </div>
              <div class="table__block--style table__block--blue">
                <v-data-table
                  :headers="headers"
                  :items="userDataSave"
                  :loading="loading"
                  :options.sync="options"
                  hide-default-footer
                >
                  <template v-slot:item.action="{ item }">
                    <a
                      class="tb__table--btn table__btn--status tb__table--success"
                      @click="client_success()"
                      :data-id="item.id"
                      v-tooltip.bottom="{
                        content: 'Одобрить',
                        class: ['table__tooltip'],
                      }"
                    >
                      <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#33AD28" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </a>
                    <a
                      class="tb__table--btn table__btn--status tb__table--close"
                      @click="client_close()"
                      :data-id="item.id"
                      v-tooltip.bottom="{
                        content: 'Отклонить',
                        class: ['table__tooltip'],
                      }"
                    >
                      <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.17 16.42l2.935-3.126 2.935-3.125m0 6.25l-5.87-6.25M8.355 4.826a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.992 0 3.848 3.848 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.848 3.848 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#F3574D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </a>
                  </template>
                </v-data-table>
              </div>
            </div>
          </div>

          <div class="add_tourist--section">
            <div class="add_tourist__section--title">
              Подтверждающие документы
            </div>

            <div class="add_tourist--filelist">
              <div class="fileitem--col">
                <div
                  class="add_tourist--fileitem"
                  :class="fileDocuments1.fileClass"
                >
                  <div class="fileitem__block">
                    <div class="fileitem__block--title">
                      Копия документа, удостоверяющего личность иностранного
                      туриста
                    </div>

                    <div
                      class="fileitem__block--file fileitem__block--view"
                      v-if="fileDocuments1.fileName"
                    >
                      <div
                        class="fileitem__block--wrap"
                        :href="fileDocuments1.fileName"
                        data-fancybox="fileDocuments1"
                      >
                        <div
                          class="fileitem__block--icon"
                          v-if="
                            fileDocuments1.fileClass == 'add_tourist--success'
                          "
                        >
                          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div
                          class="fileitem__block--icon"
                          v-else-if="
                            fileDocuments1.fileClass == 'add_tourist--error'
                          "
                        >
                          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.17 16.42l2.935-3.126 2.935-3.125m0 6.25l-5.87-6.25M8.355 4.826a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.992 0 3.848 3.848 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.848 3.848 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="fileitem__block--icon" v-else>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 15a3 3 0 100-6 3 3 0 000 6z" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="fileitem__block--text">Просмотр</div>
                      </div>
                    </div>
                    <div class="fileitem__block--file" v-else>
                      <div class="fileitem__block--wrap">
                        <div class="fileitem__block--text">Нет документа</div>
                      </div>
                    </div>
                  </div>
                  <span
                    class="fileitem__block--status"
                    v-if="!fileDocuments1.fileClass"
                  >
                    <a
                      class="tb__table--btn table__btn--status tb__table--success"
                      @click="client_success(fileDocuments1)"
                      v-tooltip.bottom="{
                        content: 'Одобрить',
                        class: ['table__tooltip'],
                      }"
                    >
                      <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#33AD28" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </a>
                    <a
                      class="tb__table--btn table__btn--status tb__table--close"
                      @click="client_close(fileDocuments1)"
                      v-tooltip.bottom="{
                        content: 'Отклонить',
                        class: ['table__tooltip'],
                      }"
                    >
                      <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.17 16.42l2.935-3.126 2.935-3.125m0 6.25l-5.87-6.25M8.355 4.826a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.992 0 3.848 3.848 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.848 3.848 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#F3574D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </a>
                  </span>
                  <span class="fileitem__block--status" v-else>
                    <a class="btn__cancel" @click="client_null(fileDocuments1)">
                      Отмена
                    </a>
                  </span>
                </div>
              </div>
              <div class="fileitem--col">
                <div
                  class="add_tourist--fileitem"
                  :class="fileDocuments2.fileClass"
                >
                  <div class="fileitem__block">
                    <div class="fileitem__block--title">
                      Договор на туристское обслуживание
                    </div>

                    <div
                      class="fileitem__block--file fileitem__block--view"
                      v-if="fileDocuments2.fileName"
                    >
                      <div
                        class="fileitem__block--wrap"
                        :href="fileDocuments2.fileName"
                        data-fancybox="fileDocuments2"
                      >
                        <div
                          class="fileitem__block--icon"
                          v-if="
                            fileDocuments2.fileClass == 'add_tourist--success'
                          "
                        >
                          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div
                          class="fileitem__block--icon"
                          v-else-if="
                            fileDocuments2.fileClass == 'add_tourist--error'
                          "
                        >
                          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.17 16.42l2.935-3.126 2.935-3.125m0 6.25l-5.87-6.25M8.355 4.826a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.992 0 3.848 3.848 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.848 3.848 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="fileitem__block--icon" v-else>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 15a3 3 0 100-6 3 3 0 000 6z" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="fileitem__block--text">Просмотр</div>
                      </div>
                    </div>
                    <div class="fileitem__block--file" v-else>
                      <div class="fileitem__block--wrap">
                        <div class="fileitem__block--text">Нет документа</div>
                      </div>
                    </div>
                  </div>
                  <span
                    class="fileitem__block--status"
                    v-if="!fileDocuments2.fileClass"
                  >
                    <a
                      class="tb__table--btn table__btn--status tb__table--success"
                      @click="client_success(fileDocuments2)"
                      v-tooltip.bottom="{
                        content: 'Одобрить',
                        class: ['table__tooltip'],
                      }"
                    >
                      <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#33AD28" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </a>
                    <a
                      class="tb__table--btn table__btn--status tb__table--close"
                      @click="client_close(fileDocuments2)"
                      v-tooltip.bottom="{
                        content: 'Отклонить',
                        class: ['table__tooltip'],
                      }"
                    >
                      <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.17 16.42l2.935-3.126 2.935-3.125m0 6.25l-5.87-6.25M8.355 4.826a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.992 0 3.848 3.848 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.848 3.848 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#F3574D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </a>
                  </span>
                  <span class="fileitem__block--status" v-else>
                    <a class="btn__cancel" @click="client_null(fileDocuments2)">
                      Отмена
                    </a>
                  </span>
                </div>
              </div>
              <div class="fileitem--col">
                <div
                  class="add_tourist--fileitem"
                  :class="fileDocuments3.fileClass"
                >
                  <div class="fileitem__block">
                    <div class="fileitem__block--title">
                      Документ, подтверждающий оплату туристского продукта по
                      Договору
                    </div>

                    <div
                      class="fileitem__block--file fileitem__block--view"
                      v-if="fileDocuments3.fileName"
                    >
                      <div
                        class="fileitem__block--wrap"
                        :href="fileDocuments3.fileName"
                        data-fancybox="fileDocuments3"
                      >
                        <div
                          class="fileitem__block--icon"
                          v-if="
                            fileDocuments3.fileClass == 'add_tourist--success'
                          "
                        >
                          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div
                          class="fileitem__block--icon"
                          v-else-if="
                            fileDocuments3.fileClass == 'add_tourist--error'
                          "
                        >
                          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.17 16.42l2.935-3.126 2.935-3.125m0 6.25l-5.87-6.25M8.355 4.826a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.992 0 3.848 3.848 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.848 3.848 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="fileitem__block--icon" v-else>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 15a3 3 0 100-6 3 3 0 000 6z" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="fileitem__block--text">Просмотр</div>
                      </div>
                    </div>
                    <div class="fileitem__block--file" v-else>
                      <div class="fileitem__block--wrap">
                        <div class="fileitem__block--text">Нет документа</div>
                      </div>
                    </div>
                  </div>
                  <span
                    class="fileitem__block--status"
                    v-if="!fileDocuments3.fileClass"
                  >
                    <a
                      class="tb__table--btn table__btn--status tb__table--success"
                      @click="client_success(fileDocuments3)"
                      v-tooltip.bottom="{
                        content: 'Одобрить',
                        class: ['table__tooltip'],
                      }"
                    >
                      <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#33AD28" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </a>
                    <a
                      class="tb__table--btn table__btn--status tb__table--close"
                      @click="client_close(fileDocuments3)"
                      v-tooltip.bottom="{
                        content: 'Отклонить',
                        class: ['table__tooltip'],
                      }"
                    >
                      <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.17 16.42l2.935-3.126 2.935-3.125m0 6.25l-5.87-6.25M8.355 4.826a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.992 0 3.848 3.848 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.848 3.848 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#F3574D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </a>
                  </span>
                  <span class="fileitem__block--status" v-else>
                    <a class="btn__cancel" @click="client_null(fileDocuments3)">
                      Отмена
                    </a>
                  </span>
                </div>
              </div>

              <div class="fileitem--col">
                <div
                  class="add_tourist--fileitem"
                  :class="fileDocuments4.fileClass"
                >
                  <div class="fileitem__block">
                    <div class="fileitem__block--title">
                      Копия нотариально засвидетельственной доверенности (при
                      необходимости)
                    </div>

                    <div
                      class="fileitem__block--file fileitem__block--view"
                      v-if="fileDocuments4.fileName"
                    >
                      <div
                        class="fileitem__block--wrap"
                        :href="fileDocuments4.fileName"
                        data-fancybox="fileDocuments4"
                      >
                        <div
                          class="fileitem__block--icon"
                          v-if="
                            fileDocuments4.fileClass == 'add_tourist--success'
                          "
                        >
                          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div
                          class="fileitem__block--icon"
                          v-else-if="
                            fileDocuments4.fileClass == 'add_tourist--error'
                          "
                        >
                          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.17 16.42l2.935-3.126 2.935-3.125m0 6.25l-5.87-6.25M8.355 4.826a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.992 0 3.848 3.848 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.848 3.848 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="fileitem__block--icon" v-else>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 15a3 3 0 100-6 3 3 0 000 6z" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="fileitem__block--text">Просмотр</div>
                      </div>
                    </div>
                    <div class="fileitem__block--file" v-else>
                      <div class="fileitem__block--wrap">
                        <div class="fileitem__block--text">Нет документа</div>
                      </div>
                    </div>
                  </div>
                  <span
                    class="fileitem__block--status"
                    v-if="!fileDocuments4.fileClass"
                  >
                    <a
                      class="tb__table--btn table__btn--status tb__table--success"
                      @click="client_success(fileDocuments4)"
                      v-tooltip.bottom="{
                        content: 'Одобрить',
                        class: ['table__tooltip'],
                      }"
                    >
                      <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#33AD28" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </a>
                    <a
                      class="tb__table--btn table__btn--status tb__table--close"
                      @click="client_close(fileDocuments4)"
                      v-tooltip.bottom="{
                        content: 'Отклонить',
                        class: ['table__tooltip'],
                      }"
                    >
                      <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.17 16.42l2.935-3.126 2.935-3.125m0 6.25l-5.87-6.25M8.355 4.826a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.992 0 3.848 3.848 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.848 3.848 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#F3574D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </a>
                  </span>
                  <span class="fileitem__block--status" v-else>
                    <a class="btn__cancel" @click="client_null(fileDocuments4)">
                      Отмена
                    </a>
                  </span>
                </div>
              </div>
              <div class="fileitem--col">
                <div
                  class="add_tourist--fileitem"
                  :class="fileDocuments5.fileClass"
                >
                  <div class="fileitem__block">
                    <div class="fileitem__block--title">
                      Копии билета или электронного билета, маршрутной квитанции и пассажирского купона или справки-подтверждения об использовании билета авиакомпании на несовершеннолетнего пассажира
                    </div>

                    <div
                      class="fileitem__block--file fileitem__block--view"
                      v-if="fileDocuments5.fileName"
                    >
                      <div
                        class="fileitem__block--wrap"
                        :href="fileDocuments5.fileName"
                        data-fancybox="fileDocuments5"
                      >
                        <div
                          class="fileitem__block--icon"
                          v-if="
                            fileDocuments5.fileClass == 'add_tourist--success'
                          "
                        >
                          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div
                          class="fileitem__block--icon"
                          v-else-if="
                            fileDocuments5.fileClass == 'add_tourist--error'
                          "
                        >
                          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.17 16.42l2.935-3.126 2.935-3.125m0 6.25l-5.87-6.25M8.355 4.826a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.992 0 3.848 3.848 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.848 3.848 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="fileitem__block--icon" v-else>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 15a3 3 0 100-6 3 3 0 000 6z" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="fileitem__block--text">Просмотр</div>
                      </div>
                    </div>
                    <div class="fileitem__block--file" v-else>
                      <div class="fileitem__block--text">Нет документа</div>
                    </div>
                  </div>
                  <span
                    class="fileitem__block--status"
                    v-if="!fileDocuments5.fileClass"
                  >
                    <a
                      class="tb__table--btn table__btn--status tb__table--success"
                      @click="client_success(fileDocuments5)"
                      v-tooltip.bottom="{
                        content: 'Одобрить',
                        class: ['table__tooltip'],
                      }"
                    >
                      <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#33AD28" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </a>
                    <a
                      class="tb__table--btn table__btn--status tb__table--close"
                      @click="client_close(fileDocuments5)"
                      v-tooltip.bottom="{
                        content: 'Отклонить',
                        class: ['table__tooltip'],
                      }"
                    >
                      <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.17 16.42l2.935-3.126 2.935-3.125m0 6.25l-5.87-6.25M8.355 4.826a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.992 0 3.848 3.848 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.848 3.848 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#F3574D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </a>
                  </span>
                  <span class="fileitem__block--status" v-else>
                    <a class="btn__cancel" @click="client_null(fileDocuments5)">
                      Отмена
                    </a>
                  </span>
                </div>
              </div>

              <div class="fileitem__archive">
                <a class="archive--link">
                  <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19 14.563l-6.146 6.243A3.984 3.984 0 0110.014 22a3.984 3.984 0 01-2.838-1.194A4.11 4.11 0 016 17.922a4.11 4.11 0 011.176-2.883l6.146-6.243A2.656 2.656 0 0115.215 8c.71 0 1.39.286 1.892.796a2.74 2.74 0 01.784 1.923 2.74 2.74 0 01-.784 1.922l-6.152 6.242a1.328 1.328 0 01-1.893 0 1.37 1.37 0 010-1.922l5.678-5.76" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                  Скачать все файлы архивом
                </a>

                <div class="fileitem__elicience">
                  Проверено «eLicience»
                </div>
              </div>
              <div class="form__block--line fileitem__comment">
                <div class="form__block--input">
                  <textarea
                    placeholder="Написать коментарий..."
                    :class="{ 'textarea--active': commentMessage }"
                    v-model="commentMessage"
                    class="input-linck textarea-linck input-linck--white"
                  ></textarea>
                </div>
              </div>
            </div>

            <div class="form__block--history">
              <div class="form__block--title">История всех действий</div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="history__item history__item--new">
                    <div class="history__item--date">
                      <span>27.01.2021</span>
                      <span class="history__item--title">Комментарий</span>
                    </div>
                    <div class="history__item--text">
                      Документ не правильно оформлен, факт пересечения
                      государственной границы РК туриста
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="history__item">
                    <div class="history__item--date">
                      <span>25.01.2021</span>
                    </div>
                    <div class="history__item--text">
                      Отправка заявки №10001
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal__wrapper--footer">
              <div class="section--link">
                <button class="next__stage input__button">
                  Направить на согласование
                </button>
                <button
                  class="next__stage input__button btn--blue"
                  @click="$modal.show('statement__eqonaq')"
                >
                  Сделать сверку с «eQonaq»
                </button>
                <button class="next__stage input__button btn--error">
                  Отказать
                </button>
              </div>
              <!--
              <div class="section--link" v-else-if="fileDocuments3.fileClass == 'add_tourist--error'">
                <button class="next__stage input__button" @click="$modal.show('statement__error')">Отказать</button>
              </div>
              -->
              <!--
              <div class="section--link">
                <button class="next__stage input__button" @click="$modal.show('statement__error')">Отказать</button>
              </div>
              -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal
      class="modal__block modal__block--middle modal__block--statement"
      name="statement__performer"
      :width="680"
      :min-width="680"
      height="auto"
    >
      <div class="modal__block--wrapper">
        <div
          class="modal__block--close"
          @click="$modal.hide('statement__performer')"
        >
          <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M31.5 10.5l-21 21M10.5 10.5l21 21" stroke="#0E1839" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
        </div>
        <div class="modal__wrapper">
          <div class="modal__title">Назначить исполнителя</div>
          <div class="modal__content">
            <div class="modal__form">
              <div class="form__block--line">
                <div class="form__block--input">
                  <div class="form__block--title">Выберите исполнителя</div>
                  <multiselect
                    v-model="optionsCheck[0]"
                    :options="optionsCheck"
                    :clearOnSelect="false"
                    :clear-on-select="false"
                    :placeholder="$t('labels.checkingAccount')"
                    :hideSelected="true"
                    style="border-color: red"
                  ></multiselect>
                  <!-- input--error -->

                  <span class="input-required input--error">{{
                    $t('registration.required.error')
                  }}</span>
                  <!--
                  <div class="subsidizing__banner--button section--link">
                    <a class="link--active">{{ $t('commands.add') }} +</a>
                  </div>
                  -->
                </div>
              </div>

              <div class="modal__wrapper--footer modal__wrapper--center">
                <button class="next__stage input__button">Назначить</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      class="modal__block modal__block--middle modal__block--statement"
      name="statement__success"
      :width="680"
      :min-width="680"
      height="auto"
    >
      <div class="modal__block--wrapper">
        <div
          class="modal__block--close"
          @click="$modal.hide('statement__success')"
        >
          <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M31.5 10.5l-21 21M10.5 10.5l21 21" stroke="#0E1839" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
        </div>
        <div class="modal__wrapper">
          <div class="modal__title">Зарегистрировать заявление</div>
          <div class="modal__content">
            <div class="modal__form">
              <div class="modal__statement">
                <div class="modal__statement--text">
                  На субсидирование затрат туроператоров в сфере въездного
                  туризма<br />
                  <a href="#" target="_blank" class="modal__statement--link"
                    >за каждого иностранного туриста №10001</a
                  >
                </div>
              </div>

              <div class="modal__wrapper--footer modal__wrapper--center">
                <button class="next__stage input__button">
                  Зарегистрировать заявление
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      class="modal__block modal__block--middle modal__block--statement"
      name="statement__error"
      :width="680"
      :min-width="680"
      height="auto"
    >
      <div class="modal__block--wrapper">
        <div
          class="modal__block--close"
          @click="$modal.hide('statement__error')"
        >
          <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M31.5 10.5l-21 21M10.5 10.5l21 21" stroke="#0E1839" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
        </div>
        <div class="modal__wrapper">
          <div class="modal__title">Проверьте все документы</div>
          <div class="modal__content">
            <div class="modal__form">
              <div class="modal__statement">
                <div class="modal__statement--image">
                  <img src="../../assets/img/statement__image.svg" />
                </div>
                <div class="modal__statement--text">
                  Для отправки комментариев, пожалуйста проверьте все документы
                </div>
              </div>

              <div class="modal__wrapper--footer modal__wrapper--center">
                <button class="next__stage input__button">Хорошо</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      class="modal__block modal__block--middle modal__block--statement"
      name="statement__eqonaq"
      :width="680"
      :min-width="680"
      height="auto"
    >
      <div class="modal__block--wrapper">
        <div
          class="modal__block--close"
          @click="$modal.hide('statement__eqonaq')"
        >
          <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M31.5 10.5l-21 21M10.5 10.5l21 21" stroke="#0E1839" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
        </div>
        <div class="modal__wrapper">
          <div class="modal__title">Запрос в ИС «eQonaq»</div>
          <div class="modal__content">
            <div class="modal__form">
              <div class="modal__statement">
                <div class="form__block--placeholder">
                  <div class="form__placeholder--circle">
                    <div class="placeholder__circle">
                      <svg width="73" height="73" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><circle cx="36.5" cy="36" r="24" stroke="#FFF7E1" stroke-width="2"/><g filter="url(#filter0_d_22_5658)"><path d="M16.16 23A24.208 24.208 0 0012 36.605C12 50.078 22.969 61 36.5 61S61 50.078 61 36.605c0-1.736-.182-3.43-.528-5.064" stroke="#FFCC47" stroke-width="4" stroke-linecap="round"/></g><defs><filter id="filter0_d_22_5658" x="5" y="16" width="63" height="52" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset/><feGaussianBlur stdDeviation="2.5"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix values="0 0 0 0 1 0 0 0 0 0.722826 0 0 0 0 0 0 0 0 0.5 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_22_5658"/><feBlend in="SourceGraphic" in2="effect1_dropShadow_22_5658" result="shape"/></filter></defs></svg>
                    </div>
                  </div>
                  <div class="form__placeholder--text">
                    Идет проверка лицензии, подождите...
                  </div>
                </div>
              </div>

              <div class="modal__wrapper--footer modal__wrapper--center">
                <button class="next__stage input__button">{{ $t('commands.save') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { api } from '../../boot/axios'
import Multiselect from 'vue-multiselect'

export default {
  name: 'ViewsTourist',
  components: {
    Multiselect,
  },
  data() {
    return {
      itemsGallery: [
        '/favicon.png',
        '/favicon.png',
        '/favicon.png',
        '/favicon.png',
      ],
      index: null,
      commentMessage: null,

      user: null,
      application: null,

      optionsCheck: ['Исполнитель 1', 'Исполнитель 2', 'Исполнитель 3'],
      headers: [
        { text: 'ФИО туриста', value: 'name', sortable: false },
        { text: '№ документа ', value: 'passport', sortable: false },
        { text: 'Уведомление ', value: 'notification', sortable: false },
        { text: 'Гражданство', value: 'region', sortable: false },
        { text: this.$t('labels.action'), value: 'action', align: 'center', sortable: false },
      ],
      userDataSave: [
        {
          name: 'Рамазан Есенов',
          passport: '6345453223',
          notification: '№43124 19 ноября 2021',
          region: 'США',
          period: '18 ноября 2021 - 20 ноября 2021',
        },
      ],

      loading: false,
      options: {
        itemsPerPage: 1,
      },

      headersApplicant: [
        { text: 'Заявитель', value: 'name', sortable: false },
        { text: 'БИН/ИИН', value: 'iin', sortable: false },
        { text: 'Лицензия', value: 'license', sortable: false },
        {
          text: 'Лицензия на осуществление туристской операторской деятельности',
          value: 'reference',
          sortable: false,
        },
        { text: 'Реквизиты', value: 'requisites', sortable: false },
      ],
      DataApplicant: [
        {
          name: 'ИП  «Арсымбетов»',
          iin: '870613300057',
          license: '№45Т412543235 до 01.12.2022 г.',
          reference_src: '#',
          requisites_src: '#',
        },
      ],

      fileDocuments1: {
        fileName: 'Название документа.doc',
        fileClass: '',
      },
      fileDocuments2: {
        fileName: 'Название документа.doc',
        fileClass: 'add_tourist--success',
      },
      fileDocuments3: {
        fileName: 'Название документа.doc',
        fileClass: 'add_tourist--error',
      },
      fileDocuments4: {
        fileName: 'Название документа.doc',
        fileClass: '',
      },
      fileDocuments5: {
        fileName: '',
        fileClass: '',
      },
    }
  },
  methods: {
    client_null(fileDocuments) {
      console.log('client_null')
      fileDocuments.fileClass = ''
    },
    client_success(fileDocuments) {
      console.log('client_success')
      fileDocuments.fileClass = 'add_tourist--success'
    },
    client_close(fileDocuments) {
      console.log('client_close')
      fileDocuments.fileClass = 'add_tourist--error'
    },
  },
  beforeCreate() {
    if (!localStorage.token) {
      this.$router.push('/ru/login')
    }

    api.get('user').then((response) => {
      if (response.data) {
        this.user = response.data
      }
    })

    api.get('applications/' + this.$route.params.id).then((response) => {
      if (response.data) {
        this.application = response.data
      }
    })
  },
}
</script>

<style scoped></style>
