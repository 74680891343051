<template>
  <div
    class="wrap__content"
    v-bind:class="{
      'wrap__content--placeholder': !user || !application,
      'wrap__content--administrator': true,
    }"
  >
    <div class="page__placeholder" v-if="!user || !application">
      <div class="form__block--placeholder">
        <div class="form__placeholder--circle">
          <div class="placeholder__circle">
            <svg width="73" height="73" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><circle cx="36.5" cy="36" r="24" stroke="#FFF7E1" stroke-width="2"/><g filter="url(#filter0_d_22_5658)"><path d="M16.16 23A24.208 24.208 0 0012 36.605C12 50.078 22.969 61 36.5 61S61 50.078 61 36.605c0-1.736-.182-3.43-.528-5.064" stroke="#FFCC47" stroke-width="4" stroke-linecap="round"/></g><defs><filter id="filter0_d_22_5658" x="5" y="16" width="63" height="52" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset/><feGaussianBlur stdDeviation="2.5"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix values="0 0 0 0 1 0 0 0 0 0.722826 0 0 0 0 0 0 0 0 0.5 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_22_5658"/><feBlend in="SourceGraphic" in2="effect1_dropShadow_22_5658" result="shape"/></filter></defs></svg>
          </div>
        </div>
        <div class="form__placeholder--logo">
          <img src="../../../assets/img/logo.svg" alt="" />
        </div>
      </div>
    </div>
    <div class="content__page--block" v-if="user && application">
      <notifications group="foo" position="bottom center" />

      <div class="content__page content__page--margintop">
        <div class="container">
          <div class="content__page--back">
            <a :href="'/' + $i18n.locale + '/administrator/administrator'">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19 12H5M12 19l-7-7 7-7" stroke="#0E1839" stroke-linecap="round" stroke-linejoin="round"/></svg>
              {{ $t('system.back') }}
            </a>
          </div>

          <div class="content__title--block">
            <div class="section__title--mini">
              Заявление на субсидирование затрат туроператоров в сфере въездного
              туризма
            </div>
            <div class="content__title section__title">
              <strong>за каждого иностранного туриста №10001</strong>
            </div>
          </div>

          <div class="add_tourist--section add_tourist--section-transition">
            <div class="add_tourist--block">
              <div class="add_tourist__section--title">Данные Заявителя</div>
              <div class="table__block--style table__block--blue">
                <v-data-table
                  :headers="headersApplicant"
                  :items="DataApplicant"
                  :loading="loading"
                  :options.sync="options"
                  hide-default-footer
                >
                  <template v-slot:item.reference="{ item }">
                    <a
                      class="tb__table--views"
                      :href="item.reference_src"
                      data-fancybox="reference"
                    >
                      Посмотреть
                    </a>
                  </template>
                  <template v-slot:item.requisites="{ item }">
                    <a
                      class="tb__table--views"
                      :href="item.requisites_src"
                      data-fancybox="requisites"
                      >Посмотреть</a
                    >
                  </template>
                </v-data-table>
              </div>
            </div>
            <div class="add_tourist--block">
              <div class="add_tourist__section--title">
                Данные иностранного туриста
              </div>
              <div class="table__block--style table__block--blue">
                <v-data-table
                  :headers="headers"
                  :items="application.data.tourists"
                  :loading="loading"
                  :options.sync="options"
                  hide-default-footer
                >
                  <template v-slot:item.action="{ item }">
                    <a
                      class="tb__table--btn table__btn--status tb__table--success"
                      @click="
                        changeTouristStatus('success'),
                          (application.data.fields.tourist.status = 'success')
                      "
                      :data-id="item.id"
                      v-tooltip.bottom="{
                        content: 'Одобрить',
                        class: ['table__tooltip'],
                      }"
                    >
                      <svg
                        width="27"
                        height="27"
                        viewBox="0 0 27 27"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        :class="[tourist.status ? 'opacity' : '']"
                      >
                        <path
                          d="M10.1242 13.5002L12.3742 15.7502L16.8742 11.2502M8.8133 5.28438C9.62052 5.21996 10.3869 4.90253 11.0032 4.37729C12.4415 3.15158 14.5569 3.15158 15.9952 4.37729C16.6116 4.90253 17.3779 5.21996 18.1852 5.28438C20.0689 5.4347 21.5648 6.93055 21.7151 8.81428C21.7794 9.6215 22.0969 10.3878 22.6222 11.0042C23.8478 12.4425 23.8478 14.5579 22.6222 15.9962C22.0969 16.6125 21.7794 17.3789 21.7151 18.1862C21.5648 20.0699 20.0689 21.5657 18.1852 21.7161C17.378 21.7804 16.6116 22.0979 15.9952 22.6232C14.5569 23.8488 12.4415 23.8488 11.0032 22.6232C10.3869 22.0979 9.62052 21.7804 8.8133 21.7161C6.92957 21.5657 5.43372 20.0699 5.2834 18.1862C5.21898 17.3789 4.90155 16.6125 4.37631 15.9962C3.1506 14.5579 3.1506 12.4425 4.37631 11.0042C4.90155 10.3878 5.21898 9.6215 5.2834 8.81428C5.43372 6.93055 6.92957 5.4347 8.8133 5.28438Z"
                          stroke="#33AD28"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </a>
                    <a
                      class="tb__table--btn table__btn--status tb__table--close"
                      @click="
                        changeTouristStatus('fail'),
                          (application.data.fields.tourist.status = 'fail')
                      "
                      :data-id="item.id"
                      v-tooltip.bottom="{
                        content: 'Отклонить',
                        class: ['table__tooltip'],
                      }"
                    >
                      <svg
                        width="27"
                        height="27"
                        viewBox="0 0 27 27"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        :class="[tourist.status ? 'opacity' : '']"
                      >
                        <path
                          d="M10.1692 16.4193L13.1047 13.2944L16.0402 10.1695M16.0402 16.4193L10.1692 10.1695M8.35618 4.82628C9.1634 4.76186 9.92974 4.44443 10.5461 3.91919C11.9844 2.69348 14.0998 2.69348 15.5381 3.91919C16.1545 4.44443 16.9207 4.76186 17.728 4.82628C19.6117 4.9766 21.1077 6.47244 21.258 8.35618C21.3223 9.1634 21.6398 9.92974 22.165 10.5461C23.3907 11.9844 23.3907 14.0998 22.165 15.5381C21.6398 16.1544 21.3223 16.9208 21.258 17.7281C21.1077 19.6118 19.6117 21.1076 17.728 21.258C16.9209 21.3223 16.1545 21.6398 15.5381 22.1651C14.0998 23.3907 11.9844 23.3907 10.5461 22.1651C9.92974 21.6398 9.1634 21.3223 8.35618 21.258C6.47245 21.1076 4.9766 19.6118 4.82628 17.7281C4.76186 16.9208 4.44443 16.1544 3.91919 15.5381C2.69348 14.0998 2.69348 11.9844 3.91919 10.5461C4.44443 9.92974 4.76186 9.1634 4.82628 8.35618C4.9766 6.47244 6.47245 4.9766 8.35618 4.82628Z"
                          stroke="#F3574D"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </a>
                  </template>
                </v-data-table>
              </div>
            </div>
          </div>

          <div class="add_tourist--section">
            <div class="add_tourist__section--title">
              Подтверждающие документы
            </div>

            <div class="add_tourist--filelist">
              <div class="fileitem--col">
                <div
                  class="add_tourist--fileitem"
                  :class="file_document_1.fileClass"
                >
                  <div class="fileitem__block">
                    <div class="fileitem__block--title">
                      Копия документа, удостоверяющего личность иностранного
                      туриста
                    </div>

                    <div
                      class="fileitem__block--file fileitem__block--view"
                      v-if="file_document_1.fileName"
                    >
                      <div
                        class="fileitem__block--wrap"
                        :href="
                          'https://backend.subsidizing.crocos.kz/' +
                          application.data.fields.file_document_1.src
                        "
                        data-fancybox="application.data.fields.file_document_1"
                      >
                        <div
                          class="fileitem__block--icon"
                          v-if="
                            file_document_1.fileClass == 'add_tourist--success'
                          "
                        >
                          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div
                          class="fileitem__block--icon"
                          v-else-if="
                            file_document_1.fileClass == 'add_tourist--error'
                          "
                        >
                          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.17 16.42l2.935-3.126 2.935-3.125m0 6.25l-5.87-6.25M8.355 4.826a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.992 0 3.848 3.848 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.848 3.848 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="fileitem__block--icon" v-else>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 15a3 3 0 100-6 3 3 0 000 6z" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="fileitem__block--text">Просмотр</div>
                      </div>
                    </div>
                    <div class="fileitem__block--file" v-else>
                      <div class="fileitem__block--wrap">
                        <div class="fileitem__block--text">Нет документа</div>
                      </div>
                    </div>
                  </div>
                  <span
                    class="fileitem__block--status"
                    v-if="!file_document_1.fileClass"
                  >
                    <a
                      class="tb__table--btn table__btn--status tb__table--success"
                      @click="
                        changeStatusFileDocuments1('success'),
                          (application.data.fields.file_document_2.status =
                            'success'),
                          client_success(file_document_1)
                      "
                      v-tooltip.bottom="{
                        content: 'Одобрить',
                        class: ['table__tooltip'],
                      }"
                    >
                      <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#33AD28" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </a>
                    <a
                      class="tb__table--btn table__btn--status tb__table--close"
                      @click="
                        changeStatusFileDocuments1('fail'),
                          (application.data.fields.file_document_2.status =
                            'fail'),
                          client_close(file_document_1)
                      "
                      v-tooltip.bottom="{
                        content: 'Отклонить',
                        class: ['table__tooltip'],
                      }"
                    >
                      <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.17 16.42l2.935-3.126 2.935-3.125m0 6.25l-5.87-6.25M8.355 4.826a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.992 0 3.848 3.848 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.848 3.848 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#F3574D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </a>
                  </span>
                  <span class="fileitem__block--status" v-else>
                    <a
                      class="btn__cancel"
                      @click="
                        changeStatusFileDocuments1(null),
                          client_null(file_document_1)
                      "
                    >
                      Отмена
                    </a>
                  </span>
                </div>
              </div>
              <div class="fileitem--col">
                <div
                  class="add_tourist--fileitem"
                  :class="file_document_2.fileClass"
                >
                  <div class="fileitem__block">
                    <div class="fileitem__block--title">
                      Договор на туристское обслуживание
                    </div>

                    <div
                      class="fileitem__block--file fileitem__block--view"
                      v-if="file_document_2.fileName"
                    >
                      <div
                        class="fileitem__block--wrap"
                        :href="
                          'https://backend.subsidizing.crocos.kz/' +
                          application.data.fields.file_document_2.src
                        "
                        data-fancybox="file_document_2"
                      >
                        <div
                          class="fileitem__block--icon"
                          v-if="
                            file_document_2.fileClass == 'add_tourist--success'
                          "
                        >
                          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div
                          class="fileitem__block--icon"
                          v-else-if="
                            file_document_2.fileClass == 'add_tourist--error'
                          "
                        >
                          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.17 16.42l2.935-3.126 2.935-3.125m0 6.25l-5.87-6.25M8.355 4.826a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.992 0 3.848 3.848 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.848 3.848 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="fileitem__block--icon" v-else>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 15a3 3 0 100-6 3 3 0 000 6z" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="fileitem__block--text">Просмотр</div>
                      </div>
                    </div>
                    <div class="fileitem__block--file" v-else>
                      <div class="fileitem__block--wrap">
                        <div class="fileitem__block--text">Нет документа</div>
                      </div>
                    </div>
                  </div>
                  <span
                    class="fileitem__block--status"
                    v-if="!file_document_2.fileClass"
                  >
                    <a
                      class="tb__table--btn table__btn--status tb__table--success"
                      @click="
                        changeStatusFileDocuments2('success'),
                          (application.data.fields.file_document_2.status =
                            null),
                          client_success(file_document_2)
                      "
                      v-tooltip.bottom="{
                        content: 'Одобрить',
                        class: ['table__tooltip'],
                      }"
                    >
                      <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#33AD28" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </a>
                    <a
                      class="tb__table--btn table__btn--status tb__table--close"
                      @click="
                        changeStatusFileDocuments2('fail'),
                          (application.data.fields.file_document_2.status =
                            null),
                          client_close(file_document_2)
                      "
                      v-tooltip.bottom="{
                        content: 'Отклонить',
                        class: ['table__tooltip'],
                      }"
                    >
                      <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.17 16.42l2.935-3.126 2.935-3.125m0 6.25l-5.87-6.25M8.355 4.826a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.992 0 3.848 3.848 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.848 3.848 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#F3574D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </a>
                  </span>
                  <span class="fileitem__block--status" v-else>
                    <a
                      class="btn__cancel"
                      @click="
                        changeStatusFileDocuments2(null),
                          (application.data.fields.file_document_2.status =
                            null),
                          client_null(file_document_2)
                      "
                    >
                      Отмена
                    </a>
                  </span>
                </div>
              </div>
              <div class="fileitem--col">
                <div
                  class="add_tourist--fileitem"
                  :class="file_document_3.fileClass"
                >
                  <div class="fileitem__block">
                    <div class="fileitem__block--title">
                      Документ, подтверждающий оплату туристского продукта по
                      Договору
                    </div>

                    <div
                      class="fileitem__block--file fileitem__block--view"
                      v-if="file_document_3.fileName"
                    >
                      <div
                        class="fileitem__block--wrap"
                        :href="
                          'https://backend.subsidizing.crocos.kz/' +
                          application.data.fields.file_document_1.src
                        "
                        data-fancybox="file_document_3"
                      >
                        <div
                          class="fileitem__block--icon"
                          v-if="
                            file_document_3.fileClass == 'add_tourist--success'
                          "
                        >
                          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div
                          class="fileitem__block--icon"
                          v-else-if="
                            file_document_3.fileClass == 'add_tourist--error'
                          "
                        >
                          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.17 16.42l2.935-3.126 2.935-3.125m0 6.25l-5.87-6.25M8.355 4.826a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.992 0 3.848 3.848 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.848 3.848 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="fileitem__block--icon" v-else>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 15a3 3 0 100-6 3 3 0 000 6z" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="fileitem__block--text">Просмотр</div>
                      </div>
                    </div>
                    <div class="fileitem__block--file" v-else>
                      <div class="fileitem__block--wrap">
                        <div class="fileitem__block--text">Нет документа</div>
                      </div>
                    </div>
                  </div>
                  <span
                    class="fileitem__block--status"
                    v-if="!file_document_3.fileClass"
                  >
                    <a
                      class="tb__table--btn table__btn--status tb__table--success"
                      @click="
                        changeStatusFileDocuments3('success'),
                          (application.data.fields.file_document_3.status =
                            null),
                          client_success(file_document_3)
                      "
                      v-tooltip.bottom="{
                        content: 'Одобрить',
                        class: ['table__tooltip'],
                      }"
                    >
                      <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#33AD28" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </a>
                    <a
                      class="tb__table--btn table__btn--status tb__table--close"
                      @click="
                        changeStatusFileDocuments3('fail'),
                          (application.data.fields.file_document_3.status =
                            null),
                          client_close(file_document_3)
                      "
                      v-tooltip.bottom="{
                        content: 'Отклонить',
                        class: ['table__tooltip'],
                      }"
                    >
                      <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.17 16.42l2.935-3.126 2.935-3.125m0 6.25l-5.87-6.25M8.355 4.826a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.992 0 3.848 3.848 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.848 3.848 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#F3574D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </a>
                  </span>
                  <span class="fileitem__block--status" v-else>
                    <a
                      class="btn__cancel"
                      @click="
                        changeStatusFileDocuments3(null),
                          (application.data.fields.file_document_3.status =
                            null),
                          client_null(file_document_3)
                      "
                    >
                      Отмена
                    </a>
                  </span>
                </div>
              </div>

              <div class="fileitem--col">
                <div
                  class="add_tourist--fileitem"
                  :class="file_document_4.fileClass"
                >
                  <div class="fileitem__block">
                    <div class="fileitem__block--title">
                      Копия нотариально засвидетельственной доверенности (при
                      необходимости)
                    </div>

                    <div
                      class="fileitem__block--file fileitem__block--view"
                      v-if="file_document_4.fileName"
                    >
                      <div
                        class="fileitem__block--wrap"
                        :href="
                          'https://backend.subsidizing.crocos.kz/' +
                          application.data.fields.file_document_4.src
                        "
                        data-fancybox="file_document_4"
                      >
                        <div
                          class="fileitem__block--icon"
                          v-if="
                            file_document_4.fileClass == 'add_tourist--success'
                          "
                        >
                          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div
                          class="fileitem__block--icon"
                          v-else-if="
                            file_document_4.fileClass == 'add_tourist--error'
                          "
                        >
                          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.17 16.42l2.935-3.126 2.935-3.125m0 6.25l-5.87-6.25M8.355 4.826a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.992 0 3.848 3.848 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.848 3.848 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="fileitem__block--icon" v-else>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 15a3 3 0 100-6 3 3 0 000 6z" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="fileitem__block--text">Просмотр</div>
                      </div>
                    </div>
                    <div class="fileitem__block--file" v-else>
                      <div class="fileitem__block--wrap">
                        <div class="fileitem__block--text">Нет документа</div>
                      </div>
                    </div>
                  </div>
                  <span
                    class="fileitem__block--status"
                    v-if="!file_document_4.fileClass"
                  >
                    <a
                      class="tb__table--btn table__btn--status tb__table--success"
                      @click="
                        changeStatusFileDocuments4('success'),
                          (application.data.fields.file_document_4.status =
                            'success'),
                          client_success(file_document_4)
                      "
                      v-tooltip.bottom="{
                        content: 'Одобрить',
                        class: ['table__tooltip'],
                      }"
                    >
                      <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#33AD28" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </a>
                    <a
                      class="tb__table--btn table__btn--status tb__table--close"
                      @click="
                        changeStatusFileDocuments4('fail'),
                          (application.data.fields.file_document_4.status =
                            'fail'),
                          client_close(file_document_4)
                      "
                      v-tooltip.bottom="{
                        content: 'Отклонить',
                        class: ['table__tooltip'],
                      }"
                    >
                      <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.17 16.42l2.935-3.126 2.935-3.125m0 6.25l-5.87-6.25M8.355 4.826a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.992 0 3.848 3.848 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.848 3.848 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#F3574D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </a>
                  </span>
                  <span class="fileitem__block--status" v-else>
                    <a
                      class="btn__cancel"
                      @click="
                        changeStatusFileDocuments4(null),
                          (application.data.fields.file_document_4.status =
                            null),
                          client_null(file_document_4)
                      "
                    >
                      Отмена
                    </a>
                  </span>
                </div>
              </div>
              <div class="fileitem--col">
                <div
                  class="add_tourist--fileitem"
                  :class="file_document_5.fileClass"
                >
                  <div class="fileitem__block">
                    <div class="fileitem__block--title">
                      Копии билета или электронного билета, маршрутной квитанции и пассажирского купона или справки-подтверждения об использовании билета авиакомпании на несовершеннолетнего пассажира
                    </div>

                    <div
                      class="fileitem__block--file fileitem__block--view"
                      v-if="application.data.fields.file_document_5.name"
                    >
                      <div
                        class="fileitem__block--wrap"
                        :href="
                          'https://backend.subsidizing.crocos.kz/' +
                          application.data.fields.file_document_1.src
                        "
                        data-fancybox="file_document_5"
                      >
                        <div
                          class="fileitem__block--icon"
                          v-if="
                            file_document_5.fileClass == 'add_tourist--success'
                          "
                        >
                          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div
                          class="fileitem__block--icon"
                          v-else-if="
                            file_document_5.fileClass == 'add_tourist--error'
                          "
                        >
                          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.17 16.42l2.935-3.126 2.935-3.125m0 6.25l-5.87-6.25M8.355 4.826a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.992 0 3.848 3.848 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.848 3.848 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="fileitem__block--icon" v-else>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 15a3 3 0 100-6 3 3 0 000 6z" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="fileitem__block--text">Просмотр</div>
                      </div>
                    </div>
                    <div class="fileitem__block--file" v-else>
                      <div class="fileitem__block--text">Нет документа</div>
                    </div>
                  </div>
                  <span
                    class="fileitem__block--status"
                    v-if="
                      application.data.fields.file_document_5.name &&
                      !file_document_5.status
                    "
                  >
                    <a
                      class="tb__table--btn table__btn--status tb__table--success"
                      @click="
                        changeStatusFileDocument5('success'),
                          (application.data.fields.file_document_5.status =
                            'success'),
                          client_success(file_document_5)
                      "
                      v-tooltip.bottom="{
                        content: 'Одобрить',
                        class: ['table__tooltip'],
                      }"
                    >
                      <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#33AD28" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </a>
                    <a
                      class="tb__table--btn table__btn--status tb__table--close"
                      @click="
                        changeStatusFileDocuments5('fail'),
                          (application.data.fields.file_document_5.status =
                            'fail'),
                          client_close(file_document_5)
                      "
                      v-tooltip.bottom="{
                        content: 'Отклонить',
                        class: ['table__tooltip'],
                      }"
                    >
                      <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.17 16.42l2.935-3.126 2.935-3.125m0 6.25l-5.87-6.25M8.355 4.826a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.992 0 3.848 3.848 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.848 3.848 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#F3574D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </a>
                  </span>
                  <span
                    class="fileitem__block--status"
                    v-if="
                      application.data.fields.file_document_5.name &&
                      file_document_5.status
                    "
                  >
                    <a
                      class="btn__cancel"
                      @click="
                        changeStatusFileDocuments5(null),
                          (application.data.fields.file_document_5.status =
                            null),
                          client_null(file_document_5)
                      "
                    >
                      Отмена
                    </a>
                  </span>
                </div>
              </div>

              <div class="fileitem__archive">
                <a class="archive--link">
                  <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19 14.563l-6.146 6.243A3.984 3.984 0 0110.014 22a3.984 3.984 0 01-2.838-1.194A4.11 4.11 0 016 17.922a4.11 4.11 0 011.176-2.883l6.146-6.243A2.656 2.656 0 0115.215 8c.71 0 1.39.286 1.892.796a2.74 2.74 0 01.784 1.923 2.74 2.74 0 01-.784 1.922l-6.152 6.242a1.328 1.328 0 01-1.893 0 1.37 1.37 0 010-1.922l5.678-5.76" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                  Скачать все файлы архивом
                </a>

                <div class="fileitem__elicience">
                  Проверено «eLicience»
                </div>
              </div>
              <div class="form__block--line fileitem__comment">
                <div class="form__block--input">
                  <textarea
                    placeholder="Написать коментарий..."
                    :class="{ 'textarea--active': commentMessage }"
                    v-model="commentMessage"
                    class="input-linck textarea-linck input-linck--white"
                  ></textarea>
                </div>
              </div>
            </div>

            <div class="form__block--history">
              <div class="form__block--title">История всех действий</div>
              <div class="row">
                <div
                  class="col-lg-12"
                  v-for="route in application.data.routes"
                  v-if="route.message"
                >
                  <div class="history__item history__item--new">
                    <div class="history__item--date">
                      <span
                        >{{ route.created_at.substr(8, 2) }}.{{
                          route.created_at.substr(5, 2)
                        }}.{{ route.created_at.substr(0, 4) }}</span
                      >
                      <span class="history__item--title">Комментарий</span>
                    </div>
                    <div class="history__item--text">
                      {{ route.message }}
                    </div>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="history__item">
                    <div class="history__item--date">
                      <span
                        >{{ application.data.created_at.substr(8, 2) }}.{{
                          application.data.created_at.substr(5, 2)
                        }}.{{ application.data.created_at.substr(0, 4) }}</span
                      >
                    </div>
                    <div class="history__item--text">
                      Отправка заявки №{{ application.data.id }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="modal__wrapper--footer"
              v-if="application.data.status.slug != 'denied'"
            >
              <!--
				<div class="section--link" v-else-if="fileDocuments3.fileClass == 'add_tourist--error'">
				<button class="next__stage input__button" @click="$modal.show('statement__error')">Отозвать заявление</button>
				</div>
				-->
              <!--
				<div class="section--link">
				<button class="next__stage input__button" @click="$modal.show('statement__error')">Отозвать заявление</button>
				</div>
				-->
              <div class="section--link">
                <!--<button class="next__stage input__button" @click="$modal.show('statement__success')">Зарегистрировать заявление</button>-->
                <button
                  class="next__stage input__button"
                  v-if="
                    ((application.data.routes[0].status &&
                      application.data.routes[0].status == 'returned') ||
                      !application.data.routes[0].status) &&
                    !commentMessage &&
                    tourist.status == 'success' &&
                    file_document_1.status == 'success' &&
                    file_document_2.status == 'success' &&
                    file_document_3.status == 'success' &&
                    file_document_4.status == 'success' &&
                    ((file_document_5.status &&
                      file_document_5.status == 'success') ||
                      !file_document_5.status)
                  "
                  @click="onRegisterApplication"
                >
                  Зарегистрировать заявление
                </button>

                <button
                  class="next__stage input__button"
                  @click="$modal.show('statement__performer')"
                  v-if="
                    application.data.routes[0].status == 'success' &&
                    !application.data.routes[2].status &&
                    user.data.role.slug == 'administrator'
                  "
                >
                  Назначить исполнителя
                </button>
                <button
                  class="next__stage input__button"
                  @click="onAssignAnExecutor"
                  v-if="user.data.role.slug == 'administrator' && executor"
                >
                  Дальше
                </button>
                <button
                  class="next__stage input__button"
                  @click="$modal.show('statement__performer')"
                  v-if="
                    application.data.routes[0].status == 'success' &&
                    user.data.role.slug == 'administrator'
                  "
                >
                  Назначить исполнителя
                </button>

                <button
                  class="next__stage input__button"
                  @click="onApprove"
                  v-if="
                    application.data.routes[0].status == 'success' &&
                    application.data.routes[1].status == 'success' &&
                    !application.data.routes[2].status &&
                    user.data.role.slug == 'executor' &&
                    tourist.status == 'success' &&
                    file_document_1.status == 'success' &&
                    file_document_2.status == 'success' &&
                    file_document_3.status == 'success' &&
                    file_document_4.status == 'success' &&
                    ((file_document_5.status &&
                      file_document_5.status == 'success') ||
                      !file_document_5.status)
                  "
                >
                  Согласовать
                </button>

                <button
                  class="next__stage input__button btn--blue"
                  v-if="
                    (tourist.status && tourist.status == 'fail') ||
                    (file_document_1.status &&
                      file_document_1.status == 'fail') ||
                    (file_document_2.status &&
                      file_document_2.status == 'fail') ||
                    (file_document_3.status &&
                      file_document_3.status == 'fail') ||
                    (file_document_4.status && file_document_4.status == 'fail')
                  "
                  @click="onRetrieveApplication"
                >
                  Вернуть заявителю с комментариями
                </button>

                <button
                  class="next__stage input__button btn--blue"
                  @click="onConfirm"
                  v-if="
                    user.data.role.slug == 'administrator' &&
                    application.data.routes[2].status &&
                    application.data.routes[2].status == 'success' &&
                    tourist.status == 'success' &&
                    file_document_1.status == 'success' &&
                    file_document_2.status == 'success' &&
                    file_document_3.status == 'success' &&
                    file_document_4.status == 'success' &&
                    ((file_document_5.status &&
                      file_document_5.status == 'success') ||
                      !file_document_5.status)
                  "
                >
                  Одобрить
                </button>

                <button
                  class="next__stage input__button btn--error"
                  @click="$modal.show('statement__refuse')"
                >
                  Отказать
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal
      class="modal__block modal__block--middle modal__block--statement"
      name="statement__refuse"
      :width="680"
      :min-width="680"
      height="auto"
    >
      <div class="modal__block--wrapper">
        <div
          class="modal__block--close"
          @click="$modal.hide('statement__refuse')"
        >
          <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M31.5 10.5l-21 21M10.5 10.5l21 21" stroke="#0E1839" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
        </div>
        <div class="modal__wrapper">
          <div class="modal__title">Отказать</div>
          <div class="modal__content">
            <div class="modal__form">
              <div class="form__block--line">
                <div class="form__block--input">
                  <div class="form__block--title">Комментарии</div>
                  <textarea
                    class="input-linck textarea-linck textarea--active textarea-refuse"
                    v-model="refuseMessage"
                  ></textarea>
                </div>
              </div>

              <div class="modal__wrapper--footer modal__wrapper--center">
                <button class="next__stage input__button" @click="onRefuse">
                  {{ $t('commands.save') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      class="modal__block modal__block--middle modal__block--statement"
      name="statement__performer"
      :width="680"
      :min-width="680"
      height="auto"
    >
      <div class="modal__block--wrapper">
        <div
          class="modal__block--close"
          @click="$modal.hide('statement__performer')"
        >
          <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M31.5 10.5l-21 21M10.5 10.5l21 21" stroke="#0E1839" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
        </div>
        <div class="modal__wrapper">
          <div class="modal__title">Назначить исполнителя</div>
          <div class="modal__content">
            <div class="modal__form">
              <div class="form__block--line">
                <div class="form__block--input">
                  <div class="form__block--title">Выберите исполнителя</div>
                  <!--
					<multiselect v-model="optionsCheck[0]"
							:options="optionsCheck"
							:clearOnSelect="false"
							:clear-on-select="false"
							:placeholder="$t('labels.checkingAccount')"
							:hideSelected="true"
							style="border-color: red;"
					></multiselect>
					-->
                  <select v-model="executor" class="input-linck select-linck">
                    <option
                      v-for="executor in executors.data"
                      :value="executor.id"
                    >
                      {{ executor.surname }} {{ executor.name }}
                    </option>
                  </select>

                  <!-- input--error -->

                  <span class="input-required input--error">{{
                    $t('registration.required.error')
                  }}</span>
                  <!--
					<div class="subsidizing__banner--button section--link">
					<a class="link--active">{{ $t('commands.add') }} +</a>
					</div>
					-->
                </div>
              </div>

              <div class="modal__wrapper--footer modal__wrapper--center">
                <button
                  class="next__stage input__button"
                  @click="$modal.hide('statement__performer')"
                >
                  Назначить
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      class="modal__block modal__block--middle modal__block--statement"
      name="statement__success"
      :width="680"
      :min-width="680"
      height="auto"
    >
      <div class="modal__block--wrapper">
        <div
          class="modal__block--close"
          @click="$modal.hide('statement__success')"
        >
          <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M31.5 10.5l-21 21M10.5 10.5l21 21" stroke="#0E1839" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
        </div>
        <div class="modal__wrapper">
          <div class="modal__title">Зарегистрировать заявление</div>
          <div class="modal__content">
            <div class="modal__form">
              <div class="modal__statement">
                <div class="modal__statement--text">
                  На субсидирование затрат туроператоров в сфере въездного
                  туризма<br />
                  <a href="#" target="_blank" class="modal__statement--link"
                    >за каждого иностранного туриста №10001</a
                  >
                </div>
              </div>

              <div class="modal__wrapper--footer modal__wrapper--center">
                <button class="next__stage input__button">
                  Зарегистрировать заявление
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      class="modal__block modal__block--middle modal__block--statement"
      name="statement__error"
      :width="680"
      :min-width="680"
      height="auto"
    >
      <div class="modal__block--wrapper">
        <div
          class="modal__block--close"
          @click="$modal.hide('statement__error')"
        >
          <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M31.5 10.5l-21 21M10.5 10.5l21 21" stroke="#0E1839" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
        </div>
        <div class="modal__wrapper">
          <div class="modal__title">Проверьте все документы</div>
          <div class="modal__content">
            <div class="modal__form">
              <div class="modal__statement">
                <div class="modal__statement--image">
                  <img src="../../../assets/img/statement__image.svg" />
                </div>
                <div class="modal__statement--text">
                  Для отправки комментариев, пожалуйста проверьте все документы
                </div>
              </div>
              <div class="modal__wrapper--footer modal__wrapper--center">
                <button class="next__stage input__button">Хорошо</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { api } from '../../../boot/axios'
import Multiselect from 'vue-multiselect'

export default {
  name: 'ViewsTourist',
  components: {
    Multiselect,
  },
  data() {
    return {
      itemsGallery: [
        '/favicon.png',
        '/favicon.png',
        '/favicon.png',
        '/favicon.png',
      ],
      index: null,
      commentMessage: null,
      user: JSON.parse(localStorage.user),
      application: null,
      executors: [],
      optionsCheck: ['Исполнитель 1', 'Исполнитель 2', 'Исполнитель 3'],
      headers: [
        { text: 'ФИО туриста', value: 'fullname', sortable: false },
        { text: '№ документа ', value: 'document_number', sortable: false },
        { text: 'Уведомление ', value: 'notification', sortable: false },
        { text: 'Гражданство', value: 'citizenship.name_ru', sortable: false },
        { text: this.$t('labels.action'), value: 'action', align: 'center', sortable: false },
      ],
      userDataSave: [
        {
          name: 'Рамазан Есенов',
          passport: '6345453223',
          notification: '№43124 19 ноября 2021',
          region: 'США',
          period: '18 ноября 2021 - 20 ноября 2021',
        },
      ],
      refuseMessage: null,
      loading: false,
      options: {
        itemsPerPage: 1,
      },
      executor: null,
      headersApplicant: [
        { text: 'Заявитель', value: 'name', sortable: false },
        { text: 'БИН/ИИН', value: 'iin', sortable: false },
        { text: 'Лицензия', value: 'license', sortable: false },
        {
          text: 'Лицензия на осуществление туристской операторской деятельности',
          value: 'reference',
          sortable: false,
        },
        { text: 'Реквизиты', value: 'requisites', sortable: false },
      ],
      DataApplicant: [
        {
          name: 'ИП «Арсымбетов»',
          iin: '870613300057',
          license: '№45Т412543235 до 01.12.2022 г.',
          reference_src: '#',
          requisites_src: '#',
        },
      ],

      tourist: {
        status: null,
      },
      file_document_1: {
        fileName: null,
        fileClass: '',
        status: null,
      },
      file_document_2: {
        fileName: null,
        fileClass: '',
        status: null,
      },
      file_document_3: {
        fileName: null,
        fileClass: '',
        status: null,
      },
      file_document_4: {
        fileName: null,
        fileClass: '',
        status: null,
      },
      file_document_5: {
        fileName: '',
        fileClass: '',
        status: null,
      },
    }
  },
  methods: {
    onAssignAnExecutor() {
      const formData = new FormData()

      formData.append('_method', 'put')
      formData.append('executor_id', this.executor)

      api
        .post(
          'applications/' + this.$route.params.id + '/assign-an-executor',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )
        .then((response) => {
          if (response.data.code == 200) {
            this.$notify({
              group: 'foo',
              type: 'success',
              title: 'Исполнитель успешно назначен',
              position: 'bottom center',
            })
          }
        })
    },
    onRegisterApplication() {
      const formData = new FormData()

      formData.append('_method', 'put')

      api
        .post(
          'applications/' + this.$route.params.id + '/registration',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )
        .then((response) => {
          if (response.data.code == 200) {
            this.$notify({
              group: 'foo',
              type: 'success',
              title: 'Заявление успешно зарегистировано',
              position: 'bottom center',
            })

            this.application = response.data
          }
        })
    },
    onApprove() {
      const formData = new FormData()

      formData.append('_method', 'put')

      api
        .post('applications/' + this.$route.params.id + '/approve', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          if (response.data.code == 200) {
            this.$notify({
              group: 'foo',
              title: 'Успешно согласовано',
              position: 'bottom center',
            })
          }
        })
    },
    onRetrieveApplication() {
      const formData = new FormData()

      formData.append('_method', 'put')
      formData.append('message', this.commentMessage)
      formData.append('tourist[status]', this.tourist.status)
      formData.append('file_document_1[status]', this.file_document_1.status)
      formData.append('file_document_2[status]', this.file_document_2.status)
      formData.append('file_document_3[status]', this.file_document_3.status)
      formData.append('file_document_4[status]', this.file_document_4.status)
      if (this.file_document_5.status)
        formData.append('file_document_5[status]', this.file_document_5.status)

      api
        .post(
          'applications/' + this.$route.params.application_id + '/retrieve',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )
        .then((response) => {
          if (response.data.code == 200) {
            this.$notify({
              group: 'foo',
              title: 'Комментарии успешно отправлены',
              position: 'bottom center',
              type: 'success',
            })
          }
        })
    },
    onRefuse() {
      const formData = new FormData()

      formData.append('_method', 'put')
      formData.append('message', this.refuseMessage)

      api
        .post(
          'applications/' + this.$route.params.application_id + '/refuse',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )
        .then((response) => {
          if (response.data.code == 200) {
            this.$notify({
              group: 'foo',
              title: 'Заявление успешно отказано',
              position: 'bottom center',
            })

            this.$router.push('/' + this.$i18n.locale + '/administration')
          }
        })
    },
    onConfirm() {
      const formData = new FormData()

      formData.append('_method', 'put')
      formData.append('message', this.commentMessage)

      api
        .post(
          'applications/' + this.$route.params.application_id + '/confirm',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )
        .then((response) => {
          if (response.data.code == 200) {
            this.$notify({
              group: 'foo',
              title: 'Заявление успешно одобрен',
              position: 'bottom center',
            })

            this.$router.push('/' + this.$i18n.locale + '/administration')
          }
        })
    },
    client_null(fileDocuments) {
      fileDocuments.fileClass = ''
    },
    client_success(fileDocuments) {
      fileDocuments.fileClass = 'add_tourist--success'
    },
    client_close(fileDocuments) {
      fileDocuments.fileClass = 'add_tourist--error'
    },
    changeTouristStatus(status) {
      this.tourist.status = status
    },
    changeStatusFileDocuments1(status) {
      this.file_document_1.status = status
    },
    changeStatusFileDocuments2(status) {
      this.file_document_2.status = status
    },
    changeStatusFileDocuments3(status) {
      this.file_document_3.status = status
    },
    changeStatusFileDocuments4(status) {
      this.file_document_4.status = status
    },
    changeStatusFileDocuments5(status) {
      this.file_document_5.status = status
    },
  },
  beforeCreate() {
    if (!localStorage.token) {
      this.$router.push('/ru/login')
    }

    api
      .get('users', {
        params: {
          'filter[role]': 'executor',
        },
      })
      .then((response) => {
        if (response.data) {
          this.executors = response.data
        }
      })

    api
      .get('applications/' + this.$route.params.application_id)
      .then((response) => {
        if (response.data) {
          this.application = response.data

          if (response.data.data.applicant) {
            this.DataApplicant[0] = {
              name: response.data.data.applicant.organization,
              iin: response.data.data.applicant.bin
                ? response.data.data.applicant.bin
                : response.data.data.applicant.iin,
              license: '№45Т412543235 до 01.12.2022 г.',
              reference_src: response.data.data.applicant.certificate_of_debt,
              requisites_src: response.data.data.applicant.requisites,
            }
          }

          if (response.data.data.fields.tourist.status) {
            if (this.user.data.role.slug == 'stationery') {
              this.tourist.status =
                response.data.data.fields.tourist.status.stationery
            }

            if (this.user.data.role.slug == 'executor') {
              this.tourist.status =
                response.data.data.fields.tourist.status.executor
            }

            if (this.user.data.role.slug == 'administrator') {
              this.tourist.status =
                response.data.data.fields.tourist.status.administrator
            }
          }

          if (response.data.data.fields.file_document_1.status) {
            if (this.user.data.role.slug == 'stationery') {
              this.file_document_1.status =
                response.data.data.fields.file_document_1.status.stationery
            }

            if (this.user.data.role.slug == 'executor') {
              this.file_document_1.status =
                response.data.data.fields.file_document_1.status.executor
            }

            if (this.user.data.role.slug == 'administrator') {
              this.file_document_1.status =
                response.data.data.fields.file_document_1.status.administrator
            }

            if (this.file_document_1.status == 'success') {
              this.file_document_1.fileClass = 'add_tourist--success'
            }

            if (this.file_document_1.status == 'fail') {
              this.file_document_1.fileClass = 'add_tourist--error'
            }

            this.file_document_1.fileName =
              response.data.data.fields.file_document_1.name
          }

          if (response.data.data.fields.file_document_2.status) {
            if (this.user.data.role.slug == 'stationery') {
              this.file_document_2.status =
                response.data.data.fields.file_document_2.status.stationery
            }

            if (this.user.data.role.slug == 'executor') {
              this.file_document_2.status =
                response.data.data.fields.file_document_2.status.executor
            }

            if (this.user.data.role.slug == 'administrator') {
              this.file_document_2.status =
                response.data.data.fields.file_document_2.status.administrator
            }

            if (this.file_document_2.status == 'success') {
              this.file_document_2.fileClass = 'add_tourist--success'
            }

            if (this.file_document_2.status == 'fail') {
              this.file_document_2.fileClass = 'add_tourist--error'
            }

            this.file_document_2.fileName =
              response.data.data.fields.file_document_2.name
          }

          if (response.data.data.fields.file_document_3.status) {
            if (this.user.data.role.slug == 'stationery') {
              this.file_document_3.status =
                response.data.data.fields.file_document_3.status.stationery
            }

            if (this.user.data.role.slug == 'executor') {
              this.file_document_3.status =
                response.data.data.fields.file_document_3.status.executor
            }

            if (this.user.data.role.slug == 'administrator') {
              this.file_document_3.status =
                response.data.data.fields.file_document_3.status.administrator
            }

            if (this.file_document_3.status == 'success') {
              this.file_document_3.fileClass = 'add_tourist--success'
            }

            if (this.file_document_3.status == 'fail') {
              this.file_document_3.fileClass = 'add_tourist--error'
            }

            this.file_document_3.fileName =
              response.data.data.fields.file_document_3.name
          }

          if (response.data.data.fields.file_document_4.status) {
            if (this.user.data.role.slug == 'stationery') {
              this.file_document_4.status =
                response.data.data.fields.file_document_4.status.stationery
            }

            if (this.user.data.role.slug == 'executor') {
              this.file_document_4.status =
                response.data.data.fields.file_document_4.status.executor
            }

            if (this.user.data.role.slug == 'administrator') {
              this.file_document_4.status =
                response.data.data.fields.file_document_4.status.administrator
            }

            if (this.file_document_4.status == 'success') {
              this.file_document_4.fileClass = 'add_tourist--success'
            }

            if (this.file_document_4.status == 'fail') {
              this.file_document_4.fileClass = 'add_tourist--error'
            }

            this.file_document_4.fileName =
              response.data.data.fields.file_document_4.name
          }

          if (response.data.data.fields.file_document_5.status) {
            if (this.user.data.role.slug == 'stationery') {
              this.file_document_5.status =
                response.data.data.fields.file_document_5.status.stationery
            }

            if (this.user.data.role.slug == 'executor') {
              this.file_document_5.status =
                response.data.data.fields.file_document_5.status.executor
            }

            if (this.user.data.role.slug == 'administrator') {
              this.file_document_5.status =
                response.data.data.fields.file_document_5.status.administrator
            }

            if (this.file_document_5.status == 'success') {
              this.file_document_5.fileClass = 'add_tourist--success'
            }

            if (this.file_document_5.status == 'fail') {
              this.file_document_5.fileClass = 'add_tourist--error'
            }

            this.file_document_5.fileName =
              response.data.data.fields.file_document_5.name
          }
        }
      })
  },
}
</script>

<style scoped>
.opacity {
  opacity: 0.5;
}
</style>
