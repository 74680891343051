<template>
  <div class="fileitem--col">
    <div
      class="add_tourist--fileitem"
      :class="{
        'add_tourist--success': isSuccess,
        'add_tourist--error': isFail,
      }"
    >
      <div class="fileitem__block">
        <div class="fileitem__block--title">{{ label }}</div>
        <div
          v-if="doc.name || addDocs"
          class="fileitem__block--file fileitem__block--view"
        >
          <template v-if="doc.name">
            <component
              :is="isCanOpenByFancy(doc.src) ? 'div' : 'a'"
              class="fileitem__block--wrap"
              :href="doc.src"
              v-bind="
                isCanOpenByFancy(doc.src)
                  ? { 'data-fancybox': name }
                  : { target: '_blank' }
              "
            >
              <div class="fileitem__block--icon">
                <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="isSuccess" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-else-if="isFail" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.17 16.42l2.935-3.126 2.935-3.125m0 6.25l-5.87-6.25M8.355 4.826a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.992 0 3.848 3.848 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.848 3.848 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-else="" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 15a3 3 0 100-6 3 3 0 000 6z" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
              </div>
              <div class="fileitem__block--text">Просмотр</div>
            </component>
          </template>
          <template v-if="addDocs">
            <component
              v-for="(addDoc, addDocKey) in Array.isArray(addDocs)
                ? addDocs
                : [addDocs]"
              :key="addDocKey"
              :is="isCanOpenByFancy(addDoc.src) ? 'div' : 'a'"
              class="fileitem__block--wrap"
              :href="addDoc.src"
              v-bind="
                isCanOpenByFancy(addDoc.src)
                  ? { 'data-fancybox': name }
                  : { target: '_blank' }
              "
            >
              <div class="fileitem__block--icon">
                <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="isSuccess" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-else-if="isFail" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.17 16.42l2.935-3.126 2.935-3.125m0 6.25l-5.87-6.25M8.355 4.826a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.992 0 3.848 3.848 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.848 3.848 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-else="" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 15a3 3 0 100-6 3 3 0 000 6z" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
              </div>
              <div class="fileitem__block--text">Просмотр</div>
            </component>
          </template>
        </div>
        <div v-else class="fileitem__block--file">
          <div class="fileitem__block--wrap">
            <div class="fileitem__block--text">Нет документа</div>
          </div>
        </div>
      </div>
      <span v-if="!(isSuccess || isFail)" class="fileitem__block--status">
        <a
          v-tooltip.bottom="{
            content: 'Одобрить',
            class: ['table__tooltip'],
          }"
          class="tb__table--btn table__btn--status tb__table--success"
          @click="$emit('changeStatus', 'success')"
        >
          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#33AD28" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
        </a>
        <a
          v-tooltip.bottom="{
            content: 'Отклонить',
            class: ['table__tooltip'],
          }"
          class="tb__table--btn table__btn--status tb__table--close"
          @click="$emit('changeStatus', 'fail')"
        >
          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.17 16.42l2.935-3.126 2.935-3.125m0 6.25l-5.87-6.25M8.355 4.826a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.992 0 3.848 3.848 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.848 3.848 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#F3574D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
        </a>
      </span>
      <span class="fileitem__block--status" v-else>
        <a class="btn__cancel" @click="$emit('changeStatus', null)"> Отмена </a>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FileDocCheck',
  props: {
    label: String,
    name: String,
    doc: Object,
    addDocs: Object,
    isSuccess: Boolean,
    isFail: Boolean,
  },
  emits: ['changeStatus'],
  methods: {
    isCanOpenByFancy(fileName) {
      return ['pdf', 'jpg', 'png', 'jpeg'].some((type) =>
        fileName?.includes(type),
      )
    },
  },
}
</script>

<style lang="scss" scoped></style>
