<template>
  <div class="wrap__content">
    <div class="content__page">
      <div class="section__subsidizing--banner">
        <div class="container">
          <div class="subsidizing__banner subsidizing__banner--blue">
            <div class="subsidizing__banner--left">
              <div class="content__page--back">
                <a @click="$router.go(-1)">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19 12H5M12 19l-7-7 7-7" stroke="#0E1839" stroke-linecap="round" stroke-linejoin="round"/></svg>
                  {{ $t('system.back') }}
                </a>
              </div>
              <div
                class="subsidizing__banner--title section__title"
                v-html="$t('subsidizing_kids.title')"
              ></div>
              <div class="subsidizing__banner--desc">
                {{ $t('subsidizing_kids.desc') }}
              </div>
              <div class="subsidizing__banner--download">
                <a
                  v-if="$i18n.locale == 'kz'"
                  href="/download/regulations-kids-go-free-kz.pdf"
                  download=""
                  >{{ $t('system.download_rules') }}</a
                >
                <a
                  v-else
                  href="/download/regulations-kids-go-free.pdf"
                  download=""
                  >{{ $t('system.download_rules') }}</a
                >
              </div>
              <div class="subsidizing__banner--button section--link">
                <a :href="'/' + $i18n.locale + '/registration'">{{
                  $t('system.apply')
                }}</a>
                <a
                  :href="'/' + $i18n.locale + '/registration'"
                  class="link--active"
                  >{{ $t('system.my_statements') }}</a
                >
              </div>
            </div>
            <div class="subsidizing__banner--right">
              <img src="../assets/img/subsidizing__banner-2.png" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div class="section__stage--subs">
        <div class="stage__subs--item stage__subs--default">
          <div class="container">
            <div class="stage__subs--block">
              <div class="stage__subs--top">
                <div
                  class="section__title"
                  v-html="$t('subsidizing_kids.stage.stage_1.title')"
                ></div>
              </div>
              <div class="stage__subs--info">
                <div class="stage__subs--desc">
                  <div v-html="$t('subsidizing_kids.stage.stage_1.desc')"></div>

                  <div
                    class="section__title"
                    v-html="$t('subsidizing_kids.stage.stage_1.title2')"
                  ></div>
                </div>
                <swiper
                  class="stage__subs--territory stage__subs--slider"
                  :options="territoryOption"
                >
                  <swiper-slide>
                    <div class="territory__item">
                      <div class="territory__item--img">
                        <img
                          src="../assets/img/territory/territory__item-1.jpg"
                          alt=""
                        />
                      </div>
                      <div
                        class="territory__item--text"
                        v-html="
                          $t('subsidizing_kids.stage.stage_1.stages.item1')
                        "
                      ></div>
                    </div>
                  </swiper-slide>
                  <swiper-slide>
                    <div class="territory__item">
                      <div class="territory__item--img">
                        <img
                          src="../assets/img/territory/territory__item-2.jpg"
                          alt=""
                        />
                      </div>
                      <div
                        class="territory__item--text"
                        v-html="
                          $t('subsidizing_kids.stage.stage_1.stages.item2')
                        "
                      ></div>
                    </div>
                  </swiper-slide>
                  <swiper-slide>
                    <div class="territory__item">
                      <div class="territory__item--img">
                        <img
                          src="../assets/img/territory/territory__item-3.jpg"
                          alt=""
                        />
                      </div>
                      <div
                        class="territory__item--text"
                        v-html="
                          $t('subsidizing_kids.stage.stage_1.stages.item3')
                        "
                      ></div>
                    </div>
                  </swiper-slide>
                  <swiper-slide>
                    <div class="territory__item">
                      <div class="territory__item--img">
                        <img
                          src="../assets/img/territory/territory__item-4.jpg"
                          alt=""
                        />
                      </div>
                      <div
                        class="territory__item--text"
                        v-html="
                          $t('subsidizing_kids.stage.stage_1.stages.item4')
                        "
                      ></div>
                    </div>
                  </swiper-slide>
                  <swiper-slide>
                    <div class="territory__item">
                      <div class="territory__item--img">
                        <img
                          src="../assets/img/territory/territory__item-5.jpg"
                          alt=""
                        />
                      </div>
                      <div
                        class="territory__item--text"
                        v-html="
                          $t('subsidizing_kids.stage.stage_1.stages.item5')
                        "
                      ></div>
                    </div>
                  </swiper-slide>
                  <swiper-slide>
                    <div class="territory__item">
                      <div class="territory__item--img">
                        <img
                          src="../assets/img/territory/territory__item-6.jpg"
                          alt=""
                        />
                      </div>
                      <div
                        class="territory__item--text"
                        v-html="
                          $t('subsidizing_kids.stage.stage_1.stages.item6')
                        "
                      ></div>
                    </div>
                  </swiper-slide>
                  <swiper-slide>
                    <div class="territory__item">
                      <div class="territory__item--img">
                        <img
                          src="../assets/img/territory/territory__item-7.jpg"
                          alt=""
                        />
                      </div>
                      <div
                        class="territory__item--text"
                        v-html="
                          $t('subsidizing_kids.stage.stage_1.stages.item7')
                        "
                      ></div>
                    </div>
                  </swiper-slide>
                  <swiper-slide>
                    <div class="territory__item">
                      <div class="territory__item--img">
                        <img
                          src="../assets/img/territory/territory__item-8.jpg"
                          alt=""
                        />
                      </div>
                      <div
                        class="territory__item--text"
                        v-html="
                          $t('subsidizing_kids.stage.stage_1.stages.item8')
                        "
                      ></div>
                    </div>
                  </swiper-slide>
                  <swiper-slide>
                    <div class="territory__item">
                      <div class="territory__item--img">
                        <img
                          src="../assets/img/territory/territory__item-9.jpg"
                          alt=""
                        />
                      </div>
                      <div
                        class="territory__item--text"
                        v-html="
                          $t('subsidizing_kids.stage.stage_1.stages.item9')
                        "
                      ></div>
                    </div>
                  </swiper-slide>
                  <swiper-slide>
                    <div class="territory__item">
                      <div class="territory__item--img">
                        <img
                          src="../assets/img/territory/territory__item-10.jpg"
                          alt=""
                        />
                      </div>
                      <div
                        class="territory__item--text"
                        v-html="
                          $t('subsidizing_kids.stage.stage_1.stages.item10')
                        "
                      ></div>
                    </div>
                  </swiper-slide>
                </swiper>
                <div class="conditions--arrows">
                  <div
                    class="conditions__arrow territory__arrow--prev"
                    slot="button-prev"
                  >
                    <svg width="74" height="24" viewBox="0 0 74 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M76 12H5M12 19l-7-7 7-7" stroke="#DCDCF0" stroke-linecap="round" stroke-linejoin="round"/></svg>
                  </div>
                  <div
                    class="conditions__arrow territory__arrow--next"
                    slot="button-next"
                  >
                    <svg width="74" height="24" viewBox="0 0 74 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M-2 12h71M62 5l7 7-7 7" stroke="#FFCE03" stroke-linecap="round" stroke-linejoin="round"/></svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="stage__subs--item stage__subs--purple">
          <div class="container">
            <div class="stage__subs--block">
              <div class="stage__subs--top">
                <div
                  class="section__title"
                  v-html="$t('subsidizing_kids.stage.stage_2.title')"
                ></div>
              </div>
              <div class="stage__subs--info">
                <div
                  class="stage__subs--desc"
                  v-html="$t('subsidizing_kids.stage.stage_2.desc')"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="stage__subs--item stage__subs--error">
          <div class="container">
            <div class="stage__subs--block">
              <div class="stage__subs--top">
                <div
                  class="section__title"
                  v-html="$t('subsidizing_kids.stage.stage_3.title')"
                ></div>
              </div>
              <div class="stage__subs--info">
                <div
                  class="stage__subs--desc"
                  v-html="$t('subsidizing_kids.stage.stage_3.desc')"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="stage__subs--item stage__subs--default">
          <div class="container">
            <div class="stage__subs--block">
              <div class="stage__subs--top">
                <div
                  class="section__title"
                  v-html="$t('subsidizing_kids.stage.stage_4.title')"
                ></div>
              </div>
              <div class="stage__subs--info">
                <div class="d-none d-xl-block">
                  <div class="obtaining--slider">
                    <div class="stage__subs--obtaining">
                      <div class="obtaining__item">
                        <div class="obtaining__item--top">
                          <div class="obtaining__item--img">
                            <img
                              src="../assets/img/obtaining/obtaining__item-1.png"
                            />
                            <span>
                              <svg width="57" height="113" viewBox="0 0 57 113" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M56.5 111a54.5 54.5 0 010-109" stroke="#FFCE03" stroke-width="4"/></svg>
                            </span>
                          </div>
                          <div class="obtaining__item--stage">
                            {{
                              $t(
                                'subsidizing_kids.stage.stage_4.stages_1.stage',
                              )
                            }}
                          </div>
                        </div>
                        <div class="obtaining__item--text">
                          {{
                            $t('subsidizing_kids.stage.stage_4.stages_1.text')
                          }}
                        </div>
                      </div>
                      <div class="obtaining__item">
                        <div class="obtaining__item--top">
                          <div class="obtaining__item--img">
                            <img
                              src="../assets/img/obtaining/obtaining__item-2.png"
                            />
                            <span>
                              <svg width="57" height="113" viewBox="0 0 57 113" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M56.5 111a54.5 54.5 0 010-109" stroke="#FFCE03" stroke-width="4"/></svg>
                            </span>
                          </div>
                          <div class="obtaining__item--stage">
                            {{
                              $t(
                                'subsidizing_kids.stage.stage_4.stages_2.stage',
                              )
                            }}
                          </div>
                        </div>
                        <div class="obtaining__item--text">
                          {{
                            $t('subsidizing_kids.stage.stage_4.stages_2.text')
                          }}
                        </div>
                      </div>
                      <div class="obtaining__item">
                        <div class="obtaining__item--top">
                          <div class="obtaining__item--img">
                            <img
                              src="../assets/img/obtaining/obtaining__item-3.png"
                            />
                            <span>
                              <svg width="57" height="113" viewBox="0 0 57 113" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M56.5 111a54.5 54.5 0 010-109" stroke="#FFCE03" stroke-width="4"/></svg>
                            </span>
                          </div>
                          <div class="obtaining__item--stage">
                            {{
                              $t(
                                'subsidizing_kids.stage.stage_4.stages_3.stage',
                              )
                            }}
                          </div>
                        </div>
                        <div class="obtaining__item--text">
                          {{
                            $t('subsidizing_kids.stage.stage_4.stages_3.text')
                          }}
                        </div>
                      </div>
                      <div class="obtaining__item">
                        <div class="obtaining__item--top">
                          <div class="obtaining__item--img">
                            <img
                              src="../assets/img/obtaining/obtaining__item-4.png"
                            />
                            <span>
                              <svg width="57" height="113" viewBox="0 0 57 113" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M56.5 111a54.5 54.5 0 010-109" stroke="#FFCE03" stroke-width="4"/></svg>
                            </span>
                          </div>
                          <div class="obtaining__item--stage">
                            {{
                              $t(
                                'subsidizing_kids.stage.stage_4.stages_4.stage',
                              )
                            }}
                          </div>
                        </div>
                        <div class="obtaining__item--text">
                          {{
                            $t('subsidizing_kids.stage.stage_4.stages_4.text')
                          }}
                        </div>
                      </div>
                      <div class="obtaining__item">
                        <div class="obtaining__item--top">
                          <div class="obtaining__item--img">
                            <img
                              src="../assets/img/obtaining/obtaining__item-5.png"
                            />
                            <span>
                              <svg width="57" height="113" viewBox="0 0 57 113" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M56.5 111a54.5 54.5 0 010-109" stroke="#FFCE03" stroke-width="4"/></svg>
                            </span>
                          </div>
                          <div class="obtaining__item--stage">
                            {{
                              $t(
                                'subsidizing_kids.stage.stage_4.stages_5.stage',
                              )
                            }}
                          </div>
                        </div>
                        <div class="obtaining__item--text">
                          {{
                            $t('subsidizing_kids.stage.stage_4.stages_5.text')
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-block d-xl-none">
                  <swiper class="obtaining--slider" :options="obtainingOption">
                    <swiper-slide class="obtaining__item">
                      <div class="obtaining__item--top">
                        <div class="obtaining__item--img">
                          <img
                            src="../assets/img/obtaining/obtaining__item-1.png"
                          />
                          <span>
                            <svg width="57" height="113" viewBox="0 0 57 113" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M56.5 111a54.5 54.5 0 010-109" stroke="#FFCE03" stroke-width="4"/></svg>
                          </span>
                        </div>
                        <div class="obtaining__item--stage">
                          {{
                            $t('subsidizing_kids.stage.stage_4.stages_1.stage')
                          }}
                        </div>
                      </div>
                      <div class="obtaining__item--text">
                        {{ $t('subsidizing_kids.stage.stage_4.stages_1.text') }}
                      </div>
                    </swiper-slide>
                    <swiper-slide class="obtaining__item">
                      <div class="obtaining__item--top">
                        <div class="obtaining__item--img">
                          <img
                            src="../assets/img/obtaining/obtaining__item-2.png"
                          />
                          <span>
                            <svg width="57" height="113" viewBox="0 0 57 113" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M56.5 111a54.5 54.5 0 010-109" stroke="#FFCE03" stroke-width="4"/></svg>
                          </span>
                        </div>
                        <div class="obtaining__item--stage">
                          {{
                            $t('subsidizing_kids.stage.stage_4.stages_2.stage')
                          }}
                        </div>
                      </div>
                      <div class="obtaining__item--text">
                        {{ $t('subsidizing_kids.stage.stage_4.stages_2.text') }}
                      </div>
                    </swiper-slide>
                    <swiper-slide class="obtaining__item">
                      <div class="obtaining__item--top">
                        <div class="obtaining__item--img">
                          <img
                            src="../assets/img/obtaining/obtaining__item-3.png"
                          />
                          <span>
                            <svg width="57" height="113" viewBox="0 0 57 113" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M56.5 111a54.5 54.5 0 010-109" stroke="#FFCE03" stroke-width="4"/></svg>
                          </span>
                        </div>
                        <div class="obtaining__item--stage">
                          {{
                            $t('subsidizing_kids.stage.stage_4.stages_3.stage')
                          }}
                        </div>
                      </div>
                      <div class="obtaining__item--text">
                        {{ $t('subsidizing_kids.stage.stage_4.stages_3.text') }}
                      </div>
                    </swiper-slide>
                    <swiper-slide class="obtaining__item">
                      <div class="obtaining__item--top">
                        <div class="obtaining__item--img">
                          <img
                            src="../assets/img/obtaining/obtaining__item-4.png"
                          />
                          <span>
                            <svg width="57" height="113" viewBox="0 0 57 113" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M56.5 111a54.5 54.5 0 010-109" stroke="#FFCE03" stroke-width="4"/></svg>
                          </span>
                        </div>
                        <div class="obtaining__item--stage">
                          {{
                            $t('subsidizing_kids.stage.stage_4.stages_4.stage')
                          }}
                        </div>
                      </div>
                      <div class="obtaining__item--text">
                        {{ $t('subsidizing_kids.stage.stage_4.stages_4.text') }}
                      </div>
                    </swiper-slide>
                    <swiper-slide class="obtaining__item">
                      <div class="obtaining__item--top">
                        <div class="obtaining__item--img">
                          <img
                            src="../assets/img/obtaining/obtaining__item-5.png"
                          />
                          <span>
                            <svg width="57" height="113" viewBox="0 0 57 113" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M56.5 111a54.5 54.5 0 010-109" stroke="#FFCE03" stroke-width="4"/></svg>
                          </span>
                        </div>
                        <div class="obtaining__item--stage">
                          {{
                            $t('subsidizing_kids.stage.stage_4.stages_5.stage')
                          }}
                        </div>
                      </div>
                      <div class="obtaining__item--text">
                        {{ $t('subsidizing_kids.stage.stage_4.stages_5.text') }}
                      </div>
                    </swiper-slide>
                  </swiper>
                  <div class="conditions--arrows">
                    <div
                      class="conditions__arrow obtaining__arrow--prev"
                      slot="button-prev"
                    >
                      <svg width="74" height="24" viewBox="0 0 74 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M76 12H5M12 19l-7-7 7-7" stroke="#DCDCF0" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </div>
                    <div
                      class="conditions__arrow obtaining__arrow--next"
                      slot="button-next"
                    >
                      <svg width="74" height="24" viewBox="0 0 74 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M-2 12h71M62 5l7 7-7 7" stroke="#FFCE03" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'

export default {
  name: 'SubsidingKids',
  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      territoryOption: {
        slidesPerView: 4,
        spaceBetween: 20,
        navigation: {
          prevEl: '.territory__arrow--prev',
          nextEl: '.territory__arrow--next',
        },
        breakpoints: {
          320: {
            slidesPerView: 1,
          },
          520: {
            slidesPerView: 1.5,
          },
          645: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 2.5,
          },
          992: {
            slidesPerView: 3,
          },
          1024: {
            slidesPerView: 3.5,
          },
          1200: {
            slidesPerView: 4,
          },
        },
      },
      obtainingOption: {
        slidesPerView: 3,
        spaceBetween: 0,
        navigation: {
          prevEl: '.obtaining__arrow--prev',
          nextEl: '.obtaining__arrow--next',
        },
        breakpoints: {
          320: {
            slidesPerView: 1,
          },
          520: {
            slidesPerView: 1.2,
          },
          645: {
            slidesPerView: 2,
          },
          992: {
            slidesPerView: 3,
          },
          1024: {
            slidesPerView: 3,
          },
        },
      },
    }
  },
  head: {
    title() {
      return {
        inner:
          this.$i18n.locale == 'kz'
            ? 'Балаларға арналған «kids go free» авиабилеттерін субсидиялау | «eQonaq» мамандандырылған ақпараттық жүйесі'
            : this.$i18n.locale == 'ru'
              ? 'Субсидирование авиабилетов для детей «kids go free» | Специализированная информационная система «eQonaq»'
              : 'Subsidized air tickets for children "kids go free" | Specialized information system "eQonaq"',
      }
    },
    meta: [{ name: 'description', content: 'My description' }],
  },
}
</script>

<style scoped></style>
