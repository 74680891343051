<template>
   <div class="main-section-title">
    <div class="main-section-title__value">
      {{ title }}
    </div>
    <div class="main-section-title__subtitle">
      <strong>{{ subtitle }}</strong>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainSectionTitle',
  props: {
    title: String,
    subtitle: String,
  },
}
</script>

<style lang="scss" scoped>
.main-section-title {
  text-align: center;
  line-height: 1.2;
  margin-bottom: 30px;

  &__value {
    font-weight: normal;
    font-size: 18px;
    text-transform: uppercase;
    color: #0e1839;
  }

  &__subtitle {
    font-size: 36px;
    text-transform: uppercase;
    margin-bottom: 0;
    line-height: 1.2;
    font-weight: normal;
    color: #0e1839;
  }
}
</style>
