<template>
  <div class="ui-back content__page--back">
    <router-link :to="href" class="ui-back__link">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'ui-back__img'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19 12H5M12 19l-7-7 7-7" stroke="#0E1839" stroke-linecap="round" stroke-linejoin="round"/></svg>
      {{ $t('system.back') }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'UiBack',
  props: {
    href: String,
  },
}
</script>

<style lang="scss" scoped>
.ui-back {
  margin-bottom: 30px;

  &__link {
    display: inline-flex;
    align-items: center;
    font-weight: normal;
    font-size: 16px;
    color: #0e1839;
    text-decoration: none;
    cursor: pointer;
    text-transform: uppercase;
  }

  &__img {
    margin-right: 10px;
  }
}
</style>
