<template>
  <modal
    class="modal__block modal__block--middle modal__block--statement"
    name="statement__refuse"
    :width="680"
    :min-width="680"
    height="auto"
  >
    <div class="modal__block--wrapper">
      <div
        class="modal__block--close"
        @click="$modal.hide('statement__refuse')"
      >
        <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M31.5 10.5l-21 21M10.5 10.5l21 21" stroke="#0E1839" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
      </div>
      <div class="modal__wrapper">
        <div class="modal__title">Отказать</div>
        <div class="modal__content">
          <div class="modal__form">
            <div class="form__block--line">
              <div class="form__block--input">
                <div class="form__block--title">Комментарии</div>
                <textarea
                  class="input-linck textarea-linck textarea--active textarea-refuse"
                  v-model="refuseMessage"
                ></textarea>
              </div>
            </div>

            <div class="modal__wrapper--footer modal__wrapper--center">
              <button class="next__stage input__button" @click="onRefuse">
                {{ $t('commands.save') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { api } from '@/boot/axios'

export default {
  name: 'StatRefuse',
  data() {
    return {
      refuseMessage: '',
    }
  },
  methods: {
    onRefuse() {
      const formData = new FormData()
      formData.append('_method', 'put')
      formData.append('message', this.refuseMessage)

      api
        .post(
          'applications/' + this.$route.params.application_id + '/refuse',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )
        .then((response) => {
          if (response.data.code == 200) {
            this.$notify({
              group: 'foo',
              title: 'Заявление успешно отказано',
              position: 'bottom center',
            })

            this.$router.push('/' + this.$i18n.locale + '/administration')
          }

          if (response.data.code == 401) {
            setTimeout(() => {
              this.$notify({
                group: 'foo',
                type: 'error',
                title: 'Не верный ключ ЭЦП',
                position: 'bottom center',
              })
            }, 3000)
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped></style>
