<template>
  <div class="wrap__content">
    <notifications group="foo" position="bottom center" />

    <div class="content__page content__page--margintop">
      <div class="container">
        <div class="content__page--back">
          <a @click="$router.go(-1)">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'ui-select__arrow'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19 12H5M12 19l-7-7 7-7" stroke="#0E1839" stroke-linecap="round" stroke-linejoin="round"/></svg>
            {{ $t('system.back') }}
          </a>
        </div>

        <div class="content__title--block">
          <div class="section__title--mini">
            {{ $t('titles.application_for_tourist_subs') }}
          </div>
          <div class="content__title section__title">
            <strong>{{ $t('titles.application_for_tourist_childs') }}</strong>
          </div>
        </div>
        <form method="POST" @submit.prevent="saveApp">
          <ExpansionPanel
            v-for="(passenger, passengerInd) in passengers"
            :key="passengerInd"
            :title="$t('labels.passenger_data', [passengerInd + 1])"
            :activate="passengerInd === 0"
            class="passenger"
            :class="{
              'passenger--last': passengerInd === passengers.length - 1,
              'passenger--error': Object.values(passenger.errors || {}).length,
            }"
          >
            <div class="passenger__form">
              <div class="passenger__data">
                <div class="passenger__data-row">
                  <UiInput
                    :model-value="passenger.name"
                    :type="'text'"
                    :label="$t('labels.name')"
                    :name="`name[${passengerInd}]`"
                    :placeholder="''"
                    :required="true"
                    :disabled="!isEditable"
                    :error="
                      getErrors(`applications.${passengerInd}.tourist.name`)
                    "
                    @update:model-value="
                      updatePassenger('name', passengerInd, $event)
                    "
                  />
                  <UiInput
                    :model-value="passenger.surname"
                    :type="'text'"
                    :label="$t('labels.surname')"
                    :name="`surname[${passengerInd}]`"
                    :placeholder="''"
                    :required="true"
                    :disabled="!isEditable"
                    :error="
                      getErrors(`applications.${passengerInd}.tourist.surname`)
                    "
                    @update:model-value="
                      updatePassenger('surname', passengerInd, $event)
                    "
                  />
                </div>
                <div class="passenger__data-row">
                  <UiInput
                    :model-value="passenger.patronymic"
                    :type="'text'"
                    :label="$t('labels.patronymic')"
                    :name="`patronymic[${passengerInd}]`"
                    :placeholder="''"
                    :required="true"
                    :disabled="!isEditable"
                    :error="
                      getErrors(
                        `applications.${passengerInd}.tourist.patronymic`,
                      )
                    "
                    @update:model-value="
                      updatePassenger('patronymic', passengerInd, $event)
                    "
                  />
                  <div class="passenger__data-subrow">
                    <UiDate
                      :model-value="passenger.date_start"
                      :type="'text'"
                      :label="$t('labels.date_start')"
                      :name="`date_start[${passengerInd}]`"
                      :placeholder="''"
                      :required="true"
                      :disabled="!isEditable"
                      :error="
                        getErrors(
                          `applications.${passengerInd}.tourist.date_start`,
                        )
                      "
                      @update:model-value="
                        updatePassenger('date_start', passengerInd, $event)
                      "
                    />
                    <UiDate
                      :model-value="passenger.date_end"
                      :type="'text'"
                      :label="$t('labels.date_end')"
                      :name="`date_end[${passengerInd}]`"
                      :placeholder="''"
                      :required="true"
                      :disabled="!isEditable"
                      :error="
                        getErrors(
                          `applications.${passengerInd}.tourist.date_end`,
                        )
                      "
                      @update:model-value="
                        updatePassenger('date_end', passengerInd, $event)
                      "
                    />
                  </div>
                  <!-- <UiInput
                    :model-value="passenger.document_number"
                    :type="'text'"
                    :label="$t('labels.document_number')"
                    :name="`document_number[${passengerInd}]`"
                    :placeholder="''"
                    :required="true"
                    :disabled="!isEditable"
                    :error="
                      getErrors(
                        `applications.${passengerInd}.tourist.document_number`,
                      )
                    "
                    @update:model-value="
                      updatePassenger('document_number', passengerInd, $event)
                    "
                  /> -->
                </div>
                <div class="passenger__data-row">
                  <UiDate
                    :model-value="passenger.date_of_birth"
                    :type="'text'"
                    :label="$t('labels.date_of_birth')"
                    :name="`date_of_birth[${passengerInd}]`"
                    :placeholder="''"
                    :required="true"
                    :disabled="!isEditable"
                    :error="
                      getErrors(
                        `applications.${passengerInd}.tourist.date_of_birth`,
                      )
                    "
                    @update:model-value="
                      updatePassenger('date_of_birth', passengerInd, $event)
                    "
                  />
                  <UiInput
                    :model-value="passenger.placement"
                    :type="'text'"
                    :label="$t('labels.placement')"
                    :name="`placement[${passengerInd}]`"
                    :placeholder="''"
                    :required="true"
                    :disabled="!isEditable"
                    :error="
                      getErrors(
                        `applications.${passengerInd}.tourist.placement`,
                      )
                    "
                    @update:model-value="
                      updatePassenger('placement', passengerInd, $event)
                    "
                  />
                  <!-- <MainCountries
                    :value="passenger.citizenship_id"
                    :label="$t('labels.citizenship_name')"
                    :name="`citizenship_name[${passengerInd}]`"
                    :required="true"
                    :disabled="!isEditable"
                    :placeholder="''"
                    :error="
                      getErrors(
                        `applications.${passengerInd}.tourist.citizenship_name`,
                      )
                    "
                    @input="
                      updatePassenger('citizenship_id', passengerInd, $event)
                    "
                  /> -->
                </div>
                <!-- <div class="passenger__data-row">
                  <div class="passenger__data-subrow">
                    <UiDate
                      :model-value="passenger.arrival_date"
                      :type="'text'"
                      :label="$t('labels.arrival_date')"
                      :name="`arrival_date[${passengerInd}]`"
                      :placeholder="''"
                      :required="true"
                      :disabled="!isEditable"
                      :error="
                        getErrors(
                          `applications.${passengerInd}.tourist.arrival_date`,
                        )
                      "
                      @update:model-value="
                        updatePassenger('arrival_date', passengerInd, $event)
                      "
                    />
                    <UiDate
                      :model-value="passenger.date_of_departure"
                      :type="'text'"
                      :label="$t('labels.date_of_departure')"
                      :name="`date_of_departure[${passengerInd}]`"
                      :placeholder="''"
                      :required="true"
                      :disabled="!isEditable"
                      :error="
                        getErrors(
                          `applications.${passengerInd}.tourist.date_of_departure`,
                        )
                      "
                      @update:model-value="
                        updatePassenger(
                          'date_of_departure',
                          passengerInd,
                          $event,
                        )
                      "
                    />
                  </div>
                </div> -->
                <div class="passenger__data-row">
                  <UiInput
                    :model-value="passenger.payment_order_number"
                    :type="'text'"
                    :label="$t('labels.payment_order_number')"
                    :sublabel="$t('labels.payment_order_number_info')"
                    :name="`payment_order_number[${passengerInd}]`"
                    :placeholder="''"
                    :required="true"
                    :disabled="!isEditable"
                    :error="
                      getErrors(
                        `applications.${passengerInd}.tourist.payment_order_number`,
                      )
                    "
                    @update:model-value="
                      updatePassenger(
                        'payment_order_number',
                        passengerInd,
                        $event,
                      )
                    "
                  />
                  <UiInput
                    :model-value="passenger.sum"
                    :type="'number'"
                    :label="$t('labels.sum')"
                    :name="`sum[${passengerInd}]`"
                    :placeholder="''"
                    :required="true"
                    :disabled="!isEditable"
                    :error="
                      getErrors(`applications.${passengerInd}.tourist.sum`)
                    "
                    @update:model-value="
                      updatePassenger('sum', passengerInd, $event)
                    "
                  />
                </div>
                <div class="passenger__data-row">
                  <UiInput
                    :model-value="passenger.amount_payable"
                    :type="'number'"
                    :label="$t('labels.amount_payable')"
                    :name="`amount_payable[${passengerInd}]`"
                    :placeholder="''"
                    :required="true"
                    :disabled="!isEditable"
                    :error="
                      getErrors(
                        `applications.${passengerInd}.tourist.amount_payable`,
                      )
                    "
                    @update:model-value="
                      updatePassenger('amount_payable', passengerInd, $event)
                    "
                  />
                </div>
                <div class="passenger__disclaimer">
                  {{ $t('labels.required_fields') }} <span>*</span>
                </div>
              </div>
              <div class="passenger__files">
                <div class="passenger__file">
                  <UiFileInput
                    :model-value="passenger.file_document_1"
                    :label="$t('labels.file_document_1')"
                    :name="`file_document_1[${passengerInd}]`"
                    :required="true"
                    :types="'pdf, jpg, jpeg, png'"
                    :disabled="!isEditable"
                    :error="
                      getErrors(`applications.${passengerInd}.file_document_1`)
                    "
                    @update:model-value="
                      updatePassenger('file_document_1', passengerInd, $event)
                    "
                  />
                </div>
                <div class="passenger__file">
                  <UiFileInput
                    :yet="true"
                    :model-value="passenger.file_document_4"
                    :add-file="passenger.file_document_4_2"
                    :add_name="`file_document_4_2[${passengerInd}]`"
                    :label="$t('labels.file_document_4')"
                    :name="`file_document_4[${passengerInd}]`"
                    :required="true"
                    :types="'pdf, jpg, jpeg, png'"
                    :disabled="!isEditable"
                    :error="
                      getErrors(`applications.${passengerInd}.file_document_4`)
                    "
                    @update:model-value="
                      updatePassenger('file_document_4', passengerInd, $event)
                    "
                    @add-file="
                      updatePassenger('file_document_4_2', passengerInd, $event)
                    "
                  />
                </div>
                <div class="passenger__file">
                  <UiFileInput
                    :yet="true"
                    :model-value="passenger.file_document_5"
                    :add-file="passenger.file_document_5_2"
                    :label="$t('labels.file_document_5')"
                    :name="`file_document_5[${passengerInd}]`"
                    :add_name="`file_document_5_2[${passengerInd}]`"
                    :required="true"
                    :types="'doc, docx, pdf, xls, xlsx'"
                    :disabled="!isEditable"
                    :error="
                      getErrors(`applications.${passengerInd}.file_document_5`)
                    "
                    @update:model-value="
                      updatePassenger('file_document_5', passengerInd, $event)
                    "
                    @add-file="
                      updatePassenger('file_document_5_2', passengerInd, $event)
                    "
                  />
                </div>
                <div class="passenger__file">
                  <UiFileInput
                    :yet="true"
                    :model-value="passenger.file_document_6"
                    :add-file="passenger.file_document_6_2"
                    :label="$t('labels.file_document_6')"
                    :name="`file_document_6[${passengerInd}]`"
                    :add_name="`file_document_6_2[${passengerInd}]`"
                    :required="true"
                    :types="'doc, docx, pdf, xls, xlsx'"
                    :disabled="!isEditable"
                    :error="
                      getErrors(`applications.${passengerInd}.file_document_6`)
                    "
                    @update:model-value="
                      updatePassenger('file_document_6', passengerInd, $event)
                    "
                    @add-file="
                      updatePassenger('file_document_6_2', passengerInd, $event)
                    "
                  />
                </div>
              </div>
              <button
                v-if="passengers.length > 1 && isEditable"
                class="passenger__remove"
                @click.prevent.stop="removePassender(passengerInd, $event)"
              >
                {{ $t('commands.remove') }}
              </button>
            </div>
          </ExpansionPanel>

          <button
            v-if="isEditable"
            class="passenger-add-btn"
            @click.prevent.stop="addNewPassenger"
          >
            <span>{{ $t('commands.add_yet') }}</span>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M6 9h3m0 0h3M9 9v3m0-3V6m0 9.75a6.75 6.75 0 110-13.5 6.75 6.75 0 010 13.5z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
          </button>

          <div class="add-data">
            <div class="add-data__select">
              <MainCheckingAccountSelect
                v-model="payment_account_id"
                :required="true"
                :disabled="!isEditable"
                :error="getErrors(`payment_account_id`)"
              />
              <MainInformationDebtsSelect
                v-model="certificate_of_debt_id"
                :required="true"
                :disabled="!isEditable"
                :error="getErrors(`certificate_of_debt_id`)"
              />
            </div>

            <div class="add-data__files">
              <UiFileInput
                :model-value="file_document_2"
                :label="$t('labels.file_document_2')"
                :name="`file_document_2`"
                :required="true"
                :info="$t('labels.file_document_2_info')"
                :types="'doc, docx, pdf'"
                :disabled="!isEditable"
                :error="getErrors(`file_document_2`)"
                @update:model-value="file_document_2 = $event"
              />
              <UiFileInput
                :model-value="file_document_3"
                :label="$t('labels.file_document_3')"
                :name="`file_document_3`"
                :required="true"
                :types="'doc, docx, pdf, jpg, jpeg, png'"
                :disabled="!isEditable"
                :error="getErrors(`file_document_3`)"
                @update:model-value="file_document_3 = $event"
              />
            </div>

            <button
              v-if="isEditable"
              class="next__stage input__button btn--blue mb-4"
              type="submit"
            >
              {{ $t('commands.save') }}
            </button>
          </div>
        </form>

        <div v-if="file1 && file2" class="add-data mt-4">
          <div>
            <div v-if="file1" class="row align-items-center mb-2">
              <UiCheckBox
                v-if="isEditable"
                :value="Boolean(file1Signed)"
                :name="'agreement'"
                :required="true"
                :disabled="true"
                :label="''"
              />
              <a @click.prevent.stop="show(file1, 'certificate')">{{
                $t('titles.application_for_subs')
              }}</a>
              <button
                v-if="isEditable && !file1Signed"
                class="next__stage input__button input__button--small ml-4"
                @click.prevent.stop="signDoc(file1, 'file1Signed')"
              >
                {{ $t('commands.sign') }}
              </button>
            </div>
            <div v-if="file2" class="row align-items-center">
              <UiCheckBox
                v-if="isEditable"
                :value="Boolean(file2Signed)"
                :name="'agreement'"
                :required="true"
                :disabled="true"
                :label="''"
              />
              <a
                :style="{ maxWidth: '50%' }"
                @click.prevent.stop="show(file2, 'provision')"
                >{{ $t('titles.info_for_application') }}</a
              >
              <button
                v-if="isEditable && !file2Signed"
                class="next__stage input__button input__button--small ml-4"
                @click.prevent.stop="signDoc(file2, 'file2Signed')"
              >
                {{ $t('commands.sign') }}
              </button>
            </div>
          </div>

          <UiCheckBox
            v-if="filesSigned"
            v-model="agreement"
            :name="'agreement'"
            :required="true"
            :label="$t('messages.kid_report_agreement')"
            class="mt-3"
          />

          <button
            v-if="isEditable && filesSigned && agreement"
            class="next__stage input__button mt-4"
            @click.prevent.stop="send"
          >
            {{ $t('commands.send') }}
          </button>

          <div v-if="application" class="form__block--history">
            <div class="form__block--title">
              {{ $t('titles.actions_history') }}
            </div>
            <div class="row">
              <div
                class="col-lg-12"
                v-for="route in application.routes"
                :key="route.id"
              >
                <div
                  v-if="route.message"
                  class="history__item history__item--new"
                >
                  <div class="history__item--date">
                    <span>{{
                      new Date(route.created_at).toLocaleDateString()
                    }}</span>
                    <span class="history__item--title">{{
                      $t('history_statusses.returned_with_comments')
                    }}</span>
                  </div>
                  <div class="history__item--text">{{ route.message }}</div>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="history__item">
                  <div class="history__item--date">
                    <span>{{
                      new Date(application.created_at).toLocaleDateString()
                    }}</span>
                  </div>
                  <div class="history__item--text">
                    {{ $t('history_statusses.sending_app', [application.id]) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from '../../../../boot/axios'

import ExpansionPanel from '@/components/ui/ExpansionPanel.vue'
import UiInput from '@/components/ui/UiInput.vue'
import UiDate from '@/components/ui/UiDate.vue'
import UiFileInput from '@/components/ui/UiFileInput.vue'
import UiCheckBox from '@/components/ui/UiCheckBox.vue'
import MainCheckingAccountSelect from '@/components/main/MainCheckingAccountSelect.vue'
import MainInformationDebtsSelect from '@/components/main/MainInformationDebtsSelect.vue'
import MainCountries from '@/components/main/MainCountries.vue'

import { downloadPDF } from '@/helpers/index'
import { NCALayerClientIns } from '@/plugins/nca'

const passengerFields = {
  name: null,
  surname: null,
  patronymic: null,
  document_number: null,
  date_of_birth: null,
  citizenship_name: null,
  citizenship_id: null,
  date_start: null,
  date_end: null,
  arrival_date: null,
  date_of_departure: null,
  payment_order_number: null,
  sum: null,
  amount_payable: null,
  placement: null,
  file_document_1: null,
  file_document_4: null,
  file_document_4_2: null,
  file_document_5: null,
  file_document_5_2: null,
  file_document_6: null,
  file_document_6_2: null,
}

export default {
  name: 'KidsGoFree',
  components: {
    ExpansionPanel,
    UiInput,
    UiDate,
    UiFileInput,
    UiCheckBox,
    MainCheckingAccountSelect,
    MainInformationDebtsSelect,
    MainCountries,
  },
  data() {
    return {
      passengers: [{ ...passengerFields }],
      certificate_of_debt_id: null,
      payment_account_id: null,
      file_document_2: null,
      file_document_3: null,
      agreement: false,
      errors: {},
      ecp: null,
      ecp_password: null,
      application: null,
      file1: '',
      file2: '',
      file1Signed: '',
      file2Signed: '',
    }
  },
  computed: {
    isValid() {
      return !Object.keys(this.errors).length
    },
    getErrors() {
      return (key) => this.errors[key] || ''
    },
    isEditable() {
      return this.$route.meta.pageType !== 'view'
    },
    appId() {
      return this.$route.params.application_id
    },
    filesSigned() {
      return this.file1Signed && this.file2Signed
    },
    isCanSave() {
      return this.filesSigned && this.agreement
    },
  },
  mounted() {
    this.fetchApp()
    this.fetchFiles()
  },
  methods: {
    addNewPassenger(e) {
      e.preventDefault()
      e.stopPropagation()

      this.passengers.push({
        ...passengerFields,
      })
    },
    updatePassenger(field, index, value) {
      this.passengers = this.passengers.map((passenger, passengerInd) => {
        if (passengerInd === index) {
          return {
            ...passenger,
            [field]: value,
          }
        }
        return passenger
      })
    },
    removePassender(index, e) {
      e.preventDefault()
      e.stopPropagation()

      this.passengers.splice(index, 1)
    },
    getParsedData() {
      const formData = new FormData()
      formData.append('view_id', 2)
      formData.append('status', 'draft')
      formData.append('certificate_of_debt_id', this.certificate_of_debt_id)
      formData.append('payment_account_id', this.payment_account_id)
      this.passengers.forEach((passenger, passengerInd) => {
        for (const key in passengerFields) {
          if (passenger[key]) {
            if (
              [
                'file_document_1',
                'file_document_4',
                'file_document_5',
                'file_document_6',
              ].includes(key)
            ) {
              if (passenger[key] instanceof File) {
                formData.append(
                  `applications[${passengerInd}][${key}]`,
                  passenger[key],
                )
              }
            } else if (
              [
                'file_document_4_2',
                'file_document_5_2',
                'file_document_6_2',
              ].includes(key)
            ) {
              if (Array.isArray(passenger[key])) {
                passenger[key].forEach((file, fileInd) => {
                  if (file instanceof File) {
                    formData.append(
                      `applications[${passengerInd}][${key}][${fileInd}]`,
                      file,
                    )
                  } else {
                    formData.append(
                      `applications[${passengerInd}][${key}][${fileInd}]`,
                      JSON.stringify(file),
                    )
                  }
                })
              }
            } else {
              formData.append(
                `applications[${passengerInd}][tourist][${key}]`,
                passenger[key],
              )
            }
          }
        }
      })
      if (this.file_document_2 instanceof File)
        formData.append('file_document_2', this.file_document_2)
      if (this.file_document_3 instanceof File)
        formData.append('file_document_3', this.file_document_3)

      return formData
    },
    saveApp() {
      if (!this.isValid) return

      const formData = this.getParsedData()

      if (this.appId) {
        formData.append('_method', 'PUT')
      }

      api
        .post(
          this.appId
            ? `/applications/update-kids/${this.appId}`
            : `/applications/kids`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )
        .then((response) => {
          if (response.data.code == 422) {
            let strError = ''
            for (let key in response.data.messages) {
              strError += ' ' + response.data.messages[key].join(' ')
            }
            this.$notify({
              group: 'foo',
              type: 'error',
              title: strError,
              position: 'top center',
            })
          } else if (response.data.code >= 200 && response.data.code < 400) {
            const app = response.data.data
            if (app.id) {
              this.fetchFiles()
            }
            this.$router.push(
              '/' +
                this.$i18n.locale +
                '/account/applications/kids-go-free/' +
                app.id +
                '/edit',
            )
          }
        })
        .catch((error) => {
          if (error.response.status >= 500) {
            this.$notify({
              group: 'foo',
              type: 'error',
              title: this.$t('messages.server_error'),
              position: 'top center',
            })
          }
        })
    },
    fetchApp() {
      if (this.appId) {
        api
          .get('applications/' + this.$route.params.application_id)
          .then((response) => {
            if (response.data) {
              this.application = response.data.data
              this.passengers = this.application.fields.tourists
              if (this.application.fields.certificate_of_debt)
                this.certificate_of_debt_id =
                  +this.application.fields.certificate_of_debt.id
              if (this.application.fields.payment_account)
                this.payment_account_id =
                  +this.application.fields.payment_account?.id
              this.file_document_2 = this.application.fields.file_document_2
              this.file_document_3 = this.application.fields.file_document_3
            }
          })
      }
    },
    fetchFiles() {
      if (this.appId) {
        api
          .get('applications/get-b64/' + this.$route.params.application_id)
          .then((response) => {
            if (response.data && response.data.data) {
              this.file1 = response.data.data.certificate
              this.file2 = response.data.data.provision
            }
          })
      }
    },
    show(base64, name) {
      downloadPDF(base64, name)
    },
    signDoc(base64, field) {
      NCALayerClientIns.signDoc(base64)
        .then((signedData) => {
          this[field] = signedData
        })
        .catch(() => {
          this.$notify({
            group: 'foo',
            type: 'error',
            title: this.$t('messages.check_nca'),
            position: 'top center',
          })
        })
    },
    send() {
      const newForm = new FormData()
      newForm.append('_method', 'PUT')
      newForm.append('signed_document_1', this.file1Signed)
      newForm.append('signed_document_2', this.file2Signed)
      api
        .post(`/applications/signed/${this.appId}`, newForm, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          if (response.data.code == 422) {
            let strError = ''
            for (let key in response.data.messages) {
              strError += ' ' + response.data.messages[key].join(' ')
            }
            this.$notify({
              group: 'foo',
              type: 'error',
              title: strError,
              position: 'top center',
            })
          } else if (response.data.code >= 200 && response.data.code < 400) {
            this.$router.push('/' + this.$i18n.locale + '/account')
          }
        })
        .catch((error) => {
          if (error.response.status >= 500) {
            this.$notify({
              group: 'foo',
              type: 'error',
              title: this.$t('messages.server_error'),
              position: 'top center',
            })
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.passenger {
  margin-bottom: 30px;

  &--last {
    margin-bottom: 40px;
  }

  &--error {
    v-deep .expansion-panel__title {
      color: #f3574d;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }

  &__data {
    width: 100%;
    margin-bottom: 20px;
    max-width: 853px;
  }

  &__data-row {
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 37px;
  }

  &__data-subrow {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 28px;
  }

  &__disclaimer {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #0e1839;

    span {
      color: #f3574d;
    }
  }

  &__files {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    align-items: stretch;
    margin-bottom: 40px;
  }

  &__file {
    & > div {
      height: 100%;
    }
  }

  &__remove {
    padding: 15px 25px;
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    background: #f3574d;
    border-radius: 15px;
    cursor: pointer;
  }
}

.passenger-add-btn {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px 25px;
  background: #6b69df;
  border-radius: 15px;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  margin: 0 auto;
  margin-bottom: 60px;
}

.add-data {
  overflow: hidden;
  border-radius: 50px;
  padding: 43px 57px 35px;
  background: #f9f9fe;

  &__files {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    align-items: stretch;
    margin-bottom: 30px;
  }

  &__select {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    align-items: flex-end;
    margin-bottom: 30px;
  }
}
</style>
