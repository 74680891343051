<template>
  <div class="form__block--history">
    <div class="form__block--title">История всех действий</div>
    <div class="row">
      <template v-for="route in application.routes">
        <div v-if="route.message" :key="route.id" class="col-lg-12">
          <div class="history__item history__item--new">
            <div class="history__item--date">
              <span
                >{{ route.created_at.substr(8, 2) }}.{{
                  route.created_at.substr(5, 2)
                }}.{{ route.created_at.substr(0, 4) }}</span
              >
              <span class="history__item--title">Комментарий</span>
            </div>
            <div class="history__item--text">{{ route.message }}</div>
          </div>
        </div>
      </template>
      <div class="col-lg-12">
        <div class="history__item">
          <div class="history__item--date">
            <span>{{
              application.created_at
                ? application.created_at.substr(8, 2) +
                  '.' +
                  application.created_at.substr(5, 2) +
                  '.' +
                  application.created_at.substr(0, 4)
                : ''
            }}</span>
          </div>
          <div class="history__item--text">
            Отправка заявки №{{ application.id }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppHistory',
  props: {
    application: Object,
  },
}
</script>

<style lang="scss" scoped></style>
