<template>
  <div class="wrap__content" v-if="user && roles && application_statuses">
    <notifications group="foo" position="bottom center" />

    <div class="content__page content__page--margintop">
      <div class="container">
        <div class="content__page--back">
          <a @click="$router.go(-1)">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19 12H5M12 19l-7-7 7-7" stroke="#0E1839" stroke-linecap="round" stroke-linejoin="round"/></svg>
            {{ $t('system.back') }}
          </a>
        </div>

        <form method="POST" @submit.prevent="onSubmit">
          <input type="hidden" name="view_id" value="2" />
          <div class="content__title--block">
            <div class="section__title--mini">
              Заявление на субсидирование затрат туроператоров в сфере въездного
              туризма
            </div>
            <div class="content__title section__title">
              <strong>за несовершеннолетних пассажиров</strong>
            </div>
          </div>

          <div class="add_tourist--section">
            <div class="row">
              <div class="col-lg-4 offset-lg-4">
                <div class="form__block--line">
                  <div class="form__block--input">
                    <div class="form__block--title">{{ $t('labels.status') }} <span>*</span></div>

                    <multiselect
                      class="form__multiselect"
                      v-model="application.status.value"
                      @change="selectApplicationStatus"
                      :options="application_statuses.data"
                      label="name"
                      name="status"
                      :placeholder="$t('labels.status')"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">{{
                        option.name
                      }}</template>
                    </multiselect>

                    <!--

										<select v-model="application.status.value" name="status"  @change="selectApplicationStatus" style="width: 100%; height: 45px; background-color: #fff; padding: 5px 20px; border: 1px solid #0E1839; border-radius: 10px;">
											<option v-for="role in application_statuses.data" :value="role.slug">{{ role.name }}</option>
										</select>

										-->

                    <!-- input--error -->
                    <span
                      class="input-required"
                      v-if="application.type_of_tour_operator.error"
                      >{{ application.type_of_tour_operator.error }}</span
                    >
                  </div>
                </div>

                <div class="form__block--line">
                  <div class="form__block--input">
                    <div class="form__block--title">
                      Тип организации туроператора <span>*</span>
                    </div>

                    <multiselect
                      class="form__multiselect"
                      v-model="application.type_of_tour_operator.value"
                      :options="roles.data"
                      label="name"
                      name="type_of_tour_operator"
                      placeholder="Тип"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">{{
                        option.name
                      }}</template>
                    </multiselect>

                    <!--

										<select v-model="application.type_of_tour_operator.value" name="type_of_tour_operator" style="width: 100%; height: 45px; background-color: #fff; padding: 5px 20px; border: 1px solid #0E1839; border-radius: 10px;">
											<option v-for="role in roles.data" :value="role.slug">{{ role.name }}</option>
										</select>

										-->

                    <!-- input--error -->
                    <span
                      class="input-required"
                      v-if="application.type_of_tour_operator.error"
                      >{{ application.type_of_tour_operator.error }}</span
                    >
                  </div>
                </div>
                <div class="form__block--line">
                  <div class="form__block--input">
                    <div class="form__block--title">
                      Название организации <span>*</span>
                    </div>
                    <input
                      type="text"
                      class="input-linck"
                      :class="[
                        application.name_of_tour_operator.error
                          ? 'input--error'
                          : '',
                      ]"
                      required=""
                      name="name_of_tour_operator"
                      v-model="application.name_of_tour_operator.value"
                    />
                    <!-- input--error -->
                    <span
                      class="input-required"
                      v-if="application.name_of_tour_operator.error"
                      >{{ application.name_of_tour_operator.error }}</span
                    >
                  </div>
                </div>
                <div class="form__block--line">
                  <div class="form__block--input">
                    <div
                      class="form__block--title"
                      v-if="
                        application.type_of_tour_operator.value &&
                        application.type_of_tour_operator.value.slug ==
                          'tour-operator-entity'
                      "
                    >
                      БИН организации <span>*</span>
                    </div>
                    <div
                      class="form__block--title"
                      v-if="
                        application.type_of_tour_operator.value &&
                        application.type_of_tour_operator.value.slug ==
                          'tour-operator-individual'
                      "
                    >
                      ИИН организации <span>*</span>
                    </div>
                    <div
                      class="form__block--title"
                      v-if="!application.type_of_tour_operator.value"
                    >
                      БИН или ИИН организации <span>*</span>
                    </div>
                    <input
                      type="text"
                      class="input-linck"
                      :class="[
                        application.bin_or_inn_of_tour_operator.error
                          ? 'input--error'
                          : '',
                      ]"
                      required=""
                      name="bin_or_inn_of_tour_operator"
                      v-model="application.bin_or_inn_of_tour_operator.value"
                    />
                    <!-- input--error -->
                    <span
                      class="input-required"
                      v-if="application.bin_or_inn_of_tour_operator.error"
                      >{{ application.bin_or_inn_of_tour_operator.error }}</span
                    >
                  </div>
                </div>
                <div class="form__block--line">
                  <div class="form__block--input">
                    <div class="form__block--title">
                      Фамилия туриста <span>*</span>
                    </div>
                    <input
                      type="text"
                      class="input-linck"
                      :class="[
                        application.tourist_surname.error ? 'input--error' : '',
                      ]"
                      required=""
                      name="tourist_surname"
                      v-model="application.tourist_surname.value"
                    />
                    <!-- input--error -->
                    <span
                      class="input-required"
                      v-if="application.tourist_surname.error"
                      >{{ application.tourist_surname.error }}</span
                    >
                  </div>
                </div>
                <div class="form__block--line">
                  <div class="form__block--input">
                    <div class="form__block--title">
                      Имя туриста <span>*</span>
                    </div>
                    <input
                      type="text"
                      class="input-linck"
                      :class="[
                        application.tourist_name.error ? 'input--error' : '',
                      ]"
                      required=""
                      name="tourist_name"
                      v-model="application.tourist_name.value"
                    />
                    <!-- input--error -->
                    <span
                      class="input-required"
                      v-if="application.tourist_name.error"
                      >{{ application.tourist_name.error }}</span
                    >
                  </div>
                </div>
                <div class="form__block--line">
                  <div class="form__block--input">
                    <div class="form__block--title">
                      Сумма выплаты <span>*</span>
                    </div>
                    <input
                      type="text"
                      class="input-linck"
                      :class="[
                        application.payout_amount.error ? 'input--error' : '',
                      ]"
                      required=""
                      name="payout_amount"
                      v-model="application.payout_amount.value"
                    />
                    <!-- input--error -->
                    <span
                      class="input-required"
                      v-if="application.payout_amount.error"
                      >{{ application.payout_amount.error }}</span
                    >
                  </div>
                </div>
                <div class="form__block--line">
                  <div class="form__block--input">
                    <div class="form__block--title">
                      Дата создания <span>*</span>
                    </div>
                    <input
                      type="date"
                      class="input-linck"
                      required=""
                      :class="[
                        application.created_date.error ? 'input--error' : '',
                      ]"
                      name="created_date"
                      v-model="application.created_date.value"
                      min="2022-01-01"
                      max="2022-12-30"
                    />
                    <!-- input--error -->
                    <span
                      class="input-required"
                      v-if="application.created_date.error"
                      >{{ application.created_date.error }}</span
                    >
                  </div>
                </div>
                <div
                  class="form__block--line"
                  v-if="
                    application.status.value &&
                    application.status.value == 'paid'
                  "
                >
                  <div class="form__block--input input--error">
                    <div class="form__block--title">
                      Квитанция об оплате <span>*</span>
                    </div>
                    <label
                      class="form__file"
                      style="padding-left: 30px; padding-right: 30px"
                    >
                      <div class="form__file--title" style="font-size: 16px">
                        Загрузить
                        <input
                          type="file"
                          name="receipt"
                          style="display: none"
                          required=""
                          v-on:change="handle_file_receipt()"
                        />
                      </div>
                    </label>
                    <span
                      class="input-required"
                      v-if="application.receipt.error"
                      >{{ application.receipt.error }}</span
                    >
                  </div>
                </div>

                <div
                  class="section--link form__listitem--button next__stage--block"
                >
                  <div class="form__listitem--input">
                    <button type="submit" class="next__stage input__button">
                      {{ $t('commands.save') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="add_tourist--section"
            v-if="
              application.tourist.value &&
              application.tourist.value == 100000000000
            "
          >
            <div class="add add_tourist--block">
              <div class="add_tourist__section--title">Данные туроператора</div>
              <div class="add_tourist__section--btn">
                <div class="form__block--line">
                  <div class="form__block--input">
                    <div class="section--link form__file--yellow">
                      <a @click="$modal.show('company_add')">Ввести вручную</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style="
                width: 100%;
                text-align: center;
                display: block;
                padding: 10px;
                color: red;
              "
              v-if="application.company.error"
            >
              {{ application.company.error }}
            </div>
          </div>

          <div
            class="add_tourist--section add_tourist--section-transition"
            v-if="
              application.tourist.value &&
              application.status.value &&
              application.status.value == 'registered'
            "
          >
            <div class="add_tourist__section--title">Данные туроператора</div>
            <div class="table__block--style">
              <v-data-table
                :headers="company_header"
                :items="new Array(application.company.value)"
                :loading="loading"
                :options.sync="options"
                hide-default-footer
              >
                <template v-slot:item.action="{ item }">
                  <a
                    class="tb__table--btn tb__table--edit"
                    @click="$modal.show('company_add')"
                    :data-id="item.id"
                  >
                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M9.625 3.5H3.5a1.75 1.75 0 00-1.75 1.75V17.5a1.75 1.75 0 001.75 1.75h12.25a1.75 1.75 0 001.75-1.75v-6.125" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M16.188 2.187a1.856 1.856 0 012.625 2.625L10.5 13.125 7 14l.875-3.5 8.313-8.313z" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                  </a>
                </template>
              </v-data-table>
            </div>
          </div>

          <div
            class="add_tourist--section"
            v-if="
              !application.tourist.value &&
              application.status.value &&
              application.status.value == 'registered'
            "
          >
            <div class="add add_tourist--block">
              <div class="add_tourist__section--title">
                Данные несовершеннолетнего пассажира
              </div>
              <div class="add_tourist__section--btn">
                <div class="form__block--line">
                  <div class="form__block--input">
                    <div class="section--link form__file--blue">
                      <a @click="$modal.show('registry__down')"
                        >Загрузить / фотографировать</a
                      >
                    </div>
                  </div>
                </div>
                <div class="form__block--line">
                  <div class="form__block--input">
                    <div class="section--link form__file--yellow">
                      <a @click="$modal.show('registry__add')"
                        >Ввести вручную</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style="
                width: 100%;
                text-align: center;
                display: block;
                padding: 10px;
                color: red;
              "
              v-if="application.tourist.error"
            >
              {{ application.tourist.error }}
            </div>
          </div>

          <div
            class="add_tourist--section add_tourist--section-transition"
            v-if="
              application.tourist.value &&
              application.status.value &&
              application.status.value == 'registered'
            "
          >
            <div class="add_tourist__section--title">
              Данные несовершеннолетнего пассажира
            </div>
            <div class="table__block--style">
              <v-data-table
                :headers="headers"
                :items="application.tourist.value"
                :loading="loading"
                :options.sync="options"
                hide-default-footer
              >
                <template v-slot:item.action="{ item }">
                  <a
                    class="tb__table--btn tb__table--edit"
                    @click="$modal.show('registry__add'), client_reset()"
                    :data-id="item.id"
                  >
                    <!-- :href="'/'+$i18n.locale + '/registry/view-'+ item.id" -->
                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M9.625 3.5H3.5a1.75 1.75 0 00-1.75 1.75V17.5a1.75 1.75 0 001.75 1.75h12.25a1.75 1.75 0 001.75-1.75v-6.125" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M16.188 2.187a1.856 1.856 0 012.625 2.625L10.5 13.125 7 14l.875-3.5 8.313-8.313z" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                  </a>
                </template>
              </v-data-table>
            </div>
          </div>

          <div
            class="add_tourist--section"
            v-if="
              application.status.value &&
              application.status.value == 'registered'
            "
          >
            <div class="add_tourist__section--title">
              Подтверждающие документы
            </div>
            <div class="add_tourist--filelist">
              <div class="fileitem--col">
                <label
                  class="add_tourist--fileitem"
                  :class="document_1.class"
                  v-if="!application.document_1.error"
                >
                  <input
                    type="file"
                    class="add_tourist--input"
                    ref="document_1"
                    name="file_document_1"
                    v-on:change="handle_file_upload_1()"
                  />
                  <div class="fileitem__block">
                    <div class="fileitem__block--title">
                      Копия документа, удостоверяющего личность
                      несовершеннолетнего пассажира
                      <span style="color: red">*</span>
                    </div>
                    <div class="fileitem__block--file" v-if="document_1.name">
                      <div class="fileitem__block--icon">
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </div>
                      <div class="fileitem__block--text">
                        {{ document_1.name }}
                      </div>
                    </div>
                    <div class="fileitem__block--file" v-else>
                      <div class="fileitem__block--icon">
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19 14.563l-6.146 6.243A3.984 3.984 0 0110.014 22a3.984 3.984 0 01-2.838-1.194A4.11 4.11 0 016 17.922a4.11 4.11 0 011.176-2.883l6.146-6.243A2.656 2.656 0 0115.215 8c.71 0 1.39.286 1.892.796a2.74 2.74 0 01.784 1.923 2.74 2.74 0 01-.784 1.922l-6.152 6.242a1.328 1.328 0 01-1.893 0 1.37 1.37 0 010-1.922l5.678-5.76" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </div>
                      <div class="fileitem__block--text">
                        Загрузить (doc, pdf, excel)
                      </div>
                    </div>
                  </div>
                  <!--<span class="fileitem__block--help" v-tooltip.top-center="'Договор на туристское обслуживание, заключенного между туроператором и иностранным туристом, составленного  в соответствии с приказом Министра по инвестициям  и развитию Республики Казахстан от 30 января 2015 года  № 81 «Об утверждении типового договора на туристское  обслуживание»'">?</span>-->
                </label>
                <label
                  class="add_tourist--fileitem"
                  :class="document_1.class"
                  v-if="application.document_1.error"
                  style="background-color: red"
                >
                  <input
                    type="file"
                    class="add_tourist--input"
                    ref="document_1"
                    name="file_document_1"
                    v-on:change="handle_file_upload_1()"
                  />
                  <div class="fileitem__block">
                    <div class="fileitem__block--title" style="color: #fff">
                      Копия документа, удостоверяющего личность
                      несовершеннолетнего пассажира
                    </div>

                    <div class="fileitem__block--file" v-if="document_1.name">
                      <div class="fileitem__block--icon">
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </div>
                      <div class="fileitem__block--text" style="color: #fff">
                        {{ document_1.name }}
                      </div>
                    </div>
                    <div class="fileitem__block--file" v-else>
                      <div class="fileitem__block--icon">
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19 14.563l-6.146 6.243A3.984 3.984 0 0110.014 22a3.984 3.984 0 01-2.838-1.194A4.11 4.11 0 016 17.922a4.11 4.11 0 011.176-2.883l6.146-6.243A2.656 2.656 0 0115.215 8c.71 0 1.39.286 1.892.796a2.74 2.74 0 01.784 1.923 2.74 2.74 0 01-.784 1.922l-6.152 6.242a1.328 1.328 0 01-1.893 0 1.37 1.37 0 010-1.922l5.678-5.76" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </div>
                      <div class="fileitem__block--text" style="color: #fff">
                        Загрузить (doc, pdf, excel)
                      </div>
                    </div>
                  </div>
                  <!--<span class="fileitem__block--help" v-tooltip.top-center="'Договор на туристское обслуживание, заключенного между туроператором и иностранным туристом, составленного  в соответствии с приказом Министра по инвестициям  и развитию Республики Казахстан от 30 января 2015 года  № 81 «Об утверждении типового договора на туристское  обслуживание»'">?</span>-->
                </label>
              </div>

              <div class="fileitem--col">
                <label
                  class="add_tourist--fileitem"
                  :class="document_2.class"
                  v-if="!application.document_2.error"
                >
                  <input
                    type="file"
                    class="add_tourist--input"
                    ref="document_2"
                    name="file_document_2"
                    v-on:change="handle_file_upload_2()"
                  />
                  <div class="fileitem__block">
                    <div class="fileitem__block--title">
                      Договор на туристское обслуживание
                      <span style="color: red">*</span>
                    </div>

                    <div class="fileitem__block--file" v-if="document_2.name">
                      <div class="fileitem__block--icon">
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </div>
                      <div class="fileitem__block--text">
                        {{ document_2.name }}
                      </div>
                    </div>
                    <div class="fileitem__block--file" v-else>
                      <div class="fileitem__block--icon">
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19 14.563l-6.146 6.243A3.984 3.984 0 0110.014 22a3.984 3.984 0 01-2.838-1.194A4.11 4.11 0 016 17.922a4.11 4.11 0 011.176-2.883l6.146-6.243A2.656 2.656 0 0115.215 8c.71 0 1.39.286 1.892.796a2.74 2.74 0 01.784 1.923 2.74 2.74 0 01-.784 1.922l-6.152 6.242a1.328 1.328 0 01-1.893 0 1.37 1.37 0 010-1.922l5.678-5.76" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </div>
                      <div class="fileitem__block--text">
                        Загрузить (doc, pdf, excel)
                      </div>
                    </div>
                  </div>
                  <span
                    class="fileitem__block--help"
                    v-tooltip.top-center="
                      'Договор на туристское обслуживание, заключенного между туроператором и иностранным туристом, составленного  в соответствии с приказом Министра по инвестициям  и развитию Республики Казахстан от 30 января 2015 года  № 81 «Об утверждении типового договора на туристское  обслуживание»'
                    "
                    >?</span
                  >
                </label>
                <label
                  class="add_tourist--fileitem"
                  :class="document_2.class"
                  v-if="application.document_2.error"
                  style="background-color: red"
                >
                  <input
                    type="file"
                    name="file_document_2"
                    class="add_tourist--input"
                    ref="document_2"
                    v-on:change="handle_file_upload_2()"
                  />
                  <div class="fileitem__block">
                    <div class="fileitem__block--title" style="color: #fff">
                      Договор на туристское обслуживание
                      <span style="color: red">*</span>
                    </div>

                    <div class="fileitem__block--file" v-if="document_2.name">
                      <div class="fileitem__block--icon">
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </div>
                      <div class="fileitem__block--text" style="color: #fff">
                        {{ document_2.name }}
                      </div>
                    </div>
                    <div class="fileitem__block--file" v-else>
                      <div class="fileitem__block--icon">
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19 14.563l-6.146 6.243A3.984 3.984 0 0110.014 22a3.984 3.984 0 01-2.838-1.194A4.11 4.11 0 016 17.922a4.11 4.11 0 011.176-2.883l6.146-6.243A2.656 2.656 0 0115.215 8c.71 0 1.39.286 1.892.796a2.74 2.74 0 01.784 1.923 2.74 2.74 0 01-.784 1.922l-6.152 6.242a1.328 1.328 0 01-1.893 0 1.37 1.37 0 010-1.922l5.678-5.76" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </div>
                      <div class="fileitem__block--text" style="color: #fff">
                        Загрузить (doc, pdf, excel)
                      </div>
                    </div>
                  </div>
                  <span
                    class="fileitem__block--help"
                    v-tooltip.top-center="
                      'Договор на туристское обслуживание, заключенного между туроператором и иностранным туристом, составленного  в соответствии с приказом Министра по инвестициям  и развитию Республики Казахстан от 30 января 2015 года  № 81 «Об утверждении типового договора на туристское  обслуживание»'
                    "
                    >?</span
                  >
                </label>
              </div>

              <div class="fileitem--col">
                <label
                  class="add_tourist--fileitem"
                  :class="document_3.class"
                  v-if="!application.document_3.error"
                >
                  <input
                    type="file"
                    class="add_tourist--input"
                    ref="document_3"
                    v-on:change="handle_file_upload_3()"
                    name="file_document_3"
                  />
                  <div class="fileitem__block">
                    <div class="fileitem__block--title">
                      Документ, подтверждающий оплату туристского продукта по
                      Договору <span style="color: red">*</span>
                    </div>

                    <div class="fileitem__block--file" v-if="document_3.name">
                      <div class="fileitem__block--icon">
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </div>
                      <div class="fileitem__block--text">
                        {{ document_3.name }}
                      </div>
                    </div>
                    <div class="fileitem__block--file" v-else>
                      <div class="fileitem__block--icon">
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19 14.563l-6.146 6.243A3.984 3.984 0 0110.014 22a3.984 3.984 0 01-2.838-1.194A4.11 4.11 0 016 17.922a4.11 4.11 0 011.176-2.883l6.146-6.243A2.656 2.656 0 0115.215 8c.71 0 1.39.286 1.892.796a2.74 2.74 0 01.784 1.923 2.74 2.74 0 01-.784 1.922l-6.152 6.242a1.328 1.328 0 01-1.893 0 1.37 1.37 0 010-1.922l5.678-5.76" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </div>
                      <div class="fileitem__block--text">
                        Загрузить (doc, pdf, excel)
                      </div>
                    </div>
                  </div>
                  <!--<span class="fileitem__block--help" v-tooltip.top-center="'Договор на туристское обслуживание, заключенного между туроператором и иностранным туристом, составленного  в соответствии с приказом Министра по инвестициям  и развитию Республики Казахстан от 30 января 2015 года  № 81 «Об утверждении типового договора на туристское  обслуживание»'">?</span>-->
                </label>
                <label
                  class="add_tourist--fileitem"
                  :class="document_3.class"
                  v-if="application.document_3.error"
                  style="background-color: red"
                >
                  <input
                    type="file"
                    class="add_tourist--input"
                    ref="document_3"
                    v-on:change="handle_file_upload_3()"
                    name="file_document_3"
                  />
                  <div class="fileitem__block">
                    <div class="fileitem__block--title" style="color: #fff">
                      Документ, подтверждающий оплату туристского продукта по
                      Договору <span style="color: red">*</span>
                    </div>

                    <div class="fileitem__block--file" v-if="document_3.name">
                      <div class="fileitem__block--icon">
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </div>
                      <div class="fileitem__block--text" style="color: #fff">
                        {{ document_3.name }}
                      </div>
                    </div>
                    <div class="fileitem__block--file" v-else>
                      <div class="fileitem__block--icon">
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19 14.563l-6.146 6.243A3.984 3.984 0 0110.014 22a3.984 3.984 0 01-2.838-1.194A4.11 4.11 0 016 17.922a4.11 4.11 0 011.176-2.883l6.146-6.243A2.656 2.656 0 0115.215 8c.71 0 1.39.286 1.892.796a2.74 2.74 0 01.784 1.923 2.74 2.74 0 01-.784 1.922l-6.152 6.242a1.328 1.328 0 01-1.893 0 1.37 1.37 0 010-1.922l5.678-5.76" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </div>
                      <div class="fileitem__block--text" style="color: #fff">
                        Загрузить (doc, pdf, excel)
                      </div>
                    </div>
                  </div>
                  <!--<span class="fileitem__block--help" v-tooltip.top-center="'Договор на туристское обслуживание, заключенного между туроператором и иностранным туристом, составленного  в соответствии с приказом Министра по инвестициям  и развитию Республики Казахстан от 30 января 2015 года  № 81 «Об утверждении типового договора на туристское  обслуживание»'">?</span>-->
                </label>
              </div>

              <div class="fileitem--col">
                <label
                  class="add_tourist--fileitem"
                  :class="document_4.class"
                  v-if="!application.document_4.error"
                >
                  <input
                    type="file"
                    class="add_tourist--input"
                    ref="document_4"
                    v-on:change="handle_file_upload_4()"
                    name="file_document_4"
                  />
                  <div class="fileitem__block">
                    <div class="fileitem__block--title">
                      Копия нотариально засвидетельственной доверенности (при
                      необходимости)
                    </div>

                    <div class="fileitem__block--file" v-if="document_4.name">
                      <div class="fileitem__block--icon">
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </div>
                      <div class="fileitem__block--text">
                        {{ document_4.name }}
                      </div>
                    </div>
                    <div class="fileitem__block--file" v-else>
                      <div class="fileitem__block--icon">
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19 14.563l-6.146 6.243A3.984 3.984 0 0110.014 22a3.984 3.984 0 01-2.838-1.194A4.11 4.11 0 016 17.922a4.11 4.11 0 011.176-2.883l6.146-6.243A2.656 2.656 0 0115.215 8c.71 0 1.39.286 1.892.796a2.74 2.74 0 01.784 1.923 2.74 2.74 0 01-.784 1.922l-6.152 6.242a1.328 1.328 0 01-1.893 0 1.37 1.37 0 010-1.922l5.678-5.76" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </div>
                      <div class="fileitem__block--text">
                        Загрузить (doc, pdf, excel)
                      </div>
                    </div>
                  </div>
                  <!--<span class="fileitem__block--help" v-tooltip.top-center="'Договор на туристское обслуживание, заключенного между туроператором и иностранным туристом, составленного  в соответствии с приказом Министра по инвестициям  и развитию Республики Казахстан от 30 января 2015 года  № 81 «Об утверждении типового договора на туристское  обслуживание»'">?</span>-->
                </label>
                <label
                  class="add_tourist--fileitem"
                  :class="document_4.class"
                  v-if="application.document_4.error"
                  style="background-color: red"
                >
                  <input
                    type="file"
                    class="add_tourist--input"
                    ref="document_4"
                    v-on:change="handle_file_upload_4()"
                    name="file_document_4"
                  />
                  <div class="fileitem__block">
                    <div class="fileitem__block--title" style="color: #fff">
                      Копия нотариально засвидетельственной доверенности (при
                      необходимости) <span style="color: red">*</span>
                    </div>

                    <div class="fileitem__block--file" v-if="document_4.name">
                      <div class="fileitem__block--icon">
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </div>
                      <div class="fileitem__block--text" style="color: #fff">
                        {{ document_4.name }}
                      </div>
                    </div>
                    <div class="fileitem__block--file" v-else>
                      <div class="fileitem__block--icon">
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19 14.563l-6.146 6.243A3.984 3.984 0 0110.014 22a3.984 3.984 0 01-2.838-1.194A4.11 4.11 0 016 17.922a4.11 4.11 0 011.176-2.883l6.146-6.243A2.656 2.656 0 0115.215 8c.71 0 1.39.286 1.892.796a2.74 2.74 0 01.784 1.923 2.74 2.74 0 01-.784 1.922l-6.152 6.242a1.328 1.328 0 01-1.893 0 1.37 1.37 0 010-1.922l5.678-5.76" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </div>
                      <div class="fileitem__block--text" style="color: #fff">
                        Загрузить (doc, pdf, excel)
                      </div>
                    </div>
                  </div>
                  <!--<span class="fileitem__block--help" v-tooltip.top-center="'Договор на туристское обслуживание, заключенного между туроператором и иностранным туристом, составленного  в соответствии с приказом Министра по инвестициям  и развитию Республики Казахстан от 30 января 2015 года  № 81 «Об утверждении типового договора на туристское  обслуживание»'">?</span>-->
                </label>
              </div>

              <div class="fileitem--col">
                <label
                  class="add_tourist--fileitem"
                  :class="document_5.class"
                  v-if="!application.document_5.error"
                >
                  <input
                    type="file"
                    class="add_tourist--input"
                    ref="document_5"
                    v-on:change="handle_file_upload_5()"
                    name="file_document_5"
                  />
                  <div class="fileitem__block">
                    <div class="fileitem__block--title">
                      Копии билета или электронного билета, маршрутной квитанции и пассажирского купона или справки-подтверждения об использовании билета авиакомпании на несовершеннолетнего пассажира
                      <span style="color: red">*</span>
                    </div>

                    <div class="fileitem__block--file" v-if="document_5.name">
                      <div class="fileitem__block--icon">
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </div>
                      <div class="fileitem__block--text">
                        {{ document_5.name }}
                      </div>
                    </div>
                    <div class="fileitem__block--file" v-else>
                      <div class="fileitem__block--icon">
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19 14.563l-6.146 6.243A3.984 3.984 0 0110.014 22a3.984 3.984 0 01-2.838-1.194A4.11 4.11 0 016 17.922a4.11 4.11 0 011.176-2.883l6.146-6.243A2.656 2.656 0 0115.215 8c.71 0 1.39.286 1.892.796a2.74 2.74 0 01.784 1.923 2.74 2.74 0 01-.784 1.922l-6.152 6.242a1.328 1.328 0 01-1.893 0 1.37 1.37 0 010-1.922l5.678-5.76" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </div>
                      <div class="fileitem__block--text">
                        Загрузить (doc, pdf, excel)
                      </div>
                    </div>
                  </div>
                  <!--<span class="fileitem__block--help" v-tooltip.top-center="'Договор на туристское обслуживание, заключенного между туроператором и иностранным туристом, составленного  в соответствии с приказом Министра по инвестициям  и развитию Республики Казахстан от 30 января 2015 года  № 81 «Об утверждении типового договора на туристское  обслуживание»'">?</span>-->
                </label>
                <label
                  class="add_tourist--fileitem"
                  :class="document_5.class"
                  v-if="application.document_5.error"
                  style="background-color: red"
                >
                  <input
                    type="file"
                    class="add_tourist--input"
                    ref="document_5"
                    v-on:change="handle_file_upload_5()"
                    name="file_document_5"
                  />
                  <div class="fileitem__block">
                    <div class="fileitem__block--title" style="color: #fff">
                      Копии билета или электронного билета, маршрутной квитанции и пассажирского купона или справки-подтверждения об использовании билета авиакомпании на несовершеннолетнего пассажира
                      <span style="color: red">*</span>
                    </div>

                    <div class="fileitem__block--file" v-if="document_5.name">
                      <div class="fileitem__block--icon">
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </div>
                      <div class="fileitem__block--text" style="color: #fff">
                        {{ document_5.name }}
                      </div>
                    </div>
                    <div class="fileitem__block--file" v-else>
                      <div class="fileitem__block--icon">
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19 14.563l-6.146 6.243A3.984 3.984 0 0110.014 22a3.984 3.984 0 01-2.838-1.194A4.11 4.11 0 016 17.922a4.11 4.11 0 011.176-2.883l6.146-6.243A2.656 2.656 0 0115.215 8c.71 0 1.39.286 1.892.796a2.74 2.74 0 01.784 1.923 2.74 2.74 0 01-.784 1.922l-6.152 6.242a1.328 1.328 0 01-1.893 0 1.37 1.37 0 010-1.922l5.678-5.76" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </div>
                      <div class="fileitem__block--text" style="color: #fff">
                        Загрузить (doc, pdf, excel)
                      </div>
                    </div>
                  </div>
                  <!--<span class="fileitem__block--help" v-tooltip.top-center="'Договор на туристское обслуживание, заключенного между туроператором и иностранным туристом, составленного  в соответствии с приказом Министра по инвестициям  и развитию Республики Казахстан от 30 января 2015 года  № 81 «Об утверждении типового договора на туристское  обслуживание»'">?</span>-->
                </label>
              </div>

              <div class="fileitem--col">
                <label
                  class="add_tourist--fileitem"
                  :class="document_6.class"
                  v-if="!application.document_6.error"
                >
                  <input
                    type="file"
                    class="add_tourist--input"
                    ref="document_6"
                    v-on:change="handle_file_upload_6()"
                    name="file_document_6"
                  />
                  <div class="fileitem__block">
                    <div class="fileitem__block--title">
                      Копия лицензии на осуществление туристской операторской
                      деятельности в сфере въездного и внутреннего туризма
                      <span style="color: red">*</span>
                    </div>

                    <div class="fileitem__block--file" v-if="document_6.name">
                      <div class="fileitem__block--icon">
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </div>
                      <div class="fileitem__block--text">
                        {{ document_6.name }}
                      </div>
                    </div>
                    <div class="fileitem__block--file" v-else>
                      <div class="fileitem__block--icon">
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19 14.563l-6.146 6.243A3.984 3.984 0 0110.014 22a3.984 3.984 0 01-2.838-1.194A4.11 4.11 0 016 17.922a4.11 4.11 0 011.176-2.883l6.146-6.243A2.656 2.656 0 0115.215 8c.71 0 1.39.286 1.892.796a2.74 2.74 0 01.784 1.923 2.74 2.74 0 01-.784 1.922l-6.152 6.242a1.328 1.328 0 01-1.893 0 1.37 1.37 0 010-1.922l5.678-5.76" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </div>
                      <div class="fileitem__block--text">
                        Загрузить (doc, pdf, excel)
                      </div>
                    </div>
                  </div>
                  <!--<span class="fileitem__block--help" v-tooltip.top-center="'Договор на туристское обслуживание, заключенного между туроператором и иностранным туристом, составленного  в соответствии с приказом Министра по инвестициям  и развитию Республики Казахстан от 30 января 2015 года  № 81 «Об утверждении типового договора на туристское  обслуживание»'">?</span>-->
                </label>
                <label
                  class="add_tourist--fileitem"
                  :class="document_6.class"
                  v-if="application.document_6.error"
                  style="background-color: red"
                >
                  <input
                    type="file"
                    class="add_tourist--input"
                    ref="document_6"
                    v-on:change="handle_file_upload_6()"
                    name="file_document_6"
                  />
                  <div class="fileitem__block">
                    <div class="fileitem__block--title" style="color: #fff">
                      Копия лицензии на осуществление туристской операторской
                      деятельности в сфере въездного и внутреннего туризма
                      <span style="color: red">*</span>
                    </div>

                    <div class="fileitem__block--file" v-if="document_6.name">
                      <div class="fileitem__block--icon">
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </div>
                      <div class="fileitem__block--text" style="color: #fff">
                        {{ document_6.name }}
                      </div>
                    </div>
                    <div class="fileitem__block--file" v-else>
                      <div class="fileitem__block--icon">
                        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19 14.563l-6.146 6.243A3.984 3.984 0 0110.014 22a3.984 3.984 0 01-2.838-1.194A4.11 4.11 0 016 17.922a4.11 4.11 0 011.176-2.883l6.146-6.243A2.656 2.656 0 0115.215 8c.71 0 1.39.286 1.892.796a2.74 2.74 0 01.784 1.923 2.74 2.74 0 01-.784 1.922l-6.152 6.242a1.328 1.328 0 01-1.893 0 1.37 1.37 0 010-1.922l5.678-5.76" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </div>
                      <div class="fileitem__block--text" style="color: #fff">
                        Загрузить (doc, pdf, excel)
                      </div>
                    </div>
                  </div>
                  <!--<span class="fileitem__block--help" v-tooltip.top-center="'Договор на туристское обслуживание, заключенного между туроператором и иностранным туристом, составленного  в соответствии с приказом Министра по инвестициям  и развитию Республики Казахстан от 30 января 2015 года  № 81 «Об утверждении типового договора на туристское  обслуживание»'">?</span>-->
                </label>
              </div>
            </div>

            <label class="form__block--checkbox">
              <div class="form__checkbox--left">
                <input type="checkbox" v-model="application.confirm.value" />
                <span
                  class="form__checkbox--checkbox"
                  v-if="!application.confirm.error"
                ></span>
                <span
                  class="form__checkbox--checkbox"
                  style="border-color: red"
                  v-if="application.confirm.error"
                ></span>
              </div>
              <div class="form__checkbox--left">
                <div class="form__checkbox--text">
                  Подтверждаю достоверность представленной информации, в том
                  числе, что не являюсь банкротом, не подлежу процедуре
                  банкротства или ликвидации в соответствии с Законом Республики
                  Казахстан «О реабилитации и банкротстве» и даю согласие на
                  использование сведений, составляющих охраняемую законом тайну,
                  а также на сбор, обработку, хранение, выгрузку и использование
                  персональных данных.
                </div>
              </div>
            </label>

            <div class="modal__wrapper--footer">
              <div class="section--link">
                <button type="submit" class="next__stage input__button">
                  Подписать
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <modal
      class="modal__block modal__block--middle modal__block--check"
      name="registry__down"
      :width="680"
      :min-width="680"
      height="auto"
    >
      <div class="modal__block--wrapper registry__download--wrapper">
        <div class="modal__block--close" @click="$modal.hide('registry__down')">
          <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M31.5 10.5l-21 21M10.5 10.5l21 21" stroke="#0E1839" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
        </div>
        <div class="modal__wrapper">
          <div class="modal__title">Данные туриста</div>
          <div class="modal__content">
            <div class="modal__form">
              <div class="modal__scaner--block">
                <div class="modal__scaner--video" v-show="!isPhotoTaken">
                  <video id="modal__video" ref="camera"></video>
                  <canvas ref="canvas" id="photoTaken"></canvas>
                  <img
                    src="assets/img/photo-pattern.png"
                    class="modal__scaner__range"
                  />
                </div>
                <div
                  class="modal__scaner--video modal__scaner--photo"
                  v-show="isPhotoTaken"
                >
                  <img :src="imgPhoto" ref="cameraImg" />
                </div>
              </div>
              <div class="modal__wrapper--footer modal__wrapper--center">
                <div class="next__stage--block">
                  <div class="section--link" v-if="isCameraOpen && !isLoading">
                    <a @click="takePhoto" class="next__stage input__button"
                      >Сфотографировать</a
                    >
                  </div>

                  <div
                    class="section--link"
                    v-if="isPhotoTaken && !isCameraOpen"
                  >
                    <a
                      @click="readyImage()"
                      class="next__stage input__button input__button--blue"
                      >Отправить</a
                    >
                    <a class="next__stage input__button">Переснять</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      class="modal__block modal__block--middle modal__block--check"
      name="registry__add"
      :width="680"
      :min-width="680"
      height="auto"
    >
      <div class="modal__block--wrapper">
        <div class="modal__block--close" @click="$modal.hide('registry__add')">
          <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M31.5 10.5l-21 21M10.5 10.5l21 21" stroke="#0E1839" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
        </div>
        <div class="modal__wrapper">
          <div class="modal__title">Данные туриста</div>
          <div class="modal__content">
            <div class="modal__form">
              <form @submit.prevent="userDataFunct()">
                <div class="form__block--line">
                  <div class="form__block--input">
                    <div class="form__block--title">
                      {{ $t('registration.input.name') }}
                      <span style="color: red">*</span>
                    </div>
                    <input
                      type="text"
                      class="input-linck"
                      :class="[tourist.name.error ? 'input--error' : '']"
                      name="name"
                      v-model="tourist.name.value"
                    />
                    <!-- input--error -->
                    <span
                      class="input-required"
                      :class="[tourist.name.error ? 'input--error' : '']"
                      v-if="tourist.name.error"
                      >{{ tourist.name.error }}</span
                    >
                  </div>
                </div>
                <div class="form__block--line">
                  <div class="form__block--input">
                    <div class="form__block--title">
                      {{ $t('registration.input.surname') }}
                      <span style="color: red">*</span>
                    </div>
                    <input
                      type="text"
                      class="input-linck"
                      :class="[tourist.surname.error ? 'input--error' : '']"
                      name="surname"
                      v-model="tourist.surname.value"
                    />
                    <!-- input--error -->
                    <span
                      class="input-required"
                      :class="[tourist.surname.error ? 'input--error' : '']"
                      v-if="tourist.surname.error"
                      >{{ tourist.surname.error }}</span
                    >
                  </div>
                </div>
                <div class="form__block--line">
                  <div class="form__block--input">
                    <div class="form__block--title">Отчество</div>
                    <input
                      type="text"
                      class="input-linck"
                      name="patronymic"
                      v-model="tourist.patronymic.value"
                    />
                    <!-- input--error -->
                    <span
                      class="input-required"
                      v-if="tourist.patronymic.error"
                      >{{ tourist.patronymic.error }}</span
                    >
                  </div>
                </div>
                <div class="form__block--line">
                  <div class="form__block--input">
                    <div class="form__block--title">
                      {{ $t('labels.password_number') }} <span style="color: red">*</span>
                    </div>
                    <input
                      type="text"
                      class="input-linck"
                      :class="[
                        tourist.document_number.error ? 'input--error' : '',
                      ]"
                      name="patronymic"
                      v-model="tourist.document_number.value"
                    />
                    <!-- input--error -->
                    <span
                      class="input-required"
                      :class="[
                        tourist.document_number.error ? 'input--error' : '',
                      ]"
                      v-if="tourist.document_number.error"
                      >{{ tourist.document_number.error }}</span
                    >
                  </div>
                </div>
                <!--
								<div class="form__block--line">
									<div class="form__block--input">
										<div class="form__block--title">
											{{ $t('labels.citizenshipe') }} <span style="color: red;">*</span>
										</div>
										<multiselect v-model="tourist.citizenship_id.value"
																 :options="citizenships"
																 :clearOnSelect="false"
																 :clear-on-select="false"
																 :placeholder="$t('labels.citizenshipe')"
																 :hideSelected="true"
																 required=""
										></multiselect>

										
										<span class="input-required" :class="[tourist.citizenship_id.error ? 'input--error' : '']" :v-if="tourist.citizenship_id.error">{{ tourist.citizenship_id.error }}</span>
									</div>
								</div>
								-->
                <div class="form__block--line">
                  <div class="form__block--input">
                    <div class="form__block--title">
                      Дата рождения <span style="color: red">*</span>
                    </div>
                    <input
                      type="date"
                      class="input-linck"
                      :class="[
                        tourist.date_of_birth.error ? 'input--error' : '',
                      ]"
                      max="9999-12-30"
                      name="period"
                      v-model="tourist.date_of_birth.value"
                    />
                    <!-- input--error -->
                    <span
                      class="input-required"
                      :class="[
                        tourist.date_of_birth.error ? 'input--error' : '',
                      ]"
                      v-if="tourist.date_of_birth.error"
                      >{{ tourist.date_of_birth.error }}</span
                    >
                  </div>
                </div>
                <div class="form__block--line">
                  <div class="form__block--input">
                    <div class="form__block--title">
                      Вылет <span style="color: red">*</span>
                    </div>
                    <multiselect
                      v-model="tourist.departure_from.value"
                      :options="catos"
                      :clearOnSelect="false"
                      :clear-on-select="false"
                      placeholder="Вылет"
                      :hideSelected="true"
                    ></multiselect>

                    <!-- input--error -->
                    <span
                      class="input-required"
                      v-if="tourist.departure_from.error"
                      >{{ tourist.departure_from.error }}</span
                    >
                  </div>
                </div>
                <div class="form__block--line">
                  <div class="form__block--input">
                    <div class="form__block--title">
                      Прилет <span style="color: red">*</span>
                    </div>
                    <multiselect
                      v-model="tourist.departure_to.value"
                      :options="catos"
                      :clearOnSelect="false"
                      :clear-on-select="false"
                      placeholder="Прилет"
                      :hideSelected="true"
                    ></multiselect>

                    <!-- input--error -->
                    <span
                      class="input-required"
                      :class="[
                        tourist.departure_to.error ? 'input--error' : '',
                      ]"
                      v-if="tourist.departure_to.error"
                      >{{ tourist.departure_to.error }}</span
                    >
                  </div>
                </div>
                <div class="form__block--line">
                  <div class="form__block--input">
                    <div class="form__block--title">
                      Дата вылета <span style="color: red">*</span>
                    </div>
                    <input
                      type="date"
                      class="input-linck"
                      name="period"
                      :class="[
                        tourist.departure_date.error ? 'input--error' : '',
                      ]"
                      v-model="tourist.departure_date.value"
                    />
                    <!-- input--error -->
                    <span
                      class="input-required"
                      :class="[
                        tourist.departure_date.error ? 'input--error' : '',
                      ]"
                      v-if="tourist.departure_date.error"
                      >{{ tourist.departure_date.error }}</span
                    >
                  </div>
                </div>

                <div class="modal__wrapper--footer modal__wrapper--center">
                  <div class="next__stage--block">
                    <div class="next__stage--text">
                      {{ $t('registration.input.required') }} *
                    </div>
                    <div class="section--link">
                      <button
                        class="next__stage input__button"
                        v-if="!application.tourist.value"
                      >
                      {{ $t('commands.add') }}
                      </button>
                      <button
                        class="next__stage input__button"
                        v-if="application.tourist.value"
                      >
                        Редактировать
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      class="modal__block modal__block--middle modal__block--check"
      name="company_add"
      :width="680"
      :min-width="680"
      height="auto"
    >
      <div class="modal__block--wrapper">
        <div class="modal__block--close" @click="$modal.hide('company_add')">
          <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M31.5 10.5l-21 21M10.5 10.5l21 21" stroke="#0E1839" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
        </div>
        <div class="modal__wrapper">
          <div class="modal__title">Данные туроператора</div>
          <div class="modal__content">
            <div class="modal__form">
              <form @submit.prevent="onStoreCompany">
                <div class="form__block--line">
                  <div class="form__block--input">
                    <div class="form__block--title">
                      Название организаций <span style="color: red">*</span>
                    </div>
                    <input
                      type="text"
                      class="input-linck"
                      :class="[company.name.error ? 'input--error' : '']"
                      name="name"
                      v-model="company.name.value"
                    />
                    <!-- input--error -->
                    <span
                      class="input-required"
                      :class="[company.name.error ? 'input--error' : '']"
                      v-if="company.name.error"
                      >{{ company.name.error }}</span
                    >
                  </div>
                </div>
                <div class="form__block--line">
                  <div class="form__block--input">
                    <div class="form__block--title">
                      БИН организаций <span style="color: red">*</span>
                    </div>
                    <input
                      type="text"
                      class="input-linck"
                      :class="[company.bin.error ? 'input--error' : '']"
                      name="bin"
                      v-model="company.bin.value"
                    />
                    <!-- input--error -->
                    <span
                      class="input-required"
                      :class="[company.bin.error ? 'input--error' : '']"
                      v-if="company.bin.error"
                      >{{ company.bin.error }}</span
                    >
                  </div>
                </div>
                <div class="form__block--line">
                  <div class="form__block--input">
                    <div class="form__block--title">
                      ФИО (Расчетный счет) <span style="color: red">*</span>
                    </div>
                    <input
                      type="text"
                      class="input-linck"
                      :class="[
                        company.payment_account_fullname.error
                          ? 'input--error'
                          : '',
                      ]"
                      name="payment_account_fullname"
                      v-model="company.payment_account_fullname.value"
                    />
                    <!-- input--error -->
                    <span
                      class="input-required"
                      :class="[
                        company.payment_account_fullname.error
                          ? 'input--error'
                          : '',
                      ]"
                      v-if="company.payment_account_fullname.error"
                      >{{ company.payment_account_fullname.error }}</span
                    >
                  </div>
                </div>
                <div class="form__block--line">
                  <div class="form__block--input">
                    <div class="form__block--title">
                      ИИН (Расчетный счет) <span style="color: red">*</span>
                    </div>
                    <input
                      type="text"
                      class="input-linck"
                      :class="[
                        company.payment_account_iin.error ? 'input--error' : '',
                      ]"
                      name="payment_account_iin"
                      v-model="company.payment_account_iin.value"
                    />
                    <!-- input--error -->
                    <span
                      class="input-required"
                      :class="[
                        company.payment_account_iin.error ? 'input--error' : '',
                      ]"
                      v-if="company.payment_account_iin.error"
                      >{{ company.payment_account_iin.error }}</span
                    >
                  </div>
                </div>
                <div class="form__block--line">
                  <div class="form__block--input">
                    <div class="form__block--title">
                      БИК (Расчетный счет) <span style="color: red">*</span>
                    </div>
                    <input
                      type="text"
                      class="input-linck"
                      :class="[
                        company.payment_account_bik.error ? 'input--error' : '',
                      ]"
                      name="payment_account_bik"
                      v-model="company.payment_account_bik.value"
                    />
                    <!-- input--error -->
                    <span
                      class="input-required"
                      :class="[
                        company.payment_account_bik.error ? 'input--error' : '',
                      ]"
                      v-if="company.payment_account_bik.error"
                      >{{ company.payment_account_bik.error }}</span
                    >
                  </div>
                </div>
                <div class="form__block--line">
                  <div class="form__block--input">
                    <div class="form__block--title">
                      Номер расчетного счета (Расчетный счет)
                      <span style="color: red">*</span>
                    </div>
                    <input
                      type="text"
                      class="input-linck"
                      :class="[
                        company.payment_account_number.error
                          ? 'input--error'
                          : '',
                      ]"
                      name="payment_account_number"
                      v-model="company.payment_account_number.value"
                    />
                    <!-- input--error -->
                    <span
                      class="input-required"
                      :class="[
                        company.payment_account_number.error
                          ? 'input--error'
                          : '',
                      ]"
                      v-if="company.payment_account_number.error"
                      >{{ company.payment_account_number.error }}</span
                    >
                  </div>
                </div>
                <div class="form__block--line">
                  <div class="form__block--input">
                    <div class="form__block--title">
                      Сведения об отсутствии/наличии задолженностей
                      <span style="color: red">*</span>
                    </div>
                    <input
                      type="file"
                      class="input-linck"
                      :class="[
                        company.certificate_of_debt.error ? 'input--error' : '',
                      ]"
                      name="certificate_of_debt"
                      ref="certificate_of_debt"
                      v-on:change="handle_file_certificate_of_debt()"
                    />
                    <!-- input--error -->
                    <span
                      class="input-required"
                      :class="[
                        company.certificate_of_debt.error ? 'input--error' : '',
                      ]"
                      v-if="company.certificate_of_debt.error"
                      >{{ company.certificate_of_debt.error }}</span
                    >
                  </div>
                </div>

                <div class="modal__wrapper--footer modal__wrapper--center">
                  <div class="next__stage--block">
                    <div class="next__stage--text">
                      {{ $t('registration.input.required') }} *
                    </div>
                    <div class="section--link">
                      <button
                        class="next__stage input__button"
                        v-if="!application.tourist.value"
                      >
                      {{ $t('commands.add') }}
                      </button>
                      <button
                        class="next__stage input__button"
                        v-if="application.tourist.value"
                      >
                        Редактировать
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { api } from '../../../boot/axios'
import * as Validator from 'validatorjs'

export default {
  name: 'AddKids',
  components: {
    Multiselect,
  },
  data() {
    return {
      user: null,
      roles: null,
      application_statuses: null,
      application: {
        clicked: null,
        type_of_tour_operator: {
          value: null,
          error: null,
        },
        name_of_tour_operator: {
          value: null,
          error: null,
        },
        payout_amount: {
          value: null,
          error: null,
        },
        created_date: {
          value: null,
          error: null,
        },
        bin_or_inn_of_tour_operator: {
          value: null,
          error: null,
        },
        tourist_surname: {
          value: null,
          error: null,
        },
        tourist_name: {
          value: null,
          error: null,
        },
        receipt: {
          value: null,
          error: null,
        },
        status: {
          value: null,
          error: null,
        },
        document_1: {
          value: null,
          error: null,
        },
        document_2: {
          value: null,
          error: null,
        },
        document_3: {
          value: null,
          error: null,
        },
        document_4: {
          value: null,
          error: null,
        },
        document_5: {
          value: null,
          error: null,
        },
        document_6: {
          value: null,
          error: null,
        },
        company: {
          value: null,
          error: null,
        },
        tourist: {
          value: null,
          error: null,
        },
        confirm: {
          value: null,
          error: null,
        },
      },
      company: {
        name: {
          value: null,
          error: null,
        },
        bin: {
          value: null,
          error: null,
        },
        certificate_of_debt: {
          value: null,
          error: null,
        },
        payment_account_fullname: {
          value: null,
          error: null,
        },
        payment_account_iin: {
          value: null,
          error: null,
        },
        payment_account_bik: {
          value: null,
          error: null,
        },
        payment_account_number: {
          value: null,
          error: null,
        },
      },
      tourist: {
        surname: {
          value: null,
          error: null,
        },
        name: {
          value: null,
          error: null,
        },
        patronymic: {
          value: null,
          error: null,
        },
        document_number: {
          value: null,
          error: null,
        },
        date_of_birth: {
          value: null,
          error: null,
        },
        departure_from: {
          value: null,
          error: null,
        },
        departure_to: {
          value: null,
          error: null,
        },
        departure_date: {
          value: null,
          error: null,
        },
      },
      catos: [
        'Нур-Султан',
        'Алматы',
        'Шымкент',
        'Караганды',
        'Костанай',
        'Петропавловск',
        'Павлодар',
        'Кокшетау',
        'Актобе',
        'Талдыкорган',
        'Атырау',
        'Усть-Каменогорск',
        'Тараз',
        'Уральск',
        'Кызылорда',
        'Актау',
        'Туркестан',
      ],
      certificate_of_debt: {
        name: null,
        class: null,
      },
      document_1: {
        name: null,
        class: null,
        error: null,
      },
      document_2: {
        name: null,
        class: null,
        error: null,
      },
      document_3: {
        name: null,
        class: null,
        error: null,
      },
      document_4: {
        name: null,
        class: null,
        error: null,
      },
      document_5: {
        name: null,
        class: null,
        error: null,
      },
      document_6: {
        name: null,
        class: null,
        error: null,
      },
      loading: false,
      options: {
        itemsPerPage: 1,
      },
      headers: [
        {
          text: 'Фамилия ребенка',
          value: 'surname',
        },
        {
          text: 'Имя ребенка',
          value: 'name',
        },
        {
          text: 'Отчество ребенка',
          value: 'patronymic',
        },
        {
          text: 'Номер паспорта',
          value: 'document_number',
        },
        {
          text: 'Дата рождения',
          value: 'date_of_birth',
        },
        {
          text: 'Дата вылета',
          value: 'departure_date',
        },
        {
          text: 'Вылет',
          value: 'departure_from',
        },
        {
          text: 'Прилет',
          value: 'departure_to',
        },
        {
          text: this.$t('labels.action'),
          value: 'action',
          align: 'center',
          sortable: false,
        },
      ],
      company_header: [
        {
          text: 'Название организаций',
          value: 'name.value',
        },
        {
          text: 'БИН организаций',
          value: 'bin.value',
        },
        {
          text: 'ФИО (Расчетный счет)',
          value: 'payment_account_fullname.value',
        },
        {
          text: 'ИИН (Расчетный счет)',
          value: 'payment_account_iin.value',
        },
        {
          text: 'БИК (Расчетный счет)',
          value: 'payment_account_bik.value',
        },
        {
          text: 'Номер (Расчетный счет)',
          value: 'payment_account_number.value',
        },
        {
          text: this.$t('labels.action'),
          value: 'action',
          align: 'center',
          sortable: false,
        },
      ],
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      imgPhoto: null,
      loaderCapture: false,
      index_for_web: 0,
    }
  },
  beforeCreate() {
    api.get('/user').then((response) => {
      if (response.data) {
        this.user = response.data
      }
    })

    api
      .get('/roles', {
        params: {
          'filter[role][slug]': 'tour-operator-individual,tour-operator-entity',
        },
      })
      .then((response) => {
        if (response.data) {
          this.roles = response.data
        }
      })

    api
      .get('/application-statuses', {
        params: {
          'filter[slug]': 'approved,denied,paid',
        },
      })
      .then((response) => {
        if (response.data) {
          this.application_statuses = response.data
        }
      })
  },
  methods: {
    onSubmit(evt) {
      this.application.type_of_tour_operator.error = null
      this.application.name_of_tour_operator.error = null
      this.application.tourist_surname.error = null
      this.application.tourist_name.error = null
      this.application.payout_amount.error = null
      this.application.created_date.error = null

      let rules = {}

      if (
        this.application.status.value &&
        this.application.status.value == 'paid'
      ) {
        rules = {
          'type_of_tour_operator.value': 'required',
          'name_of_tour_operator.value': 'required',
          'tourist_surname.value': 'required',
          'tourist_name.value': 'required',
          'payout_amount.value': 'required',
          'created_date.value': 'required',
          'receipt.value': 'required',
        }
      } else {
        rules = {
          'type_of_tour_operator.value': 'required',
          'name_of_tour_operator.value': 'required',
          'tourist_surname.value': 'required',
          'tourist_name.value': 'required',
          'payout_amount.value': 'required',
          'created_date.value': 'required',
        }
      }

      let messages = {
        ru: {
          'required.type_of_tour_operator.value':
            'Поле Сведения об отсутствии/наличии задолженностей обязательно к заполнению.',
          'required.name_of_tour_operator.value':
            'Поле Расчетный счет обязательно к заполнению.',
          'required.tourist_surname.value':
            'Поле Фамилия туриста обязательно к заполнению.',
          'required.tourist_name.value':
            'Поле Фамилия туриста обязательно к заполнению.',
          'required.payout_amount.value': 'Заполните данные туриста',
          'required.created_date.value': 'Заполните Туроператора',
          'required.receipt.value':
            'Поле Квитанция об оплате обязательно к заполнению.',
        },
        kz: {
          'required.type_of_tour_operator.value':
            'Поле Сведения об отсутствии/наличии задолженностей обязательно к заполнению.',
          'required.name_of_tour_operator.value':
            'Поле Расчетный счет обязательно к заполнению.',
          'required.tourist_surname.value':
            'Поле Фамилия туриста обязательно к заполнению.',
          'required.tourist_name.value':
            'Поле Фамилия туриста обязательно к заполнению.',
          'required.payout_amount.value': 'Заполните данные туриста',
          'required.created_date.value': 'Заполните данные туриста',
          'required.receipt.value':
            'Поле Квитанция об оплате обязательно к заполнению.',
        },
      }

      let validation = new Validator(
        this.application,
        rules,
        messages[this.$i18n.locale],
      )

      if (validation.fails()) {
        console.log(validation.errors.errors)

        for (let key in validation.errors.errors) {
          if (key == 'type_of_tour_operator.value')
            this.application.type_of_tour_operator.error =
              validation.errors.first(key)
          if (key == 'name_of_tour_operator.value')
            this.application.name_of_tour_operator.error =
              validation.errors.first(key)
          if (key == 'payout_amount.value')
            this.application.payout_amount.error = validation.errors.first(key)
          if (key == 'created_date.value')
            this.application.created_date.error = validation.errors.first(key)
          if (key == 'receipt.value')
            this.application.receipt.error = validation.errors.first(key)
        }

        this.application.clicked = null

        return
      }

      const formData = new FormData(evt.target)

      console.log(this.application.status.value)

      formData.append('status', this.application.status.value.slug)
      formData.append(
        'type_of_tour_operator',
        this.application.type_of_tour_operator.value.slug,
      )

      api
        .post('/applications2', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          if (response.data.code == 201) {
            setTimeout(() => {
              this.$notify({
                group: 'foo',
                type: 'success',
                title: 'Заявление успешно зарегистировано',
                position: 'bottom center',
              })
            }, 3000)

            this.$router.push('/' + this.$i18n.locale + '/administration')
          }
        })

      //	this.application.status.

      /*
				this.application.tourist.error = null;
				this.application.company.error = null;
				this.application.document_1.error = null;
				this.application.document_2.error = null;
				this.application.document_3.error = null;
				this.application.document_4.error = null;
				this.application.document_5.error = null;
				this.application.confirm.error = null;

				console.log(this.application);

				let rules = {
					'tourist.value': 'required',
					'company.value': 'required',
					'document_1.value': 'required',
					'document_2.value': 'required',
					'document_3.value': 'required',
					'document_5.value': 'required',
					'confirm.value': 'required',
				};

				let messages = {
					ru: {
						'required.information_about_the_absence_presence_of_debts.value': 'Поле Сведения об отсутствии/наличии задолженностей обязательно к заполнению.',
						'required.payment_account.value': 'Поле Расчетный счет обязательно к заполнению.',
						'required.tourist.value': 'Заполните данные туриста',
						'required.company.value': 'Заполните Туроператора'
					},
					kz: {
						'required.information_about_the_absence_presence_of_debts.value': 'Поле Сведения об отсутствии/наличии задолженностей обязательно к заполнению.',
						'required.payment_account.value': 'Поле Расчетный счет обязательно к заполнению.',
						'required.clients.value': 'Заполните данные туриста'
					}
				}

				let validation = new Validator(this.application, rules, messages[this.$i18n.locale]);

				if (validation.fails()) {
					for (let key in validation.errors.errors) {
						if (key == 'tourist.value') {
							this.application.tourist.error = validation.errors.first(key);
						}

						if (key == 'company.value') {
							this.application.company.error = validation.errors.first(key);
						}

						if (key == 'document_1.value') this.application.document_1.error = validation.errors.first(key);
						if (key == 'document_2.value') this.application.document_2.error = validation.errors.first(key);
						if (key == 'document_3.value') this.application.document_3.error = validation.errors.first(key);
						if (key == 'document_4.value') this.application.document_4.error = validation.errors.first(key);
						if (key == 'document_5.value') this.application.document_5.error = validation.errors.first(key);
						if (key == 'confirm.value') this.application.confirm.error = validation.errors.first(key);
					}

					this.application.clicked = null;
					
					return;
				}

				const formData = new FormData(evt.target);

				formData.append('view_id', 2);
				formData.append('status', 'registered');

				formData.append('tourist[type]', 'child');
				formData.append('tourist[surname]', this.application.tourist.value[0].surname);
				formData.append('tourist[name]', this.application.tourist.value[0].name);
				formData.append('tourist[date_of_birth]', this.application.tourist.value[0].date_of_birth);

				if (this.application.tourist.value[0].patronymic) {
					formData.append('tourist[patronymic]', this.application.tourist.value[0].patronymic);
				}

				if (this.application.tourist.value[0].document_number && this.application.tourist.value[0].document_number) {
					formData.append('tourist[document_number]', this.application.tourist.value[0].document_number);
				}

				formData.append('tourist[departure_date]', this.application.tourist.value[0].departure_date);
				formData.append('tourist[departure_from]', this.application.tourist.value[0].departure_from);
				formData.append('tourist[departure_to]', this.application.tourist.value[0].departure_to);

				formData.append('company[name]', this.application.company.value.name.value);
				formData.append('company[bin]', this.application.company.value.bin.value);
				formData.append('company[certificate_of_debt]', this.application.company.value.certificate_of_debt.value);
				formData.append('company[payment_account][fullname]', this.application.company.value.payment_account_fullname.value);	
				formData.append('company[payment_account][iin]', this.application.company.value.payment_account_iin.value);	
				formData.append('company[payment_account][bik]', this.application.company.value.payment_account_bik.value);	
				formData.append('company[payment_account][number]', this.application.company.value.payment_account_number.value);	

				api.post('/applications1', formData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}).then(response => {
					if (response.data.code == 201) {
						setTimeout(() => {
							this.$notify({
								group: 'foo',
								type: 'success',
								title: 'Заявление успешно зарегистировано',
								position: 'bottom center'
							});
						}, 3000);

						this.$router.push('/' + this.$i18n.locale + '/administration');
					}
				});

				*/
    },
    userDataFunct() {
      this.tourist.surname.error = null
      this.tourist.name.error = null
      this.tourist.patronymic.error = null
      //  this.tourist.citizenship_id.error = null;
      this.tourist.document_number.error = null
      this.tourist.date_of_birth.error = null
      this.tourist.departure_date.error = null
      this.tourist.departure_from.error = null
      this.tourist.departure_to.error = null

      let rules = {
        'surname.value': 'required|string|max:255',
        'name.value': 'required|string|max:255',
        'patronymic.value': 'string|max:255',
        'document_number.value': 'required|string|max:255',
        'date_of_birth.value': 'required',
        'departure_from.value': 'required|string',
        'departure_to.value': 'required|string',
        'departure_date.value': 'required|date',
      }

      let messages = {
        ru: {
          'required.surname.value': 'Поле Фамилия обязательно к заполнению.',
          'required.name.value': 'Поле Имя обязательно к заполнению.',
          'required.patronymic.value':
            'Поле Отчество обязательно к заполнению.',
          'required.document_number.value':
            'Поле Номер паспорта обязательно к заполнению.',
          'required.citizenship_id.value':
            'Поле Гражданство обязательно к заполнению.',
          'required.date_of_birth.value':
            'Поле Дата рождения обязательно к заполнению.',
          'required.departure_date.value':
            'Поле Дата вылета обязательно к заполнению.',
          'required.departure_from.value':
            'Поле Вылет обязательно к заполнению.',
          'required.departure_to.value':
            'Поле Прилет обязательно к заполнению.',
        },
        kz: {
          'required.surname.value': 'Поле Фамилия обязательно к заполнению.',
          'required.name.value': 'Поле Имя обязательно к заполнению.',
          'required.patronymic.value':
            'Поле Отчество обязательно к заполнению.',
          'required.document_number.value':
            'Поле Номер паспорта обязательно к заполнению.',
          'required.citizenship_id.value':
            'Поле Гражданство обязательно к заполнению.',
          'required.date_of_birth.value':
            'Поле Дата рождения обязательно к заполнению.',
          'required.departure_date.value':
            'Поле Дата вылета обязательно к заполнению.',
          'required.departure_from.value':
            'Поле Вылет обязательно к заполнению.',
          'required.departure_to.value':
            'Поле Прилет обязательно к заполнению.',
        },
      }

      let validation = new Validator(
        this.tourist,
        rules,
        messages[this.$i18n.locale],
      )

      if (validation.fails()) {
        for (let key in validation.errors.errors) {
          if (key == 'surname.value')
            this.tourist.surname.error = validation.errors.first(key)
          if (key == 'name.value')
            this.tourist.name.error = validation.errors.first(key)
          if (key == 'patronymic.value')
            this.tourist.patronymic.error = validation.errors.first(key)
          if (key == 'citizenship_id.value')
            this.tourist.citizenship_id.error = validation.errors.first(key)
          if (key == 'document_number.value')
            this.tourist.document_number.error = validation.errors.first(key)
          if (key == 'date_of_birth.value')
            this.tourist.date_of_birth.error = validation.errors.first(key)
          if (key == 'departure_from.value')
            this.tourist.departure_from.error = validation.errors.first(key)
          if (key == 'departure_to.value')
            this.tourist.departure_to.error = validation.errors.first(key)
          if (key == 'departure_date.value')
            this.tourist.departure_date.error = validation.errors.first(key)
        }

        return
      }

      if (this.application.tourist.value) {
        this.application.tourist.value[0]['surname'] =
          this.tourist.surname.value
        this.application.tourist.value[0]['name'] = this.tourist.name.value
        this.application.tourist.value[0]['patronymic'] =
          this.tourist.patronymic.value
        this.application.tourist.value[0]['document_number'] =
          this.tourist.document_number.value
        this.application.tourist.value[0]['date_of_birth'] =
          this.tourist.date_of_birth.value
        this.application.tourist.value[0]['departure_from'] =
          this.tourist.departure_from.value
        this.application.tourist.value[0]['departure_to'] =
          this.tourist.departure_to.value
        this.application.tourist.value[0]['departure_date'] =
          this.tourist.departure_date.value
      } else {
        this.application.tourist.value = []

        this.application.tourist.value.push({
          surname: this.tourist.surname.value,
          name: this.tourist.name.value,
          patronymic: this.tourist.patronymic.value,
          document_number: this.tourist.document_number.value,
          date_of_birth: this.tourist.date_of_birth.value,
          //  citizenship_id: this.tourist.citizenship_id.value,
          departure_from: this.tourist.departure_from.value,
          departure_to: this.tourist.departure_to.value,
          departure_date: this.tourist.departure_date.value,
        })
      }

      this.$modal.hide('registry__add')

      this.client_reset()
    },
    selectApplicationStatus() {
      this.application.type_of_tour_operator.value = null
      this.application.name_of_tour_operator.value = null
      this.application.payout_amount.value = null
      this.application.created_date.value = null
      this.application.tourist_surname.value = null
      this.application.tourist_name.value = null
      this.application.receipt.value = null
    },
    client_reset() {
      this.tourist.surname.value = this.application.tourist.value[0].surname
      this.tourist.name.value = this.application.tourist.value[0].name
      this.tourist.patronymic.value =
        this.application.tourist.value[0].patronymic
      this.tourist.document_number.value =
        this.application.tourist.value[0].document_number
      this.tourist.departure_to.value =
        this.application.tourist.value[0].departure_to
      this.tourist.departure_from.value =
        this.application.tourist.value[0].departure_from
      this.tourist.date_of_birth.value =
        this.application.tourist.value[0].date_of_birth
    },
    handle_file_upload_1() {
      this.document_1.name = this.$refs.document_1.files[0].name
      this.application.document_1.value = '1'
      this.application.document_1.error = null
      this.document_1.class = 'add_tourist--success'
    },
    handle_file_upload_2() {
      this.document_2.name = this.$refs.document_2.files[0].name
      this.application.document_2.error = null
      this.application.document_2.value = '1'
      this.document_2.class = 'add_tourist--success'
    },
    handle_file_upload_3() {
      this.document_3.name = this.$refs.document_3.files[0].name
      this.application.document_3.value = '1'
      this.application.document_3.error = null
      this.document_3.class = 'add_tourist--success'
    },
    handle_file_upload_4() {
      this.document_4.name = this.$refs.document_4.files[0].name
      this.application.document_4.value = '1'
      this.application.document_4.error = null
      this.document_4.class = 'add_tourist--success'
    },
    handle_file_upload_5() {
      this.document_5.name = this.$refs.document_5.files[0].name
      this.application.document_5.value = '1'
      this.application.document_5.error = null
      this.document_5.class = 'add_tourist--success'
    },
    handle_file_upload_6() {
      this.document_6.name = this.$refs.document_6.files[0].name
      this.application.document_6.value = '1'
      this.application.document_6.error = null
      this.document_6.class = 'add_tourist--success'
    },
    handle_file_certificate_of_debt() {
      this.company.certificate_of_debt.value =
        this.$refs.certificate_of_debt.files[0]
    },
    handle_file_receipt() {
      this.application.receipt.value = 1
    },
    onStoreCompany(evt) {
      const formData = new FormData()

      this.company.name.error = null
      this.company.bin.error = null
      this.company.certificate_of_debt.error = null
      this.company.payment_account_fullname.error = null
      this.company.payment_account_iin.error = null
      this.company.payment_account_bik.error = null
      this.company.payment_account_number.error = null

      let rules = {
        'name.value': 'required|string|max:255',
        'bin.value': 'required|string|max:255',
        'certificate_of_debt.value': 'required',
        'payment_account_fullname.value': 'required|string|max:255',
        'payment_account_iin.value': 'required|string|max:255',
        'payment_account_bik.value': 'required|string|max:255',
        'payment_account_number.value': 'required|string|max:255',
      }

      let messages = {
        ru: {
          'required.name.value':
            'Поле Название организаций обязательно к заполнению.',
          'required.bin.value': 'Поле БИН организаций к заполнению.',
          'required.payment_account_fullname.value':
            'Поле ФИО (Расчетный счет) обязательно к заполнению.',
          'required.payment_account_iin.value':
            'Поле ИИН (Расчетный счет) обязательно к заполнению.',
          'required.payment_account_bik.value':
            'Поле БИК (Расчетный счет) обязательно к заполнению.',
          'required.payment_account_number.value':
            'Поле Номер расчетного счета (Расчетный счет) обязательно к заполнению.',
        },
      }

      let validation = new Validator(
        this.company,
        rules,
        messages[this.$i18n.locale],
      )

      if (validation.fails()) {
        for (let key in validation.errors.errors) {
          if (key == 'name.value')
            this.company.name.error = validation.errors.first(key)
          if (key == 'bin.value')
            this.company.bin.error = validation.errors.first(key)
          if (key == 'payment_account_fullname.value')
            this.company.payment_account_fullname.error =
              validation.errors.first(key)
          if (key == 'payment_account_iin.value')
            this.company.payment_account_iin.error =
              validation.errors.first(key)
          if (key == 'payment_account_bik.value')
            this.company.payment_account_bik.error =
              validation.errors.first(key)
          if (key == 'payment_account_number.value')
            this.company.payment_account_number.error =
              validation.errors.first(key)
        }

        return
      }

      this.application.company.value = this.company

      this.$modal.hide('company_add')
    },
  },
}
</script>

<style scoped></style>
