<template>
  <div
    class="wrap__content"
    v-bind:class="{ 'wrap__content--placeholder': !user || !users }"
  >
    <div class="page__placeholder" v-if="!user || !users">
      <div class="form__block--placeholder">
        <div class="form__placeholder--circle">
          <div class="placeholder__circle">
            <svg width="73" height="73" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><circle cx="36.5" cy="36" r="24" stroke="#FFF7E1" stroke-width="2"/><g filter="url(#filter0_d_22_5658)"><path d="M16.16 23A24.208 24.208 0 0012 36.605C12 50.078 22.969 61 36.5 61S61 50.078 61 36.605c0-1.736-.182-3.43-.528-5.064" stroke="#FFCC47" stroke-width="4" stroke-linecap="round"/></g><defs><filter id="filter0_d_22_5658" x="5" y="16" width="63" height="52" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset/><feGaussianBlur stdDeviation="2.5"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix values="0 0 0 0 1 0 0 0 0 0.722826 0 0 0 0 0 0 0 0 0.5 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_22_5658"/><feBlend in="SourceGraphic" in2="effect1_dropShadow_22_5658" result="shape"/></filter></defs></svg>
          </div>
        </div>
        <div class="form__placeholder--logo">
          <img src="../../../assets/img/logo.svg" alt="" />
        </div>
      </div>
    </div>
    <div class="content__page--block" v-if="user && users">
      <notifications group="foo" position="bottom center" />

      <div class="content__page">
        <div class="container">
          <div class="registry__table">
            <!--

						<div class="registry__tabs">
							<div class="registry__tabs--item" v-bind:class="{'registry__tabs--active': category == 'all' }" @click="categoriesTab('all')">
								<a href="#">Все туроператоры</a>
							</div>
							<div class="registry__tabs--item" v-bind:class="{'registry__tabs--active': category == 'approved' }" @click="categoriesTab('approved')">
								<a href="#">Одобренные</a>
							</div>
							<div class="registry__tabs--item" v-bind:class="{'registry__tabs--active': category == 'rejected' }" @click="categoriesTab('rejected')">
								<a href="#">Отклоненные</a>
							</div>
						</div>

					-->

            <div class="table__block--block table__block--shadow">
              <div class="table__block--top">
                <div class="table__block--title">Туроператоры</div>
              </div>

              <div class="table__block--content">
                <div class="table__block--filter">
                  <div class="table__filter--search">
                    <label class="search__label">
                      <input
                        type="text"
                        value=""
                        name="filter_search"
                        v-model="filters.search"
                        :placeholder="$t('labels.search_by_text')"
                      />
                      <button>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11 19a8 8 0 100-16 8 8 0 000 16zM21 21l-4.35-4.35" stroke="#0E1839" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </button>
                    </label>
                  </div>
                  
                  <div class="table__filter--order">
                    <a
                      href="#"
                      class="filter__order--item"
                      v-bind:class="{
                        'filter__order--active': filterCategories == 'all',
                      }"
                      @click="filterTab('all')"
                      >{{ $t('labels.all') }}</a
                    >
                    <a
                      href="#"
                      class="filter__order--item"
                      v-bind:class="{
                        'filter__order--active': filterCategories == 'accept',
                      }"
                      @click="filterTab('accept')"
                      >Одобрены</a
                    >
                    <a
                      href="#"
                      class="filter__order--item"
                      v-bind:class="{
                        'filter__order--active': filterCategories == 'denied',
                      }"
                      @click="filterTab('denied')"
                      >Отклоненные</a
                    >
                    <a
                      href="#"
                      class="filter__order--item"
                      v-bind:class="{
                        'filter__order--active': filterCategories == 'blocked',
                      }"
                      @click="filterTab('under')"
                      >Заблокированные</a
                    >
                  </div>

                  <div class="section--link" style="margin-top: 20px">
                    <a
                      @click="$modal.show('company_add3')"
                      style="
                        padding-top: 12px;
                        padding-bottom: 12px;
                        background-color: #6b69df;
                        box-shadow: 0 0 3px #6b69df;
                        color: #fff;
                      "
                      >Добавить туроператора +</a
                    >
                  </div>
                </div>
                <!-- :items="applications.data"-->
                <v-data-table
                  :headers="headers"
                  :items="filteredlist"
                  :loading="loading"
                  :options.sync="options"
                  :footer-props="{
                    'items-per-page-options': [5, 10, 20, 30, 40, 50],
                  }"
                >
                  <template v-slot:item.email="{ item }">{{
                    item.email.length >= 17
                      ? item.email.slice(0, 17) + '...'
                      : item.email
                  }}</template>
                  <template v-slot:item.action="{ item }">
                    <div
                      @click="onEdit(item.id, item), $modal.show('company_add')"
                      style="cursor: pointer"
                      v-if="!item.email_verified"
                    >
                      <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M9.625 3.5H3.5a1.75 1.75 0 00-1.75 1.75V17.5a1.75 1.75 0 001.75 1.75h12.25a1.75 1.75 0 001.75-1.75v-6.125" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M16.188 2.187a1.856 1.856 0 012.625 2.625L10.5 13.125 7 14l.875-3.5 8.313-8.313z" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </div>
                    <div
                      style="cursor: pointer"
                      @click="
                        onEdit2(item.id, item), $modal.show('company_add2')
                      "
                      v-if="item.email_verified"
                    >
                      <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M9.625 3.5H3.5a1.75 1.75 0 00-1.75 1.75V17.5a1.75 1.75 0 001.75 1.75h12.25a1.75 1.75 0 001.75-1.75v-6.125" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M16.188 2.187a1.856 1.856 0 012.625 2.625L10.5 13.125 7 14l.875-3.5 8.313-8.313z" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </div>
                  </template>
                  <v-alert slot="no-results" :value="true" color="error"
                    >{{ $t('empty_search_results', [filters.search]) }}</v-alert
                  >
                </v-data-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal
      class="modal__block modal__block--middle modal__block--check"
      name="company_add"
      :width="680"
      :min-width="680"
      height="auto"
    >
      <div class="modal__block--wrapper">
        <div class="modal__block--close" @click="$modal.hide('company_add')">
          <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M31.5 10.5l-21 21M10.5 10.5l21 21" stroke="#0E1839" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
        </div>
        <div class="modal__wrapper">
          <div class="modal__title">Туроператор</div>
          <div class="modal__content">
            <div class="modal__form">
              <form @submit.prevent="onUpdateUser">
                <div class="form__block--line">
                  <div class="form__block--input">
                    <div class="form__block--title">
                      {{ $t('labels.status') }} <span style="color: red">*</span>
                    </div>
                    <multiselect
                      v-model="tour_operator.confirm.value"
                      :options="confirms"
                      label="label"
                      placeholder="Выбрать статус"
                    ></multiselect>

                    <!-- input--error -->
                    <span class="input-required"></span>
                  </div>
                </div>

                <div
                  class="form__block--line"
                  v-if="
                    tour_operator.confirm.value &&
                    tour_operator.confirm.value.value == 1
                  "
                >
                  <div class="form__block--input">
                    <div class="form__block--title">
                      Сообщение <span style="color: red">*</span>
                    </div>
                    <textarea
                      class="input-linck"
                      :class="[
                        tour_operator.message.error ? 'input--error' : '',
                      ]"
                      v-model="tour_operator.message.value"
                    ></textarea>
                    <!-- input--error -->
                    <span
                      class="input-required"
                      :class="[
                        tour_operator.message.error ? 'input--error' : '',
                      ]"
                      v-if="tour_operator.message.error"
                      >{{ tour_operator.message.error }}</span
                    >
                  </div>
                </div>

                <div class="modal__wrapper--footer modal__wrapper--center">
                  <div class="next__stage--block">
                    <div class="section--link">
                      <button
                        class="next__stage input__button"
                        v-if="
                          tour_operator.confirm.value &&
                          !tour_operator.access.value
                        "
                      >
                      {{ $t('commands.save') }}
                      </button>
                      <button
                        class="next__stage input__button"
                        v-else-if="tour_operator.access.value"
                      >
                      {{ $t('commands.save') }}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      class="modal__block modal__block--middle modal__block--check"
      name="company_add2"
      :width="680"
      :min-width="680"
      height="auto"
    >
      <div class="modal__block--wrapper">
        <div class="modal__block--close" @click="$modal.hide('company_add2')">
          <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M31.5 10.5l-21 21M10.5 10.5l21 21" stroke="#0E1839" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
        </div>
        <div class="modal__wrapper">
          <div class="modal__title">Туроператор</div>
          <div class="modal__content">
            <div class="modal__form">
              <form @submit.prevent="onUpdateUser2">
                <div class="form__block--line">
                  <div class="form__block--input">
                    <div class="form__block--title">
                      {{ $t('labels.status') }} <span style="color: red">*</span>
                    </div>
                    <multiselect
                      v-model="tour_operator.access.value"
                      :options="accesses"
                      label="label"
                      placeholder="Выбрать статус"
                    ></multiselect>

                    <!-- input--error -->
                    <span class="input-required"></span>
                  </div>
                </div>

                <div class="modal__wrapper--footer modal__wrapper--center">
                  <div class="next__stage--block">
                    <div class="section--link">
                      <button
                        class="next__stage input__button"
                        v-if="tour_operator.access.value"
                      >
                      {{ $t('commands.save') }}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      class="modal__block modal__block--middle modal__block--check"
      name="company_add3"
      :width="680"
      :min-width="680"
      height="auto"
    >
      <div class="modal__block--wrapper">
        <div class="modal__block--close" @click="$modal.hide('company_add3')">
          <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M31.5 10.5l-21 21M10.5 10.5l21 21" stroke="#0E1839" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
        </div>
        <div class="modal__wrapper">
          <div class="modal__title">Добавление туроператора</div>
          <div class="modal__content">
            <div class="modal__form">
              <form @submit.prevent="onUpdateUser3">
                <div class="form__block--line">
                  <div class="form__block--input">
                    <div class="form__block--title">
                      {{ $t('registration.input.organization') }}
                    </div>
                    <input
                      type="text"
                      class="input-linck"
                      :class="[
                        tour_operator_to_store.organization.error
                          ? 'input--error'
                          : '',
                      ]"
                      name="organization"
                      v-model="tour_operator_to_store.organization.value"
                    />
                    <!-- input--error -->
                    <span
                      class="input-required"
                      :class="[
                        tour_operator_to_store.organization.error
                          ? 'input--error'
                          : '',
                      ]"
                      v-if="tour_operator_to_store.organization.error"
                      >{{ tour_operator_to_store.organization.error }}</span
                    >
                  </div>
                </div>

                <div class="form__block--line">
                  <div class="form__block--input">
                    <div class="form__block--title">
                      Фамилия <span style="color: red">*</span>
                    </div>
                    <input
                      type="text"
                      class="input-linck"
                      :class="[
                        tour_operator_to_store.surname.error
                          ? 'input--error'
                          : '',
                      ]"
                      name="surname"
                      v-model="tour_operator_to_store.surname.value"
                    />
                    <!-- input--error -->
                    <span
                      class="input-required"
                      :class="[
                        tour_operator_to_store.surname.error
                          ? 'input--error'
                          : '',
                      ]"
                      v-if="tour_operator_to_store.surname.error"
                      >{{ tour_operator_to_store.surname.error }}</span
                    >
                  </div>
                </div>

                <div class="form__block--line">
                  <div class="form__block--input">
                    <div class="form__block--title">
                      {{ $t('labels.name') }} <span style="color: red">*</span>
                    </div>
                    <input
                      type="text"
                      class="input-linck"
                      :class="[
                        tour_operator_to_store.name.error ? 'input--error' : '',
                      ]"
                      name="name"
                      v-model="tour_operator_to_store.name.value"
                    />
                    <!-- input--error -->
                    <span
                      class="input-required"
                      :class="[
                        tour_operator_to_store.name.error ? 'input--error' : '',
                      ]"
                      v-if="tour_operator_to_store.name.error"
                      >{{ tour_operator_to_store.name.error }}</span
                    >
                  </div>
                </div>

                <div class="form__block--line">
                  <div class="form__block--input">
                    <div class="form__block--title">{{ $t('labels.patronymic') }}</div>
                    <input
                      type="text"
                      class="input-linck"
                      :class="[
                        tour_operator_to_store.patronymic.error
                          ? 'input--error'
                          : '',
                      ]"
                      name="patronymic"
                      v-model="tour_operator_to_store.patronymic.value"
                    />
                    <!-- input--error -->
                    <span
                      class="input-required"
                      :class="[
                        tour_operator_to_store.patronymic.error
                          ? 'input--error'
                          : '',
                      ]"
                      v-if="tour_operator_to_store.patronymic.error"
                      >{{ tour_operator_to_store.patronymic.error }}</span
                    >
                  </div>
                </div>

                <div class="form__block--line">
                  <div class="form__block--input">
                    <div class="form__block--title">
                      {{ $t('labels.iin') }} <span style="color: red">*</span>
                    </div>
                    <input
                      type="text"
                      class="input-linck"
                      :class="[
                        tour_operator_to_store.tin.error ? 'input--error' : '',
                      ]"
                      name="tin"
                      v-model="tour_operator_to_store.tin.value"
                    />
                    <!-- input--error -->
                    <span
                      class="input-required"
                      :class="[
                        tour_operator_to_store.tin.error ? 'input--error' : '',
                      ]"
                      v-if="tour_operator_to_store.tin.error"
                      >{{ tour_operator_to_store.tin.error }}</span
                    >
                  </div>
                </div>

                <div class="form__block--line">
                  <div class="form__block--input">
                    <div class="form__block--title">БИН</div>
                    <input
                      type="text"
                      class="input-linck"
                      :class="[
                        tour_operator_to_store.bin.error ? 'input--error' : '',
                      ]"
                      name="bin"
                      v-model="tour_operator_to_store.bin.value"
                    />
                    <!-- input--error -->
                    <span
                      class="input-required"
                      :class="[
                        tour_operator_to_store.bin.error ? 'input--error' : '',
                      ]"
                      v-if="tour_operator_to_store.bin.error"
                      >{{ tour_operator_to_store.bin.error }}</span
                    >
                  </div>
                </div>

                <div class="form__block--line">
                  <div class="form__block--input">
                    <div class="form__block--title">
                      EMAIL <span style="color: red">*</span>
                    </div>
                    <input
                      type="text"
                      class="input-linck"
                      :class="[
                        tour_operator_to_store.email.error
                          ? 'input--error'
                          : '',
                      ]"
                      name="email"
                      v-model="tour_operator_to_store.email.value"
                    />
                    <!-- input--error -->
                    <span
                      class="input-required"
                      :class="[
                        tour_operator_to_store.email.error
                          ? 'input--error'
                          : '',
                      ]"
                      v-if="tour_operator_to_store.email.error"
                      >{{ tour_operator_to_store.email.error }}</span
                    >
                  </div>
                </div>

                <div class="form__block--line">
                  <div class="form__block--input">
                    <div class="form__block--title">
                      Номер телефона <span style="color: red">*</span>
                    </div>
                    <input
                      type="text"
                      class="input-linck"
                      :class="[
                        tour_operator_to_store.phone.error
                          ? 'input--error'
                          : '',
                      ]"
                      name="phone"
                      v-model="tour_operator_to_store.phone.value"
                    />
                    <!-- input--error -->
                    <span
                      class="input-required"
                      :class="[
                        tour_operator_to_store.phone.error
                          ? 'input--error'
                          : '',
                      ]"
                      v-if="tour_operator_to_store.phone.error"
                      >{{ tour_operator_to_store.phone.error }}</span
                    >
                  </div>
                </div>

                <div class="form__block--line">
                  <div class="form__block--input">
                    <div class="form__block--title">
                      Дата регистрации <span style="color: red">*</span>
                    </div>
                    <input
                      type="text"
                      class="input-linck"
                      :class="[
                        tour_operator_to_store.date_of_registration.error
                          ? 'input--error'
                          : '',
                      ]"
                      name="date_of_registration"
                      v-model="
                        tour_operator_to_store.date_of_registration.value
                      "
                    />
                    <!-- input--error -->
                    <span
                      class="input-required"
                      :class="[
                        tour_operator_to_store.date_of_registration.error
                          ? 'input--error'
                          : '',
                      ]"
                      v-if="tour_operator_to_store.date_of_registration.error"
                      >{{
                        tour_operator_to_store.date_of_registration.error
                      }}</span
                    >
                  </div>
                </div>

                <div class="form__block--line">
                  <div class="form__block--input">
                    <div class="form__block--title">
                      Лицензия <span style="color: red">*</span>
                    </div>
                    <input
                      type="text"
                      class="input-linck"
                      :class="[
                        tour_operator_to_store.license.error
                          ? 'input--error'
                          : '',
                      ]"
                      name="license"
                      v-model="
                        tour_operator_to_store.license.value
                      "
                    />
                    <!-- input--error -->
                    <span
                      class="input-required"
                      :class="[
                        tour_operator_to_store.license.error
                          ? 'input--error'
                          : '',
                      ]"
                      v-if="tour_operator_to_store.license.error"
                      >{{
                        tour_operator_to_store.license.error
                      }}</span
                    >
                  </div>
                </div>

                <div class="modal__wrapper--footer modal__wrapper--center">
                  <div class="next__stage--block">
                    <div class="section--link">
                      <button class="next__stage input__button">
                        {{ $t('commands.save') }}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { api } from '../../../boot/axios'
import Multiselect from 'vue-multiselect'
import * as Validator from 'validatorjs'

export default {
  name: 'Account',
  components: { Multiselect },
  data() {
    return {
      users: null,
      tour_operators: null,
      tour_operator: {
        id: {
          value: null,
        },
        access: {
          value: null,
          error: null,
        },
        confirm: {
          value: null,
          error: null,
        },
        message: {
          value: null,
          error: null,
        },
      },
      tour_operator_to_store: {
        tin: {
          value: null,
          error: null,
        },
        bin: {
          value: null,
          error: null,
        },
        organization: {
          value: null,
          error: null,
        },
        email: {
          value: null,
          error: null,
        },
        phone: {
          value: null,
          error: null,
        },
        address: {
          value: null,
          error: null,
        },
        date_of_registration: {
          value: null,
          error: null,
        },
        license: {
          value: null,
          error: null,
        },
        surname: {
          value: null,
          error: null,
        },
        name: {
          value: null,
          error: null,
        },
        patronymic: {
          value: null,
          error: null,
        },
      },
      confirms: [
        {
          label: 'Одобрить',
          value: 2,
        },
        {
          label: 'Отклонить',
          value: 1,
        },
      ],
      accesses: [
        {
          label: 'Заблокировать',
          value: 1,
        },
        {
          label: 'Разблокировать',
          value: 2,
        },
      ],
      category: 'all',
      filterCategories: 'all',
      filters: {
        search: '',
      },
      user: null,
      page: 0,
      totalPassengers: 0,
      loading: false,
      options: {
        itemsPerPage: 5,
        page: 1,
      },
      headers: [
        {
          text: 'ID',
          value: 'id',
        },
        {
          text: 'Название',
          value: 'organization',
        },
        {
          text: 'БИН/ИИН',
          value: 'bin',
        },
        {
          text: 'Email',
          value: 'email',
        },
        {
          text: this.$t('registration.input.phone'),
          value: 'phone',
        },
        {
          text: 'Дата регистрации',
          value: 'created_at',
        },
        {
          text: this.$t('labels.status'),
          value: 'status',
        },
        {
          text: this.$t('labels.action'),
          value: 'action',
          align: 'center',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    filteredlist() {
      return this.users.data.filter((d) => {
        if (
          (d.organization &&
            d.organization
              .toLowerCase()
              .includes(this.filters.search.toLowerCase())) ||
          (d.bin &&
            d.bin.toLowerCase().includes(this.filters.search.toLowerCase())) ||
          (d.iin &&
            d.iin.toLowerCase().includes(this.filters.search.toLowerCase())) ||
          d.email.toLowerCase().includes(this.filters.search.toLowerCase())
        ) {
          return true
        }
      })
    },
  },

  methods: {
    modalShowCheck() {
      this.$modal.show('add__check')
    },
    modalSHideCheck() {
      this.$modal.hide('add__check')
    },
    onEdit(id, item) {
      this.tour_operator.id.value = id
      this.tour_operator.confirm.value = item.email_verified
    },
    onEdit2(id, item) {
      this.tour_operator.id.value = id
      this.tour_operator.access.value = item.access

      console.log(this.tour_operator)
    },
    categoriesTab(category) {
      this.category = category
    },
    filterTab(category) {
      this.filterCategories = category

      this.fetchTourPersons()
    },
    updateUser() {
      api.get('user').then((response) => {
        if (response.data) {
          this.user = response.data
        }
      })
    },
    onUpdateUser3() {
      this.tour_operator_to_store.tin.error = null
      this.tour_operator_to_store.bin.error = null
      this.tour_operator_to_store.surname.error = null
      this.tour_operator_to_store.name.error = null
      this.tour_operator_to_store.patronymic.error = null

      let rules = {
        'surname.value': 'required|string|max:255',
        'name.value': 'required|string|max:255',
        'patronymic.value': 'required|string|max:255',
        'tin.value': 'required|string|max:255',
        'bin.value': 'string|max:255',
      }

      let messages = {
        'required.surname.value': 'Поле Фамилия обязательно для заполнения.',
        'required.name.value': 'Поле Имя обязательно для заполнения.',
        'required.tin.value': 'Поле ИИН обязательно для заполнения.',
        'required.email.value': 'Поле EMAIL обязательно для заполнения.',
        'required.phone.value':
          'Поле Номер телефона обязательно для заполнения.',
      }

      let validation = new Validator(this.tour_operator_to_store, rules, messages)

      if (validation.fails()) {
        for (let key in validation.errors.errors) {
          if (key == 'tin.value')
            this.tour_operator_to_store.tin.error = validation.errors.first(key)
          if (key == 'name.value')
            this.tour_operator_to_store.name.error =
              validation.errors.first(key)
          if (key == 'tin.value')
            this.tour_operator_to_store.tin.error = validation.errors.first(key)
          if (key == 'tin.value')
            this.tour_operator_to_store.tin.error = validation.errors.first(key)
          if (key == 'tin.value')
            this.tour_operator_to_store.tin.error = validation.errors.first(key)
        }

        //	return;
      }

      console.log('coming soon')
    },
    update_tour_operators() {
      api
        .get('users', {
          params: {
            'filter[role]': 'tour-operator-individual,tour-operator-entity',
          },
        })
        .then((response) => {
          if (response.data) {
            this.users = {
              data: [],
            }

            let status

            for (var i = 0; i < response.data.data.length; i++) {
              if (
                response.data.data[i].email_verified &&
                response.data.data[i].email_verified == 1
              )
                status = 'Отклонен'
              if (
                response.data.data[i].email_verified &&
                response.data.data[i].email_verified == 2
              )
                status = 'Одобрен'
              if (!response.data.data[i].email_verified) status = 'Новая заявка'

              if (
                response.data.data[i].email_verified &&
                response.data.data[i].access == 1
              )
                status = 'Заблокирован'

              this.users.data[i] = {
                id: response.data.data[i].id,
                bin: response.data.data[i].bin
                  ? response.data.data[i].bin
                  : response.data.data[i].iin,
                phone: response.data.data[i].phone,
                email: response.data.data[i].email,
                organization: response.data.data[i].bin
                  ? response.data.data[i].organization
                  : response.data.data[i].surname +
                    ' ' +
                    response.data.data[i].name,
                type: response.data.data[i].bin ? 'Юр лицо' : 'Физ лицо',
                created_at: response.data.data[i].created_at,
                status: status,
                access: response.data.data[i].access,
              }
            }
          }
        })
    },
    onUpdateUser() {
      this.tour_operator.confirm.error = null
      this.tour_operator.message.error = null

      let rules = {
        'confirm.value.value': 'required|in:1,2',
        'message.value': 'required_if:confirm.value.value,1',
      }

      let messages = {
        'required.confirm.value.value': 'Поле ФИО обязательно для заполнения.',
      }

      let validation = new Validator(this.tour_operator, rules, messages)

      if (validation.fails()) {
        for (let key in validation.errors.errors) {
          if (key == 'confirm.value')
            this.tour_operator.confirm.error = validation.errors.first(key)
        }

        //	return;
      }

      const formData = new FormData()

      formData.append('_method', 'PUT')
      if (this.tour_operator.access.value)
        formData.append('access', this.tour_operator.access.value.value)
      if (this.tour_operator.confirm.value)
        formData.append('verified', this.tour_operator.confirm.value.value)

      if (this.tour_operator.confirm.value.value == 1) {
        formData.append('message', this.tour_operator.message.value)
      }

      api
        .post('users/' + this.tour_operator.id.value + '/confirm', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          if (response.data.code == 200) {
            this.$notify({
              group: 'foo',
              title: 'Статус туроператора изменен',
              position: 'bottom center',
            })

            this.update_tour_operators()

            this.$modal.hide('company_add')
          }
        })
    },
    onUpdateUser2() {
      this.tour_operator.access.error = null

      let rules = {
        'access.value.value': 'required|in:1,2',
      }

      let messages = {
        'required.access.value.value': 'Поле ФИО обязательно для заполнения.',
      }

      let validation = new Validator(this.tour_operator, rules, messages)

      if (validation.fails()) {
        for (let key in validation.errors.errors) {
          if (key == 'access.value')
            this.tour_operator.access.error = validation.errors.first(key)
        }

        //	return;
      }

      const formData = new FormData()

      formData.append('_method', 'PUT')
      formData.append('access', this.tour_operator.access.value.value)

      api
        .post('users/' + this.tour_operator.id.value + '/confirm', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          if (response.data.code == 200) {
            this.$notify({
              group: 'foo',
              title: 'Статус туроператора изменен',
              position: 'bottom center',
            })

            this.update_tour_operators()

            this.$modal.hide('company_add2')
          }
        })
    },

    fetchTourPersons() {
      api
      .get('users', {
        params: {
          'filter[role]': 'tour-operator-individual,tour-operator-entity',
          'filter[access]': this.filterCategories,
        },
      })
      .then((response) => {
        if (response.data) {
          this.users = {
            data: [],
          }

          let status

          for (var i = 0; i < response.data.data.length; i++) {
            if (
              response.data.data[i].email_verified &&
              response.data.data[i].email_verified == 1
            )
              status = 'Отклонен'
            if (
              response.data.data[i].email_verified &&
              response.data.data[i].email_verified == 2
            )
              status = 'Одобрен'
            if (!response.data.data[i].email_verified) status = 'Новая заявка'

            if (
              response.data.data[i].email_verified &&
              response.data.data[i].access == 1
            )
              status = 'Заблокирован'

            this.users.data[i] = {
              id: response.data.data[i].id,
              bin: response.data.data[i].bin
                ? response.data.data[i].bin
                : response.data.data[i].iin,
              phone: response.data.data[i].phone,
              email: response.data.data[i].email,
              organization: response.data.data[i].bin
                ? response.data.data[i].organization
                : response.data.data[i].surname +
                  ' ' +
                  response.data.data[i].name,
              type: response.data.data[i].bin ? 'Юр лицо' : 'Физ лицо',
              created_at: response.data.data[i].created_at,
              status: status,
              email_verified: response.data.data[i].email_verified,
              access: response.data.data[i].access,
            }
          }
        }
      })
    }
  },
  beforeCreate() {
    if (!localStorage.token) {
      this.$router.push('/' + this.$i18n.locale + '/login')
    }

    api.get('user').then((response) => {
      if (response.data) {
        this.user = response.data
        // console.log(this.user.data.roles[0]);
      }
    })

    api
      .get('users', {
        params: {
          'filter[role]': 'tour-operator-individual,tour-operator-entity',
        },
      })
      .then((response) => {
        if (response.data) {
          this.users = {
            data: [],
          }

          let status

          for (var i = 0; i < response.data.data.length; i++) {
            if (
              response.data.data[i].email_verified &&
              response.data.data[i].email_verified == 1
            )
              status = 'Отклонен'
            if (
              response.data.data[i].email_verified &&
              response.data.data[i].email_verified == 2
            )
              status = 'Одобрен'
            if (!response.data.data[i].email_verified) status = 'Новая заявка'

            if (
              response.data.data[i].email_verified &&
              response.data.data[i].access == 1
            )
              status = 'Заблокирован'

            this.users.data[i] = {
              id: response.data.data[i].id,
              bin: response.data.data[i].bin
                ? response.data.data[i].bin
                : response.data.data[i].iin,
              phone: response.data.data[i].phone,
              email: response.data.data[i].email,
              organization: response.data.data[i].bin
                ? response.data.data[i].organization
                : response.data.data[i].surname +
                  ' ' +
                  response.data.data[i].name,
              type: response.data.data[i].bin ? 'Юр лицо' : 'Физ лицо',
              created_at: response.data.data[i].created_at,
              status: status,
              email_verified: response.data.data[i].email_verified,
              access: response.data.data[i].access,
            }
          }
        }
      })
  },
}
</script>

<style scoped></style>
