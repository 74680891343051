var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',[_c('div',{staticClass:"container"},[_c('div',{staticClass:"header__row"},[_c('div',{staticClass:"header__left"},[_c('router-link',{attrs:{"to":'/' + _vm.$i18n.locale + '/'}},[_c('img',{staticStyle:{"width":"85px","height":"auto"},attrs:{"src":require("../assets/img/logo.svg"),"alt":""}})])],1),_c('div',{staticClass:"header__right"},[_c('div',{staticClass:"header__menu"},[_c('ul',{staticClass:"menu"},[_c('li',[_c('a',{attrs:{"href":'/' + _vm.$i18n.locale + '/'}},[_vm._v(_vm._s(_vm.$t('menu.home')))])]),_c('li',{staticClass:"menu__item--children"},[_c('a',[_vm._v(" "+_vm._s(_vm.$t('menu.subsiding'))+" "),_c('svg',{attrs:{"width":"20","height":"20","viewBox":"0 0 20 20","fill":"none","xmlns":"http://www.w3.org/2000/svg","svg-inline":'',"role":'presentation',"focusable":'false',"tabindex":'-1'}},[_c('path',{attrs:{"d":"M5 7.5l5 5 5-5","stroke":"#0E1839","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}})])]),_c('ul',{staticClass:"menu--children"},[_c('li',[_c('a',{attrs:{"href":'/' +
                      _vm.$i18n.locale +
                      '/account/applications/kids-go-free/create'}},[_vm._v(_vm._s(_vm.$t('menu.kids')))])]),_c('li',[_c('a',{attrs:{"href":'/' + _vm.$i18n.locale + '/subsiding/foreign-tourist'}},[_vm._v(_vm._s(_vm.$t('menu.foreign')))])])])])])]),_c('div',{staticClass:"header__lang"},[_c('div',{staticClass:"header__lang--top"},[_c('div',{staticClass:"header__lang--text"},[_vm._v(" "+_vm._s(_vm.langOn)+" ")]),_c('div',{staticClass:"header__lang--arrow"},[_c('svg',{attrs:{"width":"10","height":"8","viewBox":"0 0 10 8","fill":"none","xmlns":"http://www.w3.org/2000/svg","svg-inline":'',"role":'presentation',"focusable":'false',"tabindex":'-1'}},[_c('path',{attrs:{"d":"M5 7.978L.67.485h8.66L5 7.978z","fill":"#FFCE03"}})])])]),_c('div',{staticClass:"header__lang--list"},[_c('ul',[_c('li',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.setLocale('kz')}}},[_vm._v("Қазақ")])]),_c('li',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.setLocale('ru')}}},[_vm._v("Русский")])]),_c('li',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.setLocale('en')}}},[_vm._v("English")])])])])]),(
            _vm.user &&
            ['tour-operator-entity', 'tour-operator-individual'].includes(
              _vm.user.role.slug
            )
          )?_c('div',{staticClass:"header__login"},[_c('a',{staticClass:"header__login--top",attrs:{"href":'/' + _vm.$i18n.locale + '/account'}},[_c('div',{staticClass:"header__login--name"},[_vm._v(_vm._s(_vm.$t('menu.sign')))]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:({
                content: _vm.$t('menu.sign'),
                class: ['table__tooltip'],
              }),expression:"{\n                content: $t('menu.sign'),\n                class: ['table__tooltip'],\n              }",modifiers:{"bottom":true}}],staticClass:"header__login--icon"},[_c('svg',{staticStyle:{"float":"left"},attrs:{"width":"41","height":"41","viewBox":"0 0 41 41","fill":"none","xmlns":"http://www.w3.org/2000/svg","svg-inline":'',"role":'presentation',"focusable":'false',"tabindex":'-1'}},[_c('path',{attrs:{"d":"M35.133 6.85A19.986 19.986 0 0020.99.998 19.878 19.878 0 006.85 6.851 19.842 19.842 0 00.991 20.98a19.987 19.987 0 005.82 14.091c.271.272.728.027.659-.35a13.75 13.75 0 01-.229-2.502c0-5.7 3.493-10.6 8.453-12.677a.389.389 0 00.122-.638 7.468 7.468 0 01-2.32-5.682C13.63 9.32 16.79 6.15 20.695 6c4.27-.166 7.796 3.257 7.796 7.487a7.469 7.469 0 01-2.325 5.418.389.389 0 00.122.638c4.96 2.077 8.453 6.977 8.453 12.678 0 .854-.078 1.69-.228 2.5-.07.378.387.623.658.351a19.985 19.985 0 005.82-14.092c0-5.337-2.08-10.356-5.858-14.13z","fill":"#0E1839"}})])])])]):(
            _vm.user &&
            [
              'administrator',
              'executor',
              'stationery',
              'accountant' ].includes(_vm.user.role.slug)
          )?_c('div',{staticClass:"header__login"},[_c('a',{staticClass:"header__login--top",attrs:{"href":'/' + _vm.$i18n.locale + '/administration'}},[_c('div',{staticClass:"header__login--name"},[_vm._v(_vm._s(_vm.$t('menu.sign')))]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:({
                content: _vm.$t('menu.sign'),
                class: ['table__tooltip'],
              }),expression:"{\n                content: $t('menu.sign'),\n                class: ['table__tooltip'],\n              }",modifiers:{"bottom":true}}],staticClass:"header__login--icon"},[_c('svg',{staticStyle:{"float":"left"},attrs:{"width":"41","height":"41","viewBox":"0 0 41 41","fill":"none","xmlns":"http://www.w3.org/2000/svg","svg-inline":'',"role":'presentation',"focusable":'false',"tabindex":'-1'}},[_c('path',{attrs:{"d":"M35.133 6.85A19.986 19.986 0 0020.99.998 19.878 19.878 0 006.85 6.851 19.842 19.842 0 00.991 20.98a19.987 19.987 0 005.82 14.091c.271.272.728.027.659-.35a13.75 13.75 0 01-.229-2.502c0-5.7 3.493-10.6 8.453-12.677a.389.389 0 00.122-.638 7.468 7.468 0 01-2.32-5.682C13.63 9.32 16.79 6.15 20.695 6c4.27-.166 7.796 3.257 7.796 7.487a7.469 7.469 0 01-2.325 5.418.389.389 0 00.122.638c4.96 2.077 8.453 6.977 8.453 12.678 0 .854-.078 1.69-.228 2.5-.07.378.387.623.658.351a19.985 19.985 0 005.82-14.092c0-5.337-2.08-10.356-5.858-14.13z","fill":"#0E1839"}})])])])]):_c('div',{staticClass:"header__login"},[_c('a',{staticClass:"header__login--top",attrs:{"href":'/' + _vm.$i18n.locale + '/login'}},[_c('div',{staticClass:"header__login--name"},[_vm._v(_vm._s(_vm.$t('menu.sign')))]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:({
                content: _vm.$t('menu.sign'),
                class: ['table__tooltip'],
              }),expression:"{\n                content: $t('menu.sign'),\n                class: ['table__tooltip'],\n              }",modifiers:{"bottom":true}}],staticClass:"header__login--icon"},[_c('svg',{staticStyle:{"float":"left"},attrs:{"width":"41","height":"41","viewBox":"0 0 41 41","fill":"none","xmlns":"http://www.w3.org/2000/svg","svg-inline":'',"role":'presentation',"focusable":'false',"tabindex":'-1'}},[_c('path',{attrs:{"d":"M35.133 6.85A19.986 19.986 0 0020.99.998 19.878 19.878 0 006.85 6.851 19.842 19.842 0 00.991 20.98a19.987 19.987 0 005.82 14.091c.271.272.728.027.659-.35a13.75 13.75 0 01-.229-2.502c0-5.7 3.493-10.6 8.453-12.677a.389.389 0 00.122-.638 7.468 7.468 0 01-2.32-5.682C13.63 9.32 16.79 6.15 20.695 6c4.27-.166 7.796 3.257 7.796 7.487a7.469 7.469 0 01-2.325 5.418.389.389 0 00.122.638c4.96 2.077 8.453 6.977 8.453 12.678 0 .854-.078 1.69-.228 2.5-.07.378.387.623.658.351a19.985 19.985 0 005.82-14.092c0-5.337-2.08-10.356-5.858-14.13z","fill":"#0E1839"}})])])])]),_c('div',{staticClass:"header__burger"},[_c('div',{staticClass:"header__burger--click",on:{"click":_vm.mobileActive}},[_c('svg',{attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","svg-inline":'',"role":'presentation',"focusable":'false',"tabindex":'-1'}},[_c('path',{attrs:{"d":"M3 6h18M3 12h18M3 18h18","stroke":"#0E1839","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }