<template>
  <div class="wrap__content">
    <div class="content__page">
      <div class="section__subsidizing--banner">
        <div class="container">
          <div class="subsidizing__banner subsidizing__banner--yellow">
            <div class="subsidizing__banner--left">
              <div class="content__page--back">
                <a @click="$router.go(-1)">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19 12H5M12 19l-7-7 7-7" stroke="#0E1839" stroke-linecap="round" stroke-linejoin="round"/></svg>
                  {{ $t('system.back') }}
                </a>
              </div>
              <div
                class="subsidizing__banner--title section__title"
                v-html="$t('subsidizing_foreign.title')"
              ></div>
              <div class="subsidizing__banner--desc">
                {{ $t('subsidizing_foreign.desc') }}
              </div>
              <div class="subsidizing__banner--download">
                <a
                  v-if="$i18n.locale == 'kz'"
                  href="/download/regulations-kz.pdf"
                  download=""
                  >{{ $t('system.download_rules') }}</a
                >
                <a v-else href="/download/regulations.pdf" download="">{{
                  $t('system.download_rules')
                }}</a>
              </div>
              <div class="subsidizing__banner--button section--link">
                <a :href="'/' + $i18n.locale + '/registration'">{{
                  $t('system.apply')
                }}</a>
                <a
                  :href="'/' + $i18n.locale + '/registration'"
                  class="link--active"
                  >{{ $t('system.my_statements') }}</a
                >
              </div>
            </div>
            <div class="subsidizing__banner--right">
              <img src="../assets/img/subsidizing__banner-1.png" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div class="section__stage--subs">
        <div class="stage__subs--item stage__subs--default">
          <div class="container">
            <div class="stage__subs--block">
              <div class="stage__subs--top">
                <div
                  class="section__title"
                  v-html="$t('subsidizing_foreign.stage.stage_1.title')"
                ></div>
              </div>
              <div class="stage__subs--info">
                <div
                  class="stage__subs--desc"
                  v-html="$t('subsidizing_foreign.stage.stage_1.desc')"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="stage__subs--item stage__subs--purple">
          <div class="container">
            <div class="stage__subs--block">
              <div class="stage__subs--top">
                <div
                  class="section__title"
                  v-html="$t('subsidizing_foreign.stage.stage_2.title')"
                ></div>
              </div>
              <div class="stage__subs--info">
                <div
                  class="stage__subs--desc"
                  v-html="$t('subsidizing_foreign.stage.stage_2.desc')"
                ></div>

                <swiper
                  class="stage__subs--conditions stage__subs--slider"
                  :options="conditionsOption"
                >
                  <swiper-slide>
                    <div class="conditions__item">
                      <div class="conditions__item--icon">
                        <svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M57.668 65.04H16.621a8.536 8.536 0 01-8.527-8.528V35.555a8.536 8.536 0 018.527-8.528h14.433a4.191 4.191 0 004.191-4.191 8.536 8.536 0 018.528-8.527h13.895a8.536 8.536 0 018.527 8.527v33.676a8.537 8.537 0 01-8.527 8.527zM16.621 31.362a4.192 4.192 0 00-4.191 4.192v20.957a4.192 4.192 0 004.191 4.191h41.047a4.191 4.191 0 004.191-4.191V22.836a4.191 4.191 0 00-4.191-4.191H43.773a4.191 4.191 0 00-4.192 4.19 8.537 8.537 0 01-8.527 8.528H16.621z" fill="#FFCE03"/><path d="M17.488 31.363a2.168 2.168 0 01-2.168-2.168V9.105a2.168 2.168 0 012.168-2.168h39.313a2.168 2.168 0 012.168 2.168v7.372a2.168 2.168 0 01-4.336 0v-5.204H19.656v17.922a2.168 2.168 0 01-2.168 2.168z" fill="#FFCE03"/></svg>
                      </div>
                      <div class="conditions__item--text">
                        {{ $t('subsidizing_foreign.stage.stage_2.item_1') }}
                      </div>
                    </div>
                  </swiper-slide>
                  <swiper-slide>
                    <div class="conditions__item">
                      <div class="conditions__item--icon">
                        <svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M57.668 65.04H16.621a8.536 8.536 0 01-8.527-8.528V35.555a8.536 8.536 0 018.527-8.528h14.433a4.191 4.191 0 004.191-4.191 8.536 8.536 0 018.528-8.527h13.895a8.536 8.536 0 018.527 8.527v33.676a8.537 8.537 0 01-8.527 8.527zM16.621 31.362a4.192 4.192 0 00-4.191 4.192v20.957a4.192 4.192 0 004.191 4.191h41.047a4.191 4.191 0 004.191-4.191V22.836a4.191 4.191 0 00-4.191-4.191H43.773a4.191 4.191 0 00-4.192 4.19 8.537 8.537 0 01-8.527 8.528H16.621z" fill="#FFCE03"/><path d="M17.488 31.363a2.168 2.168 0 01-2.168-2.168V9.105a2.168 2.168 0 012.168-2.168h39.313a2.168 2.168 0 012.168 2.168v7.372a2.168 2.168 0 01-4.336 0v-5.204H19.656v17.922a2.168 2.168 0 01-2.168 2.168z" fill="#FFCE03"/></svg>
                      </div>
                      <div class="conditions__item--text">
                        {{ $t('subsidizing_foreign.stage.stage_2.item_2') }}
                      </div>
                    </div>
                  </swiper-slide>
                  <swiper-slide>
                    <div class="conditions__item">
                      <div class="conditions__item--icon">
                        <svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M57.668 65.04H16.621a8.536 8.536 0 01-8.527-8.528V35.555a8.536 8.536 0 018.527-8.528h14.433a4.191 4.191 0 004.191-4.191 8.536 8.536 0 018.528-8.527h13.895a8.536 8.536 0 018.527 8.527v33.676a8.537 8.537 0 01-8.527 8.527zM16.621 31.362a4.192 4.192 0 00-4.191 4.192v20.957a4.192 4.192 0 004.191 4.191h41.047a4.191 4.191 0 004.191-4.191V22.836a4.191 4.191 0 00-4.191-4.191H43.773a4.191 4.191 0 00-4.192 4.19 8.537 8.537 0 01-8.527 8.528H16.621z" fill="#FFCE03"/><path d="M17.488 31.363a2.168 2.168 0 01-2.168-2.168V9.105a2.168 2.168 0 012.168-2.168h39.313a2.168 2.168 0 012.168 2.168v7.372a2.168 2.168 0 01-4.336 0v-5.204H19.656v17.922a2.168 2.168 0 01-2.168 2.168z" fill="#FFCE03"/></svg>
                      </div>
                      <div class="conditions__item--text">
                        {{ $t('subsidizing_foreign.stage.stage_2.item_3') }}
                      </div>
                    </div>
                  </swiper-slide>
                  <swiper-slide>
                    <div class="conditions__item">
                      <div class="conditions__item--icon">
                        <svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M57.668 65.04H16.621a8.536 8.536 0 01-8.527-8.528V35.555a8.536 8.536 0 018.527-8.528h14.433a4.191 4.191 0 004.191-4.191 8.536 8.536 0 018.528-8.527h13.895a8.536 8.536 0 018.527 8.527v33.676a8.537 8.537 0 01-8.527 8.527zM16.621 31.362a4.192 4.192 0 00-4.191 4.192v20.957a4.192 4.192 0 004.191 4.191h41.047a4.191 4.191 0 004.191-4.191V22.836a4.191 4.191 0 00-4.191-4.191H43.773a4.191 4.191 0 00-4.192 4.19 8.537 8.537 0 01-8.527 8.528H16.621z" fill="#FFCE03"/><path d="M17.488 31.363a2.168 2.168 0 01-2.168-2.168V9.105a2.168 2.168 0 012.168-2.168h39.313a2.168 2.168 0 012.168 2.168v7.372a2.168 2.168 0 01-4.336 0v-5.204H19.656v17.922a2.168 2.168 0 01-2.168 2.168z" fill="#FFCE03"/></svg>
                      </div>
                      <div class="conditions__item--text">
                        {{ $t('subsidizing_foreign.stage.stage_2.item_4') }}
                      </div>
                    </div>
                  </swiper-slide>
                  <swiper-slide>
                    <div class="conditions__item">
                      <div class="conditions__item--icon">
                        <svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M57.668 65.04H16.621a8.536 8.536 0 01-8.527-8.528V35.555a8.536 8.536 0 018.527-8.528h14.433a4.191 4.191 0 004.191-4.191 8.536 8.536 0 018.528-8.527h13.895a8.536 8.536 0 018.527 8.527v33.676a8.537 8.537 0 01-8.527 8.527zM16.621 31.362a4.192 4.192 0 00-4.191 4.192v20.957a4.192 4.192 0 004.191 4.191h41.047a4.191 4.191 0 004.191-4.191V22.836a4.191 4.191 0 00-4.191-4.191H43.773a4.191 4.191 0 00-4.192 4.19 8.537 8.537 0 01-8.527 8.528H16.621z" fill="#FFCE03"/><path d="M17.488 31.363a2.168 2.168 0 01-2.168-2.168V9.105a2.168 2.168 0 012.168-2.168h39.313a2.168 2.168 0 012.168 2.168v7.372a2.168 2.168 0 01-4.336 0v-5.204H19.656v17.922a2.168 2.168 0 01-2.168 2.168z" fill="#FFCE03"/></svg>
                      </div>
                      <div class="conditions__item--text">
                        {{ $t('subsidizing_foreign.stage.stage_2.item_5') }}
                      </div>
                    </div>
                  </swiper-slide>
                  <swiper-slide>
                    <div class="conditions__item">
                      <div class="conditions__item--icon">
                        <svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M57.668 65.04H16.621a8.536 8.536 0 01-8.527-8.528V35.555a8.536 8.536 0 018.527-8.528h14.433a4.191 4.191 0 004.191-4.191 8.536 8.536 0 018.528-8.527h13.895a8.536 8.536 0 018.527 8.527v33.676a8.537 8.537 0 01-8.527 8.527zM16.621 31.362a4.192 4.192 0 00-4.191 4.192v20.957a4.192 4.192 0 004.191 4.191h41.047a4.191 4.191 0 004.191-4.191V22.836a4.191 4.191 0 00-4.191-4.191H43.773a4.191 4.191 0 00-4.192 4.19 8.537 8.537 0 01-8.527 8.528H16.621z" fill="#FFCE03"/><path d="M17.488 31.363a2.168 2.168 0 01-2.168-2.168V9.105a2.168 2.168 0 012.168-2.168h39.313a2.168 2.168 0 012.168 2.168v7.372a2.168 2.168 0 01-4.336 0v-5.204H19.656v17.922a2.168 2.168 0 01-2.168 2.168z" fill="#FFCE03"/></svg>
                      </div>
                      <div class="conditions__item--text">
                        {{ $t('subsidizing_foreign.stage.stage_2.item_6') }}
                      </div>
                    </div>
                  </swiper-slide>
                </swiper>
                <div class="conditions--arrows">
                  <div
                    class="conditions__arrow conditions__arrow--prev"
                    slot="button-prev"
                  >
                    <svg width="74" height="24" viewBox="0 0 74 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M76 12H5M12 19l-7-7 7-7" stroke="#DCDCF0" stroke-linecap="round" stroke-linejoin="round"/></svg>
                  </div>
                  <div
                    class="conditions__arrow conditions__arrow--next"
                    slot="button-next"
                  >
                    <svg width="74" height="24" viewBox="0 0 74 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M-2 12h71M62 5l7 7-7 7" stroke="#FFCE03" stroke-linecap="round" stroke-linejoin="round"/></svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="stage__subs--item stage__subs--error">
          <div class="container">
            <div class="stage__subs--block">
              <div class="stage__subs--top">
                <div
                  class="section__title"
                  v-html="$t('subsidizing_foreign.stage.stage_3.title')"
                ></div>
              </div>
              <div class="stage__subs--info">
                <div
                  class="stage__subs--desc"
                  v-html="$t('subsidizing_foreign.stage.stage_3.desc')"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="stage__subs--item stage__subs--default">
          <div class="container">
            <div class="stage__subs--block">
              <div class="stage__subs--top">
                <div
                  class="section__title"
                  v-html="$t('subsidizing_foreign.stage.stage_4.title')"
                ></div>
              </div>
              <div class="stage__subs--info">
                <div class="d-none d-xl-block">
                  <div class="obtaining--slider">
                    <div class="stage__subs--obtaining">
                      <div class="obtaining__item">
                        <div class="obtaining__item--top">
                          <div class="obtaining__item--img">
                            <img
                              src="../assets/img/obtaining/obtaining__item-1.png"
                            />
                            <span>
                              <svg width="57" height="113" viewBox="0 0 57 113" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M56.5 111a54.5 54.5 0 010-109" stroke="#FFCE03" stroke-width="4"/></svg>
                            </span>
                          </div>
                          <div class="obtaining__item--stage">
                            {{
                              $t(
                                'subsidizing_foreign.stage.stage_4.stages_1.stage',
                              )
                            }}
                          </div>
                        </div>
                        <div class="obtaining__item--text">
                          {{
                            $t(
                              'subsidizing_foreign.stage.stage_4.stages_1.text',
                            )
                          }}
                        </div>
                      </div>
                      <div class="obtaining__item">
                        <div class="obtaining__item--top">
                          <div class="obtaining__item--img">
                            <img
                              src="../assets/img/obtaining/obtaining__item-2.png"
                            />
                            <span>
                              <svg width="57" height="113" viewBox="0 0 57 113" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M56.5 111a54.5 54.5 0 010-109" stroke="#FFCE03" stroke-width="4"/></svg>
                            </span>
                          </div>
                          <div class="obtaining__item--stage">
                            {{
                              $t(
                                'subsidizing_foreign.stage.stage_4.stages_2.stage',
                              )
                            }}
                          </div>
                        </div>
                        <div class="obtaining__item--text">
                          {{
                            $t(
                              'subsidizing_foreign.stage.stage_4.stages_2.text',
                            )
                          }}
                        </div>
                      </div>
                      <div class="obtaining__item">
                        <div class="obtaining__item--top">
                          <div class="obtaining__item--img">
                            <img
                              src="../assets/img/obtaining/obtaining__item-3.png"
                            />
                            <span>
                              <svg width="57" height="113" viewBox="0 0 57 113" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M56.5 111a54.5 54.5 0 010-109" stroke="#FFCE03" stroke-width="4"/></svg>
                            </span>
                          </div>
                          <div class="obtaining__item--stage">
                            {{
                              $t(
                                'subsidizing_foreign.stage.stage_4.stages_3.stage',
                              )
                            }}
                          </div>
                        </div>
                        <div class="obtaining__item--text">
                          {{
                            $t(
                              'subsidizing_foreign.stage.stage_4.stages_3.text',
                            )
                          }}
                        </div>
                      </div>
                      <div class="obtaining__item">
                        <div class="obtaining__item--top">
                          <div class="obtaining__item--img">
                            <img
                              src="../assets/img/obtaining/obtaining__item-4.png"
                            />
                            <span>
                              <svg width="57" height="113" viewBox="0 0 57 113" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M56.5 111a54.5 54.5 0 010-109" stroke="#FFCE03" stroke-width="4"/></svg>
                            </span>
                          </div>
                          <div class="obtaining__item--stage">
                            {{
                              $t(
                                'subsidizing_foreign.stage.stage_4.stages_4.stage',
                              )
                            }}
                          </div>
                        </div>
                        <div class="obtaining__item--text">
                          {{
                            $t(
                              'subsidizing_foreign.stage.stage_4.stages_4.text',
                            )
                          }}
                        </div>
                      </div>
                      <div class="obtaining__item">
                        <div class="obtaining__item--top">
                          <div class="obtaining__item--img">
                            <img
                              src="../assets/img/obtaining/obtaining__item-5.png"
                            />
                            <span>
                              <svg width="57" height="113" viewBox="0 0 57 113" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M56.5 111a54.5 54.5 0 010-109" stroke="#FFCE03" stroke-width="4"/></svg>
                            </span>
                          </div>
                          <div class="obtaining__item--stage">
                            {{
                              $t(
                                'subsidizing_foreign.stage.stage_4.stages_5.stage',
                              )
                            }}
                          </div>
                        </div>
                        <div class="obtaining__item--text">
                          {{
                            $t(
                              'subsidizing_foreign.stage.stage_4.stages_5.text',
                            )
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-block d-xl-none">
                  <swiper class="obtaining--slider" :options="obtainingOption">
                    <swiper-slide class="obtaining__item">
                      <div class="obtaining__item--top">
                        <div class="obtaining__item--img">
                          <img
                            src="../assets/img/obtaining/obtaining__item-1.png"
                          />
                          <span>
                            <svg width="57" height="113" viewBox="0 0 57 113" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M56.5 111a54.5 54.5 0 010-109" stroke="#FFCE03" stroke-width="4"/></svg>
                          </span>
                        </div>
                        <div class="obtaining__item--stage">
                          {{
                            $t(
                              'subsidizing_foreign.stage.stage_4.stages_1.stage',
                            )
                          }}
                        </div>
                      </div>
                      <div class="obtaining__item--text">
                        {{
                          $t('subsidizing_foreign.stage.stage_4.stages_1.text')
                        }}
                      </div>
                    </swiper-slide>
                    <swiper-slide class="obtaining__item">
                      <div class="obtaining__item--top">
                        <div class="obtaining__item--img">
                          <img
                            src="../assets/img/obtaining/obtaining__item-2.png"
                          />
                          <span>
                            <svg width="57" height="113" viewBox="0 0 57 113" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M56.5 111a54.5 54.5 0 010-109" stroke="#FFCE03" stroke-width="4"/></svg>
                          </span>
                        </div>
                        <div class="obtaining__item--stage">
                          {{
                            $t(
                              'subsidizing_foreign.stage.stage_4.stages_2.stage',
                            )
                          }}
                        </div>
                      </div>
                      <div class="obtaining__item--text">
                        {{
                          $t('subsidizing_foreign.stage.stage_4.stages_2.text')
                        }}
                      </div>
                    </swiper-slide>
                    <swiper-slide class="obtaining__item">
                      <div class="obtaining__item--top">
                        <div class="obtaining__item--img">
                          <img
                            src="../assets/img/obtaining/obtaining__item-3.png"
                          />
                          <span>
                            <svg width="57" height="113" viewBox="0 0 57 113" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M56.5 111a54.5 54.5 0 010-109" stroke="#FFCE03" stroke-width="4"/></svg>
                          </span>
                        </div>
                        <div class="obtaining__item--stage">
                          {{
                            $t(
                              'subsidizing_foreign.stage.stage_4.stages_3.stage',
                            )
                          }}
                        </div>
                      </div>
                      <div class="obtaining__item--text">
                        {{
                          $t('subsidizing_foreign.stage.stage_4.stages_3.text')
                        }}
                      </div>
                    </swiper-slide>
                    <swiper-slide class="obtaining__item">
                      <div class="obtaining__item--top">
                        <div class="obtaining__item--img">
                          <img
                            src="../assets/img/obtaining/obtaining__item-4.png"
                          />
                          <span>
                            <svg width="57" height="113" viewBox="0 0 57 113" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M56.5 111a54.5 54.5 0 010-109" stroke="#FFCE03" stroke-width="4"/></svg>
                          </span>
                        </div>
                        <div class="obtaining__item--stage">
                          {{
                            $t(
                              'subsidizing_foreign.stage.stage_4.stages_4.stage',
                            )
                          }}
                        </div>
                      </div>
                      <div class="obtaining__item--text">
                        {{
                          $t('subsidizing_foreign.stage.stage_4.stages_4.text')
                        }}
                      </div>
                    </swiper-slide>
                    <swiper-slide class="obtaining__item">
                      <div class="obtaining__item--top">
                        <div class="obtaining__item--img">
                          <img
                            src="../assets/img/obtaining/obtaining__item-5.png"
                          />
                          <span>
                            <svg width="57" height="113" viewBox="0 0 57 113" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M56.5 111a54.5 54.5 0 010-109" stroke="#FFCE03" stroke-width="4"/></svg>
                          </span>
                        </div>
                        <div class="obtaining__item--stage">
                          {{
                            $t(
                              'subsidizing_foreign.stage.stage_4.stages_5.stage',
                            )
                          }}
                        </div>
                      </div>
                      <div class="obtaining__item--text">
                        {{
                          $t('subsidizing_foreign.stage.stage_4.stages_5.text')
                        }}
                      </div>
                    </swiper-slide>
                  </swiper>
                  <div class="conditions--arrows">
                    <div
                      class="conditions__arrow obtaining__arrow--prev"
                      slot="button-prev"
                    >
                      <svg width="74" height="24" viewBox="0 0 74 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M76 12H5M12 19l-7-7 7-7" stroke="#DCDCF0" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </div>
                    <div
                      class="conditions__arrow obtaining__arrow--next"
                      slot="button-next"
                    >
                      <svg width="74" height="24" viewBox="0 0 74 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M-2 12h71M62 5l7 7-7 7" stroke="#FFCE03" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'

export default {
  name: 'SubsidingForeign',
  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      conditionsOption: {
        slidesPerView: 2,
        spaceBetween: 5,
        navigation: {
          prevEl: '.conditions__arrow--prev',
          nextEl: '.conditions__arrow--next',
        },
        breakpoints: {
          320: {
            slidesPerView: 1,
          },
          520: {
            slidesPerView: 1,
          },
          992: {
            slidesPerView: 1.5,
          },
          1024: {
            slidesPerView: 2,
          },
        },
      },
      obtainingOption: {
        slidesPerView: 3,
        spaceBetween: 0,
        navigation: {
          prevEl: '.obtaining__arrow--prev',
          nextEl: '.obtaining__arrow--next',
        },
        breakpoints: {
          320: {
            slidesPerView: 1,
          },
          520: {
            slidesPerView: 1.2,
          },
          645: {
            slidesPerView: 2,
          },
          992: {
            slidesPerView: 3,
          },
          1024: {
            slidesPerView: 3,
          },
        },
      },
    }
  },
  head: {
    title() {
      return {
        inner:
          this.$i18n.locale == 'kz'
            ? 'Шетелдік турист үшін субсидиялау | «eQonaq» мамандандырылған ақпараттық жүйесі'
            : this.$i18n.locale == 'ru'
              ? 'Субсидирование за иностранного туриста | Специализированная информационная система «eQonaq»'
              : 'Subsidizing for a foreign tourist  | Specialized information system "eQonaq"',
      }
    },
    meta: [{ name: 'description', content: 'My description' }],
  },
}
</script>

<style scoped></style>
