<template>
  <div class="header__mobile">
    <div class="header__mobile--top">
      <div class="header__mobile--logo">
        <a :href="'/' + $i18n.locale + '/'">
          <img src="../assets/img/logo.svg" alt="" />
        </a>
      </div>
      <div class="header__mobile--close">
        <div class="mobile__close--click" @click="mobileActive">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M18 6L6 18M6 6l12 12" stroke="#0E1839" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
        </div>
      </div>
    </div>
    <div class="header__mobile--body">
      <div class="header__mobile--menu">
        <ul class="menu">
          <li>
            <a :href="'/' + $i18n.locale + '/'">{{ $t('menu.home') }}</a>
          </li>
          <!--          <li><a :href="'/'+ $i18n.locale +'/about'">{{ $t('menu.about') }}</a></li>-->
          <li class="menu__item--children">
            <!-- menu__item--active -->
            <a @click="activeMenu = !activeMenu">
              <!--  :href="'/'+ $i18n.locale +'/'" -->
              {{ $t('menu.subsiding') }}
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M5 7.5l5 5 5-5" stroke="#0E1839" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
            </a>
            <ul class="menu--children" :class="{ 'd-block': activeMenu }">
              <li>
                <a :href="'/' + $i18n.locale + '/subsiding/kids-go-free'">{{
                  $t('menu.kids')
                }}</a>
              </li>
              <li>
                <a :href="'/' + $i18n.locale + '/subsiding/foreign-tourist'">{{
                  $t('menu.foreign')
                }}</a>
              </li>
            </ul>
          </li>
          <!--          <li><a :href="'/'+ $i18n.locale">{{ $t('menu.consultation') }}</a></li>-->
        </ul>
      </div>
    </div>
    <div class="header__mobile--footer">
      <!--
      <div class="header__mobile--apps">
        <a href="#">
          <img src="../assets/img/gpm.png">
        </a>
        <a href="#">
          <img src="../assets/img/app.png">
        </a>
      </div>
      -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderMobile',
  data: function () {
    return {
      mobileClick: true,
      activeMenu: false,
    }
  },
  methods: {
    mobileActive() {
      this.mobileClick = false
      // this.$emit("mobileActive",this.mobileClick);
      document.body.classList.remove('mobile__active')
    },
  },
}
</script>

<style scoped></style>
