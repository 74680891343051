<template>
  <div class="wrap__content">
    <div class="content__page content__page--margintop">
      <div class="container">
        <div class="row">
          <div class="col-xl-6 offset-xl-3">
            <div class="content__page--back">
              <a :href="'/' + $i18n.locale + '/'">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19 12H5M12 19l-7-7 7-7" stroke="#0E1839" stroke-linecap="round" stroke-linejoin="round"/></svg>
                {{ $t('system.back') }}
              </a>
            </div>

            <div class="content__title--block">
              <div class="content__title section__title">
                <strong>{{ $t('system.not_found') }}</strong>
              </div>
            </div>

            <div class="page__404">
              <div class="page__404--img">
                <img src="../assets/img/404-img.png" />
              </div>
              <div class="page__404--link">
                <div class="section--link">
                  <a :href="'/' + $i18n.locale + '/'">{{
                    $t('system.to_main')
                  }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  head: {
    title() {
      return {
        inner: '404!',
      }
    },
    meta: [{ name: 'description', content: 'My description' }],
  },
}
</script>

<style scoped></style>
