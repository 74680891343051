<template>
  <div
    class="wrap__content"
    v-bind:class="{ 'wrap__content--placeholder': !user || !application }"
  >
    <div class="page__placeholder" v-if="!user || !application">
      <div class="form__block--placeholder">
        <div class="form__placeholder--circle">
          <div class="placeholder__circle">
            <svg width="73" height="73" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><circle cx="36.5" cy="36" r="24" stroke="#FFF7E1" stroke-width="2"/><g filter="url(#filter0_d_22_5658)"><path d="M16.16 23A24.208 24.208 0 0012 36.605C12 50.078 22.969 61 36.5 61S61 50.078 61 36.605c0-1.736-.182-3.43-.528-5.064" stroke="#FFCC47" stroke-width="4" stroke-linecap="round"/></g><defs><filter id="filter0_d_22_5658" x="5" y="16" width="63" height="52" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset/><feGaussianBlur stdDeviation="2.5"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix values="0 0 0 0 1 0 0 0 0 0.722826 0 0 0 0 0 0 0 0 0.5 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_22_5658"/><feBlend in="SourceGraphic" in2="effect1_dropShadow_22_5658" result="shape"/></filter></defs></svg>
          </div>
        </div>
        <div class="form__placeholder--logo">
          <img src="../../assets/img/logo.svg" alt="" />
        </div>
      </div>
    </div>
    <div class="content__page--block" v-if="user && application">
      <notifications group="foo" position="bottom center" />

      <div class="content__page content__page--margintop">
        <div class="container">
          <div class="content__page--back">
            <a :href="'/' + $i18n.locale + '/account/'">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19 12H5M12 19l-7-7 7-7" stroke="#0E1839" stroke-linecap="round" stroke-linejoin="round"/></svg>
              {{ $t('system.back') }}
            </a>
          </div>

          <div class="content__title--block">
            <div class="section__title--mini">
              Заявление на субсидирование затрат туроператоров в сфере въездного
              туризма
            </div>
            <div class="content__title section__title" v-if="application">
              <strong
                >за несовершеннолетних пассажиров №{{
                  application.data.id
                }}</strong
              >
            </div>
          </div>

          <div class="add_tourist--section add_tourist--section-transition">
            <div class="add_tourist--block">
              <div class="add_tourist__section--title">Данные Заявителя</div>
              <div class="table__block--style table__block--blue">
                <v-data-table
                  :headers="headersApplicant"
                  :items="DataApplicant"
                  :loading="loading"
                  :options.sync="options"
                  hide-default-footer
                >
                  <template v-slot:item.reference="{ item }">
                    <a
                      class="tb__table--views"
                      :href="item.reference_src"
                      data-fancybox="reference"
                    >
                      Посмотреть
                    </a>
                  </template>
                  <!--
									<template v-slot:item.requisites="{item}">
										<a class="tb__table--views"
											 :href="item.requisites_src"
											 data-fancybox="requisites"
										>
											Посмотреть
										</a>
									</template>
								-->
                </v-data-table>
              </div>
            </div>
            <div class="add_tourist--block">
              <div class="add_tourist__section--title">
                Данные иностранного туриста
              </div>
              <div class="table__block--style table__block--blue">
                <v-data-table
                  :headers="headers"
                  :items="userDataSave"
                  :loading="loading"
                  :options.sync="options"
                  hide-default-footer
                >
                  <template v-slot:item.action="{ item }">
                    <a
                      class="tb__table--btn table__btn--status tb__table--success"
                      @click="changeTouristStatus('success')"
                      :data-id="item.id"
                      :class="[
                        tourist.status && tourist.status == 'success'
                          ? 'opacity'
                          : '',
                      ]"
                      v-tooltip.bottom="{
                        content: 'Одобрить',
                        class: ['table__tooltip'],
                      }"
                    >
                      <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#33AD28" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </a>
                    <a
                      class="tb__table--btn table__btn--status tb__table--close"
                      @click="changeTouristStatus('fail')"
                      :data-id="item.id"
                      :class="[
                        tourist.status && tourist.status == 'fail'
                          ? 'opacity'
                          : '',
                      ]"
                      v-tooltip.bottom="{
                        content: 'Отклонить',
                        class: ['table__tooltip'],
                      }"
                    >
                      <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.17 16.42l2.935-3.126 2.935-3.125m0 6.25l-5.87-6.25M8.355 4.826a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.992 0 3.848 3.848 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.848 3.848 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#F3574D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </a>
                  </template>
                </v-data-table>
              </div>
            </div>
          </div>

          <div class="add_tourist--section">
            <div class="add_tourist__section--title">
              Подтверждающие документы
            </div>

            <div class="add_tourist--filelist">
              <div class="fileitem--col">
                <div
                  class="add_tourist--fileitem"
                  :class="fileDocuments1.fileClass"
                >
                  <div class="fileitem__block">
                    <div class="fileitem__block--title">
                      Копия документа, удостоверяющего личность иностранного
                      туриста
                    </div>

                    <div
                      class="fileitem__block--file fileitem__block--view"
                      v-if="fileDocuments1.fileName"
                    >
                      <div
                        class="fileitem__block--wrap"
                        :href="fileDocuments1.src"
                        data-fancybox="fileDocuments1"
                      >
                        <div
                          class="fileitem__block--icon"
                          v-if="
                            fileDocuments1.fileClass == 'add_tourist--success'
                          "
                        >
                          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div
                          class="fileitem__block--icon"
                          v-else-if="
                            fileDocuments1.fileClass == 'add_tourist--error'
                          "
                        >
                          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.17 16.42l2.935-3.126 2.935-3.125m0 6.25l-5.87-6.25M8.355 4.826a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.992 0 3.848 3.848 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.848 3.848 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="fileitem__block--icon" v-else>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 15a3 3 0 100-6 3 3 0 000 6z" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="fileitem__block--text">Просмотр</div>
                      </div>
                    </div>
                    <div class="fileitem__block--file" v-else>
                      <div class="fileitem__block--wrap">
                        <div class="fileitem__block--text">Нет документа</div>
                      </div>
                    </div>
                  </div>
                  <span
                    class="fileitem__block--status"
                    v-if="!fileDocuments1.fileClass"
                  >
                    <a
                      class="tb__table--btn table__btn--status tb__table--success"
                      @click="changeStatusFileDocuments1('success')"
                      v-tooltip.bottom="{
                        content: 'Одобрить',
                        class: ['table__tooltip'],
                      }"
                    >
                      <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#33AD28" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </a>
                    <a
                      class="tb__table--btn table__btn--status tb__table--close"
                      @click="changeStatusFileDocuments1('fail')"
                      v-tooltip.bottom="{
                        content: 'Отклонить',
                        class: ['table__tooltip'],
                      }"
                    >
                      <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.17 16.42l2.935-3.126 2.935-3.125m0 6.25l-5.87-6.25M8.355 4.826a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.992 0 3.848 3.848 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.848 3.848 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#F3574D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </a>
                  </span>
                  <span class="fileitem__block--status" v-else>
                    <a class="btn__cancel" @click="client_null(fileDocuments1)">
                      Отмена
                    </a>
                  </span>
                </div>
              </div>
              <div class="fileitem--col">
                <div
                  class="add_tourist--fileitem"
                  :class="fileDocuments2.fileClass"
                >
                  <div class="fileitem__block">
                    <div class="fileitem__block--title">
                      Договор на туристское обслуживание
                    </div>

                    <div
                      class="fileitem__block--file fileitem__block--view"
                      v-if="fileDocuments2.fileName"
                    >
                      <div
                        class="fileitem__block--wrap"
                        :href="fileDocuments2.src"
                        data-fancybox="fileDocuments2"
                      >
                        <div
                          class="fileitem__block--icon"
                          v-if="
                            fileDocuments2.fileClass == 'add_tourist--success'
                          "
                        >
                          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div
                          class="fileitem__block--icon"
                          v-else-if="
                            fileDocuments2.fileClass == 'add_tourist--error'
                          "
                        >
                          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.17 16.42l2.935-3.126 2.935-3.125m0 6.25l-5.87-6.25M8.355 4.826a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.992 0 3.848 3.848 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.848 3.848 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="fileitem__block--icon" v-else>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 15a3 3 0 100-6 3 3 0 000 6z" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="fileitem__block--text">Просмотр</div>
                      </div>
                    </div>
                    <div class="fileitem__block--file" v-else>
                      <div class="fileitem__block--wrap">
                        <div class="fileitem__block--text">Нет документа</div>
                      </div>
                    </div>
                  </div>
                  <span
                    class="fileitem__block--status"
                    v-if="!fileDocuments2.fileClass"
                  >
                    <a
                      class="tb__table--btn table__btn--status tb__table--success"
                      @click="changeStatusFileDocuments2('success')"
                      v-tooltip.bottom="{
                        content: 'Одобрить',
                        class: ['table__tooltip'],
                      }"
                    >
                      <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#33AD28" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </a>
                    <a
                      class="tb__table--btn table__btn--status tb__table--close"
                      @click="changeStatusFileDocuments2('fail')"
                      v-tooltip.bottom="{
                        content: 'Отклонить',
                        class: ['table__tooltip'],
                      }"
                    >
                      <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.17 16.42l2.935-3.126 2.935-3.125m0 6.25l-5.87-6.25M8.355 4.826a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.992 0 3.848 3.848 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.848 3.848 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#F3574D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </a>
                  </span>
                  <span class="fileitem__block--status" v-else>
                    <a class="btn__cancel" @click="client_null(fileDocuments2)">
                      Отмена
                    </a>
                  </span>
                </div>
              </div>
              <div class="fileitem--col">
                <div
                  class="add_tourist--fileitem"
                  :class="fileDocuments3.fileClass"
                >
                  <div class="fileitem__block">
                    <div class="fileitem__block--title">
                      Документ, подтверждающий оплату туристского продукта по
                      Договору
                    </div>

                    <div
                      class="fileitem__block--file fileitem__block--view"
                      v-if="fileDocuments3.fileName"
                    >
                      <div
                        class="fileitem__block--wrap"
                        :href="fileDocuments3.src"
                        data-fancybox="fileDocuments3"
                      >
                        <div
                          class="fileitem__block--icon"
                          v-if="
                            fileDocuments3.fileClass == 'add_tourist--success'
                          "
                        >
                          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div
                          class="fileitem__block--icon"
                          v-else-if="
                            fileDocuments3.fileClass == 'add_tourist--error'
                          "
                        >
                          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.17 16.42l2.935-3.126 2.935-3.125m0 6.25l-5.87-6.25M8.355 4.826a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.992 0 3.848 3.848 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.848 3.848 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="fileitem__block--icon" v-else>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 15a3 3 0 100-6 3 3 0 000 6z" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="fileitem__block--text">Просмотр</div>
                      </div>
                    </div>
                    <div class="fileitem__block--file" v-else>
                      <div class="fileitem__block--wrap">
                        <div class="fileitem__block--text">Нет документа</div>
                      </div>
                    </div>
                  </div>
                  <span
                    class="fileitem__block--status"
                    v-if="!fileDocuments3.fileClass"
                  >
                    <a
                      class="tb__table--btn table__btn--status tb__table--success"
                      @click="changeStatusFileDocuments3('success')"
                      v-tooltip.bottom="{
                        content: 'Одобрить',
                        class: ['table__tooltip'],
                      }"
                    >
                      <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#33AD28" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </a>
                    <a
                      class="tb__table--btn table__btn--status tb__table--close"
                      @click="changeStatusFileDocuments3('fail')"
                      v-tooltip.bottom="{
                        content: 'Отклонить',
                        class: ['table__tooltip'],
                      }"
                    >
                      <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.17 16.42l2.935-3.126 2.935-3.125m0 6.25l-5.87-6.25M8.355 4.826a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.992 0 3.848 3.848 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.848 3.848 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#F3574D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </a>
                  </span>
                  <span class="fileitem__block--status" v-else>
                    <a class="btn__cancel" @click="client_null(fileDocuments3)">
                      Отмена
                    </a>
                  </span>
                </div>
              </div>

              <div class="fileitem--col">
                <div
                  class="add_tourist--fileitem"
                  :class="fileDocuments4.fileClass"
                >
                  <div class="fileitem__block">
                    <div class="fileitem__block--title">
                      Копия нотариально засвидетельственной доверенности (при
                      необходимости)
                    </div>

                    <div
                      class="fileitem__block--file fileitem__block--view"
                      v-if="fileDocuments4.fileName"
                    >
                      <div
                        class="fileitem__block--wrap"
                        :href="fileDocuments4.src"
                        data-fancybox="fileDocuments4"
                      >
                        <div
                          class="fileitem__block--icon"
                          v-if="
                            fileDocuments4.fileClass == 'add_tourist--success'
                          "
                        >
                          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div
                          class="fileitem__block--icon"
                          v-else-if="
                            fileDocuments4.fileClass == 'add_tourist--error'
                          "
                        >
                          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.17 16.42l2.935-3.126 2.935-3.125m0 6.25l-5.87-6.25M8.355 4.826a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.992 0 3.848 3.848 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.848 3.848 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="fileitem__block--icon" v-else>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 15a3 3 0 100-6 3 3 0 000 6z" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="fileitem__block--text">Просмотр</div>
                      </div>
                    </div>
                    <div class="fileitem__block--file" v-else>
                      <div class="fileitem__block--wrap">
                        <div class="fileitem__block--text">Нет документа</div>
                      </div>
                    </div>
                  </div>
                  <span
                    class="fileitem__block--status"
                    v-if="!fileDocuments4.fileClass && fileDocuments4.src"
                  >
                    <a
                      class="tb__table--btn table__btn--status tb__table--success"
                      @click="changeStatusFileDocuments4('success')"
                      v-tooltip.bottom="{
                        content: 'Одобрить',
                        class: ['table__tooltip'],
                      }"
                    >
                      <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#33AD28" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </a>
                    <a
                      class="tb__table--btn table__btn--status tb__table--close"
                      @click="changeStatusFileDocuments4('fail')"
                      v-tooltip.bottom="{
                        content: 'Отклонить',
                        class: ['table__tooltip'],
                      }"
                    >
                      <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.17 16.42l2.935-3.126 2.935-3.125m0 6.25l-5.87-6.25M8.355 4.826a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.992 0 3.848 3.848 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.848 3.848 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#F3574D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </a>
                  </span>
                  <span class="fileitem__block--status" v-else>
                    <a class="btn__cancel" @click="client_null(fileDocuments4)">
                      Отмена
                    </a>
                  </span>
                </div>
              </div>
              <div class="fileitem--col">
                <div
                  class="add_tourist--fileitem"
                  :class="fileDocuments5.fileClass"
                >
                  <div class="fileitem__block">
                    <div class="fileitem__block--title">
                      Копии билета или электронного билета, маршрутной квитанции и пассажирского купона или справки-подтверждения об использовании билета авиакомпании на несовершеннолетнего пассажира
                    </div>
                    <div
                      class="fileitem__block--file fileitem__block--view"
                      v-if="fileDocuments5.fileName"
                    >
                      <div
                        class="fileitem__block--wrap"
                        :href="fileDocuments5.src"
                        data-fancybox="fileDocuments5"
                      >
                        <div
                          class="fileitem__block--icon"
                          v-if="
                            fileDocuments5.fileClass == 'add_tourist--success'
                          "
                        >
                          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div
                          class="fileitem__block--icon"
                          v-else-if="
                            fileDocuments5.fileClass == 'add_tourist--error'
                          "
                        >
                          <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.17 16.42l2.935-3.126 2.935-3.125m0 6.25l-5.87-6.25M8.355 4.826a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.992 0 3.848 3.848 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.848 3.848 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="fileitem__block--icon" v-else>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 15a3 3 0 100-6 3 3 0 000 6z" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        </div>
                        <div class="fileitem__block--text">Просмотр</div>
                      </div>
                    </div>
                    <div class="fileitem__block--file" v-else>
                      <div class="fileitem__block--text">Нет документа</div>
                    </div>
                  </div>
                  <span
                    class="fileitem__block--status"
                    v-if="!fileDocuments5.fileClass"
                  >
                    <a
                      class="tb__table--btn table__btn--status tb__table--success"
                      @click="changeStatusFileDocuments5('success')"
                      v-tooltip.bottom="{
                        content: 'Одобрить',
                        class: ['table__tooltip'],
                      }"
                    >
                      <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.124 13.5l2.25 2.25 4.5-4.5m-8.06-5.966a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.991 0 3.849 3.849 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.847 3.847 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#33AD28" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </a>
                    <a
                      class="tb__table--btn table__btn--status tb__table--close"
                      @click="changeStatusFileDocuments5('fail')"
                      v-tooltip.bottom="{
                        content: 'Отклонить',
                        class: ['table__tooltip'],
                      }"
                    >
                      <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M10.17 16.42l2.935-3.126 2.935-3.125m0 6.25l-5.87-6.25M8.355 4.826a3.848 3.848 0 002.19-.907 3.848 3.848 0 014.992 0 3.848 3.848 0 002.19.907 3.848 3.848 0 013.53 3.53c.064.807.382 1.574.907 2.19a3.848 3.848 0 010 4.992 3.848 3.848 0 00-.907 2.19 3.848 3.848 0 01-3.53 3.53 3.848 3.848 0 00-2.19.907 3.848 3.848 0 01-4.992 0 3.847 3.847 0 00-2.19-.907 3.848 3.848 0 01-3.53-3.53 3.848 3.848 0 00-.907-2.19 3.848 3.848 0 010-4.992 3.848 3.848 0 00.907-2.19 3.848 3.848 0 013.53-3.53z" stroke="#F3574D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    </a>
                  </span>
                  <span class="fileitem__block--status" v-else>
                    <a class="btn__cancel" @click="client_null(fileDocuments5)">
                      Отмена
                    </a>
                  </span>
                </div>
              </div>

              <div class="fileitem__archive">
                <a class="archive--link">
                  <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19 14.563l-6.146 6.243A3.984 3.984 0 0110.014 22a3.984 3.984 0 01-2.838-1.194A4.11 4.11 0 016 17.922a4.11 4.11 0 011.176-2.883l6.146-6.243A2.656 2.656 0 0115.215 8c.71 0 1.39.286 1.892.796a2.74 2.74 0 01.784 1.923 2.74 2.74 0 01-.784 1.922l-6.152 6.242a1.328 1.328 0 01-1.893 0 1.37 1.37 0 010-1.922l5.678-5.76" stroke="#6B69DF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                  Скачать все файлы архивом
                </a>
              </div>
              <div class="form__block--line fileitem__comment">
                <div class="form__block--input">
                  <textarea
                    placeholder="Написать коментарий..."
                    :class="{ 'textarea--active': commentMessage }"
                    v-model="commentMessage"
                    class="input-linck textarea-linck input-linck--white"
                  ></textarea>
                </div>
              </div>
            </div>

            <div class="form__block--history">
              <div class="form__block--title">История всех действий</div>
              <div class="row">
                <div
                  class="col-lg-12"
                  v-for="route in application.data.routes"
                  v-if="route.message"
                >
                  <div class="history__item history__item--new">
                    <div class="history__item--date">
                      <span
                        >{{ route.created_at.substr(8, 2) }}.{{
                          route.created_at.substr(5, 2)
                        }}.{{ route.created_at.substr(0, 4) }}</span
                      >
                      <span class="history__item--title">Комментарий</span>
                    </div>
                    <div class="history__item--text">{{ route.message }}</div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="history__item">
                    <div class="history__item--date">
                      <span
                        >{{ application.data.created_at.substr(8, 2) }}.{{
                          application.data.created_at.substr(5, 2)
                        }}.{{ application.data.created_at.substr(0, 4) }}</span
                      >
                    </div>
                    <div class="history__item--text">
                      Отправка заявки №{{ application.data.id }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="modal__wrapper--footer">
              <div
                class="section--link"
                @click="onRetrieveApplication"
                v-if="
                  (application.data.status.slug == 'sent-by' ||
                    application.data.status.slug == 'registered' ||
                    application.data.status.slug == 'resending') &&
                  commentMessage &&
                  tourist.status &&
                  fileDocuments1.status &&
                  fileDocuments2.status &&
                  fileDocuments3.status &&
                  fileDocuments4.status &&
                  fileDocuments5.status
                "
              >
                <button class="next__stage input__button">
                  Вернуть заявителю с комментариями
                </button>
              </div>
              <!--
							<div class="section--link" v-else-if="fileDocuments3.fileClass == 'add_tourist--error'">
								<button class="next__stage input__button" @click="$modal.show('statement__error')">Отозвать заявление</button>
							</div>
							-->
              <!--
							<div class="section--link">
								<button class="next__stage input__button" @click="$modal.show('statement__error')">Отозвать заявление</button>
							</div>
							-->
              <div class="section--link" v-else>
                <button
                  class="next__stage input__button"
                  @click="$modal.show('statement__success')"
                  v-if="
                    application.data.status.slug == 'send-by' &&
                    user.data.role.slug == 'stationery'
                  "
                >
                  Зарегистрировать заявление
                </button>
                <button
                  class="next__stage input__button"
                  @click="onConfirm"
                  v-if="
                    application.data.status.slug == 'registered' &&
                    user.data.role.slug == 'accountant' &&
                    tourist.status &&
                    fileDocuments1.status &&
                    fileDocuments2.status &&
                    fileDocuments3.status &&
                    fileDocuments4.status &&
                    fileDocuments5.status
                  "
                >
                  Одобрить
                </button>
                <button
                  class="next__stage input__button btn--error"
                  v-if="
                    application.data.status.slug == 'registered' &&
                    user.data.role.slug == 'accountant'
                  "
                  @click="$modal.show('statement__refuse')"
                >
                  Отказать
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal
      class="modal__block modal__block--middle modal__block--statement"
      name="statement__refuse"
      :width="680"
      :min-width="680"
      height="auto"
    >
      <div class="modal__block--wrapper">
        <div
          class="modal__block--close"
          @click="$modal.hide('statement__refuse')"
        >
          <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M31.5 10.5l-21 21M10.5 10.5l21 21" stroke="#0E1839" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
        </div>
        <div class="modal__wrapper">
          <div class="modal__title">Отказать</div>
          <div class="modal__content">
            <div class="modal__form">
              <div class="form__block--line">
                <div class="form__block--input">
                  <div class="form__block--title">Комментарии</div>
                  <textarea
                    class="input-linck textarea-linck textarea--active textarea-refuse"
                    v-model="refuse.message"
                  ></textarea>
                </div>
              </div>

              <div class="modal__wrapper--footer modal__wrapper--center">
                <button class="next__stage input__button" @click="onRefuse">
                  {{ $t('commands.save') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      class="modal__block modal__block--middle modal__block--statement"
      name="statement__success"
      :width="680"
      :min-width="680"
      height="auto"
    >
      <div class="modal__block--wrapper">
        <div
          class="modal__block--close"
          @click="$modal.hide('statement__success')"
        >
          <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M31.5 10.5l-21 21M10.5 10.5l21 21" stroke="#0E1839" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
        </div>
        <div class="modal__wrapper">
          <div class="modal__title">Зарегистрировать заявление</div>
          <div class="modal__content">
            <div class="modal__form">
              <div class="modal__statement">
                <div class="modal__statement--text">
                  На субсидирование затрат туроператоров в сфере въездного
                  туризма<br />
                  <a
                    href="#"
                    target="_blank"
                    class="modal__statement--link"
                    v-if="application"
                    >за каждого иностранного туриста №{{
                      application.data.id
                    }}</a
                  >
                </div>
              </div>

              <div class="modal__wrapper--footer modal__wrapper--center">
                <button
                  class="next__stage input__button"
                  @click="onRegisterApplication"
                >
                  Зарегистрировать заявление
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      class="modal__block modal__block--middle modal__block--statement"
      name="statement__error"
      :width="680"
      :min-width="680"
      height="auto"
    >
      <div class="modal__block--wrapper">
        <div
          class="modal__block--close"
          @click="$modal.hide('statement__error')"
        >
          <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M31.5 10.5l-21 21M10.5 10.5l21 21" stroke="#0E1839" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
        </div>
        <div class="modal__wrapper">
          <div class="modal__title">Проверьте все документы</div>
          <div class="modal__content">
            <div class="modal__form">
              <div class="modal__statement">
                <div class="modal__statement--image">
                  <img src="../../assets/img/statement__image.svg" />
                </div>
                <div class="modal__statement--text">
                  Для отправки комментариев, пожалуйста проверьте все документы
                </div>
              </div>

              <div class="modal__wrapper--footer modal__wrapper--center">
                <button class="next__stage input__button">Хорошо</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { api } from '../../boot/axios'

export default {
  name: 'ViewsTourist',
  data() {
    return {
      itemsGallery: [
        '/favicon.png',
        '/favicon.png',
        '/favicon.png',
        '/favicon.png',
      ],
      index: null,
      commentMessage: null,
      user: JSON.parse(localStorage.getItem('user')),
      application: null,
      headers: [
        {
          text: 'ФИО туриста',
          value: 'fullname',
          sortable: false,
        },
        {
          text: '№ документа',
          value: 'document_number',
          sortable: false,
        },
        {
          text: 'Дата рождения',
          value: 'date_of_birth',
          sortable: false,
        },
        {
          text: 'Вылет от куда',
          value: 'departure_from',
          sortable: false,
        },
        {
          text: 'Вылет куда',
          value: 'departure_to',
          sortable: false,
        },
        {
          text: 'Дата вылета',
          value: 'departure_date',
          sortable: false,
        },
        {
          text: 'Действий',
          value: 'action',
          align: 'center',
        },
      ],
      userDataSave: null,
      loading: false,
      options: {
        itemsPerPage: 1,
      },
      refuse: {
        message: null,
      },
      headersApplicant: [
        {
          text: 'Заявитель',
          value: 'name',
          sortable: false,
        },
        {
          text: 'БИН/ИИН',
          value: 'iin',
          sortable: false,
        },
        {
          text: 'Лицензия',
          value: 'license',
          sortable: false,
        },
        {
          text: 'Лицензия на осуществление туристской операторской деятельности',
          value: 'reference',
          sortable: false,
        },
        {
          text: 'Реквизиты',
          value: 'requisites',
          sortable: false,
        },
      ],
      DataApplicant: [
        {
          name: null,
          iin: null,
          license: '№45Т412543235 до 01.12.2022 г.',
          reference_src: '#',
          requisites_src: '#',
          status: null,
        },
      ],
      tourist: {
        status: null,
      },
      fileDocuments1: {
        fileName: null,
        fileClass: null,
        src: null,
        status: null,
      },
      fileDocuments2: {
        fileName: null,
        fileClass: null,
        src: null,
        status: null,
      },
      fileDocuments3: {
        fileName: null,
        fileClass: null,
        src: null,
        status: null,
      },
      fileDocuments4: {
        fileName: null,
        fileClass: null,
        src: null,
        status: null,
      },
      fileDocuments5: {
        fileName: null,
        fileClass: null,
        src: null,
        status: null,
      },
    }
  },
  methods: {
    client_null(fileDocuments) {
      console.log('client_null')
      fileDocuments.fileClass = ''
    },
    client_success(fileDocuments) {
      console.log('client_success')
      fileDocuments.fileClass = 'add_tourist--success'
    },
    client_close(fileDocuments) {
      console.log('client_close')
      fileDocuments.fileClass = 'add_tourist--error'
    },
    onRegisterApplication() {
      const formData = new FormData()

      formData.append('_method', 'put')

      api
        .post(
          'applications/' + this.$route.params.id + '/registration',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )
        .then((response) => {
          if (response.data.code == 200) {
            this.$notify({
              group: 'foo',
              type: 'success',
              title: 'Заявление успешно зарегистировано',
              position: 'bottom center',
            })

            this.application = response.data
          }
        })
    },
    onConfirm() {
      const formData = new FormData()

      formData.append('_method', 'put')
      formData.append('message', this.commentMessage)

      api
        .post('applications/' + this.$route.params.id + '/confirm', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          if (response.data.code == 200) {
            this.$notify({
              group: 'foo',
              title: 'Заявление успешно одобрен',
              position: 'bottom center',
            })

            this.$router.push('/ru/administrator/administrator')
          }
        })
    },
    onRetrieveApplication() {
      const formData = new FormData()

      formData.append('_method', 'put')
      formData.append('message', this.commentMessage)
      formData.append('tourist[status]', this.tourist.status)
      if (this.fileDocuments1.status)
        formData.append('file_document_1[status]', this.fileDocuments1.status)
      if (this.fileDocuments2.status)
        formData.append('file_document_2[status]', this.fileDocuments2.status)
      if (this.fileDocuments3.status)
        formData.append('file_document_3[status]', this.fileDocuments3.status)
      if (this.fileDocuments4.status)
        formData.append('file_document_4[status]', this.fileDocuments4.status)
      if (this.fileDocuments5.status)
        formData.append('file_document_5[status]', this.fileDocuments5.status)

      api
        .post('applications/' + this.$route.params.id + '/retrieve', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          if (response.data.code == 200) {
            this.$notify({
              group: 'foo',
              title: 'Комментарии успешно отправлены',
              position: 'bottom center',
              type: 'success',
            })

            setTimeout(() => {
              this.$router.push(
                '/' + this.$i18n.locale + '/administrator/administrator',
              )
            }, 2000)
          }
        })
    },
    onRefuse() {
      const formData = new FormData()

      formData.append('_method', 'put')
      formData.append('message', this.refuse.message)

      api
        .post('applications/' + this.$route.params.id + '/refuse', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          if (response.data.code == 200) {
            this.$notify({
              group: 'foo',
              title: 'Заявление успешно отказано',
              position: 'bottom center',
            })

            this.$router.push('/ru/administrator/administrator')
          }
        })
    },
    changeTouristStatus(status) {
      this.tourist.status = status
    },
    changeStatusFileDocuments1(status) {
      this.fileDocuments1.status = status

      if (status == 'success') {
        this.fileDocuments1.fileClass = 'add_tourist--success'
      }

      if (status == 'fail') {
        this.fileDocuments1.fileClass = 'add_tourist--error'
      }
    },
    changeStatusFileDocuments2(status) {
      this.fileDocuments2.status = status

      if (status == 'success') {
        this.fileDocuments2.fileClass = 'add_tourist--success'
      }

      if (status == 'fail') {
        this.fileDocuments2.fileClass = 'add_tourist--error'
      }
    },
    changeStatusFileDocuments3(status) {
      this.fileDocuments3.status = status

      if (status == 'success') {
        this.fileDocuments3.fileClass = 'add_tourist--success'
      }

      if (status == 'fail') {
        this.fileDocuments3.fileClass = 'add_tourist--error'
      }
    },
    changeStatusFileDocuments4(status) {
      this.fileDocuments4.status = status

      if (status == 'success') {
        this.fileDocuments4.fileClass = 'add_tourist--success'
      }

      if (status == 'fail') {
        this.fileDocuments4.fileClass = 'add_tourist--error'
      }
    },
    changeStatusFileDocuments5(status) {
      this.fileDocuments5.status = status

      if (status == 'success') {
        this.fileDocuments5.fileClass = 'add_tourist--success'
      }

      if (status == 'fail') {
        this.fileDocuments5.fileClass = 'add_tourist--error'
      }
    },
  },
  beforeCreate() {
    if (!localStorage.token) {
      this.$router.push('/ru/login')
    }

    /*

		api.get('user').then(response => {
			if (response.data) {
				this.user = response.data;
			}
		});

		*/

    api.get('applications/' + this.$route.params.id).then((response) => {
      if (response.data) {
        if (response.data.data.fields.file_document_1) {
          this.fileDocuments1.fileName =
            response.data.data.fields.file_document_1.name
          this.fileDocuments1.src =
            'https://backend.subsidizing.crocos.kz/' +
            response.data.data.fields.file_document_1.src

          if (
            response.data.data.fields.file_document_1.status[
              this.user.data.role.slug
            ]
          ) {
            this.changeStatusFileDocuments1(
              response.data.data.fields.file_document_1.status[
                this.user.data.role.slug
              ],
            )
          }
        }

        if (response.data.data.fields.file_document_2) {
          this.fileDocuments2.fileName =
            response.data.data.fields.file_document_2.name
          this.fileDocuments2.src =
            'https://backend.subsidizing.crocos.kz/' +
            response.data.data.fields.file_document_2.src

          if (
            response.data.data.fields.file_document_2.status[
              this.user.data.role.slug
            ]
          ) {
            this.changeStatusFileDocuments2(
              response.data.data.fields.file_document_2.status[
                this.user.data.role.slug
              ],
            )
          }
        }

        if (response.data.data.fields.file_document_3) {
          this.fileDocuments3.fileName =
            response.data.data.fields.file_document_3.name
          this.fileDocuments3.src =
            'https://backend.subsidizing.crocos.kz/' +
            response.data.data.fields.file_document_3.src

          if (
            response.data.data.fields.file_document_3.status[
              this.user.data.role.slug
            ]
          ) {
            this.changeStatusFileDocuments3(
              response.data.data.fields.file_document_3.status[
                this.user.data.role.slug
              ],
            )
          }
        }

        if (response.data.data.fields.file_document_4) {
          this.fileDocuments4.fileName =
            response.data.data.fields.file_document_4.name
          this.fileDocuments4.src =
            'https://backend.subsidizing.crocos.kz/' +
            response.data.data.fields.file_document_4.src

          if (
            response.data.data.fields.file_document_4.status[
              this.user.data.role.slug
            ]
          ) {
            this.changeStatusFileDocuments4(
              response.data.data.fields.file_document_4.status[
                this.user.data.role.slug
              ],
            )
          }
        }

        if (response.data.data.fields.file_document_5) {
          this.fileDocuments5.fileName =
            response.data.data.fields.file_document_5.name
          this.fileDocuments5.src =
            'https://backend.subsidizing.crocos.kz/' +
            response.data.data.fields.file_document_5.src

          if (
            response.data.data.fields.file_document_5.status[
              this.user.data.role.slug
            ]
          ) {
            this.changeStatusFileDocuments5(
              response.data.data.fields.file_document_5.status[
                this.user.data.role.slug
              ],
            )
          }
        }

        this.userDataSave = []

        this.userDataSave.push({
          fullname: response.data.data.tourists[0].fullname,
          document_number: response.data.data.tourists[0].document_number,
          date_of_birth: response.data.data.tourists[0].date_of_birth,
          departure_from: response.data.data.tourists[0].departure_from,
          departure_to: response.data.data.tourists[0].departure_to,
          departure_date: response.data.data.tourists[0].departure_date,
        })

        this.DataApplicant = []

        this.DataApplicant.push({
          name: response.data.data.applicant.organization,
          iin: response.data.data.applicant.bin
            ? response.data.data.applicant.bin
            : response.data.data.applicant.iin,
          license: null,
          reference_src:
            'https://backend.subsidizing.crocos.kz/' +
            response.data.data.fields.certificate_of_debt.path,
          requisites: response.data.data.fields.payment_account.number,
        })

        this.application = response.data
      }
    })
  },
}
</script>

<style scoped>
.opacity {
  opacity: 0.5;
}
</style>
